// node_modules
import { FC, useRef, useState } from "react";
// Components
import {
  FileInputButton,
  FindestButton,
  FindestTextBox,
  Modal,
} from "Components";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Styles
import sharedModalStyles from "../modal.module.scss";
import styles from "./addImageModal.module.scss";

type TAddImageModalProps = {
  isOpen: boolean;
  hasCaption: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAddImage: (image: File, caption?: string) => void;
};

export const AddImageModal: FC<TAddImageModalProps> = ({
  hasCaption,
  isOpen,
  setIsOpen,
  onAddImage,
}: TAddImageModalProps) => {
  const [caption, setCaption] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const fileUrlRef = useRef<string>("");

  const onAddImageClick = () => {
    if (!selectedFile) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please select an image."
      );
      return;
    }
    onAddImage(selectedFile, caption);
    resetModalStateAndClose();
  };

  const resetModalStateAndClose = () => {
    // call parent callbacks
    setCaption("");
    setSelectedFile(undefined);
    fileUrlRef.current = "";
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetModalStateAndClose}
      header="Add Image"
      extraClassName={styles.addImageModal}
    >
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Image</div>
        <FileInputButton
          acceptedMIMETypes={[
            "image/png",
            "image/jpeg",
            "image/gif",
            "image/webp",
          ]}
          onFileSelectedAsync={async (file: File) => {
            setSelectedFile(file);
            fileUrlRef.current = URL.createObjectURL(file);
          }}
        />
      </div>
      <div className={styles.aspectRatioBox}>
        <div className={styles.aspectRatioBoxContent}>
          {selectedFile && fileUrlRef.current ? (
            <img
              className={styles.image}
              src={fileUrlRef.current}
              alt="Preview"
            />
          ) : (
            <div>Empty preview</div>
          )}
        </div>
      </div>
      {hasCaption ? (
        <div className={sharedModalStyles.section}>
          <div className={sharedModalStyles.title}>Caption</div>
          <FindestTextBox
            value={caption}
            placeholder={"Add caption text"}
            extraClassNameInputElement={styles.captionInput}
            onChange={setCaption}
          />
        </div>
      ) : null}
      <div className={sharedModalStyles.footer}>
        <FindestButton
          title="Add image"
          onClick={onAddImageClick}
          extraClassName={styles.addButton}
        />
        <FindestButton
          title="Cancel"
          onClick={resetModalStateAndClose}
          extraClassName={styles.cancelButton}
          buttonType="cancel"
        />
      </div>
    </Modal>
  );
};
