// node_modules
import { Editor } from "@tiptap/core";
// Helpers
import { isContentEmpty } from "Helpers";

interface IUseEditorEmptyProps {
  editor: Editor | null;
}

export const useEditorEmpty = ({ editor }: IUseEditorEmptyProps) => {
  return {
    isEditorEmpty: isContentEmpty(editor?.getJSON()),
  };
};
