// node_modules
import { FC, useCallback, useContext, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
// Components
import { FindestTextBox, ObjectChip, Popover } from "Components";
// Controllers
import {
  TenantControllerSingleton,
  TenantUserControllerSingleton,
} from "Controllers";
// Types
import { TTenantDTO, TUserDTO } from "Types";
// Constants
import { GeneralConstants } from "Constants";
// Enums
import { ObjectTypeEnum } from "Enums";
// Contexts
import { AuthContext } from "Providers";

// Component props type
type TTenantSwitcherUserRowProps = {
  user: TUserDTO;
  refreshAllUsersAsync: () => Promise<void>;
};

export const TenantSwitcherUserRow: FC<TTenantSwitcherUserRowProps> = ({
  user,
  refreshAllUsersAsync,
}: TTenantSwitcherUserRowProps) => {
  // Contexts
  const { auth } = useContext(AuthContext);

  // State
  const [tenantsElementReference, setTenantsElementReference] =
    useState<HTMLDivElement | null>(null);
  const [isTenantsPopupOpen, setIsTenantsPopupOpen] = useState<boolean>(false);
  const [searchTenantNameQuery, setSearchTenantNameQuery] = useState("");
  const [allTenants, setAllTenants] = useState<TTenantDTO[]>([]);

  // refresh all tenants async
  const refreshAllTenantsAsync = useCallback(async (query: string) => {
    // set search tenant name query
    setSearchTenantNameQuery(query);

    // if query is empty
    if (!query) {
      // set all tenants
      setAllTenants([]);
      // stop execution, return
      return;
    }

    // retrieve all tenants
    const retrievedAllTenants: TTenantDTO[] =
      await TenantControllerSingleton.getAsync(query, undefined, false, true);

    // set all tenants
    setAllTenants([...retrievedAllTenants]);
  }, []);

  // debounce the refreshAllTenantsAsync function
  const debouncedRefreshAllTenantsAsync = useMemo(
    () => debounce(refreshAllTenantsAsync, GeneralConstants.DEFAULT_MS_DELAY),
    [refreshAllTenantsAsync]
  );

  // on empty tenant name button click handler
  const onEmptyTenantNameButtonClickHandler = async () => {
    // set all tenants
    setAllTenants([]);

    // clear search tenant name query
    setSearchTenantNameQuery("");
  };

  const reloadPageAfterTenantChange = (userEmail: string) => {
    // clean user email
    const cleanedUserEmail = userEmail.toLowerCase().trim();

    // clean auth context user email
    const cleanedAuthContextUserEmail = auth.userEmail.toLowerCase().trim();

    // if user email is equal to auth context user email or includes auth context user email (or vice versa)
    if (
      cleanedUserEmail === cleanedAuthContextUserEmail ||
      cleanedUserEmail.includes(cleanedAuthContextUserEmail) ||
      cleanedAuthContextUserEmail.includes(cleanedUserEmail)
    ) {
      // refresh webpage
      window.location.reload();
    }
  };

  // add user to tenant async
  const addUserToTenantAsync = async (
    tenantId: string,
    userId: string,
    userEmail: string
  ) => {
    // close tenants popup
    setIsTenantsPopupOpen(false);

    // add user to tenant
    const result: TTenantDTO | undefined =
      await TenantUserControllerSingleton.addUserToTenant(tenantId, userId);

    // refresh all users
    await refreshAllUsersAsync();

    // call on empty tenant name button click handler
    onEmptyTenantNameButtonClickHandler();

    // if result is set
    if (result) {
      // reload webpage
      reloadPageAfterTenantChange(userEmail);
    }
  };

  // remove user from tenant async
  const removeUserFromTenantAsync = async (
    tenantId: string,
    userId: string
  ) => {
    // remove user from tenant
    await TenantUserControllerSingleton.removeUserFromTenant(tenantId, userId);

    // refresh all users
    await refreshAllUsersAsync();

    // call on empty tenant name button click handler
    onEmptyTenantNameButtonClickHandler();
  };

  return (
    <tr>
      <td>{user.email}</td>
      <td>
        {user.tenants.map((tenant) => (
          <ObjectChip
            isXMarkVisible
            key={tenant.id}
            object={{
              id: tenant.id,
              name: tenant.name,
              objectType: ObjectTypeEnum.Tenant,
              type: "Tenant",
            }}
            removeIconTitle="Remove tenant"
            onRemoveClick={async () => {
              removeUserFromTenantAsync(tenant.id, user.id);
            }}
          />
        ))}
        <div
          ref={setTenantsElementReference}
          onClick={() => {
            setIsTenantsPopupOpen(true);
          }}
        >
          <span>+ Add tenant</span>
        </div>
        <Popover
          referenceEl={tenantsElementReference}
          isOpen={isTenantsPopupOpen}
          onClickOutside={() => {
            setIsTenantsPopupOpen(false);
          }}
        >
          <div>
            <FindestTextBox
              placeholder={"Tenant name"}
              onChange={debouncedRefreshAllTenantsAsync}
              showEmptyInputCrossIcon={
                searchTenantNameQuery && searchTenantNameQuery.length > 0
                  ? true
                  : false
              }
              leftIcon={faSearch}
              doAutoFocus={true}
              onEmptyInputButtonClickHandler={
                onEmptyTenantNameButtonClickHandler
              }
            />
          </div>
          <div>
            {allTenants.map((tenant) => (
              <ObjectChip
                hideMoreActionsDropdownButton
                key={tenant.id}
                object={{
                  id: tenant.id,
                  name: tenant.name,
                  objectType: ObjectTypeEnum.Tenant,
                  type: "Tenant",
                }}
                removeIconTitle="Add tenant"
                onClick={async () => {
                  addUserToTenantAsync(tenant.id, user.id, user.email);
                }}
              />
            ))}
          </div>
        </Popover>
      </td>
      <td>{user.roles.join(", ")}</td>
    </tr>
  );
};
