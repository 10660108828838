// node_modules
import { useNavigate } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useContext, useMemo } from "react";
// Components
import { DropdownButton, StopOnClickEventPropagation } from "Components";
// Contexts
import { LinkGraphContext, WindowingContext, AuthContext } from "Providers";
// Enums
import { LinksWindowTabsEnum, ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton, UserHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Styles
import styles from "./moreActionsDropdownButton.module.scss";

type TMoreActionsButtonDropdownProps = {
  extraClassNames?: {
    dropdownButton?: string;
    button?: string;
    buttonIconContainer?: string;
    attachmentOptionText?: string;
    buttonText?: string;
    optionsPopover?: string;
    optionsContainer?: string;
    dropdownButtonHover?: string;
  };
  objectType: ObjectTypeEnum;
  objectId: string;
  openReferenceModalProp?: (
    objectId: string,
    objectType: ObjectTypeEnum
  ) => void;
  dropdownPopoverDataIdentifier?: string;
  onDeleteConnection?: () => void;
  navigateCallback?: () => void;
  buttonText?: string;
  onButtonClick?: () => void;
  onClickOptionCallback?: () => void;
  dropdownOnCloseCallback?: () => void;
  deleteConnectionText?: string;
  attachmentOptions?: string[];
  onAttachmentAction?: (action: string) => void;
};

export const MoreActionsDropdownButton: FC<TMoreActionsButtonDropdownProps> = ({
  objectType,
  objectId,
  openReferenceModalProp,
  dropdownPopoverDataIdentifier,
  extraClassNames = {},
  onDeleteConnection,
  navigateCallback,
  buttonText,
  onButtonClick,
  onClickOptionCallback,
  dropdownOnCloseCallback,
  deleteConnectionText = "Delete connection",
  attachmentOptions,
  onAttachmentAction,
}: TMoreActionsButtonDropdownProps) => {
  const optionLabels: string[] = useMemo(() => {
    const options = attachmentOptions || [
      "Open page",
      "Open preview",
      "Open in tree view",
      "Open in list view",
    ];
    if (onDeleteConnection) {
      options.push(deleteConnectionText);
    }
    return options;
  }, [attachmentOptions, onDeleteConnection, deleteConnectionText]);
  // Contexts
  const { onReanchorClick } = useContext(LinkGraphContext);
  const { openGraph, minimizeAllWindows } = useContext(WindowingContext);
  const { auth } = useContext(AuthContext);

  // Hooks
  const navigate = useNavigate();

  // Custom Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

  const openReferenceModal = () => {
    setReferenceModalProps({
      id: objectId,
      type: objectType,
      isOpen: true,
      doIgnoreIsDeleted: false,
    });
  };

  const navigateToObject = useCallback(() => {
    // navigate to the object's page based on its object type
    ObjectTypeHelperSingleton.navigateBasedOnObjectType(
      objectType,
      objectId,
      navigate
    );

    // if minimize all windows is defined
    if (minimizeAllWindows) {
      // call it
      minimizeAllWindows();
    }
  }, [minimizeAllWindows, navigate, objectType, objectId]);

  const onClickOption = (optionName: string) => {
    if (optionName === "Open page") {
      navigateToObject();
      if (navigateCallback) {
        navigateCallback();
      }
    } else if (optionName === "Open preview") {
      if (openReferenceModalProp) {
        openReferenceModalProp(objectId, objectType);
      } else {
        openReferenceModal();
      }
    } else if (optionName === "Open in tree view") {
      onReanchorClick(objectId, objectType, false);
      openGraph(LinksWindowTabsEnum.TreeView);
    } else if (optionName === "Open in list view") {
      onReanchorClick(objectId, objectType, false);
      openGraph(LinksWindowTabsEnum.ListView);
    } else if (optionName === "Delete connection") {
      if (onDeleteConnection) {
        onDeleteConnection();
      }
    } else if (
      optionName === "Replace file" ||
      optionName === "Download file" ||
      optionName === "Delete file"
    ) {
      onAttachmentAction?.(optionName);
    }
    if (onClickOptionCallback) {
      onClickOptionCallback();
    }
  };

  if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
    return null;
  }

  // Render
  return (
    <StopOnClickEventPropagation>
      <DropdownButton
        isButtonEnabled={true}
        showDropdownInPortal
        optionLabels={optionLabels}
        onClickOption={onClickOption}
        onButtonClick={onButtonClick}
        extraClassNames={{
          dropdownButton: `${styles.actionsButton} ${
            extraClassNames?.dropdownButton ?? ""
          }`,
          buttonIconContainer: `${styles.buttonIconContainer} ${
            extraClassNames?.buttonIconContainer ?? ""
          }`,
          optionText: `${styles.optionText} ${
            extraClassNames?.attachmentOptionText ?? ""
          }`,
          buttonText: `${styles.buttonText} ${
            extraClassNames?.buttonText ?? ""
          }`,
          button: `${extraClassNames.button ?? ""}`,
          optionsPopover: extraClassNames.optionsPopover ?? "",
          optionsContainer: extraClassNames.optionsContainer ?? "",
          dropdownButtonHover: `${styles.dropdownButtonHover} ${
            extraClassNames.dropdownButtonHover ?? ""
          }`,
        }}
        iconNameLeft={faEllipsisV}
        showMenuOnlyOnClick
        popoverDataIdentifier={dropdownPopoverDataIdentifier}
        buttonText={buttonText}
        popoverPlacement="bottom-start"
        onCloseCallback={dropdownOnCloseCallback}
      />
      {!openReferenceModalProp && referenceModal}
    </StopOnClickEventPropagation>
  );
};
