// node_modules
import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// Styles
import styles from "./exportSearchResultsButton.module.scss";
// Components
import { FindestButton, Tooltip } from "Components";
// Interfaces
import { IDocumentSearchResult } from "Interfaces";

type TExportSearchResultsButtonProps = {
  title?: string;
  isDisabled?: boolean;
  buttonType?: "primary" | "secondary" | "cancel" | "tertiary";
  searchResults: IDocumentSearchResult[];
  tooltipText: string;
  icon?: IconProp;
  filename: string;
};

export const ExportSearchResultsButton: FC<TExportSearchResultsButtonProps> = ({
  title,
  isDisabled,
  searchResults,
  buttonType,
  tooltipText,
  icon,
  filename,
}: TExportSearchResultsButtonProps) => {
  const [
    isExportSearchResultsButtonTooltipOpen,
    setIsExportSearchResultsButtonTooltipOpen,
  ] = useState<boolean>(false);
  const [
    exportSearchResultsButtonReference,
    setExportSearchResultsButtonReference,
  ] = useState<HTMLDivElement | null>(null);

  const showExportSearchResultsButtonTooltip = () => {
    if (searchResults && searchResults.length !== 0) {
      setIsExportSearchResultsButtonTooltipOpen(true);
    }
  };
  const hideExportSearchResultsButtonTooltip = () => {
    setIsExportSearchResultsButtonTooltipOpen(false);
  };

  // Function to generate CSV content from document results
  const generateCSVContent = (docResults: IDocumentSearchResult[]): string => {
    // CSV header row
    let data = "Id,Title,Abstract,Link,Type\n";
    docResults.forEach((doc) => {
      // Get document ID
      const id = doc.documentId;
      // Get document title
      const tit = doc.title;
      // Get document abstract
      const abstract =
        (doc.mainContents &&
          doc.mainContents
            .find((content) => content.header === "Abstract")
            ?.content.replace(/"/g, '""')) ||
        "";
      // Get document URL
      const link = doc.url;
      // Get document type
      const type = doc.documentType;
      // Add row to CSV data
      data += `"${id}","${tit}","${abstract}","${link}","${type}"\n`;
    });
    return data;
  };

  // Function to download CSV file
  const downloadCSV = (data: string, filename: string) => {
    // Create a Blob with CSV data
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    // Create a link element to trigger download
    const link = document.createElement("a");
    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);
    // Set href and download attributes for the link
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    // Hide the link
    link.style.visibility = "hidden";
    // Append the link to the document body
    document.body.appendChild(link);
    // Simulate click event on the link to trigger download
    link.click();
    // Remove the link from the document body
    document.body.removeChild(link);
    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  };

  const getClassName = (): string => {
    const classNameList = [styles.exportSearchResultsButton];
    if (isDisabled) classNameList.push(styles.disabled);
    if (buttonType) classNameList.push(styles[buttonType]);

    return classNameList.join(" ");
  };

  const onClick = (fname: string) => {
    const filenameToUse = fname || "export.csv";
    const csvContent = generateCSVContent(searchResults);
    downloadCSV(csvContent, filenameToUse);
  };

  return (
    <div
      className={styles.exportDocumentButton}
      onMouseEnter={showExportSearchResultsButtonTooltip}
      onMouseLeave={hideExportSearchResultsButtonTooltip}
      ref={setExportSearchResultsButtonReference}
    >
      <FindestButton
        onClick={() => onClick(filename)}
        extraClassName={getClassName()}
        title={title}
        isDisabled={isDisabled || !searchResults || searchResults.length === 0}
      >
        {icon && (
          <span className={styles.icon}>
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </FindestButton>
      {tooltipText && (
        <Tooltip
          referenceEl={exportSearchResultsButtonReference}
          isOpen={isExportSearchResultsButtonTooltipOpen}
          tooltipText={tooltipText}
          placement="top"
        />
      )}
    </div>
  );
};
