//node_modules
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { Dropdown, FindestTextBox } from "Components";
import { CreateObjectModal } from "./CreateObjectModal";
// Styles
import sharedModalStyles from "Components/Shared/Modals/modal.module.scss";
import styles from "./createObjectModal.module.scss";
// Enums
import { EntityTypeEnum, ObjectTypeEnum } from "Enums";
// Controllers
import {
  EntityControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Types
import { TOption, TOptions } from "Types";
// Helpers
import { EntityTypeHelperSingleton } from "Helpers";
// Constants
import { EntityConstants } from "Constants";
// Interfaces
import { IEntityDTO } from "Interfaces";

// Component props type
type TCreateEntityModalProps = {
  onCreationDone: () => void;
};

// Component
export const CreateEntityModal: FC<TCreateEntityModalProps> = ({
  onCreationDone,
}: TCreateEntityModalProps) => {
  // Hooks
  const navigate = useNavigate();

  // State
  const [selectedOption, setSelectedOption] = useState<TOption<EntityTypeEnum>>(
    EntityTypeHelperSingleton.defaultEntityTypeOption
  );
  const [customTypeName, setCustomTypeName] = useState<string>("");
  const [allEntityTypesDropdownOptions, setAllEntityTypesDropdownOptions] =
    useState<TOptions<EntityTypeEnum>[]>([]);

  // Logic
  const onCreateClickAsync = useCallback(
    async (entityName: string) => {
      // create entity dto
      const newEntity = {
        title: entityName,
        type: selectedOption.value,
        customTypeName: customTypeName,
      } as IEntityDTO;

      // create entity in db
      const createdEntity = await EntityControllerSingleton.createAsync(
        newEntity
      );

      // safety-checks
      if (!createdEntity) {
        return;
      }

      // reset state and close modal
      resetModalStateAndClose();

      // go to created entity page
      navigate(`/library/entities/${createdEntity.id}`);

      // call onCreationDone callback
      onCreationDone();
    },
    [selectedOption.value, customTypeName, navigate, onCreationDone]
  );

  const refreshCustomEntityTypesAsync = useCallback(async () => {
    const allEntityTypeDropdownOptionsGroups =
      await EntityTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(
        true,
        false
      );
    setAllEntityTypesDropdownOptions(allEntityTypeDropdownOptionsGroups);
  }, []);

  useEffect(() => {
    refreshCustomEntityTypesAsync();
  }, [refreshCustomEntityTypesAsync]);

  const getIsCreateButtonDisabled = useCallback(() => {
    // if entity type is custom and custom type name is not set, disable create button
    if (
      selectedOption.value === EntityTypeEnum.Custom &&
      selectedOption.title === EntityConstants.CREATE_CUSTOM_TYPE_OPTION
    ) {
      return customTypeName.length < 1;
    }

    // otherwise, do not disable create button
    return false;
  }, [customTypeName.length, selectedOption.title, selectedOption.value]);

  const resetModalStateAndClose = () => {
    setSelectedOption(EntityTypeHelperSingleton.defaultEntityTypeOption);
    setCustomTypeName("");
  };

  const handleNewEntityTypeSelected = (option: TOption<EntityTypeEnum>) => {
    // if a custom type is selected
    if (option.value === EntityTypeEnum.Custom) {
      // if custom type is the one to create a new custom type
      if (option.title === EntityConstants.CREATE_CUSTOM_TYPE_OPTION) {
        // set custom type name to empty string
        setCustomTypeName("");
      } else {
        // otherwise set custom type name
        setCustomTypeName(option.title);
      }
    }
    // set selected option
    setSelectedOption(option);
  };

  return (
    <CreateObjectModal
      buttonText="Create entity"
      suggestionPlaceholder="Enter entity title"
      suggestionTitle="Similar entities in the universe"
      searchForSuggestionsAsync={async (suggestionValue: string) => {
        return await SearchControllerSingleton.searchEntitiesAsync(
          suggestionValue
        );
      }}
      fromObjectToSuggestionBuilder={(entity: IEntityDTO) => {
        return {
          id: entity.id,
          name: entity.title,
          objectType: ObjectTypeEnum.Entity,
          type: EntityTypeHelperSingleton.getEntityTypeDisplayName(
            entity.type,
            entity.customTypeName
          ),
        };
      }}
      navigateToSuggestionPath="/library/entities/"
      isCreateButtonDisabled={getIsCreateButtonDisabled()}
      onCreateClickAsync={onCreateClickAsync}
      closeModal={onCreationDone}
    >
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Entity Type</div>
        <Dropdown
          selectedOption={selectedOption}
          handleOptionSelect={handleNewEntityTypeSelected}
          options={allEntityTypesDropdownOptions}
          placeholderText="Select entity type"
          extraClassNames={{
            positionedPopup: styles.objectTypeDropdownPopup,
            groupedList: {
              groupedListContainer: styles.groupedListContainer,
              groupedList: styles.groupedList,
              option: styles.dropdownOption,
              groupTitle: styles.dropdownGroupTitle,
            },
            dropdownSelectText: styles.dropdownSelectText,
          }}
        />
        {selectedOption.value === EntityTypeEnum.Custom &&
          selectedOption.title ===
            EntityConstants.CREATE_CUSTOM_TYPE_OPTION && (
            <FindestTextBox
              value={customTypeName}
              onChange={(text: string) => setCustomTypeName(text)}
              placeholder={"Type custom type name..."}
              extraClassName={styles.customTypeNameTextBox}
            />
          )}
      </div>
    </CreateObjectModal>
  );
};
