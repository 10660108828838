// node_modules
import { FC, KeyboardEvent } from "react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { CollapsibleList } from "./CollapsibleList";
// styles
import styles from "./collapsibleList.module.scss";
// Interfaces
import { ICollapsibleListItemProps } from "Interfaces";

export const CollapsibleListItem: FC<ICollapsibleListItemProps> = ({ item, collapsedItems, onToggle, onClick, ItemRenderer }: ICollapsibleListItemProps) => {
  const hasChildren = item.children && item.children.length > 0;
  const isCollapsed = collapsedItems?.has(item.id);

  const handleClick = () => {
    onClick(item.id);
  };

  const handleListItemOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const handleToggleIconContainerKeyDown = (e: KeyboardEvent) => {
    if (e.key !== "Enter" || !onToggle) return;
    e.preventDefault();
    e.stopPropagation();
    onToggle(item.id);
  };

  return (
    <>
      <li
        className={styles.collapsibleListItem}
        onKeyDown={handleListItemOnKeyDown}
        role="button"
        tabIndex={0}
        onClick={handleClick}
      >
        <div className={`${[2, 3].indexOf(item.level) !== -1 ? styles[`heading-${item.level}`] : ""} ${item.isActive ? styles.isActive : ""} ${styles.collapsibleListItemInner}`}>
          <ItemRenderer item={item}>
            {hasChildren && (
              <div
                onClick={onToggle ? (e) => { e.preventDefault(); e.stopPropagation(); onToggle(item.id); } : undefined}
                onKeyDown={onToggle ? handleToggleIconContainerKeyDown : undefined}
                role="button"
                tabIndex={0}
                className={styles.collapsibleListItemIconContainer}
              >
                <FontAwesomeIcon
                  icon={isCollapsed ? faChevronRight : faChevronDown}
                />
              </div>
            )}
          </ItemRenderer>
        </div>
      </li>
      {!isCollapsed && hasChildren && (
        <CollapsibleList
          items={item.children}
          collapsedItems={collapsedItems}
          onToggle={onToggle}
          onClick={onClick}
          ItemRenderer={ItemRenderer}
        />
      )}
    </>
  );
};
