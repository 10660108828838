// node_modules
import { Placement } from "@floating-ui/react-dom";
import { FC, ReactNode } from "react";
// Styles
import styles from "./tooltip.module.scss";
// Components
import { Popover } from "Components";

export type TooltipProps = {
    referenceEl: Element | null,
    placement?: Placement | undefined,
    isOpen: boolean,
    tooltipText?: string,
    popoverOffset?: number,
    children?: ReactNode
};

export const Tooltip: FC<TooltipProps> = ({ referenceEl, placement, isOpen, tooltipText, popoverOffset, children }) => {
    return (
        <Popover
            referenceEl={referenceEl}
            isOpen={isOpen}
            placement={placement}
            extraClassName={styles.tooltip}
            showInPortal
            popoverOffset={popoverOffset}
        >
            {!children ? <h4>{tooltipText}</h4> : children}
        </Popover>
    );
};