// CustomTable.ts
import { Table } from "@tiptap/extension-table";
// Styles
import "./customTable.scss";

export const CustomTable = Table.extend({
  renderHTML({ HTMLAttributes }) {
    return ["div", { class: "tableWrapper" }, ["table", HTMLAttributes, 0]];
  },
}).configure({
  resizable: true,
  lastColumnResizable: false,
});
