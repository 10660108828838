// node_modules
import { Editor } from "@tiptap/core";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
// Interfaces
import { ICommand, IGroup } from "Interfaces";
// Components
import { EditorMenuAIToolsCommand, EditorMenuGroups } from "./EditorMenuItems";
// Hooks
import { useOverviewTable, useSelectionNode } from "Hooks";
// Providers
import { AuthContext, EditorContext } from "Providers";
// Helpers
import {
  ADVANCED_GROUP,
  AI_GROUP,
  ASK_IGOR_COMMAND,
  EditorHelperSingleton,
  INSERT_FILE_COMMAND,
  INSERT_IMAGE_COMMAND,
  INSERT_INTAKE_SHEET_COMMAND,
  INSERT_MATURITY_RADAR_COMMAND,
  INSERT_REQUIREMENTS_TABLE_COMMAND,
  INSERT_RESULTS_OVERVIEW_TABLE_COMMAND,
} from "Helpers";
//Enums
import { ObjectTypeEnum } from "Enums";
//Styles`
import styles from "./slashMenu.module.scss";

export interface ISlashMenuProps {
  editor: Editor;
  items: IGroup[];
  command: (command: ICommand) => void;
}

export const SlashMenu = forwardRef(
  ({ editor, items, command }: ISlashMenuProps, ref) => {
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
    const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

    const {
      setAskIgorModalOptions,
      setIsAddImageModalOpen,
      objectEdited,
      openFileUploadModal,
      setIsOverviewTableModalOpen,
      setIsRequirementsTableModalOpen,
      setIsMaturityRadarModalOpen,
    } = useContext(EditorContext);
    const { hasAdvanced } = useContext(AuthContext);

    const selectionNodeData = useSelectionNode({ editor });
    const { getIsOverviewTableAlreadyInDocument } = useOverviewTable();

    const currentItems = useMemo(() => {
      let result = [...items];
      if (!hasAdvanced) {
        result = result.filter((group) => group.label !== ADVANCED_GROUP.label);
      }

      if (hasAdvanced && objectEdited?.objectType !== ObjectTypeEnum.Study) {
        result.forEach((group) => {
          group.commands = group.commands.filter(
            (groupCommand) =>
              groupCommand.label !== INSERT_INTAKE_SHEET_COMMAND.label
          );
        });
      }

      result = result.filter((group) => group.commands.length > 0);

      return result;
    }, [items, hasAdvanced, objectEdited?.objectType]);

    const isAIGroupInItems: boolean = currentItems.some(
      (group) => group.label === AI_GROUP.label
    );
    const editorMenuGroups: IGroup[] = currentItems.filter(
      (group) => group.label !== AI_GROUP.label
    );

    const suggestionCommand = command;

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowDown") {
          if (!currentItems.length) {
            return false;
          }

          const commands = currentItems[selectedGroupIndex].commands;

          let newCommandIndex = selectedCommandIndex + 1;
          let newGroupIndex = selectedGroupIndex;

          if (commands.length - 1 < newCommandIndex) {
            newCommandIndex = 0;
            newGroupIndex = selectedGroupIndex + 1;
          }

          if (currentItems.length - 1 < newGroupIndex) {
            newGroupIndex = 0;
          }

          setSelectedCommandIndex(newCommandIndex);
          setSelectedGroupIndex(newGroupIndex);

          return true;
        }

        if (event.key === "ArrowUp") {
          if (!currentItems.length) {
            return false;
          }

          let newCommandIndex = selectedCommandIndex - 1;
          let newGroupIndex = selectedGroupIndex;

          if (newCommandIndex < 0) {
            newGroupIndex = selectedGroupIndex - 1;
            newCommandIndex =
              currentItems[newGroupIndex]?.commands.length - 1 || 0;
          }

          if (newGroupIndex < 0) {
            newGroupIndex = currentItems.length - 1;
            newCommandIndex = currentItems[newGroupIndex].commands.length - 1;
          }

          setSelectedCommandIndex(newCommandIndex);
          setSelectedGroupIndex(newGroupIndex);

          return true;
        }

        if (event.key === "Enter") {
          if (
            !currentItems.length ||
            selectedGroupIndex === -1 ||
            selectedCommandIndex === -1
          ) {
            return false;
          }

          const command =
            currentItems[selectedGroupIndex].commands[selectedCommandIndex];

          commandOnClickHandler(command);

          return true;
        }

        return false;
      },
    }));

    const selectedCommand: ICommand =
      currentItems[selectedGroupIndex]?.commands[selectedCommandIndex];

    const commandOnClickHandler = useCallback(
      (clickedCommand: ICommand) => {
        suggestionCommand(clickedCommand);

        const defaultInput: string =
          EditorHelperSingleton.getHeadingText(
            editor,
            editor.$pos(editor.state.selection.$from.pos)
          ) ?? "";

        switch (clickedCommand.name) {
          case INSERT_RESULTS_OVERVIEW_TABLE_COMMAND.name:
            if (getIsOverviewTableAlreadyInDocument(editor)) return;
            setIsOverviewTableModalOpen(true);
            break;
          case ASK_IGOR_COMMAND.name:
            setAskIgorModalOptions((prevAskIgorModalOptions) => {
              return {
                ...prevAskIgorModalOptions,
                selectedMenuItem: undefined,
                defaultInput,
                isOpen: true,
              };
            });
            break;
          case INSERT_IMAGE_COMMAND.name:
            setIsAddImageModalOpen(true);
            break;
          case INSERT_FILE_COMMAND.name:
            openFileUploadModal();
            break;
          case INSERT_REQUIREMENTS_TABLE_COMMAND.name:
            setIsRequirementsTableModalOpen(true);
            break;
          case INSERT_MATURITY_RADAR_COMMAND.name:
            setIsMaturityRadarModalOpen(true);
            break;
          default:
            break;
        }
      },
      [
        editor,
        getIsOverviewTableAlreadyInDocument,
        openFileUploadModal,
        setAskIgorModalOptions,
        setIsAddImageModalOpen,
        setIsMaturityRadarModalOpen,
        setIsOverviewTableModalOpen,
        setIsRequirementsTableModalOpen,
        suggestionCommand,
      ]
    );

    useEffect(() => {
      setSelectedGroupIndex(0);
      setSelectedCommandIndex(0);
    }, [currentItems]);

    if (!currentItems.length) {
      return null;
    }

    return (
      <div
        className={`${styles.slashMenuContentContainer} ${
          isAIGroupInItems || editorMenuGroups.length > 0
            ? styles.hasContent
            : ""
        }`}
      >
        {isAIGroupInItems && (
          <EditorMenuAIToolsCommand
            selectedCommand={selectedCommand}
            extraClassNames={{
              editorMenuCommand: styles.slashMenuAIToolsCommand,
            }}
            isFromSlashMenu={true}
            editor={editor}
          />
        )}
        {isAIGroupInItems && editorMenuGroups.length > 0 && (
          <div className={styles.slashMenuSpacer} />
        )}
        {editorMenuGroups.length > 0 && (
          <div
            className={`${styles.editorMenuGroupsContainer} ${
              !isAIGroupInItems ? styles.hasNoUpperContent : ""
            }`}
          >
            <EditorMenuGroups
              selectedCommand={selectedCommand}
              groups={editorMenuGroups}
              editor={editor}
              commandOnClickHandler={commandOnClickHandler}
              doShowTooltip={false}
              actionOptions={{
                nodePos: selectionNodeData
                  ? selectionNodeData.fromNodePos
                  : undefined,
                node: selectionNodeData
                  ? selectionNodeData.fromNode
                  : undefined,
                isFromSlashMenu: true,
              }}
            />
          </div>
        )}
      </div>
    );
  }
);

SlashMenu.displayName = "SlashMenu";
