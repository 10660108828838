// node_modules
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  FileInputButton,
  FindestButton,
  LoadingStatusIndicator,
} from "Components";
// Controllers
import { ImportControllerSingleton } from "Controllers";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";

// Component
export const Import: FC = () => {
  // Hooks
  const navigate = useNavigate();

  // State
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [isImporting, setIsImporting] = useState<boolean>(false);

  // Logic
  const onImportFileClickAsync = async (): Promise<void> => {
    // safety-checks
    if (!selectedFile) {
      // stop execution, return
      return;
    }

    // set is importing to true
    setIsImporting(true);

    // import file
    const result: boolean = await ImportControllerSingleton.importAsync(
      selectedFile
    );

    // if result is true
    if (result) {
      // show success message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Success,
        "File imported successfully."
      );

      // navigate to admin page
      navigate("/admin");
    } else {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "File import failed."
      );
      // set selected file to undefined
      setSelectedFile(undefined);
    }

    // set is importing to false
    setIsImporting(false);
  };

  // Render
  return (
    <div>
      <div
        onClick={() => {
          navigate("/admin");
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div>
        <h1>Import</h1>
        <FileInputButton
          acceptedMIMETypes={["application/json"]}
          onFileSelectedAsync={async (file: File) => {
            setSelectedFile(file);
          }}
        />
      </div>
      <div>
        <FindestButton
          title="Import file"
          isDisabled={!selectedFile || isImporting}
          onClick={onImportFileClickAsync}
        />
        {isImporting && (
          <div>
            <LoadingStatusIndicator size={30} status={1} />
          </div>
        )}
      </div>
    </div>
  );
};
