// node_modules
import { ChangeEvent } from "react";
// Helpers
import { FileHelperSingleton } from "Helpers";

interface IUseFileUploadProps {
  onFileSelectedAsync?: (file: File) => Promise<void>;
  fileSizeLimit?: number;
  acceptedMIMETypes?: string[];
}

export const useFileUpload = ({
  onFileSelectedAsync,
  fileSizeLimit = 2048000,
  acceptedMIMETypes,
}: IUseFileUploadProps) => {
  const onFileInputChangeAsync = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!FileHelperSingleton.isFileUploaded(event)) return;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const file = event.target.files![0];

    if (
      !FileHelperSingleton.isFileFormatAccepted(file, acceptedMIMETypes ?? [])
    ) {
      return;
    }

    if (FileHelperSingleton.isFileSizeTooLarge(file, fileSizeLimit)) {
      return;
    }

    if (onFileSelectedAsync) await onFileSelectedAsync(file);
  };

  return {
    onFileInputChangeAsync,
  };
};
