// node_modules
import { faListTree } from "@fortawesome/pro-regular-svg-icons";
import {
  faExpand,
  faSitemap,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, useEditor } from "@tiptap/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Interfaces
import { IEntityDTO, IObject, ISavedDocumentDTO, IStudyDTO } from "Interfaces";
// Components
import { EditorContent, FindestButton } from "Components";
// Contexts
import { LinkGraphContext, WindowingContext } from "Providers";
// Controllers
import {
  DocumentObjectTypeEnums,
  LinksWindowTabsEnum,
  ObjectTypeEnum,
} from "Enums";
// Helpers
import {
  EntityTypeHelperSingleton,
  ExtensionsKit,
  GetCustomLink,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  SET_CONTENT_COMMAND,
  StudyTypeHelperSingleton,
} from "Helpers";
// Types
import { TReferenceModalProps } from "Types";
// Styles
import referenceModalStyles from "./referenceModal.module.scss";
// Controllers
import {
  EntityControllerSingleton,
  SavedDocumentControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
// Custom hooks
import { useLoadURL } from "Hooks";

interface IReferenceCard {
  id?: string;
  type?: ObjectTypeEnum;
  url?: string;
  onClickCallback?: () => void;
  setReferenceModalProps: Dispatch<SetStateAction<TReferenceModalProps>>;
  doIgnoreIsDeleted?: boolean;
}

export const ReferenceCard: FC<IReferenceCard> = ({
  id,
  type,
  url,
  onClickCallback,
  setReferenceModalProps,
  doIgnoreIsDeleted,
}: IReferenceCard) => {
  const [reference, setReference] = useState<IObject | undefined>(undefined);

  const { onReanchorClick } = useContext(LinkGraphContext);
  const { openGraph, minimizeAllWindows } = useContext(WindowingContext);

  const { loadImagesAsync, loadFilesAsync, loadRatingsAsync } = useLoadURL();
  const navigate = useNavigate();

  const referenceCardEditor = useEditor(
    {
      extensions: [...ExtensionsKit, GetCustomLink(navigate)],
      editable: false,
    },
    [reference?.description, reference?.objectType]
  );

  useEffect(() => {
    if (!referenceCardEditor || !reference) {
      return;
    }

    let newContent: Content = "";
    if (
      [ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
        reference.objectType
      )
    ) {
      try {
        newContent = reference.description
          ? JSON.parse(reference.description)
          : "";
      } catch (error) {
        newContent = reference.description ?? "";
      }
    }

    SET_CONTENT_COMMAND.action(referenceCardEditor, {
      content: newContent,
    });

    (async () => {
      await loadImagesAsync(referenceCardEditor);
      await loadFilesAsync(referenceCardEditor);
      await loadRatingsAsync(
        referenceCardEditor,
        reference.id,
        reference.objectType
      );
    })();
  }, [
    loadFilesAsync,
    loadImagesAsync,
    loadRatingsAsync,
    reference,
    reference?.description,
    referenceCardEditor,
  ]);

  useEffect(() => {
    if (!type) {
      setReference(undefined);
      return;
    }

    (async () => {
      LogHelperSingleton.log("ShowReferencePopover");

      if (!id) {
        if (!url) return;
        setReference({
          id: "",
          title: url ?? "",
          description: "",
          savedDocuments: [],
          totalDocumentsCount: 0,
          createdByUsername: "",
          createdOnDate: "",
          linkedFiles: [],
          referencedBy: [],
          isLocked: false,
          objectType: type,
        });

        return;
      }

      if (type === ObjectTypeEnum.Entity) {
        const entity: IObject | undefined =
          await EntityControllerSingleton.getByIdAsync(id, doIgnoreIsDeleted);

        if (!entity) return;

        setReference({ ...entity, objectType: ObjectTypeEnum.Entity });
      } else if (type === ObjectTypeEnum.Study) {
        const study: IObject | undefined =
          await StudyControllerSingleton.getByIdAsync(id);

        if (!study) return;

        setReference({ ...study, objectType: ObjectTypeEnum.Study });
      } else if (DocumentObjectTypeEnums.includes(type)) {
        const savedDocument: ISavedDocumentDTO | undefined =
          await SavedDocumentControllerSingleton.getByIdAsync(id);

        if (!savedDocument) return;

        setReference({
          id: savedDocument.id,
          title: savedDocument.title,
          description: "",
          savedDocuments: [],
          totalDocumentsCount: 0,
          createdByUsername: savedDocument.createdByUsername ?? "",
          createdOnDate: savedDocument.dateAdded.toString(),
          linkedFiles: [],
          referencedBy: [],
          isLocked: false,
          objectType: type,
        });
      }
    })();
  }, [doIgnoreIsDeleted, id, type, url]);

  const getReferenceTypeDisplay = (
    currentReference: IObject,
    currentReferenceType: ObjectTypeEnum
  ): string => {
    let referenceTypeDisplay = "";

    switch (currentReferenceType) {
      case ObjectTypeEnum.Entity:
        referenceTypeDisplay =
          EntityTypeHelperSingleton.getEntityTypeDisplayName(
            (currentReference as IEntityDTO).type,
            (currentReference as IEntityDTO).customTypeName
          );
        break;
      case ObjectTypeEnum.Study:
        referenceTypeDisplay = StudyTypeHelperSingleton.getStudyTypeDisplayName(
          (currentReference as IStudyDTO).type,
          (currentReference as IStudyDTO).customTypeName
        );
        break;
      case ObjectTypeEnum.ScienceArticle:
      case ObjectTypeEnum.UsPatent:
      case ObjectTypeEnum.MagPatent:
      case ObjectTypeEnum.Weblink:
        referenceTypeDisplay =
          ObjectTypeHelperSingleton.getObjectTypeDisplayName(
            currentReferenceType
          );
        break;
      default:
        break;
    }

    return referenceTypeDisplay;
  };

  const onOpenPreviewClick = useCallback(() => {
    if (!reference) return;

    setReferenceModalProps((prevProps: TReferenceModalProps) => ({
      ...prevProps,
      isOpen: true,
      id: reference.id,
      type: reference.objectType,
    }));

    LogHelperSingleton.log("OpenReferencePreview");

    if (onClickCallback) onClickCallback();
  }, [onClickCallback, reference, setReferenceModalProps]);

  const onOpenPageClick = useCallback(() => {
    if (!reference) return;

    if (onClickCallback) onClickCallback();

    ObjectTypeHelperSingleton.navigateBasedOnObjectType(
      reference.objectType,
      reference.id,
      navigate
    );

    LogHelperSingleton.log("OpenReferencePage");

    if (minimizeAllWindows) {
      minimizeAllWindows();
    }
  }, [minimizeAllWindows, navigate, reference, onClickCallback]);

  const onOpenInTreeViewClick = () => {
    if (!reference) return;

    if (onClickCallback) onClickCallback();

    onReanchorClick(reference.id, reference.objectType, false);
    openGraph(LinksWindowTabsEnum.TreeView);

    LogHelperSingleton.log("OpenReferenceInTreeView");
  };

  const onOpenInListViewClick = () => {
    if (!reference) return;

    if (onClickCallback) onClickCallback();

    onReanchorClick(reference.id, reference.objectType, false);
    openGraph(LinksWindowTabsEnum.ListView);

    LogHelperSingleton.log("OpenReferenceInListView");
  };

  if (!reference) return null;

  return (
    <div className={`${referenceModalStyles.referencePopover}`}>
      <div className={referenceModalStyles.popoverContent}>
        <div className={referenceModalStyles.section}>
          <div className={referenceModalStyles.properties}>
            <div className={referenceModalStyles.type}>
              <FontAwesomeIcon
                className={`${referenceModalStyles.objectIcon} ${
                  referenceModalStyles[
                    ObjectTypeHelperSingleton.getObjectTypeClassName(
                      reference.objectType
                    )
                  ]
                }`}
                icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                  reference.objectType
                )}
              />
              <h6>
                {getReferenceTypeDisplay(reference, reference.objectType)}
              </h6>
            </div>
            <div
              className={`${referenceModalStyles.title} ${
                reference.objectType === ObjectTypeEnum.Entity
                  ? referenceModalStyles.entity
                  : ""
              }`}
            >
              <h2 className={referenceModalStyles.titleText}>
                {reference.title}
              </h2>
            </div>
          </div>
        </div>
        {[ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
          reference.objectType
        ) ? (
          <>
            <div className={referenceModalStyles.popoverBody}>
              <div className={referenceModalStyles.markdownContainer}>
                <EditorContent
                  editor={referenceCardEditor}
                  className="compactEditorContent"
                />
              </div>
              <div className={referenceModalStyles.entityLikeImageContainer}>
                {reference.images && reference.images.length > 0 && (
                  <div
                    className={
                      referenceModalStyles.entityLikeImageAspectRatioBox
                    }
                  >
                    <div
                      className={
                        referenceModalStyles.entityLikeImageAspectRatioBoxContent
                      }
                    >
                      {
                        <img
                          className={referenceModalStyles.entityLikeImage}
                          src={reference.images[0].path}
                          alt={reference.images[0].caption}
                        />
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={referenceModalStyles.moreActionsButtons}>
              <FindestButton
                styleProps={["noWrap"]}
                buttonType="secondary"
                title="Open page"
                onClick={onOpenPageClick}
                leftIconName={faUpRightAndDownLeftFromCenter}
              />
              <FindestButton
                styleProps={["noWrap"]}
                buttonType="secondary"
                title="Open preview"
                onClick={onOpenPreviewClick}
                leftIconName={faExpand}
              />
              <FindestButton
                styleProps={["noWrap"]}
                buttonType="secondary"
                title="Open in tree view"
                onClick={onOpenInTreeViewClick}
                leftIconName={faSitemap}
              />
              <FindestButton
                styleProps={["noWrap"]}
                buttonType="secondary"
                title="Open in list view"
                onClick={onOpenInListViewClick}
                leftIconName={faListTree}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
