// node_modules
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FC, useCallback } from "react";
// Styles
import styles from "./deletableTextbox.module.scss";

type TDeletableTextboxProps = {
  text: string;
  index: number;
  onChange: (index: number, newText: string) => void;
  onDelete: (index: number) => void;
};

export const DeletableTextbox: FC<TDeletableTextboxProps> = ({
  text,
  index,
  onChange,
  onDelete,
}: TDeletableTextboxProps) => {
  // Logic
  const onClickDeleteHandler = useCallback(() => {
    // call parent onDelete
    onDelete(index);
  }, [index, onDelete]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // get event target value
      const eventTargetValue = event.target.value;

      // call parent onChange
      onChange(index, eventTargetValue);
    },
    [index, onChange]
  );

  // Render
  return (
    <div className={styles.deletableTextbox}>
      <input
        className={styles.inputField}
        type="text"
        value={text}
        onChange={onChangeHandler}
      />
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.deleteIcon}
        onClick={onClickDeleteHandler}
      />
    </div>
  );
};
