// node_modules
import {
  faCopy,
  faHighlighter,
  faSatellite,
  faSearchPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { JSONContent } from "@tiptap/react";
import {
  CSSProperties,
  FC,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// Components
import { PositionedPopup, RolesChecker } from "Components";
// Styles
import styles from "./textSelectionMenuPopup.module.scss";
// Custom hooks
import {
  DocumentControllerSingleton,
  HighlightControllerSingleton,
} from "Controllers";
import {
  EntityTypeEnum,
  LogFeatureNameEnum,
  RolesEnum,
  SavedDocumentTypeEnum,
  TextSelectionMenuPopupItemEnum,
  ToastTypeEnum,
} from "Enums";
import {
  EnvironmentVariableHelperSingleton,
  LogHelperSingleton,
  NavigatorHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
import { useClickOutsideRef, useTextSelection } from "Hooks";
import { IEntityDTO } from "Interfaces";
import { QueryContext } from "Providers";
import { TextSelectionMenuPopupItem } from "./TextSelectionMenuPopupItem";
// Types
import { THighlightDTO } from "Types";
// Constants
import { EditorConstants } from "Constants";

export type TTextSelectionMenuPopupProps = {
  selectedText: string;
  selectionBoundingClientRect: DOMRect | undefined;
  documentId: string | number;
  documentType: SavedDocumentTypeEnum;
  disabledItems?: TextSelectionMenuPopupItemEnum[];
  scrollPositionTop?: number;
  scrollHeight?: number;
  addedObject?: (object: IEntityDTO) => void;
  onCreateDocumentHighlight?: (highlight: THighlightDTO) => void;
  saveAsEntityMenuItemDisplayValue?: string;
  isDocumentAlreadySaved: boolean;
};

export const TextSelectionMenuPopup: FC<TTextSelectionMenuPopupProps> = ({
  selectedText,
  selectionBoundingClientRect,
  documentId,
  documentType,
  disabledItems,
  addedObject,
  scrollPositionTop,
  scrollHeight,
  onCreateDocumentHighlight,
  saveAsEntityMenuItemDisplayValue,
  isDocumentAlreadySaved,
}: TTextSelectionMenuPopupProps) => {
  // Context
  const { query, setQuery } = useContext(QueryContext);
  // Custom hooks
  const { unselect } = useTextSelection();
  // Ref
  const textSelectionMenuPopupContainerRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  // State
  const [isMenuDisplayed, setIsMenuDisplayed] = useState<boolean>(false);

  // Logic
  const resetMenuDisplay = useCallback((): void => {
    setIsMenuDisplayed(false);
    unselect();
  }, [unselect]);

  useClickOutsideRef(textSelectionMenuPopupContainerRef, () =>
    setIsMenuDisplayed(false)
  );

  useEffect(() => {
    if (!selectedText || !selectionBoundingClientRect) {
      resetMenuDisplay();
      return;
    }
    setIsMenuDisplayed(true);
  }, [resetMenuDisplay, selectedText, selectionBoundingClientRect]);

  const isItemEnabled = (item: TextSelectionMenuPopupItemEnum): boolean => {
    if (!disabledItems) {
      return true;
    }

    for (const disabledItem of disabledItems) {
      if (disabledItem === item) {
        return false;
      }
    }

    return true;
  };

  const getTextSelectionMenuPopupPosition = (): CSSProperties => {
    // Calculation of the height of the text selection popup menu
    let popupHeight = 8;
    isItemEnabled(TextSelectionMenuPopupItemEnum.Copy)
      ? (popupHeight = popupHeight + 40)
      : null;
    isItemEnabled(TextSelectionMenuPopupItemEnum.AddAsSearchTerm)
      ? (popupHeight = popupHeight + 40)
      : null;
    isItemEnabled(TextSelectionMenuPopupItemEnum.SaveAsEntity)
      ? (popupHeight = popupHeight + 40)
      : null;
    isItemEnabled(TextSelectionMenuPopupItemEnum.SaveAsHighlight)
      ? (popupHeight = popupHeight + 40)
      : null;
    // Scroll position top of the modal
    const scrollposition = scrollPositionTop ? scrollPositionTop : 0;
    const scrollContainerHeight = scrollHeight
      ? scrollHeight
      : window.innerHeight * 0.9;
    // Calculate the dimensions of the modal and the margins to the edges of the screen
    let modalWidth;
    if (window.innerWidth * 0.8 < 1200) {
      modalWidth = window.innerWidth * 0.8;
    } else {
      modalWidth = 1200;
    }
    const marginLeft = (window.innerWidth - modalWidth) / 2;
    const marginTop = (window.innerHeight * 0.1) / 2;
    // Calculate the popup position
    const popupPositionStyle: CSSProperties = {};
    popupPositionStyle.position = "absolute";
    popupPositionStyle.margin = "0";

    if (selectionBoundingClientRect) {
      // Calculate to place the modal in the middle of the selected text
      popupPositionStyle.left = `${
        selectionBoundingClientRect.left -
        marginLeft +
        selectionBoundingClientRect.width / 2 -
        198 / 2
      }px`;
      // If there is enough space for the popup to be placed at the bottom of the selected text:
      // Place the popup at the bottom of the selected text
      if (
        scrollContainerHeight -
          (scrollposition + selectionBoundingClientRect.bottom - marginTop) >
        popupHeight + 16
      ) {
        popupPositionStyle.top = `${
          scrollposition + selectionBoundingClientRect.bottom - marginTop + 4
        }px`;
        // Else place the popup at the top of the selected text:
        // Place the popup at the top of the selected text
      } else {
        popupPositionStyle.top = `${
          scrollposition +
          selectionBoundingClientRect.top -
          marginTop -
          popupHeight -
          4
        }px`;
      }
    }
    return popupPositionStyle;
  };

  const copySelectedTextToClipboard = async () => {
    // Copy the selected text to the clipboard
    await NavigatorHelperSingleton.copyToClipboardAsync(selectedText);
    // Show a success message
    ToastHelperSingleton.showToast(
      ToastTypeEnum.Success,
      "Text copied to clipboard."
    );
    // Clear the selected text popup
    resetMenuDisplay();
  };

  const addSelectedTextAsSearchTerm = async () => {
    // Check if a query has been set to add the keyword to
    if (!query) return;

    // Check if some text has been selected
    if (!selectedText || selectedText.trim().length == 0) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please select some text to add as a search term."
      );
      return;
    }

    // create environment variable in the database and update query
    await EnvironmentVariableHelperSingleton.addEnvironmentVariableAsync(
      selectedText.trim(),
      query,
      setQuery
    );

    // Show a success message
    ToastHelperSingleton.showToast(ToastTypeEnum.Success, "Search term added.");

    resetMenuDisplay();
  };

  const addSelectedTextAsEntity = async () => {
    LogHelperSingleton.log(
      `${LogFeatureNameEnum.AdvancedSearch}-SaveSelectionAsEntityOptionClick`
    );
    // Check if some text has been selected
    if (!selectedText || selectedText.trim().length == 0) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please select some text to add as an entity."
      );
      return;
    }

    if (!addedObject) return;
    addedObject({
      title: selectedText.trim(),
      type: EntityTypeEnum.Undefined,
    } as IEntityDTO);

    resetMenuDisplay();
  };

  const addSelectedTextAsHighlight = async () => {
    // Check if some text has been selected
    if (!selectedText || selectedText.trim().length == 0) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please select some text to add as a highlight."
      );
      return;
    }

    let saveDocumentLogProperties = undefined;
    if (!isDocumentAlreadySaved) {
      saveDocumentLogProperties = {
        ActionOrigin: `${query ? `${LogFeatureNameEnum.AdvancedSearch}-` : ""}${
          LogFeatureNameEnum.TextSelectionMenu
        }`,
        ...(query ? { QueryGuid: query?.guid } : {}),
      };
    }

    // Create or get the document to link to the entity
    const savedDocument =
      await DocumentControllerSingleton.createWithoutWebAsync(
        documentId,
        documentType,
        saveDocumentLogProperties
      );

    // Check if the document has been created otherwise display an error message and stop
    if (!savedDocument) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to save document."
      );
      return;
    }

    const highlightDescription: JSONContent =
      EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
    highlightDescription.content = [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: selectedText.trim(),
          },
        ],
      },
    ];

    // Create the highlight and link it to the document
    const createdHighlight =
      await HighlightControllerSingleton.addHighlightToDocumentAsync(
        JSON.stringify(highlightDescription),
        {
          id: savedDocument.id,
          type: savedDocument.savedDocumentType,
          webpageUrl: "",
          webpageTitle: "",
        },
        {
          ActionOrigin: `${
            query ? `${LogFeatureNameEnum.AdvancedSearch}-` : ""
          }${LogFeatureNameEnum.TextSelectionMenu}`,
        }
      );

    // Check if the highlight has been created otherwise display an error message and stop
    if (!createdHighlight) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to create highlight."
      );
      return;
    }

    // Indicate that the highlight has been added
    ToastHelperSingleton.showToast(ToastTypeEnum.Success, "Highlight created.");

    // Clear the selected text popup
    resetMenuDisplay();

    // Call the callback function if it exists
    if (onCreateDocumentHighlight) {
      onCreateDocumentHighlight(createdHighlight);
    }
  };

  // Render
  return (
    <>
      {isMenuDisplayed ? (
        <div ref={textSelectionMenuPopupContainerRef}>
          <PositionedPopup
            extraStyle={getTextSelectionMenuPopupPosition()}
            extraClassName={styles.textSelectionMenuPopupContainer}
          >
            {isItemEnabled(TextSelectionMenuPopupItemEnum.Copy) ? (
              <TextSelectionMenuPopupItem
                title={TextSelectionMenuPopupItemEnum.Copy}
                icon={faCopy}
                isChildrenDisplayed={false}
                onClickHandler={copySelectedTextToClipboard}
              ></TextSelectionMenuPopupItem>
            ) : null}
            <RolesChecker
              roles={[RolesEnum.Viewer, RolesEnum.External]}
              isExcluding={true}
            >
              {isItemEnabled(TextSelectionMenuPopupItemEnum.SaveAsHighlight) ? (
                <TextSelectionMenuPopupItem
                  title={TextSelectionMenuPopupItemEnum.SaveAsHighlight}
                  icon={faHighlighter}
                  isChildrenDisplayed={false}
                  onClickHandler={addSelectedTextAsHighlight}
                ></TextSelectionMenuPopupItem>
              ) : null}
              {isItemEnabled(TextSelectionMenuPopupItemEnum.SaveAsEntity) ? (
                <TextSelectionMenuPopupItem
                  title={
                    saveAsEntityMenuItemDisplayValue ??
                    TextSelectionMenuPopupItemEnum.SaveAsEntity
                  }
                  icon={faSatellite}
                  isChildrenDisplayed={false}
                  onClickHandler={addSelectedTextAsEntity}
                ></TextSelectionMenuPopupItem>
              ) : null}
            </RolesChecker>
            {isItemEnabled(TextSelectionMenuPopupItemEnum.AddAsSearchTerm) &&
            query ? (
              <TextSelectionMenuPopupItem
                title={TextSelectionMenuPopupItemEnum.AddAsSearchTerm}
                icon={faSearchPlus}
                isChildrenDisplayed={false}
                onClickHandler={addSelectedTextAsSearchTerm}
              ></TextSelectionMenuPopupItem>
            ) : null}
          </PositionedPopup>
        </div>
      ) : null}
    </>
  );
};
