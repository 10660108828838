// node_modules
import {
  faChevronDown,
  faChevronUp,
  faSquarePlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
// Components
import { Popover, Tooltip } from "Components";
import { EditorMenuGroups } from "./EditorMenuGroups";
// Styles
import styles from "../editorMenu.module.scss";
// Custom hooks
import { useClickOutsideRef, useTooltipDelay, useOverviewTable } from "Hooks";
// Helpers
import {
  ADVANCED_GROUP,
  INSERT_RESULTS_OVERVIEW_TABLE_COMMAND,
  INSERT_INTAKE_SHEET_COMMAND,
  INSERT_REQUIREMENTS_TABLE_COMMAND,
  INSERT_MATURITY_RADAR_COMMAND,
} from "Helpers";
// Interfaces
import { ICommand, IGroup } from "Interfaces";
// Providers
import { EditorContext } from "Providers";
// Enums
import { ObjectTypeEnum } from "Enums";

interface IEditorMenuAdvancedCommandProps {
  editor: Editor;
  setIsOverviewTableModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditorMenuAdvancedCommand: FC<IEditorMenuAdvancedCommandProps> = ({
  editor,
  setIsOverviewTableModalOpen,
}: IEditorMenuAdvancedCommandProps) => {
  const [isAdvancedMenuOpen, setIsAdvancedMenuOpen] = useState(false);
  const {
    objectEdited,
    setIsRequirementsTableModalOpen,
    setIsMaturityRadarModalOpen,
  } = useContext(EditorContext);

  const { isTooltipShown, handleMouseEnter, handleMouseLeave } = useTooltipDelay(1000);

  const advancedCommands: IGroup[] =
    objectEdited?.objectType === ObjectTypeEnum.Study
      ? [ADVANCED_GROUP]
      : [
          {
            ...ADVANCED_GROUP,
            commands: ADVANCED_GROUP.commands.filter(
              (command) => command.name !== INSERT_INTAKE_SHEET_COMMAND.name
            ),
          },
        ];

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useClickOutsideRef(
    containerRef,
    () => {
      setIsAdvancedMenuOpen(false);
    },
    [buttonRef]
  );
  const { getIsOverviewTableAlreadyInDocument } = useOverviewTable();

  const onClickHandler = (command: ICommand): void => {
    setIsAdvancedMenuOpen(false);
    switch (command.name) {
      case INSERT_RESULTS_OVERVIEW_TABLE_COMMAND.name:
        if (getIsOverviewTableAlreadyInDocument(editor)) {
          return;
        }
        setIsOverviewTableModalOpen(true);
        break;
      case INSERT_REQUIREMENTS_TABLE_COMMAND.name:
        setIsRequirementsTableModalOpen(true);
        break;
      case INSERT_MATURITY_RADAR_COMMAND.name:
        setIsMaturityRadarModalOpen(true);
        break;
      default:
        command.action(editor);
        break;
    }
  };

  return (
    <div ref={containerRef}>
      <button
        type="button"
        title={ADVANCED_GROUP.label}
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setIsAdvancedMenuOpen(!isAdvancedMenuOpen);
        }}
        className={`${styles.editorMenuCommand} ${styles.advancedCommand}`}
      >
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={faSquarePlus} />
        </div>
        <FontAwesomeIcon
          className={styles.chevronIcon}
          icon={isAdvancedMenuOpen ? faChevronUp : faChevronDown}
        />
        <Tooltip
          referenceEl={buttonRef.current}
          isOpen={isTooltipShown}
          tooltipText={ADVANCED_GROUP.label}
        />
      </button>
      <Popover
        referenceEl={buttonRef.current}
        isOpen={isAdvancedMenuOpen}
        placement="bottom-end"
      >
        <EditorMenuGroups
          groups={advancedCommands}
          editor={editor}
          commandOnClickHandler={onClickHandler}
          doShowTooltip={false}
        />
      </Popover>
    </div>
  );
};
