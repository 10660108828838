// nodes_modules
import { useCallback, useState, useEffect } from "react";
import { Editor } from "@tiptap/core";
import { NodePos } from "@tiptap/react";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Table from "@tiptap/extension-table";

export const useEditorTable = (editor: Editor) => {
  const [selectedCell, setSelectedCell] = useState<NodePos | null>(null);
  const [table, setTable] = useState<NodePos | null>(null);
  const [isMergeAllowed, setIsMergeAllowed] = useState<boolean>(false);
  const [isSplitAllowed, setIsSplitAllowed] = useState<boolean>(false);
  const [isTableCellChevronVisible, setIsTableCellChevronVisible] = useState<boolean>(table !== null && selectedCell?.element !== null);

  const onSelectionUpdateHandler = useCallback(
    async ({ editor: updatedEditor }: { editor: Editor }) => {
      const tiptapNodePos: NodePos | null = updatedEditor.$pos(
        updatedEditor.state.selection.$from.pos
      );

      const newSelectedCell: NodePos | null =
        tiptapNodePos.closest(TableCell.name) ||
        tiptapNodePos.closest(TableHeader.name);

      setTable(newSelectedCell?.closest(Table.name) ?? null);
      setSelectedCell(newSelectedCell);
      setIsSplitAllowed(
        newSelectedCell?.attributes.colspan > 1 ||
          newSelectedCell?.attributes.rowspan > 1
      );
      setIsMergeAllowed(
        document.querySelectorAll("td.selectedCell").length > 1 ||
          document.querySelectorAll("th.selectedCell").length > 1
      );
      if (newSelectedCell) {
        setIsTableCellChevronVisible(true);
      }
    },
    []
  );

  useEffect(() => {
    if (editor) {
      editor.on("selectionUpdate", onSelectionUpdateHandler);
      editor.on("focus", onSelectionUpdateHandler);
    }

    return () => {
      if (editor) {
        editor.off("selectionUpdate", onSelectionUpdateHandler);
        editor.off("focus", onSelectionUpdateHandler);
      }
    };
  }, [editor, onSelectionUpdateHandler]);

  return {
    selectedCell,
    table,
    isMergeAllowed,
    isSplitAllowed,
    setIsTableCellChevronVisible,
    isTableCellChevronVisible
  };
};
