// node_modules
import {
  FC,
  useState,
  useEffect,
  useRef,
  useContext,
  ChangeEvent,
  useCallback,
} from "react";
// Components
import {
  FileInputButton,
  FileUploadProgressIndicator,
  FindestButton,
  MoreActionsDropdownButton,
} from "Components";
// Interfaces
import { IDocumentDetails } from "Interfaces";
// Types
import { TSavedFileDTO } from "Types";
// Providers
import { ElementVisibilityContext } from "Providers";
//Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import {
  ObjectTypeHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
  FileHelperSingleton,
} from "Helpers";
// Controllers
import { SavedFileControllerSingleton } from "Controllers";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { faFileLines } from "@fortawesome/pro-regular-svg-icons";
// Styles
import styles from "./documentAttachment.module.scss";

// Component props type
type TDocumentAttachmentProps = {
  document: IDocumentDetails;
  moreActions: string | undefined;
  updateDocument: (has: boolean) => void;
};

// Component
export const DocumentAttachment: FC<TDocumentAttachmentProps> = ({
  document: documentObject,
  moreActions,
  updateDocument,
}) => {
  // State
  const [attachment, setAttachment] =
    useState<TSavedFileDTO | undefined>(undefined);
  const [fileUploadProgress, setFileUploadProgress] =
    useState<number | undefined>(undefined);

  // Refs
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const onUploadFromActions = useRef<string | undefined>();
  const prevDocumentRef = useRef<IDocumentDetails | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  // Context
  const { canUserEdit } = useContext(ElementVisibilityContext);

  // Size and types of attachment constants
  const acceptedMIMETypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const fileSizeLimit = 50_000_000;

  // Logic
  const onPreviewFile = () => {
    attachment?.url && window.open(attachment.url, "_blank");
  };

  const onTitleKeyDown = (e: React.KeyboardEvent) => {
    e.key === "Enter" && onPreviewFile();
  };

  const onDownloadAttachment = async () => {
    const attachmentUrl = attachment?.url;
    const attachmentTitle = attachment?.title;

    if (!attachmentUrl || !attachmentTitle) {
      return;
    }

    try {
      const response = await fetch(attachmentUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachmentTitle;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
      LogHelperSingleton.log("DocumentAttachmentDownloaded");
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not download file."
      );
      return;
    }
  };

  const onAddAttachment = async (file: File): Promise<void> => {
    if (!documentObject || !canUserEdit) {
      return;
    }

    abortControllerRef.current = new AbortController();

    // add an attachment to document
    const createdFile: TSavedFileDTO | undefined | null =
      await SavedFileControllerSingleton.createSavedFileUsingForm(
        file,
        file.name,
        documentObject.id,
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          documentObject.documentType
        ),
        setFileUploadProgress,
        abortControllerRef.current.signal
      );

    // safety-checks
    if (!createdFile) {
      // Check if upload was aborted
      if (createdFile === null) {
        return undefined;
      }

      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not attach file."
      );
      return undefined;
    }

    LogHelperSingleton.log("DocumentAttachmentUploaded");
    getAttachment();
  };

  const onDeleteAttachment = async (): Promise<void> => {
    if (!documentObject || !canUserEdit) {
      return;
    }

    if (attachment) {
      const createdFile = await SavedFileControllerSingleton.deleteAsync(
        attachment.id
      );

      // safety-checks
      if (!createdFile) {
        // Check if upload was aborted
        if (createdFile === null) {
          return undefined;
        }

        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete file."
        );
        return undefined;
      }
      LogHelperSingleton.log("DocumentAttachmentDeleted");
      getAttachment();
    } else {
      return;
    }
  };

  const getAttachment = useCallback(async () => {
    const linkedFiles = await SavedFileControllerSingleton.getLinkedToObject(
      documentObject.id
    );
    if (linkedFiles?.length) {
      setAttachment(linkedFiles[0]);
      updateDocument(true);
    } else {
      setAttachment(undefined);
      updateDocument(false);
    }
  }, [documentObject.id, updateDocument]);

  const onUploadFile = useCallback(async () => {
    if (!documentObject || !canUserEdit) {
      return;
    }

    if (fileInput.current) {
      fileInput.current.value = "";
      fileInput.current.click();
    }
  }, [canUserEdit, documentObject]);

  const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!FileHelperSingleton.isFileUploaded(event)) {
      return;
    }

    // get file
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      // if component only accepts certain MIME types && check if the file size exceeds the limit
      if (
        !FileHelperSingleton.isFileFormatAccepted(file, acceptedMIMETypes) ||
        FileHelperSingleton.isFileSizeTooLarge(file, fileSizeLimit)
      ) {
        getAttachment();
        return;
      }

      attachment &&
        (await SavedFileControllerSingleton.deleteAsync(attachment.id));
      setAttachment(undefined);
      onAddAttachment(file);
    }
  };

  const onMoreOptionsClick = (action: string) => {
    if (action === "Replace file") {
      onUploadFile();
    } else if (action === "Download file") {
      onDownloadAttachment();
    } else if (action === "Delete file") {
      onDeleteAttachment();
    }
  };

  useEffect(() => {
    if (onUploadFromActions.current !== moreActions) {
      onUploadFromActions.current = moreActions;
      moreActions === "upload" && onUploadFile();
    }
  }, [moreActions, onUploadFile]);

  useEffect(() => {
    if (prevDocumentRef.current !== documentObject) {
      prevDocumentRef.current = documentObject;
      getAttachment();
    }
  }, [documentObject, getAttachment]);

  // Render
  return (
    <div className={styles.documentAttachment}>
      <input ref={fileInput} type="file" onChange={handleFileInput} />
      {attachment ? (
        <>
          <p>Full text file</p>
          <div className={styles.documentAttachmentBar}>
            <FontAwesomeIcon icon={faFileLines} />
            <a onClick={onPreviewFile} onKeyDown={onTitleKeyDown}>
              {attachment.title}.{attachment.fileExtension}
            </a>
            <MoreActionsDropdownButton
              objectId={documentObject.id}
              objectType={ObjectTypeHelperSingleton.documentTypeToObjectType(
                documentObject.documentType
              )}
              extraClassNames={{
                dropdownButton: styles.dropdownButton,
                dropdownButtonHover: styles.dropdownButtonHover,
                attachmentOptionText: styles.attachmentOptionText,
                optionsPopover: styles.optionsPopover,
                optionsContainer: styles.optionsContainer,
              }}
              attachmentOptions={[
                "Replace file",
                "Download file",
                "Delete file",
              ]}
              onAttachmentAction={onMoreOptionsClick}
            />
          </div>
          <FindestButton title="Preview file" onClick={onPreviewFile} />
        </>
      ) : (
        <>
          <div className={styles.documentAttachmentBar}>
            <FontAwesomeIcon icon={faFileCirclePlus} />
            <p>Add file (PDF, docx, pptx), maximum file size 50MB</p>
          </div>
          <FileInputButton
            buttonText="Add file"
            fileSizeLimit={fileSizeLimit}
            acceptedMIMETypes={acceptedMIMETypes}
            onFileSelectedAsync={onAddAttachment}
            extraClassNames={{
              fileInputButton: styles.fileInputButton,
              optionText: styles.optionText,
            }}
          />
        </>
      )}

      <FileUploadProgressIndicator fileUploadProgress={fileUploadProgress} />
    </div>
  );
};
