// node_modules
import {
  faArrowLeftLong,
  faArrowRightLong,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
// Components
import { FindestButton } from "Components";
import { CaseDataSelection } from "./CaseDataSelection";
import { CaseSelection } from "./CaseSelection";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles
import styles from "./igorCaseMigrationPage.module.scss";

enum IgorCaseMigrationStepEnum {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  CaseSelection,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  CaseDataSelection,
}

export const IgorCaseMigrationPage: FC = () => {
  // State
  const [selectedFromTenant, setSelectedFromTenant] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [selectedFromOrganization, setSelectedFromOrganization] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [selectedCase, setSelectedCase] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [selectedToTenant, setSelectedToTenant] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState<IgorCaseMigrationStepEnum>(
    IgorCaseMigrationStepEnum.CaseSelection
  );

  // Custom hooks
  const navigate = useNavigate();

  // Render
  return (
    <div className={styles.igorCaseMigrationContainer}>
      <div
        onClick={() => {
          navigate("/admin");
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div
        style={{
          display:
            currentStep === IgorCaseMigrationStepEnum.CaseSelection
              ? "block"
              : "none",
        }}
      >
        <CaseSelection
          selectedFromTenant={selectedFromTenant}
          selectedFromOrganization={selectedFromOrganization}
          selectedCase={selectedCase}
          selectedToTenant={selectedToTenant}
          setSelectedFromTenant={setSelectedFromTenant}
          setSelectedFromOrganization={setSelectedFromOrganization}
          setSelectedCase={setSelectedCase}
          setSelectedToTenant={setSelectedToTenant}
        />
        {selectedFromTenant &&
          selectedFromOrganization &&
          selectedCase &&
          selectedToTenant && (
            <FindestButton
              title="Go to case data selection"
              rightIconName={faArrowRightLong}
              onClick={() =>
                setCurrentStep(IgorCaseMigrationStepEnum.CaseDataSelection)
              }
            />
          )}
      </div>
      <div
        style={{
          display:
            selectedFromTenant &&
            selectedFromOrganization &&
            selectedCase &&
            selectedToTenant &&
            currentStep === IgorCaseMigrationStepEnum.CaseDataSelection
              ? "block"
              : "none",
        }}
      >
        <FindestButton
          title="Go back to case selection"
          leftIconName={faArrowLeftLong}
          buttonType="secondary"
          onClick={() =>
            setCurrentStep(IgorCaseMigrationStepEnum.CaseSelection)
          }
        />
        {selectedFromTenant &&
          selectedFromOrganization &&
          selectedCase &&
          selectedToTenant && (
            <CaseDataSelection
              selectedFromTenant={selectedFromTenant}
              selectedFromOrganization={selectedFromOrganization}
              selectedCase={selectedCase}
              selectedToTenant={selectedToTenant}
            />
          )}
      </div>
    </div>
  );
};
