import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";
import { FC, useContext, useEffect, useState, useRef } from "react";
// Context
import { LinkGraphContext, WindowingContext } from "Providers";
// Components
import { HasAdvanced, Popover } from "Components";
import { WindowBubble } from "./WindowBubble";
// Enums
import { LogFeatureNameEnum } from "Enums";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Types
import { TIdNameTypeObjectType } from "Types";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Styles
import styles from "./windowingSidebar.module.scss";

export const WindowingSidebar: FC = () => {
  // Context
  const { searchWindowPreviews, reopenWindow, openGraph } =
    useContext(WindowingContext);
  const { closeReferenceModal, isReferenceModalOpen } =
    useContext(LinkGraphContext);

  // State
  const [sidebarElementReference, setSidebarElementReference] =
    useState<HTMLDivElement | null>(null);
  const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);

  // Refs
  const searchWindowBubbleRef = useRef<HTMLDivElement | null>(null);
  const navigationContainerRef = useRef<HTMLDivElement | null>(null);

  // Custom hooks
  useClickOutsideRef(navigationContainerRef, () => {
    setIsPopoverShown(false);
  });

  const handleElementClick = (element: TIdNameTypeObjectType) => {
    LogHelperSingleton.logWithProperties("OpenQuery", {
      QueryGuid: element.id,
      ActionOrigin: LogFeatureNameEnum.ActiveQueries,
    });
    reopenWindow(element.id);
    if (isReferenceModalOpen) {
      closeReferenceModal();
    }
    hideAllReferenceModalsVisually();
    setIsPopoverShown(false);
  };

  const hideAllReferenceModalsVisually = () => {
    document
      .querySelectorAll('[class*="referenceModal_referenceModalOverlay__"]')
      .forEach((elem: Element) => {
        (elem as HTMLElement).style.display = "none";
      });
  };

  useEffect(() => {
    if (!isPopoverShown) {
      setSidebarElementReference(null);
    }
  }, [isPopoverShown]);

  return (
    <>
      <div
        ref={navigationContainerRef}
        className={styles.navigationWindowsContainer}
      >
        <WindowBubble
          setSidebarElementReference={setSidebarElementReference}
          onClick={() => {
            openGraph();
          }}
          icon={faChartNetwork}
          tooltipText="Explorer window"
        />
        <HasAdvanced>
          <WindowBubble
            setSidebarElementReference={setSidebarElementReference}
            onClick={
              isPopoverShown
                ? () => setIsPopoverShown(false)
                : () => setIsPopoverShown(true)
            }
            icon={faSearch}
            windowCount={searchWindowPreviews.length}
            tooltipText={
              searchWindowPreviews.length > 0
                ? "Active queries"
                : "No active queries"
            }
            isActive={isPopoverShown}
            bubbleRef={searchWindowBubbleRef}
          />
        </HasAdvanced>
        {isPopoverShown && (
          <Popover
            extraClassName={styles.searchWindowPreviewsPopover}
            referenceEl={sidebarElementReference}
            placement="bottom-start"
            popoverOffset={4}
          >
            <>
              {searchWindowPreviews.map((searchWindowPreview) => {
                return (
                  <div
                    className={styles.searchWindowPreviewItem}
                    key={searchWindowPreview.id}
                    onClick={() => {
                      handleElementClick(searchWindowPreview);
                    }}
                  >
                    <p>{searchWindowPreview.name}</p>
                  </div>
                );
              })}
            </>
          </Popover>
        )}
      </div>
    </>
  );
};
