// node_modules
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
// Styles
import styles from "./accountBox.module.scss";
// Components
import { UserIcon } from "Components";

type TAccountBoxProps = {
  email?: string;
  text: string;
  icon?: IconDefinition;
  extraClassname?: string;
  onComponentClick?: () => void;
  onTextClick?: () => void;
  onIconClick?: () => void;
  isCollapsed?: boolean;
  extraClassNames?: { goBackContentContainer?: string; iconContainer?: string };
};

export const AccountBox: FC<TAccountBoxProps> = ({
  email,
  text,
  icon,
  extraClassname,
  onComponentClick,
  onTextClick,
  onIconClick,
  isCollapsed,
  extraClassNames = {},
}) => {
  // Render
  return (
    <div
      className={`${styles.goBackContainer} ${
        extraClassname ? extraClassname : ""
      }`}
    >
      <div
        className={`${styles.goBackContentContainer} ${
          extraClassNames.goBackContentContainer ?? ""
        }`}
        onClick={onComponentClick}
      >
        <div
          className={`${styles.iconContainer} ${
            extraClassNames.iconContainer ?? ""
          }`}
        >
          {icon ? <FontAwesomeIcon icon={icon} onClick={onIconClick} /> : null}
        </div>
        {email ? <UserIcon email={email} /> : null}
        {!isCollapsed && <div onClick={onTextClick}>{text}</div>}
      </div>
    </div>
  );
};
