// node_modules
import { FC, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTurnDown,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-solid-svg-icons";
// Components
import { FindestButton, ListItemLink, Popover } from "Components";
// Constants
import { GeneralConstants } from "Constants";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal, useClickOutsideRef } from "Hooks";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles
import styles from "./objectSwitcher.module.scss";

type TObjectSwitcherProps = {
  selectedObject: TIdNameTypeObjectType | undefined;
  objects: TIdNameTypeObjectType[];
  onObjectSelect: (object: TIdNameTypeObjectType) => void;
  objectIdEdited: string | undefined;
};

export const ObjectSwitcher: FC<TObjectSwitcherProps> = ({
  selectedObject,
  objects,
  onObjectSelect,
  objectIdEdited,
}: TObjectSwitcherProps) => {
  // Ref
  const containerRef = useRef<HTMLDivElement>(null);

  // State
  const [isObjectListPopoverOpen, setIsObjectListPopoverOpen] =
    useState<boolean>(false);
  const [switcherReference, setSwitcherReference] =
    useState<HTMLDivElement | null>(null);

  // Custom Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();
  useClickOutsideRef(
    containerRef,
    () => {
      setIsObjectListPopoverOpen(false);
    },
    [],
    GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
  );

  const onPopoverListItemClick = (
    object: TIdNameTypeObjectType,
    isSelectedObject: boolean
  ) => {
    if (!isSelectedObject) {
      onObjectSelect(object);
    }
    setIsObjectListPopoverOpen(false);
  };

  const toggleObjectListPopover = () => {
    setIsObjectListPopoverOpen(!isObjectListPopoverOpen);
  };

  const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
    setReferenceModalProps({
      id: objectId,
      type: objectType,
      isOpen: true,
      doIgnoreIsDeleted: false,
    });
  };

  return (
    <div ref={containerRef} className={styles.objectSwitcher}>
      <div className={styles.objectSwitcherMainContent}>
        <div
          onClick={toggleObjectListPopover}
          ref={setSwitcherReference}
          className={styles.objectSwitcherButton}
        >
          <div className={styles.objectCount}>{objects.length}</div>
          <div className={styles.title}>Parent objects</div>
          <div
            className={`${styles.iconContainer} ${
              isObjectListPopoverOpen ? styles.active : ""
            }`}
          >
            <FontAwesomeIcon
              icon={isObjectListPopoverOpen ? faChevronUp : faChevronDown}
            />
          </div>
        </div>
        {selectedObject && (
          <ListItemLink
            key={selectedObject.id}
            navigateTo={`/library/${ObjectTypeHelperSingleton.getObjectTypeReactRouteName(
              selectedObject.objectType
            )}/${selectedObject.id}`}
            iconHasColor={objectIdEdited === selectedObject.id}
            icon={undefined}
            extraClassNames={{
              listItem: styles.selectedObject,
              listItemIconContainer: styles.listItemIconContainer,
              itemText: styles.itemText,
            }}
            object={selectedObject}
            openReferenceModal={openReferenceModal}
            theme={[
              "transparent",
              "smallMoreActionsButton",
              objectIdEdited === selectedObject.id ? "selected" : "",
            ]}
          />
        )}
      </div>
      {isObjectListPopoverOpen && (
        <Popover
          referenceEl={switcherReference}
          isOpen={isObjectListPopoverOpen}
          placement="bottom-start"
          extraClassName={styles.objectSwitcherPopover}
        >
          <div className={styles.popover}>
            <h6 className={styles.popoverTitle}>
              Load list from parent object
            </h6>
            <div className={styles.popoverList}>
              {objects.map((object) => {
                const isSelectedObject = object.id === selectedObject?.id;
                return (
                  <ListItemLink
                    key={object.id}
                    navigateTo={""}
                    iconHasColor
                    icon={undefined}
                    object={object}
                    onClick={() => {
                      onPopoverListItemClick(object, isSelectedObject);
                    }}
                    extraClassNames={{
                      listItem: `${styles.popoverListItemContainer} ${
                        isSelectedObject ? styles.selectedObject : ""
                      }`,
                      listItemContent: styles.popoverListItemContent,
                      listItemIconContainer:
                        styles.popoverListItemIconContainer,
                      rightContent: styles.popoverListItemRightContent,
                    }}
                    moreActionsDropdownPopoverDataIdentifier={
                      GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
                    }
                    openReferenceModal={openReferenceModal}
                    navigateCallback={() => {
                      onPopoverListItemClick(object, isSelectedObject);
                    }}
                  >
                    <FindestButton
                      extraClassName={styles.popoverListItemButton}
                      leftIconName={
                        isSelectedObject ? undefined : faArrowTurnDown
                      }
                      title={isSelectedObject ? "Current" : "Load list"}
                      styleProps={["textTransformNone"]}
                      buttonType="secondary"
                    />
                  </ListItemLink>
                );
              })}
            </div>
          </div>
        </Popover>
      )}
      {referenceModal}
    </div>
  );
};
