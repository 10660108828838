// node_modules
import { Editor } from "@tiptap/core";
import { FC } from "react";
// Components
import { EditorMenuCommand } from "./EditorMenuCommand";
// Interfaces
import { IActionOptions, ICommand, IGroup } from "Interfaces";
// Styles
import styles from "../editorMenu.module.scss";

interface IEditorMenuGroupProps {
  editor: Editor;
  group: IGroup;
  commandOnClickCallback?: (command: ICommand) => void;
  commandOnClickHandler?: (command: ICommand) => void;
  doShowTooltip: boolean;
  actionOptions?: IActionOptions;
  selectedCommand?: ICommand;
}

export const EditorMenuGroup: FC<IEditorMenuGroupProps> = ({
  editor,
  group,
  commandOnClickCallback,
  commandOnClickHandler,
  doShowTooltip,
  actionOptions,
  selectedCommand,
}: IEditorMenuGroupProps) => {
  return (
    <div className={styles.editorMenuGroup}>
      <div className={styles.editorMenuGroupLabel}>{group.label}</div>
      {group.commands.map((command: ICommand) => (
        <EditorMenuCommand
          selectedCommand={selectedCommand}
          extraClassNames={{ editorMenuCommand: styles.editorMenuGroupCommand }}
          key={command.name}
          editor={editor}
          command={command}
          actionOptions={actionOptions}
          doShowTooltip={doShowTooltip}
          onClickCallback={commandOnClickCallback}
          onClickHandler={
            commandOnClickHandler
              ? () => {
                  if (commandOnClickHandler) commandOnClickHandler(command);
                }
              : undefined
          }
        />
      ))}
    </div>
  );
};
