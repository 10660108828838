// node_modules
import { useCallback, useEffect, useRef, useState } from "react";

interface IUseObserveScrollingProps {
  ref: React.RefObject<HTMLElement>;
  doStartObserving: boolean;
}

export const useObserveScrolling = ({
  ref,
  doStartObserving,
}: IUseObserveScrollingProps) => {
  const [distanceFromBottom, setDistanceFromBottom] = useState<number>(0);

  // the observer needs to be a ref, because it's used in the useEffect
  // and we need to be able to disconnect it when the users scrolls
  const observerRef = useRef<MutationObserver | undefined>(undefined);

  // scroll to bottom when the ref changes
  const scrollNeededCallback = useCallback(
    (mutationList: MutationRecord[]): void => {
      if (!ref.current) return;
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          ref.current.scrollTo(0, ref.current.scrollHeight);
        }
      }
    },
    [ref]
  );

  useEffect(() => {
    if (doStartObserving) {
      const element = ref.current;
      if (element && !observerRef.current) {
        const observer = new MutationObserver(scrollNeededCallback);
        observer.observe(element, { childList: true, subtree: true });
        observerRef.current = observer;
      }
    } else {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    }
  }, [doStartObserving, ref, scrollNeededCallback]);

  const onScroll = () => {
    const element = ref.current;
    if (!element) return;

    const newDistanceFromBottom =
      element.scrollHeight - element.scrollTop - element.clientHeight;
    setDistanceFromBottom(newDistanceFromBottom);
    // if the distance from the bottom is bigger than 5 then it's not an automatic scroll
    // and we should stop observing
    if (newDistanceFromBottom > 5) {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = undefined;
      }
    }
  };

  const disconnectObserver = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = undefined;
    }
  };

  return {
    onScroll,
    distanceFromBottom,
    disconnectObserver,
  };
};
