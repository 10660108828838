import { FindestButton } from "Components";
import { TenantControllerSingleton } from "Controllers";
import { ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { useFetch } from "Hooks";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TTenantDTO, TUseFetch } from "Types";
// Styles
import styles from "./tenantDetails.module.scss";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TenantDetails: FC = () => {
  // Hooks
  const { tenantId } = useParams();
  const navigate = useNavigate();
  // Retrieve the selected tenant
  const { fetchedData: fetchedTenant }: TUseFetch<TTenantDTO> = useFetch(
    `api/tenant/${tenantId}`
  );
  // State
  const [tenant, setTenant] = useState<TTenantDTO | null>(null);

  useEffect(() => {
    if (fetchedTenant) {
      setTenant(fetchedTenant);
    }
  }, [fetchedTenant]);

  if (!tenantId) {
    navigate("/admin/tenants");
    return null;
  }

  if (!tenant) return <div></div>;

  const onDeleteTenant = async () => {
    const isSuccessfullyDeleted = await TenantControllerSingleton.deleteAsync(
      tenantId
    );

    if (!isSuccessfullyDeleted) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not delete tenant."
      );
      return;
    }

    // show success toast
    ToastHelperSingleton.showToast(
      ToastTypeEnum.Success,
      "Tenant deleted successfully."
    );

    navigate("/admin/tenants");
  };

  const onApplyMigrations = async () => {
    const updatedTenant = await TenantControllerSingleton.applyMigrationsAsync(
      tenantId
    );

    if (!updatedTenant) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not apply migration to tenant."
      );
      return;
    }

    setTenant(updatedTenant);
  };

  return (
    <div className={styles.tenantDetailsContainer}>
      <div
        onClick={() => {
          navigate("/admin/tenants");
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <h2 className={styles.tenantName}>{tenant.name}</h2>
      <div className={styles.tenantDetailsSection}>
        <h3 className={styles.tenantDetailsSectionTitle}>Last migration:</h3>
        <p>{tenant.lastMigration}</p>
      </div>
      <h3 className={styles.tenantDetailsSectionTitle}>Options:</h3>
      {tenant.areMigrationsAvailable ? (
        <div className={styles.tenantDetailsSection}>
          <FindestButton title="Apply migrations" onClick={onApplyMigrations} />
        </div>
      ) : null}
      <div className={styles.tenantDetailsSection}>
        <FindestButton
          buttonType="secondary"
          title="Delete tenant"
          onClick={onDeleteTenant}
        />
      </div>
    </div>
  );
};
