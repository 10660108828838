// node_modules
import { FC, useState, useRef, useMemo, useCallback } from "react";
import {
  faTable,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
// Components
import { Popover, Tooltip } from "Components";
import { EditorMenuGroups } from "./EditorMenuGroups";
import { EditorTablePanel } from "../EditorFloatingTableMenu/EditorTablePanel";
// Commands
import {
  INSERT_TABLE_COMMAND,
  TABLE_COMMANDS,
  TABLE_MENU_GROUPS,
} from "Helpers";
// Styles
import styles from "../editorMenu.module.scss";
// Custom hooks
import { useEditorTable, useClickOutsideRef, useSelectionNode, useTooltipDelay } from "Hooks";
// Interfaces
import { ICommand, IGroup } from "Interfaces";

interface IEditorMenuTableCommandProps {
  editor: Editor;
}

export const EditorMenuTableCommand: FC<IEditorMenuTableCommandProps> = ({
  editor,
}) => {
  const [isTableMenuOpen, setIsTableMenuOpen] = useState(false);
  const [isTablePanelOpen, setIsTablePanelOpen] = useState(false);

  // Custom hooks
  const { isMergeAllowed, isSplitAllowed } = useEditorTable(editor);
  const selectionNodeData = useSelectionNode({ editor });
  const { isTooltipShown, handleMouseEnter, handleMouseLeave } = useTooltipDelay(1000);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isMultipleRowSelected = useCallback((selectedCells: Element[]): boolean => {
    const isDifferentParent = (cell: Element, _index: number, array: Element[]) => {
      return cell.parentElement !== array[0].parentElement;
    };
    return selectedCells.some(isDifferentParent);
  }, []);
  
  const isMultipleColumnSelected = (selectedCells: Element[]): boolean => {
    if (selectedCells.length < 2) return false;
    const [firstCell, secondCell] = selectedCells.slice(0, 2);
    return firstCell.parentElement === secondCell.parentElement;
  };

  const getCommandWithUpdatedLabel = useCallback((command: ICommand, isMultipleSelected: (cells: Element[]) => boolean) => {
    let newLabel = command.label;
    const selectedCells = Array.from(editor.view.dom.querySelectorAll(".selectedCell"));
    if (selectedCells.length > 1 && isMultipleSelected(selectedCells)) {
      newLabel = `${command.label}s`;
    }
    return {
      ...command,
      label: newLabel
    };
  }, [editor.view.dom]);

  const onInsertHandler = (rows: number, cols: number) => {
    TABLE_COMMANDS.INSERT_TABLE_COMMAND.action(editor, {
      rows,
      cells: cols,
      nodePos: selectionNodeData?.fromNodePos,
    });
    setIsTablePanelOpen(false);
  };

  const isTableActive = editor.isActive("table");

  const groups = useMemo(() => {
    return TABLE_MENU_GROUPS.reduce((acc: IGroup[], group) => {
      const commands = group.commands
        .filter((command) => {
          const isInsertTableCommand =
            command.name === TABLE_COMMANDS.INSERT_TABLE_COMMAND.name;
          const isMergeCellsCommand =
            command.name === TABLE_COMMANDS.MERGE_CELLS_COMMAND.name;
          const isSplitCellCommand =
            command.name === TABLE_COMMANDS.SPLIT_CELL_COMMAND.name;

          if (!isTableActive) {
            return isInsertTableCommand;
          }

          if (!isMergeAllowed && !isSplitAllowed) {
            return (
              !isInsertTableCommand &&
              !isMergeCellsCommand &&
              !isSplitCellCommand
            );
          }

          if (isMergeAllowed && !isSplitAllowed) {
            return !isInsertTableCommand && !isSplitCellCommand;
          }

          if (!isMergeAllowed && isSplitAllowed) {
            return !isInsertTableCommand && !isMergeCellsCommand;
          }

          return true;
        })
        .map((command) => {
          if (command.name === TABLE_COMMANDS.INSERT_TABLE_COMMAND.name) {
            return {
              ...command,
              label: command.editorMenuLabel ?? command.label,
            };
          } else if (command.name === TABLE_COMMANDS.DELETE_ROW_COMMAND.name) {
            return getCommandWithUpdatedLabel(command, isMultipleRowSelected);
          } else if (command.name === TABLE_COMMANDS.DELETE_COLUMN_COMMAND.name) {
            return getCommandWithUpdatedLabel(command, isMultipleColumnSelected);
          }
          return command;
        });

      if (commands.length > 0) {
        acc.push({ ...group, commands });
      }

      return acc;
    }, []);
  }, [isMergeAllowed, isSplitAllowed, isTableActive, isMultipleRowSelected, getCommandWithUpdatedLabel]);

  useClickOutsideRef(
    containerRef,
    () => {
      setIsTableMenuOpen(false);
      setIsTablePanelOpen(false);
    },
    [buttonRef]
  );

  return (
    <div ref={containerRef}>
      <button
        type="button"
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setIsTableMenuOpen(!isTableMenuOpen)}
        className={`${styles.editorMenuCommand} ${styles.editorMenuTableCommand} ${
          isTableActive ? styles.isActive : ""
        }`}
      >
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={faTable} />
        </div>
        <FontAwesomeIcon
          className={styles.chevronIcon}
          icon={isTableMenuOpen ? faChevronUp : faChevronDown}
        />
        <Tooltip
          referenceEl={buttonRef.current}
          isOpen={isTooltipShown}
          tooltipText="Manage table"
        />
      </button>
      <Popover
        referenceEl={buttonRef.current}
        isOpen={isTableMenuOpen}
        placement="bottom-end"
      >
        <EditorMenuGroups
          groups={groups}
          editor={editor}
          commandOnClickHandler={(command: ICommand) => {
            if (command.label === INSERT_TABLE_COMMAND.editorMenuLabel) {
              setIsTablePanelOpen(true);
            } else {
              command.action(editor);
            }

            setIsTableMenuOpen(false);
          }}
          doShowTooltip={false}
        />
      </Popover>
      <Popover
        referenceEl={buttonRef.current}
        isOpen={isTablePanelOpen}
        placement="bottom-end"
      >
        <EditorTablePanel
          onInsert={onInsertHandler}
          onCancel={() => setIsTablePanelOpen(false)}
        />
      </Popover>
    </div>
  );
};
