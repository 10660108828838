// React
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
// Context
import { AuthContext } from "./AuthProvider";
// Enum
import { ObjectTypeEnum } from "Enums";
// Helpers
import { UserHelperSingleton } from "Helpers";
// Contexts
import { EditorContext } from "Providers";

export type TElementVisibilityContext = {
  isLeftSidebarVisible: boolean;
  isRightSidebarVisible: boolean;
  isTopbarVisible: boolean;
  isReferenceSidebarVisible: boolean;
  isPinButtonVisible: boolean;
  isShareButtonVisible: boolean;
  isEditorEllipsisMenuVisible: boolean;
  isDocumentDropdownVisible: boolean;
  isWindowingSidebarVisible: boolean;
  isRightSidebarCollapsed: boolean;
  setIsRightSidebarCollapsed: (isCollapsed: boolean) => void;
  isObjectEntityOrStudy: boolean;
  // Access related information
  canUserEdit: boolean;
  isInReferenceModal?: boolean;
};

export const defaultElementVisibilityContext: TElementVisibilityContext = {
  isLeftSidebarVisible: false,
  isRightSidebarVisible: false,
  isTopbarVisible: false,
  isReferenceSidebarVisible: false,
  isPinButtonVisible: false,
  isShareButtonVisible: false,
  isEditorEllipsisMenuVisible: false,
  isDocumentDropdownVisible: false,
  isWindowingSidebarVisible: false,
  isRightSidebarCollapsed: false,
  setIsRightSidebarCollapsed: () => { return; },
  isObjectEntityOrStudy: false,
  isInReferenceModal: undefined,

  // Access related information
  canUserEdit: false,
};

type TElementVisibilityProviderProps = {
  children?: ReactNode;
  isInReferenceModal?: boolean;
};

export const ElementVisibilityContext =
  createContext<TElementVisibilityContext>({
    ...defaultElementVisibilityContext,
  });

export const ElementVisibilityProvider = ({
  children,
  isInReferenceModal,
}: TElementVisibilityProviderProps) => {
  // Contexts
  const {
    auth,
    isUserExternal,
    isUserAtLeastContributor,
    isUserAtLeastViewer,
  } = useContext(AuthContext);
  const { isEditOn, objectEdited } = useContext(EditorContext);

  // State
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState<boolean>(false);

  // Determine if current object is an entity or study
  const isObjectEntityOrStudy = useMemo(() => {
    return (
      !!objectEdited &&
      (objectEdited.objectType === ObjectTypeEnum.Entity ||
        objectEdited.objectType === ObjectTypeEnum.Study)
    );
  }, [objectEdited]);

  // - Left sidebar
  const isLeftSidebarVisible = useMemo(() => {
    return !isUserExternal;
  }, [isUserExternal]);
  // - Topbar
  const isTopbarVisible = useMemo(() => {
    return !isUserExternal;
  }, [isUserExternal]);
  // - Reference sidebar
  const isReferenceSidebarVisible = useMemo(() => {
    return !isUserExternal && !!isEditOn;
  }, [isUserExternal, isEditOn]);

  const isRightSidebarVisible = useMemo(() => {
    return isObjectEntityOrStudy && !!objectEdited;
  }, [isObjectEntityOrStudy, objectEdited]);

  // - Pin button
  const isPinButtonVisible = useMemo(() => {
    // If the user is not at least viewer, don't show the pin button
    return isObjectEntityOrStudy && isUserAtLeastViewer;
  }, [isUserAtLeastViewer, isObjectEntityOrStudy]);

  // - Share button
  const isShareButtonVisible = useMemo(() => {
    // If the user is not at least viewer, don't show the share button
    return isObjectEntityOrStudy && isUserAtLeastContributor;
  }, [isUserAtLeastContributor, isObjectEntityOrStudy]);

  // - Editor ellipsis menu
  const isEditorEllipsisMenuVisible = useMemo(() => {
    // If the user is not at least contributor, don't show the ellipsis menu
    return isUserAtLeastContributor && isObjectEntityOrStudy;
  }, [isUserAtLeastContributor, isObjectEntityOrStudy]);

  // - Document dropdown
  const isDocumentDropdownVisible = useMemo(() => {
    // If the user is not at least contributor, don't show the document dropdown
    return isUserAtLeastContributor;
  }, [isUserAtLeastContributor]);

  // - Windowing sidebar
  const isWindowingSidebarVisible = useMemo(() => {
    return (
      isUserAtLeastViewer ||
      !UserHelperSingleton.isSharingRestrictedToObject(auth)
    );
  }, [auth, isUserAtLeastViewer]);

  // Memo all the values
  const providerValue = useMemo(() => {
    return {
      isLeftSidebarVisible,
      isTopbarVisible,
      isReferenceSidebarVisible,
      isPinButtonVisible,
      isShareButtonVisible,
      isEditorEllipsisMenuVisible,
      canUserEdit: isUserAtLeastContributor,
      isDocumentDropdownVisible,
      isWindowingSidebarVisible,
      isObjectEntityOrStudy,
      isRightSidebarVisible,
      isInReferenceModal,
      isRightSidebarCollapsed,
      setIsRightSidebarCollapsed
    };
  }, [
    isLeftSidebarVisible,
    isRightSidebarVisible,
    isTopbarVisible,
    isReferenceSidebarVisible,
    isShareButtonVisible,
    isPinButtonVisible,
    isEditorEllipsisMenuVisible,
    isUserAtLeastContributor,
    isDocumentDropdownVisible,
    isWindowingSidebarVisible,
    isObjectEntityOrStudy,
    isInReferenceModal,
    isRightSidebarCollapsed,
    setIsRightSidebarCollapsed
  ]);

  // Render
  return (
    <ElementVisibilityContext.Provider value={providerValue}>
      {children}
    </ElementVisibilityContext.Provider>
  );
};
