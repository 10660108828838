// node_modules
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import StarterKit from "@tiptap/starter-kit";
import Focus from "@tiptap/extension-focus";

export const TiptapExtensionsKit = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3],
    },
    horizontalRule: false,
    codeBlock: false,
    strike: false,
    code: false,
    blockquote: false,
  }),
  Focus.configure({
    className: "has-focus",
    mode: "all",
  }),
  Placeholder.configure({
    placeholder: "Type / to browse options",
  }),
  Highlight,
  Subscript,
  Superscript,
  TableRow,
  TableHeader,
  TableCell,
];
