import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { TOldUserDTO } from "Types";
import { UserIcon } from "../UserIcon/UserIcon";
import styles from "./userItem.module.scss";

type UserItemProps = {
  user: TOldUserDTO;
  onDeleteClick?: (user: TOldUserDTO) => void;
};

export const UserItem: FC<UserItemProps> = ({
  user,
  onDeleteClick,
}: UserItemProps) => {
  return (
    <div className={styles.userItem}>
      <UserIcon extraClassName={styles.userIconContainer} email={user.email} />
      <span className={styles.email}>{user.email}</span>
      {onDeleteClick && (
        <FontAwesomeIcon
          className={styles.deleteIcon}
          icon={faTrash}
          onClick={() => {
            onDeleteClick(user);
          }}
        />
      )}
    </div>
  );
};
