import { TableOfContentsStorage } from "@tiptap-pro/extension-table-of-contents";
import { ICollapsibleListItem } from "Interfaces";
import { useCallback, useState } from "react";

export const useTableOfContents = () => {
  const [tableOfContents, setTableOfContents] = useState<TableOfContentsStorage | null>(null);
  const [staticTOCItems, setStaticTOCItems] = useState<ICollapsibleListItem[]>([]);
  const [scrollingParentElement, setScrollingParentElement] = useState<HTMLDivElement | null>(null);

  const isActive = (child: HTMLHeadingElement | HTMLElement, container: HTMLDivElement) => {
    const childRect = child.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    if (childRect.top <= containerRect.top) {
      return true;
    }
    return false;
  };

  const getActiveHeader = useCallback((headers: (HTMLHeadingElement | HTMLElement)[], scrollableParent: HTMLDivElement) => {
    // get last active header
    const activeHeader = [...headers].reverse().find((child) => child && isActive(child, scrollableParent));
    return activeHeader;
  }, []);

  const getStaticTOCItemElements = useCallback((currStaticTOCItems: ICollapsibleListItem[]): HTMLElement[] => {
    const elements = currStaticTOCItems.map((item) => {
      const element = document.getElementById(item.id) as HTMLElement;
      return element;
    });
    return elements;
  }, []);

  const setTocItemActive = useCallback((id: string) => {
    if (!tableOfContents) return;

    setTableOfContents(prev => {
      if (!prev) return prev;

      return {
        ...prev,
        content: prev.content.map((header) => {
          return {
            ...header,
            isActive: header.id === id,
          };
        }),
      };
    });

    setStaticTOCItems(prev => {
      return prev.map((header) => {
        return {
          ...header,
          isActive: header.id === id,
        };
      });
    });
  }, [setStaticTOCItems, tableOfContents]);

  const updateTOCItems = useCallback(() => {
    if (!tableOfContents || !scrollingParentElement) return;
    // get all html elements of headers
    const headers = Array.from(scrollingParentElement.querySelectorAll("h1, h2, h3")) as HTMLElement[];

    const allHeadersInsideScrollableParent = [...headers, ...getStaticTOCItemElements(staticTOCItems)];

    const activeHeader = getActiveHeader(allHeadersInsideScrollableParent, scrollingParentElement);
    const activeHeaderId = activeHeader?.getAttribute("id") ?? headers[0]?.getAttribute("id");
    if (!activeHeaderId) return;
    setTocItemActive(activeHeaderId);
  }, [getActiveHeader, getStaticTOCItemElements, scrollingParentElement, setTocItemActive, staticTOCItems, tableOfContents]);


  const scrollToItem = (element: Element, id: string) => {
    if (!scrollingParentElement) return;
    const elementRect = element.getBoundingClientRect();
    const scrollTop = scrollingParentElement.scrollTop;
    const scrollingParentY = scrollingParentElement?.getBoundingClientRect().y ?? 0;
    const distanceBetweenHeaderAndScrollingParent = elementRect.y - scrollingParentY;

    const tolerance = 1; // Allow for a small margin of error
    // Check if the scrolling parent is currently at the bottom
    const isScrollingParentCurrentlyAtTheBottom = Math.abs(scrollingParentElement.scrollHeight - (scrollingParentElement.clientHeight + scrollTop)) <= tolerance;
    // Check if the scrolling will be at the bottom after scrolling to the element
    const willScrollingParentBeAtTheBottom =
      scrollingParentElement.scrollHeight - scrollingParentElement.scrollTop <= 
      distanceBetweenHeaderAndScrollingParent + scrollingParentElement.clientHeight + tolerance;

    if (isScrollingParentCurrentlyAtTheBottom && willScrollingParentBeAtTheBottom) {
      setTocItemActive(id);
    } else if (willScrollingParentBeAtTheBottom) {
      // No smooth scroll just for this case, since we don't have callback ability
      element.scrollIntoView({ behavior: "instant" as ScrollBehavior });
      // We still need to wrap it with timeout, because we already listen to scroll event, and following line needs to run after that.
      setTimeout(() => {
        setTocItemActive(id);
      }, 100);
    } else {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return {
    tableOfContents,
    setTableOfContents,
    staticTOCItems,
    setStaticTOCItems,
    setScrollingParentElement,
    updateTOCItems,
    setTocItemActive,
    scrollingParentElement,
    scrollToItem
  };
};