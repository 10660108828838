// node_modules
import { useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
import { useNavigate } from "react-router-dom";
// Hooks
import { useAnyLinkRemovedListener } from "Hooks";
// Types
import { TExplorerObjectItem } from "Types";
// Helpers
import { LinkGraphHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";

export const useExplorerAnyLinkRemovedListener = (
  listItems: TExplorerObjectItem[] | undefined,
  setListItems: React.Dispatch<
    React.SetStateAction<TExplorerObjectItem[] | undefined>
  >,
  parents: TExplorerObjectItem[] | undefined,
  setParents: React.Dispatch<
    React.SetStateAction<TExplorerObjectItem[] | undefined>
  >,
  selectedParentId: string | undefined,
  setSelectedParentId: React.Dispatch<React.SetStateAction<string | undefined>>,
  objectIdEdited: string | undefined
) => {
  const navigate = useNavigate();

  const anyLinkRemoved = useCallback(
    (fromId: string, toId: string): void => {
      if (!listItems || listItems.length < 1) return;

      const isCollapsedByListItemId = new Map<string, boolean | undefined>();
      LinkGraphHelperSingleton.mapIsCollapsed(
        listItems,
        isCollapsedByListItemId
      );

      let newListItems: TExplorerObjectItem[] = cloneDeep(
        LinkGraphHelperSingleton.removeChildFromParent(listItems, fromId, toId)
      );

      let newParents: TExplorerObjectItem[] = cloneDeep(
        LinkGraphHelperSingleton.removeChildFromParent(
          parents ?? [],
          fromId,
          toId
        )
      ).filter(
        (object) => object.lowerLevelNodes && object.lowerLevelNodes.length > 0
      );

      const uniqueChildrenParentIds =
        LinkGraphHelperSingleton.extractUniqueChildrenParentIds(newParents);
      newParents = newParents.filter((p) => !uniqueChildrenParentIds.has(p.id));

      const newParentsCommonChildren =
        LinkGraphHelperSingleton.getNewParentsCommonChildren(newParents);

      let doResetSelectedParentId = false;

      if (parents && parents.length === 2 && newParents.length === 1) {
        newListItems = [
          {
            ...newParents[0],
            isCollapsed: false,
            lowerLevelNodes: [...newParents[0].lowerLevelNodes],
          },
        ];
        doResetSelectedParentId = !!selectedParentId;
      } else if (
        newParents.length > 0 &&
        !LinkGraphHelperSingleton.getChildrenIds(newParents).has(fromId)
      ) {
        newListItems = newParentsCommonChildren;
        if (
          selectedParentId &&
          !LinkGraphHelperSingleton.getChildrenIds(newParents).has(
            selectedParentId
          )
        ) {
          doResetSelectedParentId = true;
        }
      }

      const fromIdObject: TExplorerObjectItem | undefined =
        LinkGraphHelperSingleton.getExplorerObjectItemById(
          fromId,
          newParents
        ) ??
        LinkGraphHelperSingleton.getExplorerObjectItemById(
          fromId,
          newListItems
        ) ??
        newListItems[0];

      if (
        fromIdObject &&
        objectIdEdited &&
        !LinkGraphHelperSingleton.getChildrenIds(newParents).has(
          objectIdEdited
        ) &&
        !LinkGraphHelperSingleton.getChildrenIds(newListItems).has(
          objectIdEdited
        )
      ) {
        ObjectTypeHelperSingleton.navigateBasedOnObjectType(
          fromIdObject.objectType,
          fromIdObject.id,
          navigate
        );
      }

      if (doResetSelectedParentId) {
        setSelectedParentId(undefined);
      }

      LinkGraphHelperSingleton.setIsCollapsed(
        newListItems,
        isCollapsedByListItemId
      );
      setListItems(newListItems);
      setParents(newParents);
    },
    [
      listItems,
      navigate,
      objectIdEdited,
      parents,
      selectedParentId,
      setListItems,
      setParents,
      setSelectedParentId,
    ]
  );

  useAnyLinkRemovedListener(anyLinkRemoved);
};
