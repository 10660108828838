// node_modules
import {
  IconDefinition,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useRef, useState } from "react";
// Components
import { GroupedList, Popover } from "Components";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Types
import { TOption, TOptions, TGroupedListExtraClassNames } from "Types";
// Styles
import styles from "./dropdown.module.scss";

type TDropdownProps<T> = {
  className?: string;
  classNameSelect?: string;
  children?: JSX.Element[];
  isDisabled?: boolean;
  isEditable?: boolean;
  placeholderText?: string;
  options: TOptions<T>[];
  handleOptionSelect: (option: TOption<T>) => void;
  selectedOption: TOption<T> | undefined;
  extraPopupClassName?: string;
  extraClassNames?: {
    positionedPopup?: string;
    groupedList?: TGroupedListExtraClassNames;
    dropdownSelectText?: string;
  };
  leftIconProps?: {
    icon: IconDefinition;
    className: string;
  };
  outsideClickExceptionDataIdentifier?: string;
};

export function Dropdown<T>({
  extraPopupClassName,
  selectedOption,
  handleOptionSelect,
  options,
  placeholderText = "Select",
  isDisabled,
  className,
  classNameSelect,
  extraClassNames = {},
  isEditable = true,
  leftIconProps,
  outsideClickExceptionDataIdentifier = "",
}: TDropdownProps<T>) {
  // Ref
  const dropDownRef = useRef<HTMLDivElement>(null);

  // State
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  // Custom hooks
  useClickOutsideRef(
    dropDownRef,
    () => {
      setIsDropdownOpen(false);
    },
    [],
    outsideClickExceptionDataIdentifier
  );

  const showHideDropdown = (): CSSProperties => {
    const dropdownListPositionCss: CSSProperties = {};
    dropdownListPositionCss.visibility = isDropdownOpen ? "visible" : "hidden";
    return dropdownListPositionCss;
  };

  const onDropDownClick = () => {
    if (!isDisabled && isEditable) setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div
      ref={dropDownRef}
      className={`${styles.divDropdown} ${className ? className : ""}`}
      onClick={onDropDownClick}
    >
      <div
        ref={setReferenceElement}
        className={`${styles.divDropdownSelect} ${
          isEditable ? "" : styles.isReadOnly
        } ${isDisabled ? styles.disabled : ""} ${
          classNameSelect ? classNameSelect : ""
        }`}
      >
        {leftIconProps && (
          <FontAwesomeIcon
            icon={leftIconProps.icon}
            className={leftIconProps.className}
          />
        )}
        {selectedOption ? (
          <div className={extraClassNames.dropdownSelectText || ""}>
            {selectedOption.title}
          </div>
        ) : (
          <div
            className={`${styles.placeholder} ${
              extraClassNames.dropdownSelectText || ""
            }`}
          >
            {placeholderText}
          </div>
        )}
        {isEditable && (
          <FontAwesomeIcon
            icon={isDropdownOpen ? faChevronUp : faChevronDown}
          />
        )}
      </div>
      {options && isDropdownOpen ? (
        <Popover
          referenceEl={referenceElement}
          isOpen={isDropdownOpen}
          extraClassName={extraClassNames.positionedPopup || ""}
          placement="bottom-start"
        >
          <GroupedList
            extraPopupClassName={extraPopupClassName}
            options={options}
            handleOptionSelect={handleOptionSelect}
            selectedOptions={selectedOption ? [selectedOption] : undefined}
            extraClassNames={extraClassNames.groupedList}
            outsideClickExceptionDataIdentifier={
              outsideClickExceptionDataIdentifier
            }
          />
        </Popover>
      ) : (
        <div style={showHideDropdown()} className={styles.divDropdownList}>
          <div>No results found.</div>
        </div>
      )}
    </div>
  );
}
