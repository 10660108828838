// node_modules
import { Editor } from "@tiptap/core";
import { FC } from "react";
// Components
import { EditorMenuGroup } from "./EditorMenuGroup";
// Interfaces
import { IActionOptions, ICommand, IGroup } from "Interfaces";
// Styles
import styles from "../editorMenu.module.scss";

interface IEditorMenuGroupsProps {
  editor: Editor;
  groups: IGroup[];
  commandOnClickCallback?: (command: ICommand) => void;
  commandOnClickHandler?: (command: ICommand) => void;
  doShowTooltip: boolean;
  actionOptions?: IActionOptions;
  selectedCommand?: ICommand;
}

export const EditorMenuGroups: FC<IEditorMenuGroupsProps> = ({
  editor,
  groups,
  commandOnClickCallback,
  commandOnClickHandler,
  doShowTooltip,
  actionOptions,
  selectedCommand,
}: IEditorMenuGroupsProps) => {
  return (
    <>
      {groups.map((group: IGroup, index: number) => (
        <div key={`${group.name}-wrapper`}>
          <EditorMenuGroup
            selectedCommand={selectedCommand}
            editor={editor}
            group={group}
            commandOnClickCallback={commandOnClickCallback}
            commandOnClickHandler={commandOnClickHandler}
            doShowTooltip={doShowTooltip}
            actionOptions={actionOptions}
          />
          {index < groups.length - 1 && (
            <div className={styles.editorMenuGroupSeperator} />
          )}
        </div>
      ))}
    </>
  );
};
