// node_modules
import { FC, useState, useMemo } from "react";
// Components
import { EditableNumberInput, FindestButton } from "Components";
// Styles
import styles from "../editorMenu.module.scss";

interface IEditorTablePanelProps {
  onInsert: (rows: number, cols: number) => void;
  onCancel: () => void;
}

export const EditorTablePanel: FC<IEditorTablePanelProps> = ({
  onInsert,
  onCancel,
}) => {
  const [rows, setRows] = useState<number>(3);
  const [cols, setCols] = useState<number>(3);

  const isValid = useMemo(() => rows > 1 && cols > 0, [rows, cols]);

  const handleInsert = () => {
    if (!isValid) return;

    onInsert(rows, cols);
  };

  return (
    <form
      className={styles.editorMenuItemPanel}
      onSubmit={(e) => {
        e.preventDefault();
        handleInsert();
      }}
    >
      <div className={styles.line}>
        <span>Rows</span>
        <EditableNumberInput
          value={rows}
          onValueChange={setRows}
          placeholder="Enter number of rows"
          min={2}
        />
      </div>
      <div className={styles.line}>
        <span>Columns</span>
        <EditableNumberInput
          value={cols}
          onValueChange={setCols}
          placeholder="Enter number of columns"
          min={1}
        />
      </div>
      <div className={styles.footer}>
        <FindestButton title="Cancel" buttonType="secondary" onClick={onCancel} />
        <FindestButton title="Insert" type="submit" isDisabled={!isValid} />
      </div>
    </form>
  );
};
