// node_modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
import { FC, useRef, useState } from "react";
// Components
import { Popover, Tooltip } from "Components";
import { EditorLinkPanel } from "../EditorFloatingLinkMenu";
// Helpers
import { INSERT_LINK_COMMAND, LogHelperSingleton } from "Helpers";
// Styles
import styles from "../editorMenu.module.scss";
// Hooks
import { useClickOutsideRef, useTooltipDelay } from "Hooks";
// Enums
import { LogFeatureNameEnum } from "Enums";

interface IEditorMenuLinkCommandProps {
  editor: Editor;
}

export const EditorMenuLinkCommand: FC<IEditorMenuLinkCommandProps> = ({
  editor,
}: IEditorMenuLinkCommandProps) => {
  // State
  const [isLinkMenuOpen, setIsLinkMenuOpen] = useState(false);
  // Custom hooks
  const { isTooltipShown, handleMouseEnter, handleMouseLeave } = useTooltipDelay(1000);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useClickOutsideRef(
    containerRef,
    () => {
      setIsLinkMenuOpen(false);
    },
    [buttonRef]
  );

  return (
    <div ref={containerRef}>
      <button
        type="button"
        title={INSERT_LINK_COMMAND.label}
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setIsLinkMenuOpen(!isLinkMenuOpen);
        }}
        className={`${styles.editorMenuCommand} ${
          styles.editorMenuLinkCommand
        } ${INSERT_LINK_COMMAND.isActive(editor) ? styles.isActive : ""}`}
      >
        {INSERT_LINK_COMMAND.icon && (
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={INSERT_LINK_COMMAND.icon} />
          </div>
        )}
        <Tooltip
          referenceEl={buttonRef.current}
          isOpen={isTooltipShown}
          tooltipText={INSERT_LINK_COMMAND.description}
        />
      </button>
      <Popover
        referenceEl={buttonRef.current}
        isOpen={isLinkMenuOpen}
        placement="bottom-end"
      >
        <EditorLinkPanel
          isInsertMode
          onSave={(url: string, text?: string) => {
            INSERT_LINK_COMMAND.action(editor, { url, linkText: text });

            LogHelperSingleton.log(
              `${LogFeatureNameEnum.Reporting}-InsertLink`
            );

            setIsLinkMenuOpen(false);
          }}
          onCancel={() => {
            setIsLinkMenuOpen(false);
          }}
        />
      </Popover>
    </div>
  );
};
