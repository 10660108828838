// node_modules
import axios, { AxiosResponse } from "axios";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TImageDTO } from "Types";

export class ImageController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/image`;

  public async addImageAsync(
    image: File | Blob,
    caption?: string,
    doOnlyAddImage?: boolean
  ): Promise<TImageDTO | undefined> {
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("caption", caption ? caption : "");
      formData.append("doOnlyAddImage", doOnlyAddImage ? "true" : "false");

      const response = await axios.post<TImageDTO>(
        `${this._resourcePath}`,
        formData
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async addImageToObjectAsync(
    image: File | Blob,
    objectId: string,
    objectType: ObjectTypeEnum,
    caption?: string
  ): Promise<TImageDTO | undefined> {
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("objectType", objectType.toString());
      formData.append("caption", caption ? caption : "");

      const response = await axios.post<TImageDTO>(
        `${this._resourcePath}/addToObject/${objectId}`,
        formData
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async deleteObjectImageAsync(
    imageId: string,
    objectId: string,
    objectType: ObjectTypeEnum
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await axios.delete(
        `${this._resourcePath}/${imageId}/fromObject/${objectId}?objectType=${objectType}`
      );

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async getLinkedToObject(
    objectId: string
  ): Promise<TImageDTO[] | undefined> {
    try {
      const response = await axios.get<TImageDTO[]>(
        `${this._resourcePath}/linkedto/${objectId}`
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async getByIdsAsync(ids: string[]): Promise<TImageDTO[]> {
    try {
      const response = await axios.get<TImageDTO[]>(`${this._resourcePath}`, {
        params: {
          ids,
        },
      });

      if (response && response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }
}

export const ImageControllerSingleton = new ImageController();
