/* eslint-disable react/no-danger */
// node_modules
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
import { Heading } from "@tiptap/extension-heading";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Link } from "@tiptap/extension-link";
import { Mark, Node } from "@tiptap/pm/model";
import { FC, useCallback, useContext, useMemo, useRef, useState } from "react";
// Components
import { Popover, Tooltip } from "Components";
import { EditorMenuGroups } from "./EditorMenuGroups";
// Helpers
import {
  CREATE_ENTITY_COMMAND,
  CREATE_GROUP,
  FORMAT_MENU_GROUPS,
  PARAGRAPH_COMMAND,
} from "Helpers";
// Styles
import styles from "../editorMenu.module.scss";
// Hooks
import { useClickOutsideRef, useCreateEntity, useTooltipDelay, useSelectionNode } from "Hooks";
// Interfaces
import { ICommand, IGroup, IUseSelectionNode } from "Interfaces";
// Providers
import { EditorContext } from "Providers";

interface IEditorMenuFormatCommandProps {
  editor: Editor;
}

export const EditorMenuFormatCommand: FC<IEditorMenuFormatCommandProps> = ({
  editor,
}: IEditorMenuFormatCommandProps) => {
  // Context
  const { setIsLinkingModalOpen, setLinkToName, objectEdited } = useContext(EditorContext);
  // State
  const [isFormatMenuOpen, setIsFormatMenuOpen] = useState(false);
  // Custom hooks
  const { isTooltipShown, handleMouseEnter, handleMouseLeave } = useTooltipDelay(1000);

  const activeFormatMenuItem =
    FORMAT_MENU_GROUPS.map((group) =>
      group.commands.find((command) => command.isActive(editor))
    ).find((command) => command !== undefined) ?? PARAGRAPH_COMMAND;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useClickOutsideRef(
    containerRef,
    () => {
      setIsFormatMenuOpen(false);
    },
    [buttonRef]
  );
  const selectionNodeData = useSelectionNode({ editor });

  const openLinkingModal = useCallback(() => {
    setIsLinkingModalOpen(true);
    setLinkToName(selectionNodeData?.selectedText || "");
  }, [selectionNodeData?.selectedText, setIsLinkingModalOpen, setLinkToName]);

  const { onCreateEntityAsync } = useCreateEntity({
    openLinkingModal,
  });

  const isCustomLinkMarkPresent = (node: Node) => {
    return node.marks.some(
      (mark: Mark) =>
        mark.type.name === Link.name && mark.attrs.id && mark.attrs.type
    );
  };

  const isCreateGroupAvailable = useMemo(() => {
    let newIsCreateGroupAvailable = true;

    if (selectionNodeData) {
      editor.state.doc.nodesBetween(
        selectionNodeData.fromNodePos,
        selectionNodeData.toNodePos,
        (node) => {
          if (isCustomLinkMarkPresent(node)) {
            newIsCreateGroupAvailable = false;
          }
        }
      );
    }

    return newIsCreateGroupAvailable;
  }, [editor.state.doc, selectionNodeData]);

  const shouldIncludeGroup = (
    group: IGroup,
    currSelectionNodeData: IUseSelectionNode | null,
    currIsCreateGroupAvailable: boolean
  ) => {
    if (group.name === CREATE_GROUP.name) {
      if (
        !currSelectionNodeData ||
        !currSelectionNodeData.selectedText ||
        currSelectionNodeData.fromNode !== currSelectionNodeData.toNode ||
        (currSelectionNodeData.fromNode.type.name !== Paragraph.name &&
          currSelectionNodeData.fromNode.type.name !== Heading.name) ||
        !currIsCreateGroupAvailable
      ) {
        return false;
      }
    }
    return true;
  };

  const groups = useMemo(() => {
    return FORMAT_MENU_GROUPS.filter((group) =>
      shouldIncludeGroup(group, selectionNodeData, isCreateGroupAvailable)
    );
  }, [isCreateGroupAvailable, selectionNodeData]);

  const commandOnClickHandlerAsync = useCallback(
    async (command: ICommand): Promise<void> => {
      if (!editor) return;

      if (
        command.name === CREATE_ENTITY_COMMAND.name &&
        objectEdited &&
        selectionNodeData?.selectedText
      ) {
        setIsFormatMenuOpen(false);

        await onCreateEntityAsync(
          objectEdited.id,
          objectEdited.objectType,
          selectionNodeData.selectedText,
          editor
        );
        return;
      }

      setIsFormatMenuOpen(false);
      command.action(editor);
    },
    [editor, objectEdited, onCreateEntityAsync, selectionNodeData?.selectedText]
  );

  return activeFormatMenuItem ? (
    <div ref={containerRef}>
      <button
        type="button"
        title="Format"
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setIsFormatMenuOpen(!isFormatMenuOpen);
        }}
        className={`${styles.editorMenuCommand} ${styles.editorMenuFormatCommand}`}
      >
        {activeFormatMenuItem.customIconAsHtml && (
          <div
            className={styles.customIconAsHtmlDiv}
            dangerouslySetInnerHTML={{
              __html: activeFormatMenuItem.customIconAsHtml,
            }}
          />
        )}
        {activeFormatMenuItem.icon && (
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={activeFormatMenuItem.icon} />
          </div>
        )}
        {activeFormatMenuItem.label}
        <FontAwesomeIcon
          className={styles.chevronIcon}
          icon={isFormatMenuOpen ? faChevronUp : faChevronDown}
        />
        <Tooltip
          referenceEl={buttonRef.current}
          isOpen={isTooltipShown}
          tooltipText="Format"
        />
      </button>
      <Popover
        referenceEl={buttonRef.current}
        isOpen={isFormatMenuOpen}
        placement="bottom-end"
      >
        <EditorMenuGroups
          groups={groups}
          doShowTooltip={false}
          editor={editor}
          commandOnClickCallback={() => {
            setIsFormatMenuOpen(false);
          }}
          commandOnClickHandler={commandOnClickHandlerAsync}
        />
      </Popover>
    </div>
  ) : null;
};
