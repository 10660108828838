import { faChevronDown, faLock } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useContext, useMemo } from "react";
// Components
import { DropdownButton } from "Components";
// Contexts
import { AuthContext, EditorContext } from "Providers";
// Types
import { TDropdownButtonOption } from "Types";
// Enums
import { LogFeatureNameEnum } from "Enums";
// Helpers
import { LogHelperSingleton, UserHelperSingleton } from "Helpers";
// Styles
import styles from "./objectUnlockDropdown.module.scss";

type TObjectUnlockDropdownDropdownProps = {
  onUnlockPageClick?: () => void;
};

export const ObjectUnlockDropdown: FC<TObjectUnlockDropdownDropdownProps> = ({
  onUnlockPageClick,
}: TObjectUnlockDropdownDropdownProps) => {
  // Contexts
  const { auth } = useContext(AuthContext);
  const { isEditOn, isObjectLocked, toggleIsObjectLockedAsync } =
    useContext(EditorContext);

  // Logic
  const onClickOption = useCallback(
    (optionName: TDropdownButtonOption) => {
      if (optionName === "unlock page") {
        LogHelperSingleton.log(
          `${LogFeatureNameEnum.Reporting}-UnlockPage-Manually`
        );

        if (onUnlockPageClick) {
          onUnlockPageClick();
          toggleIsObjectLockedAsync(false);
        }
      }
    },
    [onUnlockPageClick, toggleIsObjectLockedAsync]
  );

  // Memos
  const dropdownOptions: TDropdownButtonOption[] = useMemo(() => {
    if (isObjectLocked && UserHelperSingleton.hasAccessToAllLocked(auth)) {
      return ["unlock page"];
    }
    return [];
  }, [isObjectLocked, auth]);

  // Render
  return (
    <DropdownButton
      isButtonEnabled
      optionLabels={dropdownOptions}
      onClickOption={onClickOption}
      extraClassNames={{
        dropdownButton: [
          styles.dropdownButton,
          !isEditOn ? styles.viewMode : "",
        ].join(" "),
        dropdownButtonHover: styles.dropdownButtonHover,
        button: styles.objectUnlockButton,
        rightIcon: styles.rightIcon,
        buttonIconContainer: styles.buttonIconContainer,
      }}
      buttonText="Page is locked"
      iconNameRight={faChevronDown}
      iconNameLeft={faLock}
      titleText="Page is locked"
    />
  );
};
