import { faXmark, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { default as ReactModal } from "react-modal";

import styles from "./modal.module.scss";

type TModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  header?: string;
  isFullscreen?: boolean;
  children: ReactNode;
  title?: string;
  extraClassName?: string;
  extraClassNames?: {
    container?: string;
    header?: string;
    headerText?: string;
    overlay?: string;
  };
  headerProps?: {
    icon?: IconDefinition;
    onIconClick?: () => void;
    text?: string;
  };
  /** Function that will be run when the modal is requested
        to be closed (either by clicking on overlay or pressing ESC).
        Note: It is not called if isOpen is changed by other means. */
  onRequestClose?: () => void;
  id?: string;
  stylingProps?: { doesContainerHaveInset?: boolean };
};

export const Modal = ({
  extraClassName = "",
  isOpen,
  onClose,
  onRequestClose,
  header,
  children,
  title,
  extraClassNames = {},
  headerProps = {},
  isFullscreen,
  id,
  stylingProps = {},
}: TModalProps) => {
  return (
    <ReactModal
      appElement={document.getElementById("root") as HTMLElement}
      isOpen={isOpen}
      id={`${id}_referenceModal`}
      className={`${extraClassName} ${
        stylingProps.doesContainerHaveInset
          ? styles.ignoreInternalAlignment
          : ""
      } ${extraClassNames.container || ""} ${styles.modal} ${
        isFullscreen ? styles.fullscreen : ""
      } ${id ? id : ""}`}
      overlayClassName={`${styles.overlay} ${extraClassNames.overlay || ""}`}
      onRequestClose={onRequestClose ?? onClose}
    >
      {(header || onClose || headerProps.text) && (
        <div className={`${styles.header} ${extraClassNames.header || ""}`}>
          <div
            className={`${styles.headerText} ${
              extraClassNames.headerText || ""
            }`}
          >
            {headerProps.icon && (
              <FontAwesomeIcon
                title={title}
                className={`${styles.headerIcon} ${
                  headerProps.onIconClick ? styles.clickable : ""
                }`}
                onClick={headerProps.onIconClick || undefined}
                icon={headerProps.icon}
              />
            )}
            <span>{header ? header : headerProps.text}</span>
          </div>
          {onClose && (
            <div className={styles.closeModal} onClick={onClose} title="Close">
              <FontAwesomeIcon icon={faXmark} />
            </div>
          )}
        </div>
      )}
      {children}
    </ReactModal>
  );
};
