// node_modules
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
// Styles
import styles from "./tabs.module.scss";
// Types
import { TTab } from "Types";

export type TabsProps = {
  onSelectedTabChange?: (selectedTab: string) => void;
  disabledTabs?: TTab[];
  tabs: TTab[];
  defaultSelectedTab?: string;
  extraTabNaming?: { [key: string]: string };
  extraClassNames?: { container?: string; tab?: string };
  theme?: "compact";
};

export const Tabs: FC<TabsProps> = ({
  tabs,
  onSelectedTabChange,
  disabledTabs,
  defaultSelectedTab,
  extraTabNaming,
  extraClassNames,
  theme,
}: TabsProps) => {
  // Logic
  const isTabDisabled = useCallback(
    (tab: TTab) => {
      // if disabled tabs is defined and tab is in disabled tabs
      return (
        disabledTabs?.find((disabledTab: TTab) => disabledTab.name === tab.name)
      );
    },
    [disabledTabs]
  );

  // State
  const [selectedTab, setSelectedTab] = useState<string | undefined>(
    defaultSelectedTab ??
      tabs.find((tab: TTab) => !isTabDisabled(tab))?.name ??
      undefined
  );

  useEffect(() => {
    if (defaultSelectedTab) {
      setSelectedTab(defaultSelectedTab);
    }
  }, [defaultSelectedTab]);

  // Logic
  const onSelectedTab = (tab: string) => {
    // set selected tab state
    setSelectedTab(tab);

    // if on selected tab change callback is defined
    if (onSelectedTabChange) {
      // call it
      onSelectedTabChange(tab);
    }
  };

  // Render
  return (
    <div
      className={`${styles.tabs} ${theme ? styles[theme] : ""} ${
        extraClassNames?.container ?? ""
      }`}
    >
      {tabs.map((tab: { name: string; icon?: IconDefinition }) => {
        return (
          <div
            key={tab.name}
            role="button"
            tabIndex={0}
            aria-pressed={selectedTab === tab.name}
            onClick={() =>
              isTabDisabled(tab) ? null : onSelectedTab(tab.name)
            }
            onKeyDown={(e) => {
              if (!isTabDisabled(tab) && (e.key === "Enter" || e.key === " ")) {
                onSelectedTab(tab.name);
              }
            }}
            className={`${styles.tab} ${
              selectedTab === tab.name ? styles.selected : ""
            } ${isTabDisabled(tab) ? styles.disabled : ""} ${
              extraClassNames?.tab ?? ""
            }`}
          >
            {tab.icon && (
              <div className={styles.tabIcon}>
                <FontAwesomeIcon icon={tab.icon} />
              </div>
            )}
            <h4>
              {extraTabNaming?.[tab.name]
                ? `${tab.name} ${extraTabNaming[tab.name]}`
                : tab.name}
            </h4>
          </div>
        );
      })}
    </div>
  );
};
