// React
import { FC, useContext, useMemo, useState } from "react";
// Node modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-solid-svg-icons";
// Shared Components
import { FindestButton, FindestTextBox } from "Components/Shared";
// Providers and Contexts
import { AuthContext } from "Providers";
// Helpers
import { RegexHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { AuthControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Styles
import styles from "./passwordSettings.module.scss";

export const PasswordSettings: FC = () => {
  const { auth, verifyAuth } = useContext(AuthContext);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentSixDigitCode, setCurrentSixDigitCode] = useState("");
  const [isSixDigitCodeRequested, setIsSixDigitCodeRequested] = useState(false);
  const [isCurrentPasswordSeen, setIsCurrentPasswordSeen] =
    useState<boolean>(false);
  const [isNewPasswordSeen, setIsNewPasswordSeen] = useState<boolean>(false);

  const doesNewPasswordHaveValue = useMemo(
    () => newPassword.length > 0,
    [newPassword]
  );

  const doesContainDigit = useMemo(() => {
    return RegexHelperSingleton.ContainsDigit(newPassword);
  }, [newPassword]);

  const doesContainLowercaseLetter = useMemo(() => {
    return RegexHelperSingleton.ContainsLowercaseLetter(newPassword);
  }, [newPassword]);

  const doesContainUppercaseLetter = useMemo(() => {
    return RegexHelperSingleton.ContainsUppercaseLetter(newPassword);
  }, [newPassword]);

  const doesContainSpecialCharacter = useMemo(() => {
    return RegexHelperSingleton.ContainsSpecialCharacter(newPassword);
  }, [newPassword]);

  const isPasswordLongEnough = useMemo(() => {
    return newPassword.length >= 6;
  }, [newPassword]);

  const isPasswordValid = useMemo(() => {
    return (
      doesContainDigit &&
      doesContainLowercaseLetter &&
      doesContainUppercaseLetter &&
      doesContainSpecialCharacter &&
      isPasswordLongEnough
    );
  }, [
    doesContainDigit,
    doesContainLowercaseLetter,
    doesContainUppercaseLetter,
    doesContainSpecialCharacter,
    isPasswordLongEnough,
  ]);

  const onRequestSixDigitCode = async () => {
    const isSuccess =
      await AuthControllerSingleton.requestFirstTimePasswordCode();
    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Unable to request first time password code."
      );
      return;
    }
    setIsSixDigitCodeRequested(true);
    if (isSixDigitCodeRequested) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Success,
        "A new verification code was sent."
      );
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Success,
        "A verification code was sent to your email address."
      );
    }
  };

  const onClickChangePassword = async () => {
    // Check if the six digit code is valid
    if (!auth.hasPassword && currentSixDigitCode.length !== 6) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "The six digit verification code is not valid."
      );
      return;
    }

    // Check if the password is valid
    if (!isPasswordValid) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "The given new password is not valid. Please check the red requirements to see what is missing."
      );
      return;
    }

    // Send the change password request to the server
    const changePasswordResult =
      await AuthControllerSingleton.changePasswordAsync(
        currentPassword,
        newPassword,
        auth.hasPassword ? undefined : currentSixDigitCode
      );

    // Check if the change password request was successful
    if (!changePasswordResult.isSuccess) {
      // If now errors were returned, show a generic error message
      if (changePasswordResult.errors.length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "An unknown error occured while trying to change your password. Please contact support@findest.eu for help."
        );
        return;
      }

      // Show all error messages
      changePasswordResult.errors.forEach((error) => {
        ToastHelperSingleton.showToast(ToastTypeEnum.Error, error);
      });
      return;
    }

    // Show a success message
    ToastHelperSingleton.showToast(
      ToastTypeEnum.Success,
      "Your password was successfully changed. You will now have to login with your new password."
    );
    // Reset the password fields
    setCurrentPassword("");
    setNewPassword("");
    verifyAuth(false);

    // Redirect user to login page
    window.location.href = "/";
  };

  return (
    <div className={styles.settingsItem}>
      <h2>Change password</h2>
      <form>
        <div className={styles.settingsItemContentBody}>
          <div className={styles.settingsItemInputs}>
            {auth.hasPassword ? (
              <div className={styles.section}>
                <h4 className={styles.sectionTitle}>Current password</h4>
                <FindestTextBox
                  type={isCurrentPasswordSeen ? "text" : "password"}
                  value={currentPassword}
                  placeholder="Current password"
                  onChange={setCurrentPassword}
                  extraClassName={styles.textbox}
                  autoComplete="off"
                  rightIconProps={{
                    icon: isCurrentPasswordSeen ? faEye : faEyeSlash,
                    tooltip: isCurrentPasswordSeen
                      ? "Hide password"
                      : "Show password",
                    onClick: () => {
                      setIsCurrentPasswordSeen(!isCurrentPasswordSeen);
                    },
                  }}
                />
              </div>
            ) : (
              <div className={styles.section}>
                {isSixDigitCodeRequested ? (
                  <>
                    <h4 className={styles.sectionTitle}>Verification code</h4>
                    <FindestTextBox
                      autoComplete="off"
                      type="number"
                      extraClassName={styles.textbox}
                      onChange={setCurrentSixDigitCode}
                    />
                    <div className={styles.footerText}>
                      <p>Didn&apos;t receive your code?</p>
                      <p
                        onClick={onRequestSixDigitCode}
                        className={styles.link}
                      >
                        Request a new one.
                      </p>
                    </div>
                  </>
                ) : (
                  <FindestButton
                    title="Verify your email address"
                    onClick={onRequestSixDigitCode}
                  />
                )}
              </div>
            )}
            <div
              className={`${styles.section} ${
                !isSixDigitCodeRequested && !auth.hasPassword
                  ? styles.disabled
                  : ""
              }`}
            >
              <h4 className={styles.sectionTitle}>New password</h4>
              <FindestTextBox
                type={isNewPasswordSeen ? "text" : "password"}
                value={newPassword}
                placeholder="New password"
                onChange={setNewPassword}
                extraClassName={styles.textbox}
                autoComplete="off"
                rightIconProps={{
                  icon: isNewPasswordSeen ? faEye : faEyeSlash,
                  tooltip: isNewPasswordSeen
                    ? "Hide password"
                    : "Show password",
                  onClick: () => {
                    setIsNewPasswordSeen(!isNewPasswordSeen);
                  },
                }}
              />
            </div>
          </div>
          <div
            className={`${styles.passwordRequirements} ${
              !isSixDigitCodeRequested && !auth.hasPassword
                ? styles.disabled
                : ""
            }`}
          >
            <p>Your password needs to pass the following requirements:</p>
            <ul className={styles.requirementsList}>
              <li
                className={
                  !doesNewPasswordHaveValue
                    ? undefined
                    : isPasswordLongEnough
                    ? styles.requirementMet
                    : styles.requirementNotMet
                }
              >
                {doesNewPasswordHaveValue && (
                  <FontAwesomeIcon
                    className={styles.requirementIcon}
                    icon={isPasswordLongEnough ? faCircleCheck : faCircleXmark}
                  />
                )}
                Be at least 6 characters long
              </li>
              <li
                className={
                  !doesNewPasswordHaveValue
                    ? undefined
                    : doesContainUppercaseLetter
                    ? styles.requirementMet
                    : styles.requirementNotMet
                }
              >
                {doesNewPasswordHaveValue && (
                  <FontAwesomeIcon
                    className={styles.requirementIcon}
                    icon={
                      doesContainUppercaseLetter ? faCircleCheck : faCircleXmark
                    }
                  />
                )}
                Contains at least 1 uppercase letter
              </li>
              <li
                className={
                  !doesNewPasswordHaveValue
                    ? undefined
                    : doesContainLowercaseLetter
                    ? styles.requirementMet
                    : styles.requirementNotMet
                }
              >
                {doesNewPasswordHaveValue && (
                  <FontAwesomeIcon
                    className={styles.requirementIcon}
                    icon={
                      doesContainLowercaseLetter ? faCircleCheck : faCircleXmark
                    }
                  />
                )}
                Contains at least 1 lowercase letter
              </li>
              <li
                className={
                  !doesNewPasswordHaveValue
                    ? undefined
                    : doesContainDigit
                    ? styles.requirementMet
                    : styles.requirementNotMet
                }
              >
                {doesNewPasswordHaveValue && (
                  <FontAwesomeIcon
                    className={styles.requirementIcon}
                    icon={doesContainDigit ? faCircleCheck : faCircleXmark}
                  />
                )}
                Contains at least 1 number
              </li>
              <li
                className={
                  !doesNewPasswordHaveValue
                    ? undefined
                    : doesContainSpecialCharacter
                    ? styles.requirementMet
                    : styles.requirementNotMet
                }
              >
                {doesNewPasswordHaveValue && (
                  <FontAwesomeIcon
                    className={styles.requirementIcon}
                    icon={
                      doesContainSpecialCharacter
                        ? faCircleCheck
                        : faCircleXmark
                    }
                  />
                )}
                Contains at least 1 special character
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`${styles.settingsItemFooter} ${
            !isSixDigitCodeRequested && !auth.hasPassword ? styles.disabled : ""
          }`}
        >
          <FindestButton
            title="Change Password"
            onClick={onClickChangePassword}
          />
        </div>
      </form>
    </div>
  );
};
