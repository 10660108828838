const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

type DateDisplayFormat = "YYYY-MM-DD" | "DD-MM-YYYY";

export class DateHelper {
    // string const storing the invalid date message
    private readonly INVALID_DATE_MESSAGE = "Invalid Date";

    public getDateInFormatFromDate(date: Date, format: DateDisplayFormat, separator = "-"): string {
        // get new instance of date so we don't modify the original
        const newDate = new Date(date);

        // get month (in JS months are 0-11), day and year
        const month = newDate.getMonth() + 1;
        const day = newDate.getDate();
        const year = newDate.getFullYear();

        // get year, month and day display formats
        const yearDisplayFormat = `${year}`;
        const monthDisplayFormat = `${month < 10 ? `0${month}` : month}`;
        const dayDisplayFormat = `${day < 10 ? `0${day}` : day}`;

        // return formatted date
        switch (format) {
            case "YYYY-MM-DD":
                return `${yearDisplayFormat}${separator}${monthDisplayFormat}${separator}${dayDisplayFormat}`;
            case "DD-MM-YYYY":
                return `${dayDisplayFormat}${separator}${monthDisplayFormat}${separator}${yearDisplayFormat}`;
            default:
                return "";
        }
    }

    public getTodayFormatted(): string {
        const today = new Date();
        const pad = (num: number) => num.toString().padStart(2, "0");

        const year = today.getFullYear();
        const month = pad(today.getMonth() + 1); // Months are 0-indexed
        const day = pad(today.getDate());

        return `${year}-${month}-${day}`;
    }

    public getShortenedDate(date: Date, doUsePmAm = false): string {
        const newDate = new Date(date);

        // If the date is not the same year as today, return the full date
        if (!this.isThisYear(newDate)) {
            return this.getDateWithYear(newDate, doUsePmAm, true);
        }

        // If the date is today, then only return the time
        if (this.isToday(newDate)) {
            return this.getFormattedTime(newDate, doUsePmAm);
        }

        return `${this.getShortenedMonthName(newDate)} ${newDate.getDate()}`;
    }

    public formatUTCDate(dateInput:string): string {
        const date = new Date(dateInput); // Ensure input is converted to a Date object
        const pad = (num: number)  => num.toString().padStart(2, "0"); // Pad single digits with zero
    
        // Construct date and time components
        const year = date.getUTCFullYear();
        const month = pad(date.getUTCMonth() + 1); // Months are 0-indexed
        const day = pad(date.getUTCDate());
        const hours = pad(date.getUTCHours());
        const minutes = pad(date.getUTCMinutes());
    
        // Format timezone part; for UTC+0 it's just "UTC"
        const timezone = "UTC"; // This can be dynamic based on user location if needed
    
        // Combine components into the final formatted string
        return `${year}-${month}-${day} ${hours}:${minutes} ${timezone}`;
    }

    public getCurrentDateAsUtc(): Date {
        const now = new Date();
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    public getDateWithYear(date: Date, doUsePmAm = false, isShortened = false): string {
        const newDate = new Date(date);
        if (this.isToday(newDate)) {
            return this.getFormattedTime(newDate, doUsePmAm);
        }
        const month = isShortened ? this.getShortenedMonthName(newDate) : months[newDate.getMonth()];
        return `${month} ${newDate.getDate()}, ${newDate.getFullYear()}`;
    }

    public getDateInFormatFromString(value: string, format: DateDisplayFormat, separator = "-"): string {
        // remove all non-numeric characters
        const cleanedValue = value.replace(/[^0-9]\/- /g, "");

        // get date from cleaned value
        const date = new Date(cleanedValue);

        // if the date is invalid
        if (date.toString() === this.INVALID_DATE_MESSAGE) {
            // return empty string
            return "";
        } else {
            // return formatted date
            return this.getDateInFormatFromDate(date, format, separator);
        }
    }

    /** check if the value is a valid date by creating a new date object and checking if its time is not NaN */
    public isValidDate(value: string): boolean {
        return !isNaN(new Date(value).getTime());
    }

    private isThisYear(date: Date): boolean {
        const today = new Date();
        return date.getFullYear() === today.getFullYear();
    }

    private isToday = (date: Date): boolean => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    private getFormattedTime = (date: Date, doUsePmAm = false): string => {
        let hours = date.getHours();
        const minutes = date.getMinutes();

        let ampm = "";
        if (doUsePmAm) {   
            ampm = hours >= 12 ? " PM" : " AM";         
            hours = (hours % 12) || 12;
        }

        const newMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${hours}:${newMinutes}${ampm}`;
    };

    private getShortenedMonthName = (date: Date) => {
        return months[date.getMonth()].substring(0, 3);
    };
}

export const DateHelperSingleton = new DateHelper();