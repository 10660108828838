// node_modules
import { Mark, Node } from "@tiptap/pm/model";
import { Editor } from "@tiptap/react";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
// Constants
import { GeneralConstants } from "Constants";
// Types
import { TPredefineRatingDTO } from "Types";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum } from "Enums";
// Helpers
import {
  LogHelperSingleton,
  RatingMarkExtension,
  SET_RATING_AT_NODE_POS_COMMAND,
} from "Helpers";

interface IUseRatingProps {
  ref?: RefObject<HTMLDivElement>;
}

export const useRating = ({ ref }: IUseRatingProps) => {
  const [ratingElement, setRatingElement] =
    useState<HTMLElement | undefined>(undefined);
  const [ratingData, setRatingData] =
    useState<TPredefineRatingDTO | undefined>(undefined);

  const setRatingDataTimeout = useRef<NodeJS.Timeout | null>(null);

  const clearSetRatingDataTimeout = useCallback(() => {
    if (setRatingDataTimeout.current) {
      clearTimeout(setRatingDataTimeout.current);
      setRatingDataTimeout.current = null;
    }
  }, []);

  const resetRating = useCallback(() => {
    setRatingElement(undefined);
    setRatingData(undefined);
    clearSetRatingDataTimeout();
  }, [clearSetRatingDataTimeout]);

  useEffect(() => {
    if (!ref) return;

    const refElement = ref.current;

    const mouseOverHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const sourceId = target.getAttribute("sourceId");
      const sourceType = target.getAttribute("sourceType");
      const targetId = target.getAttribute("targetId");

      if (!sourceId || !sourceType || !targetId) {
        resetRating();
        return;
      }

      setRatingDataTimeout.current = setTimeout(() => {
        setRatingData({
          sourceId,
          sourceType: parseInt(sourceType, 10),
          targetId: [targetId],
        });
      }, GeneralConstants.DEFAULT_POPOVER_MS_DELAY);
      setRatingElement(target);
    };

    if (refElement) {
      refElement.addEventListener("mouseover", mouseOverHandler);
    }

    return () => {
      if (refElement) {
        refElement.removeEventListener("mouseover", mouseOverHandler);
        clearSetRatingDataTimeout();
      }
    };
  }, [clearSetRatingDataTimeout, ref, resetRating]);

  const updateRatingInEditor = useCallback(
    (
      editor: Editor,
      sourceId: string,
      targetId: string,
      newCount: number,
      newScore: number,
      newIsRatingNeeded: boolean
    ): void => {
      const ratingDataToUpdate: { mark: Mark; nodePos: number }[] = [];

      editor.state.doc.descendants((node: Node, pos: number) => {
        const ratingMark: Mark | undefined = node.marks.find(
          (mark) =>
            mark.type.name === RatingMarkExtension.name &&
            mark.attrs.sourceId === sourceId &&
            mark.attrs.targetId === targetId
        );

        if (ratingMark) {
          ratingDataToUpdate.push({ mark: ratingMark, nodePos: pos });
        }
      });

      if (!ratingDataToUpdate || ratingDataToUpdate.length <= 0) return;

      ratingDataToUpdate.forEach(
        ({ mark, nodePos }: { mark: Mark; nodePos: number }) => {
          SET_RATING_AT_NODE_POS_COMMAND.action(editor, {
            nodePos,
            rating: newScore,
            ratersCount: newCount,
            isRatingNeeded: newIsRatingNeeded,
            objectId: sourceId,
            objectType: mark.attrs.sourceType,
            targetId,
          });
        }
      );

      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-UpdateRating`);
    },
    []
  );

  const getRatingState = useCallback(
    (
      editor: Editor,
      sourceId: string,
      sourceType: ObjectTypeEnum
    ): {
      ratingsDoneCount: number;
      ratingsToDoCount: number;
    } => {
      const ratingState: {
        ratingsDoneCount: number;
        ratingsToDoCount: number;
      } = { ratingsDoneCount: 0, ratingsToDoCount: 0 };

      editor.state.doc.descendants((node: Node) => {
        const ratingMark: Mark | undefined = node.marks.find(
          (mark) =>
            mark.type.name === RatingMarkExtension.name &&
            mark.attrs.sourceId === sourceId &&
            mark.attrs.sourceType === sourceType
        );

        if (ratingMark) {
          if (ratingMark.attrs.isRatingNeeded) {
            ratingState.ratingsToDoCount++;
          } else {
            ratingState.ratingsDoneCount++;
          }
        }
      });

      return ratingState;
    },
    []
  );

  return {
    ratingElement,
    ratingData,
    resetRating,
    updateRatingInEditor,
    getRatingState,
  };
};
