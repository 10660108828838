// node_modules
import * as commands from "prosemirror-commands";
import { MarkType, Node, NodeType } from "prosemirror-model";
import { liftListItem, sinkListItem, wrapInList } from "prosemirror-schema-list";
import { Command, EditorState, Plugin, Selection, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Components
import { findestMarkdownParser, findestMarkdownSerializer, findestSchema } from "Components";
// Enums
import {
    LogFeatureNameEnum, ObjectTypeEnum, OtherMarkdownCustomBlockNameEnumStrings,
    ToastTypeEnum,
    TopDepthMarkdownCustomBlockNameEnumStrings
} from "Enums";
// Helpers
import { LogHelperSingleton, MarkdownItHelperSingleton, ProseMirrorHelperSingleton, ProseMirrorTablesHelperSingleton, ToastHelperSingleton, isResizing, updateLoadingIndicatorPluginState, updateTableColumnResizingPluginState } from "Helpers";
// Types
import { TEditorDirection, TEditorMenuProps, THeaderLevel, TIdNameTypeObjectType, TSavedFileDTO } from "Types";
// Constants
import { EventConstants, ProseMirrorConstants } from "Constants";

export const useEditor = (): {
    editorMenuProps: TEditorMenuProps,
    editorView?: EditorView,
    editorState?: EditorState,
    objectCreatedByUsername: string,
    setObjectCreatedByUsername: (createdByUsername: string) => void,
    objectCreatedOnDate: string,
    setObjectCreatedOnDate: (createdOnDate: string) => void,
    setObjectId: (id: string) => void,
    objectId: string,
    setObjectName: (name: string) => void,
    objectName: string,
    onAttachFileClickRef: MutableRefObject<(file: File) => Promise<TSavedFileDTO | undefined>>,
    onAttachFileClickHandler: (file: File) => Promise<void>,
    updateEditorView: (editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void) => void,
    updateEditorViewObjectSource: (newSource: string, objectIdEdited?: string, objectTypeEdited?: ObjectTypeEnum) => void,
    forceUpdateEditorViewSource: (newSource: string) => void,
    updateOnSourceChangeCallback: (newOnSourceChangeCallback: (newSource: string) => void) => void,
    updateEditorViewStatePlugins: (plugins: Plugin[]) => void,
    applyFunctionOnEditor: (givenFunction: (state: EditorState, dispatch?: ((tr: Transaction) => void) | undefined) => boolean) => void,
    insertObject: (objectInformation: TIdNameTypeObjectType, state: EditorState, dispatch?: ((tr: Transaction) => void) | undefined) => boolean,
    editorRef: (element: HTMLDivElement | null) => void,
    editorContainerRef: MutableRefObject<HTMLDivElement | null>,
    focusEditor: () => void
    } => {
    // State
    const [isBold, setIsBold] = useState<boolean>(false);
    const [isItalic, setIsItalic] = useState<boolean>(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isLink, setIsLink] = useState<boolean>(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [isOrderedList, setIsOrderedList] = useState<boolean>(false);
    const [isBulletList, setIsBulletList] = useState<boolean>(false);
    const [headerLevel, setHeaderLevel] = useState<THeaderLevel>(0);
    const [isSelectionInTable, setIsSelectionInTable] = useState<boolean>(false);
    const [isSelectionInList, setIsSelectionInList] = useState<boolean>(false);
    const [areSeveralParagraphsSelected, setAreSeveralParagraphsSelected] = useState<boolean>(false);
    const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);
    const [editorState, setEditorState] = useState<EditorState | undefined>(undefined);
    const [objectCreatedByUsername, setObjectCreatedByUsername] = useState<string>("");
    const [objectId, setObjectId] = useState<string>("");
    const [objectName, setObjectName] = useState<string>("");
    const [objectCreatedOnDate, setObjectCreatedOnDate] = useState<string>("");

    // Ref
    const onSourceChangeCallbackRef = useRef<(newSource: string) => void>(() => { return; });
    const onAttachFileClickRef = useRef<(file: File) => Promise<TSavedFileDTO | undefined>>(async () => { return undefined; });
    const editorContainerRef = useRef<HTMLDivElement | null>(null);
    const objectIdEditedRef = useRef<string | undefined>(undefined);
    const objectTypeEditedRef = useRef<ObjectTypeEnum | undefined>(undefined);

    // Logic
    const refreshEditorMenuProps = useCallback((currentEditorView: EditorView | undefined, currentAreSeveralParagraphsSelected: boolean) => {
        // safety-checks
        if (!currentEditorView || currentAreSeveralParagraphsSelected) { 
            setIsBold(false);
            setIsItalic(false);
            setIsHighlighted(false);
            setIsLink(false);
            setIsSubscript(false);
            setIsSuperscript(false);
            setHeaderLevel(0);
            setIsBulletList(false);
            setIsOrderedList(false);
            return; 
        }

        // get selection data
        const { parent, from, to } = ProseMirrorHelperSingleton.getSelectionData(currentEditorView);
        
        // determine if the current range has one of possible marks
        let isCurrentBold = parent.rangeHasMark(from, to, findestSchema.marks.strong);
        let isCurrentItalic = parent.rangeHasMark(from, to, findestSchema.marks.em);
        let isCurrentHighlighted = parent.rangeHasMark(from, to, findestSchema.marks.marked);
        let isCurrentSubscript = parent.rangeHasMark(from, to, findestSchema.marks.subscript);
        let isCurrentSuperscript = parent.rangeHasMark(from, to, findestSchema.marks.superscript);
        // get is link info
        let { isCurrentLink } = ProseMirrorHelperSingleton.getIsLinkInfo(currentEditorView);

        // check which marks don't exist in the current range, but are available in the current state
        if (currentEditorView.state.storedMarks) {
            isCurrentBold = false;
            isCurrentItalic = false;
            isCurrentHighlighted = false;
            isCurrentLink = false;
            isCurrentSubscript = false;
            isCurrentSuperscript = false;
            // for each stored marks
            for (let i = 0; i < currentEditorView.state.storedMarks.length; i++) {
                const mark = currentEditorView.state.storedMarks[i];
                // if the mark is a strong mark
                if (mark.type.name === "strong") {
                    isCurrentBold = true;
                } else if (mark.type.name === "em") {
                    isCurrentItalic = true;
                } else if (mark.type.name === "marked") {
                    isCurrentHighlighted = true;
                } else if (mark.type.name === "link") {
                    isCurrentLink = true;
                } else if (mark.type.name === "subscript") {
                    isCurrentSubscript = true;
                } else if (mark.type.name === "superscript") {
                    isCurrentSuperscript = true;
                }
            }
        }

        // set the status of the marks
        setIsBold(isCurrentBold);
        setIsItalic(isCurrentItalic);
        setIsHighlighted(isCurrentHighlighted);
        setIsLink(isCurrentLink);
        setIsSubscript(isCurrentSubscript);
        setIsSuperscript(isCurrentSuperscript);

        // get the current depth of the cursor
        const currentDepth = currentEditorView.state.selection.$anchor.depth;
        // determine if the cursor is currently in a heading
        const currentNode = currentEditorView.state.selection.$anchor.node(currentDepth);
        setHeaderLevel(currentNode.type.name === "heading" ? currentNode.attrs.level : 0);
        // determine if the cursor is currently in a list'
        let isCurrentOrderedList = false;
        let isCurrentBulletList = false;

        // Get the first found ordered list or bullet list or document node
        const firstListNodeOrDocumentNodeData = ProseMirrorHelperSingleton.getFirstNodeDataWithTypes(currentEditorView.state, 
            currentNode, currentDepth, ["ordered_list", "bullet_list"]);
        // If a node was found then check if they are ordered or bullet lists
        if(firstListNodeOrDocumentNodeData.node) {
            if(firstListNodeOrDocumentNodeData.node.type.name === "ordered_list" || firstListNodeOrDocumentNodeData.node.type.name === "bullet_list") {
                // set the list status
                isCurrentOrderedList = firstListNodeOrDocumentNodeData.node.type.name === "ordered_list";
                isCurrentBulletList = firstListNodeOrDocumentNodeData.node.type.name === "bullet_list";
            }
        }

        setIsOrderedList(isCurrentOrderedList && !isCurrentBulletList);
        setIsBulletList(isCurrentBulletList && !isCurrentOrderedList);

        // check if the cursor is currently in a table
        setIsSelectionInTable(ProseMirrorTablesHelperSingleton.isSelectionInTable(currentEditorView.state));
        // check if the cursor is currently in a list 
        setIsSelectionInList(isCurrentBulletList || isCurrentOrderedList);
    }, []);

    const focusEditor = useCallback(() => {
        // safety-checks
        if (!editorView) { return; }

        // set focus
        editorView.focus();
    }, [editorView]);

    const updateEditorView = useCallback((editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback = false, callbackBeforeSetState?: (newState: EditorState) => void) => {
        // create a new state by applying the transaction to editor view to update's state
        let newEditorViewState: EditorState = editorViewToUpdate.state.apply(transaction);
        
        // update the editor view to update's state
        editorViewToUpdate.updateState(newEditorViewState);

        // clean doc by removing empty custom blocks
        newEditorViewState = ProseMirrorHelperSingleton.cleanDoc(editorViewToUpdate);

        // update the editor view to update's state
        editorViewToUpdate.updateState(newEditorViewState);

        // fix cursor position
        newEditorViewState = ProseMirrorHelperSingleton.fixCursorPosition(editorViewToUpdate);

        // update the editor view to update's state
        editorViewToUpdate.updateState(newEditorViewState);

        // get are several paragraphs selected
        const currentAreSeveralParagraphsSelected = ProseMirrorHelperSingleton
            .areSeveralParagraphsSelected(editorViewToUpdate);

        // set are several paragraphs selected
        setAreSeveralParagraphsSelected(currentAreSeveralParagraphsSelected);
        
        if (!currentAreSeveralParagraphsSelected) {
            // get is link info
            const { isCurrentLink, isNextLink } = ProseMirrorHelperSingleton.getIsLinkInfo(editorViewToUpdate);
            // remove the stored link mark when needed (cursor before or after a link mark, or no link mark)
            if((isCurrentLink && !isNextLink) || (!isCurrentLink && isNextLink) || (!isCurrentLink && !isNextLink)) {
                // create a new state by applying the transaction to editor view to update's state
                newEditorViewState = editorViewToUpdate.state.apply(editorViewToUpdate.state.tr
                    .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                    .removeStoredMark(findestSchema.marks.link)
                );
                    
                // update the editor view to update's state
                editorViewToUpdate.updateState(newEditorViewState);

                // set is lin to false
                setIsLink(false);
            }
        }

        // refresh state based on the new editor view to update's state
        refreshEditorMenuProps(editorViewToUpdate, currentAreSeveralParagraphsSelected);
        
        // do not call on source change function when we add/remove characters 
        // (including white-space, line-break, etc...)
        if (transaction.steps && transaction.steps.length > 0 && doCallOnSourceChangeCallback) {
            const updatedMarkdown = findestMarkdownSerializer.serialize(newEditorViewState.doc);
            onSourceChangeCallbackRef.current(updatedMarkdown);
        }

        // call callbackBeforeSetState
        if (callbackBeforeSetState) {
            callbackBeforeSetState(newEditorViewState);
        }
        
        // update editor state
        setEditorState(newEditorViewState);

        // set focus
        focusEditor();
    }, [focusEditor, refreshEditorMenuProps]);
    
    const setFocusAndCursorAtEnd = useCallback((currentEditorView: EditorView) => {

        // set cursor at the end
        const selection = Selection.atEnd(currentEditorView.state.doc);
        const transaction = currentEditorView.state.tr
            .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
            .setSelection(selection);
        updateEditorView(currentEditorView, transaction, false);
    }, [updateEditorView]);

    const updateEditorViewSource = useCallback((newSource: string, doCallOnSourceCallback = false) => {
        // safety-checks, do nothing if editorView is not defined
        if (!editorView) return;

        // pre process markdown
        newSource = MarkdownItHelperSingleton.preProcessMarkdown(newSource);

        // get new editor node from new source
        const newEditorNode: Node | null = findestMarkdownParser.parse(newSource);
        
        // safety-checks, do nothing if new editor node is not defined
        if (!newEditorNode) { return; }
        
        // create transaction that replaces the current document with the new source
        const transaction: Transaction = editorView.state.tr
            .replaceWith(
                0,
                editorView.state.doc.nodeSize - 2,
                newEditorNode
            );
        
        // update the editor view by applying the transaction
        updateEditorView(editorView, transaction, doCallOnSourceCallback);
        
        // set focus and cursor at the end
        setFocusAndCursorAtEnd(editorView);
    }, [editorView, setFocusAndCursorAtEnd, updateEditorView]);

    const updateEditorViewObjectSource = useCallback((newSource: string, newObjectIdEdited?: string, newObjectTypeEdited?: ObjectTypeEnum) => {
        // safety-checks, do nothing if editorView is not defined
        // or if the object id edited is the same as the current object id edited
        if (!editorView || (newObjectIdEdited !== undefined && newObjectIdEdited === objectIdEditedRef.current && 
            newObjectTypeEdited !== undefined && newObjectTypeEdited === objectTypeEditedRef.current)) { return; }
        // set the new object id edited
        objectIdEditedRef.current = newObjectIdEdited;
        // set the new object type edited
        objectTypeEditedRef.current = newObjectTypeEdited;
        // Change the editor view source
        updateEditorViewSource(newSource, false);
    }, [editorView, updateEditorViewSource]);

    const updateOnSourceChangeCallback = useCallback((newOnSourceChangeCallback: (newSource: string) => void) => {        
        // set the new on source change callback in the ref
        onSourceChangeCallbackRef.current = newOnSourceChangeCallback;
    }, []);

    const updateEditorViewStatePlugins = useCallback((newPlugins: Plugin[]) => {
        // safety-checks, do nothing if editorView is not defined
        if (!editorView) { return; }

        // get new editor view state by reconfiguring the editor view's state with the new plugins
        const newEditorViewState: EditorState = editorView.state.reconfigure({ plugins: newPlugins });

        // update the editor view's state
        editorView.updateState(newEditorViewState);
        
        // set editor state
        setEditorState(newEditorViewState);
    }, [editorView]);

    const initializeEditor = useCallback(() => {
        // do nothing if editor view is already initialized
        if (editorView) { return; }

        // create doc from empty string
        const doc: Node | null = findestMarkdownParser.parse("");

        // safety-checks, do nothing if doc is not defined
        if (!doc) { return; }

        // create new editor view state
        const newEditorViewState: EditorState = EditorState.create({ doc });

        // create new editor view
        const newEditorView: EditorView = new EditorView(null, { state: newEditorViewState });

        // set new editor view's dispatch transaction
        newEditorView.setProps({
            dispatchTransaction: function(transaction: Transaction) {
                updateEditorView(newEditorView, transaction, true);
            }
        });
        
        // update editor view
        setEditorView(newEditorView);
    }, [editorView, updateEditorView]);

    const resetEditor = useCallback(() => {
        // set hook state and on source change callback to default values
        setEditorView(undefined);
        setIsBold(false);
        setIsItalic(false);
        setIsLink(false);
        setIsOrderedList(false);
        setIsBulletList(false);
        setHeaderLevel(0);
        onSourceChangeCallbackRef.current = () => { return; };
    }, []);

    useEffect(() => {
        // safety-checks
        if (editorView && editorView.dom) {
            // add event listeners on mouse leave to update 
            // the prosemirror table column resizing plugin state
            editorView.dom.onmouseleave = function() {
                // if user was resizing
                if (isResizing(editorView)) {
                    // update the prosemirror table column resizing plugin state
                    updateTableColumnResizingPluginState(editorView, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                }
            };
        }

        return () => {
            // safety-checks
            if (editorView && editorView.dom) {
                // remove event listeners on mouse leave
                editorView.dom.onmouseleave = null;
            }
        };
    }, [editorView]);

    const applyFunctionOnEditor = useCallback((givenFunction: (state: EditorState, dispatch?: (tr: Transaction) => void) => boolean): void => {
        // safety-checks
        if (!editorView) { return; }
        
        // apply function on editor
        givenFunction(editorView.state, function(transaction: Transaction) {
            updateEditorView(editorView, transaction, true);
        });
    }, [editorView, updateEditorView]);

    const scrollCursorPositionIntoView = useCallback(() => {
        if(!editorView) { return; }
        // get the node at the current cursor position
        const nodeAtCurrentCursorPosition = editorView.domAtPos(editorView.state.selection.$from.pos).node;

        // get node's html parent element
        const nodeHTMLParentElement = nodeAtCurrentCursorPosition.parentElement;

        // safety-checks
        if (!nodeHTMLParentElement) { 
            return; 
        }

        // get index of the node in the parent html element children array
        const nodeIndex = Array.prototype.indexOf.call(nodeHTMLParentElement.children, nodeAtCurrentCursorPosition);

        // scroll the cursor position into view
        nodeHTMLParentElement.children[nodeIndex].scrollIntoView({ behavior: "smooth", block: "center"});
    }, [editorView]);

    const addLinkHandler = useCallback((url: string, title: string): void => {
        // safety-checks
        if (!editorView || headerLevel !== 0) { return; }

        url = url.replaceAll("\n", " ");
        url = url.replaceAll(" ", "%20");

        const attrs = { title: null, href: url, rel: "noreferrer noopener" };
        const node = findestSchema.text(title, [findestSchema.marks.link.create(attrs)]);
        editorView.dispatch(editorView.state.tr.insert(editorView.state.selection.$from.pos, node));
    }, [editorView, headerLevel]);
    
    const applyInsertDocument = useCallback((documentUrl: string) => {
        // safety-checks
        if (!editorView) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertDocumentRef`);
        
        // set focus
        focusEditor();

        addLinkHandler(documentUrl, "[Ref]");

        // scroll cursor position into view
        scrollCursorPositionIntoView();
    }, [addLinkHandler, editorView, focusEditor, scrollCursorPositionIntoView]);

    const addTextHandler = useCallback((text: string): void => {
        // safety-checks
        if (!editorView) { return; }
        
        // add text where cursor is
        editorView.dispatch(editorView.state.tr.insertText(text));
    }, [editorView]);
    
    const applyInsertText = useCallback((text: string) => {
        // safety-checks
        if (!editorView) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertText`);
        
        // set focus
        focusEditor();

        addTextHandler(text);

        // scroll cursor position into view
        scrollCursorPositionIntoView();
    }, [addTextHandler, editorView, focusEditor, scrollCursorPositionIntoView]);

    const applyInsertHighlight = useCallback((highlightId: string, highlightUrl: string, highlightText: string) => {
        // safety-checks
        if (!editorView || headerLevel !== 0) { return; }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertHighlight`);

        // apply function on editor
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean => 
            ProseMirrorHelperSingleton.insertHighlight(highlightId, highlightUrl, highlightText, state, dispatch));

        // scroll cursor position into view
        scrollCursorPositionIntoView();
    }, [applyFunctionOnEditor, editorView, headerLevel, scrollCursorPositionIntoView]);

    const applyInsertImage = useCallback((imageId: string, imageUrl: string, imageCaption: string, referenceUrl: string) => {
        // safety-checks
        if (!editorView || headerLevel !== 0) { return; }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertImage`);
        
        // apply function on editor
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean => 
            ProseMirrorHelperSingleton.insertImage(imageId, imageUrl, imageCaption, referenceUrl, state, dispatch));

        // scroll the new image into view
        scrollCursorPositionIntoView();
    }, [applyFunctionOnEditor, editorView, headerLevel, scrollCursorPositionIntoView]);

    // paste files event handler
    const onPasteFiles = useCallback(async (event: Event) => {
        // safety-checks
        if (!editorView || !objectIdEditedRef.current ||
                !objectTypeEditedRef.current) {
            return;
        }
        
        // try to paste images from paste files event
        await ProseMirrorHelperSingleton.handlePasteImagesAsync(
            editorView,
            updateEditorView,
            event,
            objectIdEditedRef.current,
            objectTypeEditedRef.current,
            applyInsertImage
        );

        // update loading indicator plugin state and set isLoading to false
        updateLoadingIndicatorPluginState(editorView, false);
    }, [applyInsertImage, editorView, updateEditorView]);

    // add and remove listener on paste files event
    useEffect(() => {
        // add event listener
        window.addEventListener(EventConstants.PASTE_FILES_EVENT, onPasteFiles);
        
        // remove event listener on unmount
        return () => {
            window.removeEventListener(EventConstants.PASTE_FILES_EVENT, onPasteFiles);
        };
    }, [applyInsertImage, editorView, onPasteFiles, updateEditorView]);

    const insertObject = useCallback((objectInformation: TIdNameTypeObjectType, 
            state: EditorState, dispatch?: (tr: Transaction) => void): boolean => {
        // safety-checks
        if (!dispatch) {
            // return false
            return false;
        }

        // insert object as reference
        return ProseMirrorHelperSingleton
            .insertObjectAsReference(objectInformation, state, dispatch);
    }, []);

    const applyInsertFile = useCallback((
            fileId: string, 
            fileUrl: string, 
            fileTitle: string, 
            fileExtension: string) => {
        // safety-checks
        if (!editorView || headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertFile`);

        // apply function on editor
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean => 
            ProseMirrorHelperSingleton.insertFile(fileId, fileUrl, fileTitle, fileExtension, state, dispatch));
        
        // Scroll the new file into view
        scrollCursorPositionIntoView();
    }, [applyFunctionOnEditor, editorView, headerLevel, scrollCursorPositionIntoView]);

    const applyInsertTable = useCallback((numberOfColumns: number, numberOfRows: number) => {
        // safety-checks
        // do no insert table if cursor is in on a heading
        if (!editorView || headerLevel !== 0 || numberOfColumns < 1 ||
                numberOfRows < 1) { return; }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertTable`);

        // get position where to insert the table
        const position = ProseMirrorHelperSingleton.getPositionWhereToInsertNewElement(editorView.state.selection);
        
        // apply function on editor
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean => 
            ProseMirrorTablesHelperSingleton.insertTable(position, numberOfColumns, numberOfRows, state, dispatch));

        // scroll the new table into view
        scrollCursorPositionIntoView();
    }, [applyFunctionOnEditor, editorView, headerLevel, scrollCursorPositionIntoView]);

    const applyAddColumn = useCallback((direction: TEditorDirection): void => {
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean => 
            ProseMirrorTablesHelperSingleton.addColumn(direction, state, dispatch));
    }, [applyFunctionOnEditor]);

    const applyAddRow = useCallback((direction: TEditorDirection): void => {
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean => 
            ProseMirrorTablesHelperSingleton.addRow(direction, state, dispatch));
    }, [applyFunctionOnEditor]);

    const applyDeleteTable = useCallback((): void => {
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean =>
            ProseMirrorTablesHelperSingleton.deleteTable(state, dispatch));
    }, [applyFunctionOnEditor]);

    const applyDeleteRow = useCallback((): void => {
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean =>
            ProseMirrorTablesHelperSingleton.deleteRow(state, dispatch));
    }, [applyFunctionOnEditor]);

    const applyDeleteColumn = useCallback((): void => {
        applyFunctionOnEditor((state: EditorState, dispatch?: (tr: Transaction) => void): boolean =>
            ProseMirrorTablesHelperSingleton.deleteColumn(state, dispatch));
    }, [applyFunctionOnEditor]); 

    const applyInsertObject = useCallback((objectInformation: TIdNameTypeObjectType) => {
        // safety-checks
        // do no insert table if cursor is in on a heading
        if (!editorView || headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertObject`);

        // apply function on editor
        applyFunctionOnEditor(insertObject.bind(null, objectInformation));
        
        // scroll the new table into view
        scrollCursorPositionIntoView();
    }, [applyFunctionOnEditor, editorView, headerLevel, insertObject, scrollCursorPositionIntoView]);


    const applyMark = useCallback((markType: MarkType): void => {
        // get function to apply
        const markTypeToApply = commands.toggleMark(markType);
        
        // apply function on editor
        applyFunctionOnEditor(markTypeToApply);
    }, [applyFunctionOnEditor]);

    const applyBoldMark = useCallback((): void => {
        // do no apply bold mark if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-ApplyBold`);

        // apply or unapply bold on editor
        applyMark(findestSchema.marks.strong);
        // update state
        setIsBold(!isBold);
    }, [applyMark, headerLevel, isBold]);

    const applyItalicMark = useCallback((): void => {
        // do no apply italic mark if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-ApplyItalic`);

        applyMark(findestSchema.marks.em);
        setIsItalic(!isItalic);
    }, [applyMark, headerLevel, isItalic]);

    const applyUnlinkMark = useCallback((): void => {
        // Safety-checks
        if(!editorView) return;

        // do no apply unlink mark if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        const cursorIsNoSelection = editorView.state.selection.from === editorView.state.selection.to;
 
        if(cursorIsNoSelection) {
            // Get the node at the cursor position
            const nodeAtCurrentCursorPosition = editorView.domAtPos(editorView.state.selection.$from.pos).node;
            // Get the associated HTML element
            const currentNodeHtmlElement = nodeAtCurrentCursorPosition.parentElement;
            if(!currentNodeHtmlElement) { 
                // If no HTML element is associated with the node the use default behavior
                applyMark(findestSchema.marks.link);
                return;
            }

            // Get the start and end position of the link
            const startPosition = editorView.posAtDOM(currentNodeHtmlElement, 0, 0);
            const endPosition = startPosition + currentNodeHtmlElement.innerText.length;

            // Remove the link mark
            const tr = editorView.state.tr.removeMark(startPosition, endPosition, findestSchema.marks.link);
            updateEditorView(editorView, tr, true);
        } else {
            // Remove the link mark over the selection
            const tr = editorView.state.tr.removeMark(editorView.state.selection.from,
                editorView.state.selection.to, findestSchema.marks.link);
            updateEditorView(editorView, tr, true);
        }
    }, [applyMark, editorView, headerLevel, updateEditorView]);

    const applyHighlightMark = useCallback(() => {
        // do no apply highlight mark if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-ApplyHighlightMark`);

        applyMark(findestSchema.marks.marked);
        setIsHighlighted(!isHighlighted);
    }, [applyMark, headerLevel, isHighlighted]);

    const applySubscriptMark = useCallback((): void => {
        // do no apply subscript mark if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        // do no apply superscript mark if subscript is already applied
        if (isSuperscript) { 
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Cannot use sub and superscript simultaneously.");
            return;
        }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-ApplySubScript`);

        applyMark(findestSchema.marks.subscript);
    }, [applyMark, headerLevel, isSuperscript]);

    const applySuperscriptMark = useCallback((): void => {
        // do no apply superscript mark if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        // do no apply superscript mark if subscript is already applied
        if (isSubscript) { 
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Cannot use sub and superscript simultaneously.");
            return;
        }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-ApplySuperScript`);

        applyMark(findestSchema.marks.superscript);
    }, [applyMark, headerLevel, isSubscript]);


    const getMarksToRemove = useCallback((): MarkType[] => {
        const marksToRemove: MarkType[] = [];

        // remove bold mark if needed
        if (isBold) {
            marksToRemove.push(findestSchema.marks.strong);
        }
        // remove italic mark if needed
        if (isItalic) {
            marksToRemove.push(findestSchema.marks.em);
        }
        // remove highlight mark if needed
        if (isHighlighted) {
            marksToRemove.push(findestSchema.marks.marked);
        }
        // remove subscript mark if needed
        if (isSubscript) {
            marksToRemove.push(findestSchema.marks.subscript);
        }
        // remove superscript mark if needed
        if (isSuperscript) {
            marksToRemove.push(findestSchema.marks.superscript);
        }

        return marksToRemove;
    }, [isBold, isHighlighted, isItalic, isSubscript, isSuperscript]);
    
    const applyHeaderLevel = useCallback((newHeaderLevel: number): void => {
        // safety-checks
        if (!editorView) {
            return;
        }

        LogHelperSingleton.logWithProperties(`${LogFeatureNameEnum.Reporting}-ApplyHeaderLevel`, { HeaderLevel: newHeaderLevel });

        // if new header level is different from 1, 2 or 3
        let applyHeading: Command | null = null;
        if (newHeaderLevel < 1 || newHeaderLevel > 3) {
            // then change block type to paragraph
            applyHeading = commands.setBlockType(findestSchema.nodes.paragraph);
        } else {
            // get function to apply
            applyHeading = commands.setBlockType(findestSchema.nodes.heading, { level: newHeaderLevel, id: crypto.randomUUID() });

            // get all marks to remove
            const marksToRemove = getMarksToRemove();
            // get the node at the cursor position
            const nodeAtCurrentCursorPosition = editorView.domAtPos(editorView.state.selection.$from.pos).node;
            // get the associated HTML element
            const currentNodeHtmlElement = nodeAtCurrentCursorPosition.parentElement;
            // safety-checks
            if(!currentNodeHtmlElement) {
                return;
            }
            // get the start and end position of the element
            const startPosition = editorView.posAtDOM(currentNodeHtmlElement, 0, 0);
            const endPosition = startPosition + currentNodeHtmlElement.innerText.length + 2;
            
            // remove the needed marks
            let tr = editorView.state.tr;
            marksToRemove.forEach(markToRemove => {
                tr = tr.removeMark(startPosition, endPosition, markToRemove);
            });
            updateEditorView(editorView, tr, true);
        }

        // apply function on editor
        applyFunctionOnEditor(applyHeading);
    }, [applyFunctionOnEditor, editorView, getMarksToRemove, updateEditorView]);

    const applyListNode = useCallback((currentEditorView: EditorView, nodeType: NodeType): void => {
        // get the current position of the cursor
        let { $from } = currentEditorView.state.selection;

        // get current depth
        let depth = $from.depth;

        // get current node from depth
        let currentNode = $from.node(depth);

        // get current node parent
        const currentNodeParent = $from.node(--depth);

        // init function to apply
        const listToApply = wrapInList(nodeType);

        // if node is a custom blockProseMirrorHelper.
        // then need to apply some custom logic to make wrapInList work
        if (TopDepthMarkdownCustomBlockNameEnumStrings.includes(currentNodeParent.type.name) || 
                TopDepthMarkdownCustomBlockNameEnumStrings.includes(currentNode.type.name) ||
                OtherMarkdownCustomBlockNameEnumStrings.includes(currentNodeParent.type.name)  ||
                OtherMarkdownCustomBlockNameEnumStrings.includes(currentNode.type.name)) {

            // set current node to the top depth custom block
            while (!TopDepthMarkdownCustomBlockNameEnumStrings.includes(currentNode.type.name)) {
                currentNode = $from.node(--depth);
            }

            // create a transaction which replaces the top depth custom block with an empty paragraph
            let tr = currentEditorView.state.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .replaceWith($from.before(depth), $from.after(depth), findestSchema.nodes.paragraph.create());
            // put cursor at the start of the empty paragraph
            tr = tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .setSelection(new TextSelection((tr.doc.resolve($from.start(depth)))));

            // update the editor view to update's state
            updateEditorView(currentEditorView, tr, false);
            
            // apply the list to empty paragraph
            applyFunctionOnEditor(listToApply);

            // get current selection
            $from = currentEditorView.state.selection.$from;

            // get depth
            depth = $from.depth;

            // replace empty paragraph with the previous top depth custom block node
            tr = currentEditorView.state.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .replaceWith($from.before(depth), $from.start(depth), currentNode);

            // update the editor view to update's state
            updateEditorView(currentEditorView, tr, true);
        } else {
            applyFunctionOnEditor(listToApply);
        }
    }, [applyFunctionOnEditor, updateEditorView]);

    const applyOrderedList = useCallback((): void => {
        // safety-checks
        // do no apply ordered list if cursor is in on a heading
        if (isBulletList || !editorView || headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-OrderedList`);

        if (!isOrderedList) {
            applyListNode(editorView, findestSchema.nodes.ordered_list);
            setIsOrderedList(true);

            if (isBulletList) {
                setIsBulletList(false);
            }
        } else {
            setIsOrderedList(false);
        }
    }, [applyListNode, editorView, headerLevel, isBulletList, isOrderedList]);

    const applyBulletList = useCallback((): void => {
        // safety-checks
        // do no apply bullet list if cursor is in on a heading
        if (!editorView || headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-BullitList`);

        if (!isBulletList) {
            applyListNode(editorView, findestSchema.nodes.bullet_list);
            setIsBulletList(true);

            if (isOrderedList) {
                setIsOrderedList(false);
            }
        } else {
            setIsBulletList(false);
        }
    }, [applyListNode, editorView, headerLevel, isBulletList, isOrderedList]);

    const applyReduceListDepth = useCallback((): void => {
        // do no apply reduce list depth if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-ReduceListDepth`);

        const applyListDepthReduction = liftListItem(findestSchema.nodes.list_item);
        applyFunctionOnEditor(applyListDepthReduction);
    }, [applyFunctionOnEditor, headerLevel]);

    const applyIncreaseListDepth = useCallback((): void => {
        // do no apply increase list depth if cursor is in on a heading
        if (headerLevel !== 0) { return; }

        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-IncreaseListDepth`);

        const applyListDepthIncrease = sinkListItem(findestSchema.nodes.list_item);
        applyFunctionOnEditor(applyListDepthIncrease);
    }, [applyFunctionOnEditor, headerLevel]);

    // Logic
    useEffect(() => {
        // initialize editor
        initializeEditor();
    }, [initializeEditor]);

   useEffect(() => {
       // when umounting
       return () => {
           // reset editor
           resetEditor();
       };
   }, [resetEditor]);

    const onAttachFileClickHandler = useCallback(async (file: File): Promise<void> => {
        if (!onAttachFileClickRef || !onAttachFileClickRef.current) {
            return;
        }

        // get created file
        const createdFile: TSavedFileDTO | undefined = await onAttachFileClickRef.current(file);
        
        // safety-checks
        if (!createdFile) { return; }

        // add file to the editor
        applyInsertFile(
            createdFile.id, 
            createdFile.url, 
            createdFile.title, 
            createdFile.fileExtension
        );
    }, [applyInsertFile]);

    const onIntakeSheetClickAsync = useCallback(async (): Promise<void> => {
        // safety-checks
        if (!editorView) {
            // stop here
            return;
        }

        // get add intake sheet transaction
        const transaction: Transaction | undefined = await ProseMirrorHelperSingleton
            .getAddIntakeSheetTransactionAsync(editorView);

        // safety-checks
        if (!transaction) {
            // stop here
            return;
        }

        // update editor view
        updateEditorView(editorView, transaction, true);
    }, [editorView, updateEditorView]);

    const editorRef = useCallback((element: HTMLDivElement | null) => {
        // safety-checks
        if (!editorView) { return; }

        // if element is not null
        if (element) {
            // append the editor view's DOM to the element
            element.appendChild(editorView.dom);
            // set focus and cursor at the end
            setFocusAndCursorAtEnd(editorView);
        }
    }, [editorView, setFocusAndCursorAtEnd]);

    const hookValues = useMemo(() => {
        return {
            editorMenuProps: {
                isBold,
                applyBoldMark,
                isItalic,
                applyItalicMark,
                isHighlighted,
                applyHighlightMark,
                headerLevel,
                applyHeaderLevel,
                isLink,
                applyUnlinkMark,
                addLinkHandler,
                isSubscript,
                applySubscriptMark,
                isSuperscript,
                applySuperscriptMark,
                isOrderedList,
                applyOrderedList,
                isBulletList,
                applyBulletList,
                applyReduceListDepth,
                applyIncreaseListDepth,
                applyInsertTable,
                applyInsertHighlight,
                applyInsertImage,
                applyInsertFile,
                applyInsertDocument,
                applyInsertText,
                applyAddColumn,
                applyAddRow,
                applyDeleteTable,
                applyDeleteRow,
                applyDeleteColumn,
                isSelectionInTable,
                isSelectionInList,
                areSeveralParagraphsSelected,
                applyInsertObject,
                onIntakeSheetClickAsync: objectTypeEditedRef.current === ObjectTypeEnum.Study ? onIntakeSheetClickAsync : undefined
            },
            editorView,
            editorState,
            objectCreatedByUsername,
            setObjectCreatedByUsername,
            objectId,
            setObjectId,
            objectName,
            setObjectName,
            objectCreatedOnDate,
            setObjectCreatedOnDate,
            onAttachFileClickRef,
            onAttachFileClickHandler,
            updateEditorView,
            updateEditorViewObjectSource,
            forceUpdateEditorViewSource: updateEditorViewSource,
            updateOnSourceChangeCallback,
            updateEditorViewStatePlugins,
            applyFunctionOnEditor,
            insertObject,
            editorRef,
            editorContainerRef,
            focusEditor
        };
    }, [addLinkHandler, applyAddColumn, applyAddRow, applyBoldMark, applyBulletList, applyDeleteColumn, applyDeleteRow, applyDeleteTable, applyFunctionOnEditor, applyHeaderLevel, applyHighlightMark, applyIncreaseListDepth, applyInsertDocument, applyInsertFile, applyInsertHighlight, applyInsertImage, applyInsertObject, applyInsertTable, applyInsertText, applyItalicMark, applyOrderedList, applyReduceListDepth, applySubscriptMark, applySuperscriptMark, applyUnlinkMark, areSeveralParagraphsSelected, editorRef, editorState, editorView, focusEditor, headerLevel, insertObject, isBold, isBulletList, isHighlighted, isItalic, isLink, isOrderedList, isSelectionInList, isSelectionInTable, isSubscript, isSuperscript, objectCreatedByUsername, objectCreatedOnDate, objectId, objectName, onAttachFileClickHandler, onIntakeSheetClickAsync, updateEditorView, updateEditorViewObjectSource, updateEditorViewSource, updateEditorViewStatePlugins, updateOnSourceChangeCallback]);
    
    return hookValues;
};