// node_modules
import { faArrowTurnUp, faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from "lodash.clonedeep";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Styles
import styles from "./explorer.module.scss";
// Contexts
import { LinkGraphContext, LinksContext, WindowingContext } from "Providers";
// Components
import {
  PubSubExplorerCollapsibleList,
  PubSubExplorerObjectSwitcher,
  Tooltip,
} from "Components";
// Types
import { TExplorerObjectItem, TLinkGraphDTO } from "Types";
// Helpers
import {
  LinkGraphHelperSingleton,
  ObjectTypeHelperSingleton,
  SetHelperSingleton,
  SharedToHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Hooks
import {
  useExplorerAnyLinkAddedListener,
  useExplorerAnyLinkRemovedListener,
  useExplorerObjectDeletedListener,
  useObjectReferenceModal,
} from "Hooks";
// Enums
import { LinksWindowTabsEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Controllers
import { LinkingControllerSingleton } from "Controllers";

// Component
export const Explorer: FC = () => {
  // Contexts
  const { linkGraphForObjectEdited } = useContext(LinksContext);
  const { onReanchorClick } = useContext(LinkGraphContext);
  const { openGraph } = useContext(WindowingContext);

  // State
  const [listItems, setListItems] = useState<TExplorerObjectItem[] | undefined>(
    undefined
  );
  const [parents, setParents] = useState<TExplorerObjectItem[] | undefined>(
    undefined
  );
  const [selectedParentId, setSelectedParentId] = useState<string | undefined>(
    undefined
  );
  const [
    isLoadOneLevelUpButtonTooltipOpen,
    setIsLoadOneLevelUpButtonTooltipOpen,
  ] = useState<boolean>(false);
  const [isEditListButtonTooltipOpen, setIsEditListButtonTooltipOpen] =
    useState<boolean>(false);
  const [loadOneLevelUpButtonReference, setLoadOneLevelUpButtonReference] =
    useState<HTMLButtonElement | null>(null);
  const [editListButtonReference, setEditListButtonReference] =
    useState<HTMLButtonElement | null>(null);

  // Custom Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  /** Collapses all list items and their lower level nodes recursively */
  const collapseLowerLevelNodesRecursively = useCallback(
    (currentListItems: TExplorerObjectItem[]): TExplorerObjectItem[] => {
      // go through each list item
      return currentListItems.map((listItem) => {
        // set isCollapsed to true
        listItem.isCollapsed = true;
        // if list item has lower level nodes
        // collapse lower level nodes recursively
        if (listItem.lowerLevelNodes) {
          listItem.lowerLevelNodes = [
            ...collapseLowerLevelNodesRecursively(listItem.lowerLevelNodes),
          ];
        }
        // return list item
        return listItem;
      });
    },
    []
  );

  /** Assigns isCollapsed property on new items from previous items */
  const setIsCollapsedOnNewItems = useCallback(
    (
      newItems: TExplorerObjectItem[],
      previousItems: TExplorerObjectItem[]
    ): TExplorerObjectItem[] => {
      // go through each new item
      return newItems.map((newItem: TExplorerObjectItem) => {
        // find the previous item with the same id as the new item
        const previousItem = LinkGraphHelperSingleton.getExplorerObjectItemById(
          newItem.id,
          previousItems
        );

        // if previous item is found
        if (previousItem) {
          // assign isCollapsed property to new item from previous item
          newItem.isCollapsed = previousItem.isCollapsed;

          // if new item has children
          if (newItem.lowerLevelNodes && newItem.lowerLevelNodes.length > 0) {
            // recursively assign isCollapsed property to new item's children
            newItem.lowerLevelNodes = [
              ...setIsCollapsedOnNewItems(
                newItem.lowerLevelNodes,
                previousItem.lowerLevelNodes
              ),
            ];
          } else {
            // otherwise, set new item's children to previous item's children
            newItem.lowerLevelNodes = [...previousItem.lowerLevelNodes];
          }
        } else {
          // otherwise, assign isCollapsed property to new item as true
          newItem.isCollapsed = true;

          // if new item has children
          if (newItem.lowerLevelNodes && newItem.lowerLevelNodes.length > 0) {
            // recursively collapse lower level nodes
            newItem.lowerLevelNodes = [
              ...collapseLowerLevelNodesRecursively(newItem.lowerLevelNodes),
            ];
          }
        }

        // return the new item
        return newItem;
      });
    },
    [collapseLowerLevelNodesRecursively]
  );

  /** Builds children from link graph */
  const buildChildren = useCallback(
    (
      linkGraph: TLinkGraphDTO | undefined,
      currentSelectedParent?: TExplorerObjectItem
    ): TExplorerObjectItem[] => {
      // if link graph and current selected parent are not set, return an empty array
      if (!linkGraph && !currentSelectedParent) return [];

      // init new children
      let newChildren: TExplorerObjectItem[] = [];

      // if current selected parent is set
      if (currentSelectedParent) {
        // default children are the selected parent node's lower level nodes
        newChildren = [
          ...setIsCollapsedOnNewItems(
            currentSelectedParent.lowerLevelNodes,
            listItems ?? []
          ),
        ];
      } else if (linkGraph) {
        // otherwise, if link graph is set
        // default child is the focused node with its lower level nodes
        const focusedListItem: TExplorerObjectItem = {
          ...linkGraph.focusedNode,
          lowerLevelNodes: [
            ...setIsCollapsedOnNewItems(
              linkGraph.lowerLevelNodes,
              listItems ?? []
            ),
          ],
          isCollapsed: false,
        };
        newChildren = [focusedListItem];

        // if there is only one parent node
        if (linkGraph.upperLevelNodes.length === 1) {
          // default child is the parent node with its lower level nodes
          const parentListItem: TExplorerObjectItem = {
            ...linkGraph.upperLevelNodes[0],
            lowerLevelNodes: [
              focusedListItem,
              ...setIsCollapsedOnNewItems(
                linkGraph.upperLevelNodes[0].lowerLevelNodes,
                listItems ?? []
              ),
            ],
            isCollapsed: false,
          };
          newChildren = [parentListItem];
        }
      }

      // return new children
      return newChildren;
    },
    [listItems, setIsCollapsedOnNewItems]
  );

  const { entityId, studyId } = useParams();
  const objectId = entityId ?? studyId;
  const objectType: ObjectTypeEnum | undefined = entityId
    ? ObjectTypeEnum.Entity
    : studyId
      ? ObjectTypeEnum.Study
      : undefined;
  useEffect(() => {
    if (!objectId || !objectType) return;

    // only set list items to a new value if:
    // - list items is not set and object edited's link graph is available (explorer first display)
    const isFirstDisplay = !listItems && linkGraphForObjectEdited;

    // - or list items is set but object edited is not in the previous list items or parents (rendering of an object outside previous object edited's link graph)
    const currentChildrenIds: Set<string> =
      LinkGraphHelperSingleton.getChildrenIds(listItems ?? []);
    const currentParentIds: Set<string> =
      LinkGraphHelperSingleton.getChildrenIds(parents ?? []);
    const isObjectOutsideGraph =
      listItems &&
      linkGraphForObjectEdited &&
      !currentChildrenIds.has(objectId) &&
      !currentParentIds.has(objectId);

    // - or a new parent has been selected (by comparing children ids of selected parent with the ones displayed)
    const selectedParent: TExplorerObjectItem | undefined =
      LinkGraphHelperSingleton.getExplorerObjectItemById(
        selectedParentId,
        parents
      );
    let selectedParentChildrenIds: Set<string> =
      LinkGraphHelperSingleton.getChildrenIds(
        selectedParent?.lowerLevelNodes ?? [],
        true
      );
    const listItemsChildrenIds: Set<string> =
      LinkGraphHelperSingleton.getChildrenIds(listItems ?? [], true);
    const didSelectedParentChange =
      listItems &&
      selectedParent &&
      (!SetHelperSingleton.areSetsEqual(
        selectedParentChildrenIds,
        listItemsChildrenIds
      ) ||
        (selectedParentChildrenIds.size === 0 &&
          listItemsChildrenIds.size === 0));

    // if is first display or object is outside graph
    if (isFirstDisplay || isObjectOutsideGraph) {
      // set parent list items using the link graph for object edited
      setParents(cloneDeep([...buildParents(linkGraphForObjectEdited)]));
    }

    // if is first display or object is outside graph or selected parent changed
    if (isFirstDisplay || isObjectOutsideGraph || didSelectedParentChange) {
      // set list items using the link graph for object edited
      setListItems(
        cloneDeep([...buildChildren(linkGraphForObjectEdited, selectedParent)])
      );
    }

    // if the object displayed is a parent, but not the selected one (we just switched to it)
    const objectIdFromURL = SharedToHelperSingleton.getObjectIdFromURL(
      location.pathname
    );
    const parentObjectFromURL = parents?.find(
      (parent) => parent.id === objectIdFromURL
    );
    if (
      didSelectedParentChange &&
      parentObjectFromURL &&
      selectedParent &&
      selectedParentId !== parentObjectFromURL.id
    ) {
      // display the selected one
      ObjectTypeHelperSingleton.navigateBasedOnObjectType(
        selectedParent.objectType,
        selectedParent.id,
        navigate
      );
    }

    // if selected parent changed and selected parent is set
    // we also need to check if the object displayed is a child of one of the parents (but not the selected one)
    if (didSelectedParentChange && selectedParent) {
      // get selected parent direct children ids
      selectedParentChildrenIds = LinkGraphHelperSingleton.getChildrenIds(
        selectedParent.lowerLevelNodes ?? [],
        true
      );

      // go through each parent
      for (const parent of parents ?? []) {
        // get current parent direct children ids
        const parentChildrenIds = LinkGraphHelperSingleton.getChildrenIds(
          parent.lowerLevelNodes ?? [],
          true
        );

        // remove from parent children ids the ones which are in the selected parent children ids
        selectedParentChildrenIds.forEach((id) => parentChildrenIds.delete(id));

        // get object displayed from current parent children ids
        const childDisplayed =
          LinkGraphHelperSingleton.getExplorerObjectItemById(
            objectIdFromURL,
            parent.lowerLevelNodes.filter((node) =>
              parentChildrenIds.has(node.id)
            )
          );

        // if object displayed is a child of one of the parents (but not the selected one)
        if (parent.id !== selectedParentId && childDisplayed) {
          // display the selected parent instead
          ObjectTypeHelperSingleton.navigateBasedOnObjectType(
            selectedParent.objectType,
            selectedParent.id,
            navigate
          );
        }
      }
    }

    // if object displayed is one of the parents, but there is no selected parent
    if (
      parentObjectFromURL &&
      !selectedParent &&
      parents &&
      parents.length > 1
    ) {
      // set selected parent id to the parent object from URL
      setSelectedParentId(parentObjectFromURL.id);
    }
  }, [
    buildChildren,
    linkGraphForObjectEdited,
    listItems,
    location.pathname,
    navigate,
    objectId,
    objectType,
    parents,
    selectedParentId,
  ]);

  // function to get build parents from link graph
  const buildParents = (
    linkGraph: TLinkGraphDTO | undefined
  ): TExplorerObjectItem[] => {
    // if link graph is not set, return an empty array
    if (!linkGraph) return [];

    // init new parents
    const newParents: TExplorerObjectItem[] = [];

    // go throuch each upper level node of the link graph
    for (const upperLevelNode of linkGraph.upperLevelNodes) {
      // add upper level node to new parents
      // add to upper level node's lower level nodes the link graph's focused node (and its lower level nodes)
      // as well as the upper level node's lower level nodes
      newParents.push({
        ...upperLevelNode,
        lowerLevelNodes: [
          {
            ...linkGraph.focusedNode,
            lowerLevelNodes: [...linkGraph.lowerLevelNodes],
          },
          ...upperLevelNode.lowerLevelNodes,
        ],
      });
    }

    // return new parents
    return newParents;
  };

  const openReferenceModal = (
    currObjectId: string,
    currObjectType: ObjectTypeEnum
  ) => {
    setReferenceModalProps({
      id: currObjectId,
      type: currObjectType,
      isOpen: true,
      doIgnoreIsDeleted: false,
    });
  };

  const updateIsCollapsedInListItems = (
    currentListItems: TExplorerObjectItem[],
    listItemToUpdate: TExplorerObjectItem,
    newListItemChildren: TExplorerObjectItem[] | undefined
  ): TExplorerObjectItem[] => {
    // map through each current list item
    return currentListItems.map((currentListItem) => {
      // if current list item is the list item to update
      if (currentListItem.id === listItemToUpdate.id) {
        // toggle isCollapsed
        const isCollapsed = !currentListItem.isCollapsed;

        // if list item is now expanded and new list item children are available
        // set lower level nodes to new list item children
        const lowerLevelNodes = [
          ...(!isCollapsed && newListItemChildren
            ? setIsCollapsedOnNewItems(
                newListItemChildren,
                currentListItem.lowerLevelNodes ?? []
              )
            : currentListItem.lowerLevelNodes),
        ];

        // return a new object with the updated properties
        return { ...currentListItem, isCollapsed, lowerLevelNodes };
      } else if (
        currentListItem.lowerLevelNodes &&
        currentListItem.lowerLevelNodes.length > 0
      ) {
        // otherwise, if current list item has lower level nodes
        // update isCollapsed in lower level nodes
        const lowerLevelNodes = [
          ...updateIsCollapsedInListItems(
            currentListItem.lowerLevelNodes,
            listItemToUpdate,
            newListItemChildren
          ),
        ];

        // return a new object with the updated lowerLevelNodes
        return { ...currentListItem, lowerLevelNodes };
      }

      // return current list item if no updates were made
      return currentListItem;
    });
  };

  const toggleIsCollapsedAsync = async (
    listItemToUpdate: TExplorerObjectItem
  ): Promise<void> => {
    // init new list item's children
    let newListItemChildren: TExplorerObjectItem[] | undefined = undefined;

    // if list item to update is collapsed (going to be expanded)
    if (listItemToUpdate.isCollapsed) {
      // get link graph for the list item (2 levels deep of children because we want to be able to show or not expand chevron icon)
      const linkGraphForListItem: TLinkGraphDTO | undefined =
        await LinkingControllerSingleton.getLinkGraphAsync(
          listItemToUpdate.id,
          listItemToUpdate.objectType,
          2
        );

      // if link graph for the list item is set and lower level nodes is set on it
      if (linkGraphForListItem && linkGraphForListItem.lowerLevelNodes) {
        // set new list item's children to the lower level nodes of the link graph for the list item
        newListItemChildren = [...linkGraphForListItem.lowerLevelNodes];
      } else {
        // otherwise, set new list item's children to undefined
        newListItemChildren = undefined;
      }
    }

    // update isCollapsed in list items and set new list items
    setListItems(
      cloneDeep([
        ...updateIsCollapsedInListItems(
          listItems ?? [],
          listItemToUpdate,
          newListItemChildren
        ),
      ])
    );
    setParents(
      cloneDeep([
        ...updateIsCollapsedInListItems(
          parents ?? [],
          listItemToUpdate,
          newListItemChildren
        ),
      ])
    );
  };

  // function to open explorer object item in explorer's list view
  const openExplorerObjectItemInListView = (): void => {
    // init explorer object item to open
    let explorerObjectItemToOpen: TExplorerObjectItem | undefined = undefined;

    // if selected parent id is set
    if (selectedParentId) {
      // find explorer object item by id in parents
      explorerObjectItemToOpen =
        LinkGraphHelperSingleton.getExplorerObjectItemById(
          selectedParentId,
          parents
        );
    } else {
      // otherwise, open the first item from list items in explorer's list view
      // find the first item in list items
      explorerObjectItemToOpen = listItems?.[0];
    }

    // if explorer object item to open is not set, stop execution, return
    if (!explorerObjectItemToOpen) return;

    // open explorer object item in explorer's list view
    onReanchorClick(
      explorerObjectItemToOpen.id,
      explorerObjectItemToOpen.objectType,
      false
    );
    openGraph(LinksWindowTabsEnum.ListView);
  };

  /** Load parents of selected parent or only parent */
  const loadOneLevelUp = async (): Promise<void> => {
    // if selected parent id is not set, and there are at least 2 parents
    if (!selectedParentId && parents && parents.length >= 2) {
      // show information toast
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Information,
        "Please select a parent."
      );
      // stop execution, return
      return;
    }

    // if less than 1 parent
    if (!parents || parents.length === 0) {
      // show information toast
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Information,
        "No parents found."
      );
      // stop execution, return
      return;
    }

    // get selected parent
    const selectedParent: TExplorerObjectItem | undefined =
      LinkGraphHelperSingleton.getExplorerObjectItemById(
        selectedParentId,
        parents
      );

    // if selected parent is not set, get the first parent
    const parentToLoad: TExplorerObjectItem = selectedParent ?? parents[0];

    // build node ids already visited from items displayed
    const childrenIds: Set<string> = LinkGraphHelperSingleton.getChildrenIds(
      listItems ?? []
    );
    const parentIds: Set<string> = LinkGraphHelperSingleton.getChildrenIds(
      parents ?? []
    );
    parentIds.forEach((id) => childrenIds.add(id));
    childrenIds.delete(parentToLoad.id);
    const nodeIdsAlreadyVisited: string[] = Array.from(childrenIds);

    // get link graph for the parent to load
    const linkGraphForParentToLoad: TLinkGraphDTO | undefined =
      await LinkingControllerSingleton.postLoadMoreLinkGraph(
        parentToLoad.id,
        parentToLoad.objectType,
        nodeIdsAlreadyVisited,
        true
      );

    // if link graph for the parent to load is not set,
    // or there are no upper level nodes in the link graph for the parent to load
    if (
      !linkGraphForParentToLoad ||
      linkGraphForParentToLoad.upperLevelNodes.length < 1
    ) {
      // show information toast
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Information,
        "No parents found."
      );
      // stop execution, return
      return;
    }

    // set selected parent id to undefined
    setSelectedParentId(undefined);

    // if there are at least 2 parents, and selected parent id is set
    if (parents.length >= 2 && selectedParentId) {
      // set lower level nodes to the list items
      // (list items are the children of the selected parent)
      linkGraphForParentToLoad.lowerLevelNodes = [...(listItems ?? [])];
    } else if (parents.length === 1) {
      // otherwise, if there is only one parent
      // set lower level nodes to the lower level nodes of the first item in the list items
      // (list items is just the only parent)
      linkGraphForParentToLoad.lowerLevelNodes = [
        ...(listItems?.[0]?.lowerLevelNodes ?? []),
      ];
    }

    // set parents using the link graph for the parent to load
    setParents(cloneDeep([...buildParents(linkGraphForParentToLoad)]));

    // set list items using the link graph for the parent to load
    setListItems(cloneDeep([...buildChildren(linkGraphForParentToLoad)]));
  };

  /** Listen to any link added using websockets */
  useExplorerAnyLinkAddedListener(
    listItems,
    setListItems,
    parents,
    setParents,
    selectedParentId,
    objectId
  );
  /** Listen to any link removed using websockets */
  useExplorerAnyLinkRemovedListener(
    listItems,
    setListItems,
    parents,
    setParents,
    selectedParentId,
    setSelectedParentId,
    objectId
  );
  /** Listen to object deleted using websockets */
  useExplorerObjectDeletedListener(
    listItems,
    setListItems,
    parents,
    setParents,
    selectedParentId,
    setSelectedParentId,
    objectId,
    objectType
  );

  // render
  return (
    <div className={styles.explorerMenu}>
      <div className={styles.explorerMenuHeaderContainer}>
        <h6 className={styles.menuHeader}>Explorer</h6>
        <div className={styles.menuActions}>
          {parents && parents.length >= 1 && (
            <>
              <button
                type="button"
                title="Load one level up"
                className={`${styles.listActionButton} ${
                  !selectedParentId && parents.length >= 2
                    ? styles.disabled
                    : ""
                }`}
                ref={setLoadOneLevelUpButtonReference}
                onClick={loadOneLevelUp}
                onMouseEnter={() => setIsLoadOneLevelUpButtonTooltipOpen(true)}
                onMouseLeave={() => setIsLoadOneLevelUpButtonTooltipOpen(false)}
              >
                <FontAwesomeIcon
                  icon={faArrowTurnUp}
                  className={styles.menuHeaderIcon}
                />
              </button>
              <Tooltip
                referenceEl={loadOneLevelUpButtonReference}
                isOpen={isLoadOneLevelUpButtonTooltipOpen}
                tooltipText={
                  !selectedParentId && parents.length >= 2
                    ? "No level up available"
                    : "Load one level up"
                }
              />
            </>
          )}
          <button
            type="button"
            title="Edit list"
            className={styles.listActionButton}
            ref={setEditListButtonReference}
            onClick={openExplorerObjectItemInListView}
            onMouseEnter={() => setIsEditListButtonTooltipOpen(true)}
            onMouseLeave={() => setIsEditListButtonTooltipOpen(false)}
          >
            <FontAwesomeIcon
              icon={faPenToSquare}
              className={styles.menuHeaderIcon}
            />
          </button>
          <Tooltip
            referenceEl={editListButtonReference}
            isOpen={isEditListButtonTooltipOpen}
            tooltipText="Edit list"
          />
        </div>
      </div>
      <div className={`${styles.scrollableParent}`}>
        <PubSubExplorerObjectSwitcher
          objects={parents}
          setObjects={setParents}
          objectIdEdited={objectId}
          selectedObjectId={selectedParentId}
          setSelectedObjectId={setSelectedParentId}
        />
        <PubSubExplorerCollapsibleList
          parents={parents}
          items={listItems}
          setItems={setListItems}
          objectIdEdited={objectId}
          openReferenceModal={openReferenceModal}
          toggleIsCollapsedAsync={toggleIsCollapsedAsync}
        />
      </div>
      {referenceModal}
    </div>
  );
};
