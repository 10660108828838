// node_modules
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef } from "react";
// Styles
import buttonStyles from "Styles/buttons.module.scss";
import styles from "./fileInputButton.module.scss";
// Custom hooks
import { useFileUpload } from "Hooks";
// Components
import { FileInput } from "./FileInput";

type TFileInputButtonProps = {
  onFileSelectedAsync?: (file: File) => Promise<void>;
  buttonText?: string;
  icon?: IconDefinition;
  extraClassNames?: {
    fileInputButtonContainer?: string;
    fileInputButton?: string;
    iconContainer?: string;
    optionText?: string;
  };
  fileSizeLimit?: number;
  buttonTitle?: string;
  acceptedMIMETypes?: string[];
};

export const FileInputButton: FC<TFileInputButtonProps> = ({
  onFileSelectedAsync,
  buttonText = "Choose file",
  icon,
  extraClassNames = {},
  fileSizeLimit = 2048000,
  buttonTitle,
  acceptedMIMETypes,
}: TFileInputButtonProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const { onFileInputChangeAsync } = useFileUpload({
    onFileSelectedAsync,
    fileSizeLimit,
    acceptedMIMETypes,
  });

  const onButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
      fileInput.current.click();
    }
  };

  return (
    <div
      title={buttonTitle}
      className={`${styles.fileInputButtonContainer} ${
        extraClassNames.fileInputButtonContainer || ""
      }`}
    >
      <FileInput
        title="File upload button"
        inputRef={fileInput}
        onChange={onFileInputChangeAsync}
      />
      <button
        type="button"
        className={`${buttonStyles.secondaryButton} ${
          extraClassNames.fileInputButton || ""
        }`}
        onClick={onButtonClick}
      >
        {icon && (
          <div className={extraClassNames.iconContainer}>
            <FontAwesomeIcon className={styles.attachFileIcon} icon={icon} />
          </div>
        )}
        <div className={extraClassNames.optionText}>{buttonText}</div>
      </button>
    </div>
  );
};
