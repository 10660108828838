// node_modules
import { FC, ReactNode } from "react";
import {
  faLightbulb,
  faCheck,
  faTriangleExclamation,
  faCircleExclamation,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styles
import styles from "./informationBox.module.scss";

type TInformationBoxProps = {
  title?: string;
  subTitle?: string;
  type: "information" | "success" | "warning" | "error";
  children?: ReactNode;
};

export const InformationBox: FC<TInformationBoxProps> = ({
  title,
  subTitle,
  type,
  children,
}: TInformationBoxProps) => {
  const boxTypeIconMapping: { [type: string]: IconDefinition } = {
    information: faLightbulb,
    success: faCheck,
    warning: faTriangleExclamation,
    error: faCircleExclamation,
  };

  // Render
  return (
    <div className={`${styles.informationBox} ${styles[type]}`}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon
          className={styles.leftIcon}
          icon={boxTypeIconMapping[type]}
        />
      </div>
      <div className={styles.contentContainer}>
        {children || (
          <>
            <div className={styles.title}>{title ?? "Default Title"}</div>
            <div className={styles.actionContainer}>
              {subTitle && <div>{subTitle || "Default Subtitle"}</div>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
