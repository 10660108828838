import { faArrowLeftLong } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Components
import {
  FindestButton,
  FindestTextBox,
  Modal,
  Popover,
  TextArea,
  ToggleSwitch,
  UserItem,
} from "Components";
// Controllers
import { SharedToControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum, SharedToSettingsEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  LogHelperSingleton,
  ToastHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Types
import { TOldUserDTO, TSharedToUserDTO } from "Types";
// Styles
import styles from "./sharingModal.module.scss";

type SharingModalProps = {
  objectId: string;
  objectType: ObjectTypeEnum;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  owner?: string;
};

export const SharingModal: FC<SharingModalProps> = ({
  objectId,
  objectType,
  isOpen,
  setIsOpen,
}: SharingModalProps) => {
  // Ref
  const formRef = useRef<HTMLFormElement>(null);
  // State
  const [emailsToShareWith, setEmailsToShareWith] = useState<string[]>([]);
  const [usersWithAccess, setUsersWithAccess] =
    useState<TSharedToUserDTO[] | undefined>(undefined);
  const [sharedUniverseUsers, setSharedUniverseUsers] = useState<string[]>([]);
  const [currentEmailValue, setCurrentEmailValue] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [suggestedEmails, setSuggestedEmails] = useState<TOldUserDTO[]>([]);
  const [referenceElement, setReferenceElement] =
    useState<HTMLSpanElement | null>(null);
  const [sharedToSettings, setSharedToSettings] =
    useState<SharedToSettingsEnum>(SharedToSettingsEnum.OnlyObject);

  // Memo
  const loweredEmailsToShareWith = useMemo(
    () => emailsToShareWith.map((email) => email.toLowerCase()),
    [emailsToShareWith]
  );
  const loweredEmailsWithAccess = useMemo(
    () =>
      usersWithAccess
        ? usersWithAccess.map((user) => user.email.toLowerCase())
        : [],
    [usersWithAccess]
  );

  const refreshUsersWithAccessAsync = useCallback(async () => {
    if (!objectId) return;

    const users = await SharedToControllerSingleton.getSharedToUsersAsync(
      objectId
    );

    setUsersWithAccess(users);
  }, [objectId]);

  useEffect(() => {
    if (usersWithAccess === undefined) {
      setUsersWithAccess([]);
      refreshUsersWithAccessAsync();
    }
  }, [objectId, refreshUsersWithAccessAsync, usersWithAccess]);

  useEffect(() => {
    const sharedSetting =
      usersWithAccess &&
      usersWithAccess.length > 0 &&
      usersWithAccess.some(
        (user) =>
          user.sharedToSetting === SharedToSettingsEnum.ObjectAndLinkedObjects
      )
        ? SharedToSettingsEnum.ObjectAndLinkedObjects
        : SharedToSettingsEnum.OnlyObject;
    setSharedToSettings(sharedSetting);
  }, [usersWithAccess]);

  const resetModalStateAndCloseAsync = async () => {
    // call parent callbacks
    setIsOpen(false);
    resetModalState();

    // log
    LogHelperSingleton.log("StopSharing");
  };

  const resetModalState = () => {
    // reset state
    setEmailsToShareWith([]);
    setSharedUniverseUsers([]);
    setSuggestedEmails([]);
    setCurrentEmailValue("");
    setMessage("");
  };

  const onEnter = (text: string) => {
    onAddEmail(text);
  };

  const onAddEmailButton = () => {
    if (currentEmailValue.length > 0) {
      onAddEmail(currentEmailValue);
    } else {
      return;
    }
  };

  const onAddEmail = (text: string, doSkipFormCheck = false) => {
    if (!formRef.current) return;
    setSuggestedEmails([]);

    if (!doSkipFormCheck && !formRef.current.checkValidity()) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please fill in a valid email address."
      );
      return;
    }

    if (loweredEmailsToShareWith.includes(text.toLowerCase())) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "This email address is already being added."
      );
      setCurrentEmailValue("");
      return;
    }

    if (loweredEmailsWithAccess.includes(text.toLowerCase())) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "This email address already has access."
      );
      setCurrentEmailValue("");
      return;
    }

    setEmailsToShareWith([...emailsToShareWith, text]);
    setCurrentEmailValue("");
  };

  const onDeleteWhileSharing = (user: TOldUserDTO) => {
    if (!emailsToShareWith) return;

    setEmailsToShareWith(
      emailsToShareWith.filter((email) => email !== user.email)
    );
  };

  const onDeleteUserWithAccess = async (user: TOldUserDTO) => {
    if (!usersWithAccess) return;

    const isSuccess = await SharedToControllerSingleton.unshareToUserAsync(
      objectId,
      user.id
    );

    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Something went wrong while removing access."
      );
      return;
    }

    setUsersWithAccess(usersWithAccess.filter((u) => u.email !== user.email));
  };

  const onSendAsync = async (currentSharedToSettings: SharedToSettingsEnum) => {
    const addedUsers = await SharedToControllerSingleton.shareToUsersAsync(
      objectId,
      objectType,
      emailsToShareWith,
      message,
      currentSharedToSettings
    );

    setCurrentEmailValue("");
    setMessage("");
    setEmailsToShareWith([]);

    if (addedUsers) {
      setSharedUniverseUsers(addedUsers.universeEmails);

      // Map users to include shared to settings
      const mappedAddedUsers = addedUsers.users.map((user) => {
        return {
          ...user,
          sharedToSetting: currentSharedToSettings,
        };
      });

      if (usersWithAccess) {
        setUsersWithAccess([...usersWithAccess, ...mappedAddedUsers]);
      } else {
        setUsersWithAccess(mappedAddedUsers);
      }

      // if there are any errors
      if (addedUsers.errorMessagePerEmail) {
        // go through error message per email dictionary in addedUsers
        for (const [email, errorMessage] of Object.entries(
          addedUsers.errorMessagePerEmail
        )) {
          // show them in a toast
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            `Something went wrong while sharing with ${email}: ${errorMessage}`
          );
        }
      }
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Something went wrong while sharing."
      );
      return;
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onCurrentEmailChange = async (text: string) => {
    setCurrentEmailValue(text);

    const foundUsers = await SharedToControllerSingleton.searchUsersAsync(
      objectId,
      text
    );
    setSuggestedEmails(foundUsers);
  };

  const onMessageChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onSharedToSettingsChangeAsync = async (
    isChecked: boolean,
    currentEmailsToShareWith: string[],
    currentUsersWithAccess: TOldUserDTO[] | undefined
  ): Promise<void> => {
    // get new shared to settings
    const newSharedToSettings: SharedToSettingsEnum = isChecked
      ? SharedToSettingsEnum.ObjectAndLinkedObjects
      : SharedToSettingsEnum.OnlyObject;

    // set state
    setSharedToSettings(newSharedToSettings);

    if (
      currentEmailsToShareWith.length === 0 &&
      currentUsersWithAccess &&
      currentUsersWithAccess.length > 0
    ) {
      // update shared to settings
      const isSuccess: boolean =
        await SharedToControllerSingleton.updateSharedToSettingsAsync(
          objectId,
          newSharedToSettings
        );

      // check if update was successful
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Something went wrong while updating the sharing settings."
        );
      }
    }
  };

  if (sharedUniverseUsers.length > 0) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={resetModalStateAndCloseAsync}
        header="Shared with Universe accounts"
        extraClassNames={{
          container: styles.sharingModal,
          header: styles.header,
        }}
      >
        <div className={styles.sharedWithUniverseAccounts}>
          <div className={styles.topParagraph}>
            <p>
              You just sent a sharing request to the following Universe
              account(s):
            </p>
          </div>
          <div>
            {sharedUniverseUsers.map((email) => {
              const userDto: TOldUserDTO = {
                id: email,
                email: email,
                roles: [],
              };
              return <UserItem key={email} user={userDto} />;
            })}
          </div>
          <div className={styles.bottomParagraph}>
            <p>
              These account will not be visible in the list of people with
              access because people with a Universe account have access to all
              the Universe library items.
            </p>
            <p>
              They did receive an email with the message you want to share this
              item with them.
            </p>
          </div>
        </div>
        <div>
          <FindestButton
            title="Done"
            onClick={() => {
              setSharedUniverseUsers([]);
            }}
          />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetModalStateAndCloseAsync}
      extraClassNames={{
        container: styles.sharingModal,
        header: styles.header,
      }}
      headerProps={{
        text: "Share",
        ...(emailsToShareWith.length > 0
          ? {
              icon: faArrowLeftLong,
              onIconClick: resetModalState,
            }
          : {}),
      }}
    >
      <div
        className={`${styles.emailInputContainer} ${
          emailsToShareWith.length > 0 ||
          (usersWithAccess && usersWithAccess.length > 0)
            ? ""
            : styles.step1
        }`}
      >
        <form ref={formRef} onSubmit={onSubmit}>
          <span className={styles.addEmailContainer} ref={setReferenceElement}>
            <FindestTextBox
              type="email"
              placeholder="Add people by email"
              onEnter={onEnter}
              onChange={onCurrentEmailChange}
              value={currentEmailValue}
              extraClassNameInputElement={styles.emailInput}
            />
            <FindestButton
              title="Add"
              onClick={onAddEmailButton}
              isDisabled={currentEmailValue.length > 0 ? false : true}
              extraClassName={styles.addEmailButton}
            />
          </span>
        </form>
        {suggestedEmails.length > 0 && (
          <Popover
            extraClassName={styles.suggestedEmailsPopup}
            referenceEl={referenceElement}
          >
            {suggestedEmails.map((user) => (
              <div
                onClick={() => {
                  onAddEmail(user.email, true);
                }}
                key={user.email}
                className={styles.suggestedEmail}
              >
                <span className={styles.email}>{user.email}</span>
                {!UserHelperSingleton.isUserExternalByRoles(user.roles) && (
                  <span className={styles.type}>Universe account</span>
                )}
              </div>
            ))}
          </Popover>
        )}
      </div>
      {emailsToShareWith.length > 0 && (
        <>
          <div className={styles.users}>
            {emailsToShareWith.map((email) => {
              const userDto: TOldUserDTO = {
                email: email,
                id: email,
                roles: [],
              };

              return (
                <UserItem
                  key={email}
                  user={userDto}
                  onDeleteClick={onDeleteWhileSharing}
                />
              );
            })}
          </div>
          <TextArea
            extraClassName={styles.messageBox}
            placeholder="Message (optional)"
            value={message}
            onChange={onMessageChanged}
          />
        </>
      )}
      {emailsToShareWith.length === 0 &&
        usersWithAccess &&
        usersWithAccess.length > 0 && (
          <div className={styles.peopleWithAccess}>
            <h3>People with access</h3>
            {usersWithAccess.map((user) => {
              return (
                <UserItem
                  key={user.id}
                  user={user}
                  onDeleteClick={onDeleteUserWithAccess}
                />
              );
            })}
          </div>
        )}
      <div className={styles.sharingSettings}>
        <h3>Settings</h3>
        <ToggleSwitch checked readOnly label="Share this object" />
        <ToggleSwitch
          checked={
            sharedToSettings === SharedToSettingsEnum.ObjectAndLinkedObjects
          }
          onChange={(isChecked: boolean) =>
            onSharedToSettingsChangeAsync(
              isChecked,
              emailsToShareWith,
              usersWithAccess
            )
          }
          label="Share objects linked to this object"
        />
      </div>
      <div className={styles.footer}>
        {emailsToShareWith.length > 0 && (
          <>
            <FindestButton
              title="Send"
              onClick={() => onSendAsync(sharedToSettings)}
            />
            <FindestButton
              buttonType="cancel"
              title="Cancel"
              onClick={resetModalState}
            />
          </>
        )}
        {emailsToShareWith.length === 0 &&
          usersWithAccess &&
          usersWithAccess.length > 0 && (
            <FindestButton
              title="Done"
              onClick={resetModalStateAndCloseAsync}
            />
          )}
      </div>
    </Modal>
  );
};
