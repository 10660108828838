// node_modules
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  faDatabase,
  faFile,
  faMemory,
  faRobot,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { IsFindestAdministrator } from "Components";

export const AdminOverviewPage: FC = () => {
  // Custom hooks
  const navigate = useNavigate();

  // Render
  return (
    <div>
      <IsFindestAdministrator>
        <div
          onClick={() => {
            navigate("/admin/tenantswitcher");
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faUser} /> Tenant switcher
          </h3>
        </div>
        <div
          onClick={() => {
            navigate("/admin/tenants");
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faDatabase} /> Tenants
          </h3>
        </div>
        <div
          onClick={() => {
            navigate("/admin/caching");
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faMemory} /> Caching
          </h3>
        </div>
        <div
          onClick={() => {
            navigate("/admin/igorcasemigration");
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faRobot} /> Igor case migration
          </h3>
        </div>
        <div
          onClick={() => {
            navigate("/admin/import");
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faFile} /> Import
          </h3>
        </div>
      </IsFindestAdministrator>
    </div>
  );
};
