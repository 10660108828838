// node_modules
import { useRef, useState, useEffect, useCallback } from "react";
// Components
import { Popover, FindestButton, Checkbox } from "Components";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// types
import { TAskIgorSettings } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";
// icons
import {
  faChevronDown,
  faChevronUp,
  faArrowUpRightFromSquare,
  faTriangleExclamation,
  faGlobe,
  faFlask,
  faAward,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // Styles
import styles from "./askIgorSettings.module.scss";

type TAskIgorSettingsProps = Readonly<{
  settings?: {
    input?: boolean;
    content?: string;
    documents?: boolean;
    highlights?: boolean;
    info: boolean;
    documentsSelected?: string[];
  };
  outsideClickExceptionDataIdentifier?: string;
  onSettingsChange?: (
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number
  ) => void;
}>;

export function AskIgorSettings({
  settings,
  outsideClickExceptionDataIdentifier = "",
  onSettingsChange,
}: TAskIgorSettingsProps) {
  // Ref
  const dropDownRef = useRef<HTMLDivElement>(null);

  // State
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [sliderWidth, setSliderWidth] = useState<number>(32);
  const [step, setStep] = useState<number>(1);
  const [documentTypes, setDocumentTypes] = useState<TAskIgorSettings[]>([
    { id: ObjectTypeEnum.Weblink, name: "Web", isChecked: true },
    { id: ObjectTypeEnum.ScienceArticle, name: "Science", isChecked: true },
    { id: ObjectTypeEnum.UsPatent, name: "Patents", isChecked: true },
  ]);

  const segementSeperator = { first: 4.1428, second: 2.1333, third: 0.4595 };

  // Custom hooks
  useClickOutsideRef(
    dropDownRef,
    () => {
      setIsDropdownOpen(false);
    },
    [],
    outsideClickExceptionDataIdentifier
  );

  const onDropDownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dataPointsAmount = useCallback(
    (width: number): number | undefined => {
      // Setting dataPointsAmount based on the width of the slider
      if (width == 64) return 25; // To avoid rounding issues
      if (width > 99.5) return undefined; // All/Max
      if (width < 33) return Math.floor(width / segementSeperator.first + 3); // First segment elements: bigger in width (4.1428% each)
      if (width < 64) return Math.floor(width / segementSeperator.second - 5); // 2.1333% each
      return Math.round(width / segementSeperator.third - 116); // 0.4595% each
    },
    [segementSeperator.first, segementSeperator.second, segementSeperator.third]
  );

  const typesSelected = (currDocumentTypes: TAskIgorSettings[]): number[] => {
    const selectedIds = new Set(
      currDocumentTypes.filter((type) => type.isChecked).map((type) => type.id)
    );

    if (selectedIds.has(ObjectTypeEnum.UsPatent)) {
      selectedIds.add(ObjectTypeEnum.MagPatent);
    } else {
      selectedIds.delete(ObjectTypeEnum.MagPatent);
    }

    return Array.from(selectedIds);
  };

  useEffect(() => {
    // 10 and 25 dataPointAmounts to avoid rounding issues
    if (sliderWidth > 63 && sliderWidth < 64.9) {
      setSliderWidth(64);
    }
    if (Math.floor(sliderWidth) == 32 || Math.ceil(sliderWidth) == 32) {
      setSliderWidth(32);
    }
  }, [sliderWidth, isDropdownOpen]);

  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const direction = sliderWidth < parseFloat(e.target.value) ? "add" : "sub";
    let newSelected = parseFloat(e.target.value);

    if (Math.floor(newSelected) == 64 || Math.ceil(newSelected) == 64) {
      newSelected = 64;
    }
    if (Math.floor(newSelected) == 32 || Math.ceil(newSelected) == 32) {
      newSelected = 32;
    }
    if (Math.round(newSelected) == 99) {
      newSelected = 99;
    }

    // Setting the range step on 10 & 25 dataPointAmounts based on the direction
    if (newSelected > 64 || (newSelected === 64 && direction === "add")) {
      setStep(segementSeperator.third);
    } else if (
      newSelected > 32 ||
      (newSelected === 32 && direction === "add")
    ) {
      setStep(segementSeperator.second);
    } else {
      setStep(segementSeperator.first);
    }

    setSliderWidth(newSelected);
    setSubmitDisabled(false);
  };

  const onInputChange = (type?: string) => {
    if (sliderWidth) {
      const modifier = type === "add" ? 1 : -1;
      let inputSkip = 0;

      // Setting the range step on 10 & 25 dataPointAmounts based on the direction
      if (sliderWidth == 64 && type === "add") {
        inputSkip = 0.9 + segementSeperator.third;
      } else if (sliderWidth > 64 || (sliderWidth == 65 && type === "add")) {
        inputSkip = segementSeperator.third;
      } else if (
        sliderWidth > 32 ||
        (Math.round(sliderWidth) === 32 && type === "add")
      ) {
        inputSkip = segementSeperator.second;
      } else {
        inputSkip = segementSeperator.first;
      }

      setStep(inputSkip);

      if (Math.round(sliderWidth) >= 99 && type === "add") {
        setSliderWidth(100);
      } else if (sliderWidth > 99.95 && type !== "add") {
        setSliderWidth(98.903);
      } else {
        setSliderWidth(sliderWidth + modifier * inputSkip);
      }
    }

    setSubmitDisabled(false);
  };

  const onCheckboxChange = (typeId: number, isChecked: boolean) => {
    const newTypes = documentTypes.map((type) =>
      type.id === typeId ? { ...type, isChecked } : type
    );
    setDocumentTypes(newTypes);

    const areAllTypesNotChecked = newTypes.every((type) => !type.isChecked);
    setSubmitDisabled(areAllTypesNotChecked);
  };

  const onSubmit = () => {
    const sliderText = dataPointsAmount(sliderWidth)
      ? `top ${dataPointsAmount(sliderWidth)}`
      : "all";

    if (settings?.info) {
      setTitle("General knowledge");
    } else if (settings?.documentsSelected?.length) {
      setTitle(
        `${settings.documentsSelected.length} selected document${
          settings.documentsSelected.length > 1 ? "s" : ""
        } incl. all document highlights`
      );
    } else if (!settings?.highlights && !settings?.documents) {
      setTitle("All connected documents");
    } else if (
      settings?.highlights &&
      settings?.documents &&
      settings.content
    ) {
      setTitle(
        `${
          settings.content && "Page content"
        }, ${sliderText} highlights, and ${sliderText} documents incl. ${sliderText} highlights per document`
      );
    } else if (settings?.input) {
      setTitle(
        `${sliderText} highlights, and ${sliderText} documents incl. ${sliderText} highlights per document`
      );
    } else {
      setTitle(
        `${sliderText} documents, and per document ${sliderText} highlights`
      );
    }

    onSettingsChange &&
      onSettingsChange(
        typesSelected(documentTypes),
        dataPointsAmount(sliderWidth)
      );
    setSubmitDisabled(true);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    onSubmit();
  }, [settings]);

  return (
    <div ref={dropDownRef} className={styles.divDropdown}>
      <div
        ref={setReferenceElement}
        className={`${styles.divDropdownSelect} ${
          isDropdownOpen ? styles.isOpen : ""
        }`}
        onClick={onDropDownClick}
        aria-hidden="true"
      >
        {settings?.info && (
          <div className={styles.warn}>
            <FontAwesomeIcon icon={faGlobe} />
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )}
        <div className={styles.title}>{title}</div>
        <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
      </div>
      {settings && isDropdownOpen && (
        <Popover
          referenceEl={referenceElement}
          isOpen={isDropdownOpen}
          placement="bottom-start"
        >
          <div className={styles.AskIgorSettings}>
            {settings.info ? (
              <div className={styles.section}>
                <h4 className={styles.info}>
                  My answer will be based on{" "}
                  <b>general knowledge of the internet</b> that the model was
                  trained on.
                </h4>
                <div className={styles.warning}>
                  <span>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                  <p>
                    This affects the accuracy of my answer, so always double
                    check.
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.findest.com/contributor-workflows-and-features/build-reports-with-ai#sources-used-per-task"
                    >
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.section}>
                  <h4>Sources used to generate content</h4>
                  {settings.documentsSelected?.length ? (
                    <>
                      <h4 className={styles.info}>
                        You selected {settings.documentsSelected.length}{" "}
                        document
                        {settings.documentsSelected.length > 1 ? "s" : ""}.
                      </h4>
                      <h4 className={styles.info}>
                        To change the selection, or use the automatic selection
                        by our ranker, go back to the bottom of the page.
                        Deselect all to make use of our automatic selection.
                      </h4>
                    </>
                  ) : (
                    <div className={styles.table}>
                      <div className={styles.column}>
                        <h4>Page content</h4>
                        <p>{settings.content ?? "-"}</p>
                      </div>
                      <div className={styles.column}>
                        <h4>Documents linked</h4>
                        {!settings.documents && (
                          <p>All documents (no highlights)</p>
                        )}
                        {settings.documents &&
                          (!dataPointsAmount(sliderWidth) ? (
                            <p>
                              <span>All</span> + <span>all</span> highlights
                            </p>
                          ) : (
                            <p>
                              Top <span>{dataPointsAmount(sliderWidth)}</span> +
                              top <span>{dataPointsAmount(sliderWidth)}</span>{" "}
                              highlights
                            </p>
                          ))}
                      </div>
                      <div className={styles.column}>
                        <h4>Highlights linked</h4>
                        {!settings.highlights && <p>-</p>}
                        {settings.highlights &&
                          (!dataPointsAmount(sliderWidth) ? (
                            <p>
                              <span>All</span>{" "}
                            </p>
                          ) : (
                            <p>
                              Top <span>{dataPointsAmount(sliderWidth)}</span>
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.findest.com/contributor-workflows-and-features/build-reports-with-ai#sources-used-per-task"
                    className={styles.moreInformation}
                  >
                    More information
                    <span>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </span>
                  </a>
                </div>
                {!settings.documentsSelected?.length && (
                  <>
                    {settings.documents && (
                      <div className={styles.section}>
                        <h4>Amount of documents and highlights</h4>
                        <div className={styles.selection}>
                          <div className={styles.slider}>
                            <div className={styles.rangeItem}>
                              <div className={`${styles.rangeInput}`}>
                                <input
                                  type="range"
                                  min="3"
                                  max="100"
                                  step={step}
                                  className={styles.formRange}
                                  value={sliderWidth}
                                  onChange={onSliderChange}
                                />
                                <div className={styles.rangeLine}>
                                  <span
                                    className={styles.activeLine}
                                    style={{
                                      width: `${(
                                        (sliderWidth * 100) / 97 -
                                        3
                                      ).toFixed(2)}%`,
                                    }}
                                  ></span>
                                </div>
                                <div className={styles.dotLine}>
                                  <span
                                    className={styles.activeDot}
                                    style={{
                                      left: `${(
                                        (sliderWidth * 100) / 97 -
                                        3
                                      ).toFixed(2)}%`,
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <ul>
                                {[3, 10, 25, "All"].map((val, index) => (
                                  <li
                                    key={val}
                                    className={`${
                                      index <= sliderWidth / 32
                                        ? styles.active
                                        : ""
                                    }`}
                                  >
                                    <span>{val}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className={styles.input}>
                            <div className={styles.inputHolder}>
                              <p>
                                {dataPointsAmount(sliderWidth)
                                  ? dataPointsAmount(sliderWidth)
                                  : "All"}
                              </p>
                              <div>
                                <FontAwesomeIcon
                                  icon={faChevronUp}
                                  className={
                                    sliderWidth >= 99.5 ? styles.disabled : ""
                                  }
                                  onClick={() => {
                                    sliderWidth < 100 && onInputChange("add");
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className={
                                    sliderWidth < 5 ? styles.disabled : ""
                                  }
                                  onClick={() => {
                                    sliderWidth > 5 && onInputChange();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {!dataPointsAmount(sliderWidth) && (
                          <div className={styles.warning}>
                            <span>
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                            </span>
                            <p>
                              Beware that increasing the amount of sources might
                              cause problems.
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://docs.findest.com/contributor-workflows-and-features/build-reports-with-ai#sources-used-per-task"
                              >
                                Read more
                              </a>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={styles.section}>
                      <h4>Filter document type</h4>
                      <div className={styles.types}>
                        {documentTypes.map((label, index) => (
                          <div key={label.id}>
                            <Checkbox
                              onCheckboxChange={(isChecked: boolean) => {
                                onCheckboxChange(label.id, isChecked);
                              }}
                              isChecked={label.isChecked}
                            />
                            <label htmlFor={label.name}>
                              <FontAwesomeIcon
                                icon={[faGlobe, faFlask, faAward][index]}
                              />{" "}
                              {label.name}
                            </label>
                          </div>
                        ))}
                      </div>
                      {documentTypes.every((type) => !type.isChecked) && (
                        <div className={styles.warningText}>
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          <p>Select at least one document type</p>
                        </div>
                      )}
                    </div>
                    <div>
                      <FindestButton
                        title="Apply changes"
                        onClick={onSubmit}
                        isDisabled={
                          submitDisabled ||
                          documentTypes.every((type) => !type.isChecked)
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
}
