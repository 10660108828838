import { Editor } from "@tiptap/core";
import { FC } from "react";
import {
  EditorMenuCommands,
  EditorMenuLinkCommand,
} from "./EditorMenuItems";
import {
  BOLD_COMMAND,
  ITALIC_COMMAND,
  HIGHLIGHT_COMMAND,
  LIST_COMMANDS,
} from "Helpers";
import styles from "./compactEditorMenu.module.scss";

interface ICompactEditorMenuProps {
  editor: Editor | null;
}

export const CompactEditorMenu: FC<ICompactEditorMenuProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={styles.editorMenu}>
      <EditorMenuCommands
        commands={[
          BOLD_COMMAND,
          ITALIC_COMMAND,
          HIGHLIGHT_COMMAND,
          ...LIST_COMMANDS,
        ]}
        editor={editor}
        doShowTooltip={true}
        doNotShowCommandLabels
      />
      <div className={styles.editorSpacer} />
      <div className={styles.editorMenuCommands}>
        <EditorMenuLinkCommand editor={editor} />
      </div>
    </div>
  );
};
