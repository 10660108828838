// node_modules
import {
  faArrowUpRightFromSquare,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Components
import {
  AddImageModal,
  CreatedByAccount,
  DocumentAttachment,
  DocumentHighlights,
  DocumentImages,
  DocumentMainContents,
  DocumentMetaData,
  DropdownButton,
  FindestButton,
  LinkCreatedEntityModal,
  MainTitle,
  ObjectSearchPopupContent,
  Popover,
  PubSubConnectedObjects,
  Tabs,
  TextSelectionMenuPopup,
  Tooltip,
} from "Components";
import { DocumentSearchResult } from "Components/Queries/SearchResults";
// Constants
import { GeneralConstants } from "Constants";
// Helpers
import {
  DateHelperSingleton,
  ImageHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Hooks
import {
  useLinkNewEntityToQuery,
  useObjectReferenceModal,
  useTextSelection,
} from "Hooks";
// Contexts
import {
  AuthContext,
  ElementVisibilityContext,
  QueryContext,
  defaultQueryViewOptionsContext,
} from "Providers";
// Types
import {
  TDropdownButtonOption,
  THeaderContentDTO,
  THighlightDTO,
  TIdNameTypeObjectType,
  TImageDTO,
  TTab,
} from "Types";
// Styles
import "Styles/documentHighlights.scss";
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";
// Controllers
import {
  DocumentControllerSingleton,
  HighlightControllerSingleton,
  ImageControllerSingleton,
  SavedFileControllerSingleton,
} from "Controllers";
// Enums
import {
  DocumentViewTabItemEnum,
  EntityTypeEnum,
  ObjectTypeEnum,
  SavedDocumentTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Interfaces
import {
  IDocumentDetails,
  IDocumentSearchResult,
  IEntityDTO,
} from "Interfaces";

type TDocumentViewProps = {
  document: IDocumentDetails;
  onSaveElementClick: (
    element: TIdNameTypeObjectType,
    closeSavePopupCallback?: () => void
  ) => void;
  updateDocument?: (document: IDocumentDetails) => void;
  refreshDocuments?: () => void;
  updateHighlights?: (highlights: THighlightDTO[]) => void;
  onDeleteImage?: (image: TImageDTO) => void;
  onAddImage?: (image: TImageDTO, caption?: string) => void;
  doShowGoToUrlButton?: boolean;
  isMainTitleEditable?: boolean;
  onUpdateTitle?: (newTitle: string) => void;
  isInModal?: boolean;
};

export const DocumentView: FC<TDocumentViewProps> = ({
  document,
  updateDocument,
  onSaveElementClick,
  refreshDocuments,
  updateHighlights,
  onDeleteImage,
  onAddImage,
  doShowGoToUrlButton = true,
  isMainTitleEditable = false,
  isInModal,
  onUpdateTitle,
}) => {
  const documentUrl = document.fullUrl
    ? document.fullUrl
    : document.url
    ? document.url
    : undefined;

  // Context
  const { query } = useContext(QueryContext);
  const { auth, isUserExternal } = useContext(AuthContext);
  const { canUserEdit, isDocumentDropdownVisible } = useContext(
    ElementVisibilityContext
  );

  const documentDetailsTabs = (): TTab[] => {
    if (document.documentType === SavedDocumentTypeEnum.ScienceArticle) {
      return [
        { name: DocumentViewTabItemEnum.DocumentInformation },
        { name: DocumentViewTabItemEnum.SimilarDocuments },
        { name: DocumentViewTabItemEnum.Attachment },
      ];
    } else {
      return [
        { name: DocumentViewTabItemEnum.DocumentInformation },
        { name: DocumentViewTabItemEnum.Attachment },
      ];
    }
  };

  // State
  const [highlightedContent, setHighlightedContent] =
    useState<undefined | THeaderContentDTO[]>(undefined);
  const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
  const [isLinkCreatedEntityModalOpen, setIsLinkCreatedEntityModalOpen] =
    useState<boolean>(false);
  const [containerElementReference, setContainerElementReference] =
    useState<HTMLDivElement | null>(null);
  const [scrollPositionTop, setScrollPositionTop] = useState<number>(0);
  const [isScrollPositionTop, setIsScrollPositionTop] = useState<boolean>(true);
  const [scrollPositionTopOnSelectedText, setScrollPositionTopOnSelectedText] =
    useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(
    window.innerHeight * 0.9
  );
  const [objectTryingToAdd, setObjectTryingToAdd] =
    useState<IEntityDTO | undefined>(undefined);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(
    documentDetailsTabs()[0].name
  );
  const [similarDocuments, setSimilarDocuments] = useState<
    IDocumentSearchResult[]
  >([]);
  const [attachment, setAttachment] = useState<boolean | undefined>(undefined);
  const [moreActions, setMoreActions] = useState<string>("");
  const [
    isEntityBeingSavedFromModalAfterTextSelection,
    setIsEntityBeingSavedFromModalAfterTextSelection,
  ] = useState<boolean>(false);
  const [isOpenArticleButtonTooltipOpen, setIsOpenArticleButtonTooltipOpen] =
    useState<boolean>(false);

  // Refs
  const openArticleButtonContainerRef = useRef<HTMLDivElement>(null);

  // Memos
  const documentObjectType = useMemo(
    () =>
      ObjectTypeHelperSingleton.documentTypeToObjectType(document.documentType),
    [document.documentType]
  );

  const isLocalFile = useMemo(() => {
    if (!document || !documentUrl) return false;
    return documentUrl.includes("file://");
  }, [document, documentUrl]);

  const documentTitle = useMemo(() => {
    if (!document) return "";

    // External users should not see the paths of local files
    if (!isLocalFile || !isUserExternal) return document.title;

    const splitUrl = document.title.split("/");
    return splitUrl[splitUrl.length - 1];
  }, [document, isLocalFile, isUserExternal]);

  // Custom hooks
  const {
    setSelectedTextOnMouseEvent,
    selectedText,
    selectionBoundingClientRect,
  } = useTextSelection();
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();
  const { linkNewEntityToQueryAsync } = useLinkNewEntityToQuery(
    query,
    document,
    documentObjectType,
    setIsLinkCreatedEntityModalOpen,
    updateDocument
  );

  const requestContentHighlighting = useCallback(async () => {
    if (!query) return;
    if (highlightedContent) return;

    const response =
      await HighlightControllerSingleton.getHighlightContentAsync(
        query.id,
        document.id,
        document.mainContents || []
      );

    if (response) {
      setHighlightedContent(response.contents);
    }
  }, [document.id, document.mainContents, highlightedContent, query]);

  useEffect(() => {
    // When opening a document with main contents that is not highighted yet
    // we request the server to highlight the contents
    if (!document.mainContents || highlightedContent) return;

    (async () => {
      await requestContentHighlighting();
    })();
  }, [document.mainContents, highlightedContent, requestContentHighlighting]);

  useEffect(() => {
    (async () => {
      const linkedFiles = await SavedFileControllerSingleton.getLinkedToObject(
        document.id
      );
      if (linkedFiles?.length) {
        setAttachment(true);
      } else {
        setAttachment(false);
      }
    })();

    if (document.documentType === SavedDocumentTypeEnum.ScienceArticle) {
      (async () => {
        // get similar science articles
        const similarScienceArticles =
          await DocumentControllerSingleton.getRelatedScienceArticles(
            document.id
          );
        if (similarScienceArticles) {
          setSimilarDocuments(similarScienceArticles);
        }
      })();
    }
  }, [document.id, document.documentType]);

  const tryingToAddObject = (object: IEntityDTO) => {
    setObjectTryingToAdd(object);
    setIsEntityBeingSavedFromModalAfterTextSelection(true);
  };

  useEffect(() => {
    setIsLinkCreatedEntityModalOpen(!!objectTryingToAdd);
  }, [objectTryingToAdd]);

  const openArticleLink = () => {
    if (!doShowGoToUrlButton || !documentUrl) return;

    // log
    const logProperties: { DocumentId: string; QueryGuid?: string } = {
      DocumentId: document.id,
    };
    if (query) {
      logProperties.QueryGuid = query.guid;
    }
    LogHelperSingleton.logWithProperties("GotoDocumentUrl", logProperties);

    // Check if the file is on the local file system
    if (documentUrl.includes("file://")) {
      // If the file is on the local file system then indicate that chrome is not allowed
      // to open the file file
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Chrome is not allowed to open local files. We have copied the file path to your clipboard. You can paste it in the address bar yourself if needed."
      );
      navigator.clipboard.writeText(documentUrl);
      return;
    }

    // open document in new tab
    window.open(documentUrl, "_blank", "noopener noreferrer");
  };

  const scrollEvent = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    const target = mouseEvent.target as HTMLDivElement;
    setScrollPositionTop(target.scrollTop);
    setScrollHeight(target.scrollHeight);
    if (target.scrollTop > 0) {
      setIsScrollPositionTop(false);
    } else {
      setIsScrollPositionTop(true);
    }
  };

  const onSelectedTextOnMouseEvent = (mouseEvent: MouseEvent<HTMLElement>) => {
    setSelectedTextOnMouseEvent(mouseEvent);
    setScrollPositionTopOnSelectedText(scrollPositionTop);
  };

  const onImageDeletedAsync = async (image: TImageDTO) => {
    // If the user is readonly then do nothing
    if (!canUserEdit) return;

    // Confirm with the user that they want to delete the image
    if (!confirm("Are you sure you want to delete this image?")) return;

    // Call on the server to the delete the image
    const isSuccess = await ImageControllerSingleton.deleteObjectImageAsync(
      image.id,
      document.id,
      ObjectTypeHelperSingleton.documentTypeToObjectType(document.documentType)
    );

    // Check if the image was deleted successfully
    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not delete image."
      );
      return;
    }

    // log
    LogHelperSingleton.log("RemoveDocumentImage");

    if (refreshDocuments) {
      refreshDocuments();
    }

    if (onDeleteImage) {
      onDeleteImage(image);
    }
  };

  const onCreateNewEntity = async (text: string) => {
    setIsLinkCreatedEntityModalOpen(true);
    setObjectTryingToAdd({
      title: text,
      type: EntityTypeEnum.Undefined,
    } as IEntityDTO);
  };

  const clickActionsOption = async (option: TDropdownButtonOption) => {
    // If the user is readonly then do nothing
    if (!canUserEdit) return;

    if (option === "add image") {
      setIsImageModalOpen(true);
    } else if (option === "add highlight") {
      await onAddHighlightClickAsync();
    } else if (option === "add file") {
      setActiveTab(DocumentViewTabItemEnum.Attachment);
      if (attachment) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "There is an attachment already. Please replace it."
        );
        setMoreActions("");
      } else {
        setMoreActions("upload");
      }
    }
  };

  const onAddHighlightClickAsync = async (): Promise<void> => {
    // If the current document is not set or the user is readonly then do nothing
    if (!document || !canUserEdit) {
      return;
    }

    // create empty highlight and add it to document
    let linkedEmptyHighlight: THighlightDTO | undefined =
      await HighlightControllerSingleton.addEmptyHighlightToDocumentAsync(
        document.id,
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          document.documentType
        ),
        {
          ActionOrigin: "DocumentView",
        }
      );

    // safety-checks
    if (!linkedEmptyHighlight) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not add highlight."
      );
      return;
    }

    // set doAutoTurnEditModeOn to true on created linked empty highlight
    // in order to turn edit mode on automatically in the highlight component
    linkedEmptyHighlight = {
      ...linkedEmptyHighlight,
      doAutoTurnEditModeOn: true,
    };

    // add created linked empty highlight to document highlights
    if (updateHighlights) {
      updateHighlights([...document.highlights, linkedEmptyHighlight]);
    }
    if (refreshDocuments) {
      refreshDocuments();
    }
  };

  const onImageSubmitted = async (image: File, caption?: string) => {
    // If the user is readonly then do nothing
    if (!canUserEdit) return;

    const newImage: TImageDTO | undefined =
      await ImageHelperSingleton.addImageToObjectAsync(
        image,
        document.id,
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          document.documentType
        ),
        caption
      );

    if (!newImage) return;

    // If the image was added successfully then add it to the current document
    if (onAddImage) {
      onAddImage(newImage, caption);
    }

    if (refreshDocuments) {
      refreshDocuments();
    }
  };

  const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
    setReferenceModalProps((previousReferenceModalProps) => {
      return {
        ...previousReferenceModalProps,
        isOpen: true,
        id: objectId,
        type: objectType,
      };
    });
  };

  const updateSimilarDocuments = (updatedDocument: IDocumentSearchResult) => {
    setSimilarDocuments(
      similarDocuments.map((similarDocument) => {
        if (similarDocument.documentId === updatedDocument.documentId) {
          return updatedDocument;
        }
        return similarDocument;
      })
    );
  };

  const onLinkCreatedEntityModalClose = () => {
    setIsEntityBeingSavedFromModalAfterTextSelection(false);
  };

  const onCreateNewEntityClick = async (
    entity: IEntityDTO,
    linkType: string,
    linkedObject?: TIdNameTypeObjectType
  ) => {
    linkNewEntityToQueryAsync(
      entity,
      linkType,
      linkedObject,
      isEntityBeingSavedFromModalAfterTextSelection
    );
  };

  return (
    <div
      className={`${entityLikeCardStyles.entityLikeCard} ${
        isInModal ? entityLikeCardStyles.isInModal : ""
      }`}
      onScroll={scrollEvent}
    >
      {!isInModal && (
        <div
          className={`${entityLikeCardStyles.isScrollingElement} ${
            isScrollPositionTop ? "" : entityLikeCardStyles.isScrolling
          }`}
        ></div>
      )}
      <div className={entityLikeCardStyles.entityLikeCardHeaderContainer}>
        <div
          className={
            entityLikeCardStyles.entityLikeCardHeaderContainerTopContent
          }
        >
          {doShowGoToUrlButton && (
            <div ref={openArticleButtonContainerRef}>
              <FindestButton
                title="Open article"
                buttonType={"secondary"}
                isDisabled={!documentUrl}
                rightIconName={faArrowUpRightFromSquare}
                onClick={openArticleLink}
                onMouseOver={() => setIsOpenArticleButtonTooltipOpen(true)}
                onMouseOut={() => setIsOpenArticleButtonTooltipOpen(false)}
              />
              <Tooltip
                referenceEl={openArticleButtonContainerRef.current}
                isOpen={isOpenArticleButtonTooltipOpen}
                tooltipText={
                  documentUrl ? documentUrl : "The document has no URL"
                }
                placement="bottom-start"
              />
            </div>
          )}
          <div
            className={
              entityLikeCardStyles.entityLikeCardHeaderContainerTopContentRight
            }
          >
            {isDocumentDropdownVisible && (
              <DropdownButton
                isButtonEnabled={true}
                optionLabels={["add image", "add highlight", "add file"]}
                onClickOption={clickActionsOption}
                extraClassNames={{
                  optionsContainer: entityLikeCardStyles.optionsContainer,
                  dropdownButton: entityLikeCardStyles.optionsButton,
                  optionText: `${entityLikeCardStyles.optionText} ${entityLikeCardStyles.optionTextActions}`,
                }}
                buttonText="actions"
                iconNameRight={faChevronDown}
              />
            )}
            {document.createdByUsername && document.dateAdded && (
              <CreatedByAccount
                email={document.createdByUsername}
                createdDate={DateHelperSingleton.getDateWithYear(
                  document.dateAdded
                )}
              />
            )}
          </div>
        </div>
        <MainTitle
          title={documentTitle}
          isEditable={isMainTitleEditable}
          onMouseUp={onSelectedTextOnMouseEvent}
          onUpdateTitle={isMainTitleEditable ? onUpdateTitle : undefined}
        />
      </div>
      {!UserHelperSingleton.isSharingRestrictedToObject(auth) && (
        <>
          <PubSubConnectedObjects
            mainObjectId={document.id}
            mainObjectType={documentObjectType}
            connectedObjects={document.connectedObjects}
            onConnectToObjectClick={() => {
              setIsSavePopupOpen(true);
            }}
            extraClassName={entityLikeCardStyles.connectedObjectsContainer}
            setContainerElementReference={setContainerElementReference}
            doHideTitleOnEmptyOrUnsetConnectedObjects={true}
            disableConnectToNewObjectButton={
              UserHelperSingleton.isUserViewer(auth) ||
              UserHelperSingleton.isUserExternalByAuth(auth)
            }
          />
          <Popover
            referenceEl={containerElementReference}
            placement="bottom-start"
            isOpen={isSavePopupOpen}
            exceptionDataIdentifiter={
              GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
            }
            onClickOutside={() => {
              setIsSavePopupOpen(false);
            }}
            extraClassName={entityLikeCardStyles.objectSearchPopupContainer}
          >
            <ObjectSearchPopupContent
              currentObjectId={document.id}
              onElementClick={(element) => {
                onSaveElementClick(element, () => {
                  setIsSavePopupOpen(false);
                });
              }}
              doShowRecentActivity={true}
              initialLinkedObjects={query?.connectedObjects}
              initialLinkedObjectsTitle="Query Connections"
              doShowCreateButton
              onCreateClick={onCreateNewEntity}
              openReferenceModal={openReferenceModal}
            />
          </Popover>
        </>
      )}
      <div className={entityLikeCardStyles.tabsContainer}>
        <Tabs
          tabs={documentDetailsTabs()}
          onSelectedTabChange={setActiveTab}
          defaultSelectedTab={activeTab}
          theme="compact"
          disabledTabs={
            document.documentType === SavedDocumentTypeEnum.ScienceArticle &&
            similarDocuments.length === 0
              ? [documentDetailsTabs()[1]]
              : []
          }
          {...(similarDocuments.length > 0
            ? {
                extraTabNaming: {
                  [DocumentViewTabItemEnum.SimilarDocuments.toString()]: ` (${similarDocuments.length})`,
                },
              }
            : {})}
          {...(attachment && {
            extraTabNaming: {
              [DocumentViewTabItemEnum.Attachment.toString()]: " (1)",
            },
          })}
        />
      </div>
      <div className={entityLikeCardStyles.entityLikeCardContentContainer}>
        {activeTab === DocumentViewTabItemEnum.DocumentInformation && (
          <div className={entityLikeCardStyles.informationContainer}>
            <DocumentMainContents
              document={document}
              onSelectedTextOnMouseEvent={onSelectedTextOnMouseEvent}
              highlightedContent={highlightedContent}
            />
            <div className={entityLikeCardStyles.rightContentContainer}>
              <DocumentMetaData
                document={document}
                onSelectedTextOnMouseEvent={onSelectedTextOnMouseEvent}
              />
              <DocumentHighlights
                document={document}
                updateHighlights={(highlights) => {
                  if (updateHighlights) {
                    updateHighlights(highlights);
                  }
                  if (refreshDocuments) {
                    refreshDocuments();
                  }
                }}
              />
              <DocumentImages
                document={document}
                onImageDeletedAsync={onImageDeletedAsync}
              />
            </div>
          </div>
        )}
        {activeTab === DocumentViewTabItemEnum.SimilarDocuments && (
          <div className={entityLikeCardStyles.listContainer}>
            {similarDocuments.map((similarDocument) => (
              <DocumentSearchResult
                key={similarDocument.documentId}
                document={similarDocument}
                doIncludeSaveButton
                updateDocument={updateSimilarDocuments}
                queryViewOptions={
                  defaultQueryViewOptionsContext.allQueryViewOptions
                }
                hideZeroScoreMetadata
              />
            ))}
          </div>
        )}
        {activeTab === DocumentViewTabItemEnum.Attachment && (
          <div className={entityLikeCardStyles.informationContainer}>
            <DocumentAttachment
              document={document}
              updateDocument={(has) => {
                setAttachment(has);
                setMoreActions("");
              }}
              moreActions={moreActions}
            />
          </div>
        )}
      </div>
      <TextSelectionMenuPopup
        selectedText={selectedText}
        selectionBoundingClientRect={selectionBoundingClientRect}
        documentId={document.id}
        documentType={document.documentType}
        isDocumentAlreadySaved={
          !!document.createdByUsername && !!document.dateAdded
        }
        addedObject={tryingToAddObject}
        scrollHeight={scrollHeight}
        scrollPositionTop={scrollPositionTopOnSelectedText}
        onCreateDocumentHighlight={(createdHighlight) => {
          if (updateHighlights) {
            updateHighlights([...document.highlights, createdHighlight]);
          }
          if (refreshDocuments) {
            refreshDocuments();
          }
        }}
      />
      {isLinkCreatedEntityModalOpen && (
        <LinkCreatedEntityModal
          creatingEntity={objectTryingToAdd}
          isOpen={isLinkCreatedEntityModalOpen}
          setIsOpen={setIsLinkCreatedEntityModalOpen}
          onCreateEntityClickAsync={onCreateNewEntityClick}
          initialLinkedObjects={query?.connectedObjects}
          onClose={onLinkCreatedEntityModalClose}
        />
      )}
      <AddImageModal
        isOpen={isImageModalOpen}
        setIsOpen={setIsImageModalOpen}
        onAddImage={onImageSubmitted}
        hasCaption={true}
      />
      {referenceModal}
    </div>
  );
};
