// node_modules
import { Editor } from "@tiptap/react";
import { FC, useCallback, useContext, useEffect, useState } from "react";
// Components
import { Popover, RatingStar } from "Components";
// Styles
import styles from "./ratingProgressPopover.module.scss";
// Custom hooks
import { useRating } from "Hooks";
// Providers
import { EditorContext } from "Providers";
// Interfaces
import { IObject } from "Interfaces";

type TRatingProgressPopoverProps = {
  editor: Editor;
  isOpen: boolean;
  referenceElement: HTMLDivElement | null;
};

export const RatingProgressPopover: FC<TRatingProgressPopoverProps> = ({
  isOpen,
  referenceElement,
  editor,
}: TRatingProgressPopoverProps) => {
  const { objectEdited } = useContext(EditorContext);

  const [averageRatingInPercent, setAverageRatingInPercent] =
    useState<number>(0);
  const [totalNumberOfRatings, setTotalNumberOfRatings] = useState<number>(0);
  const [numberOfRatingsDone, setNumberOfRatingsDone] = useState<number>(0);

  const { getRatingState } = useRating({});

  const refreshObjectRating = useCallback(
    (currEditor: Editor, currObjectEdited: IObject | null) => {
      if (!currObjectEdited) return;

      const ratingState: {
        ratingsDoneCount: number;
        ratingsToDoCount: number;
      } = getRatingState(
        currEditor,
        currObjectEdited.id,
        currObjectEdited.objectType
      );

      setTotalNumberOfRatings(
        ratingState.ratingsDoneCount + ratingState.ratingsToDoCount
      );

      setNumberOfRatingsDone(ratingState.ratingsDoneCount);

      const newAverageRatingInPercent =
        ((ratingState.ratingsDoneCount /
          (ratingState.ratingsDoneCount + ratingState.ratingsToDoCount)) *
          100) /
        100;
      setAverageRatingInPercent(
        isNaN(newAverageRatingInPercent) ? 0 : newAverageRatingInPercent
      );
    },
    [getRatingState]
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    refreshObjectRating(editor, objectEdited);
  }, [isOpen, refreshObjectRating, editor, objectEdited]);

  return (
    <Popover
      extraClassName={styles.ratingProgressPopover}
      popoverOffset={8}
      placement="left-start"
      referenceEl={referenceElement}
      isOpen={isOpen}
      showInPortal
    >
      <h3>Rating progress</h3>
      <RatingStar
        extraClassNames={{ container: styles.ratingStarContainer }}
        size="xlarge"
        rating={averageRatingInPercent}
        isRatingShown={true}
        doShowRatingAsPercentage={true}
      />
      <h6>{`${numberOfRatingsDone}/${totalNumberOfRatings} RATINGS`}</h6>
    </Popover>
  );
};
