// React
import { faTrashRestore } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
// Interfaces
import { IEntityDTO, IUndeleteObjectsDTO } from "Interfaces";
// Enums
import {
  ObjectTypeEnum,
  OrderByEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Hooks
import { useCheckboxedList, useObjectReferenceModal } from "Hooks";
// Types
import { TButtonDefinition, TEntitiesDTO } from "Types";
// Controllers
import { EntityControllerSingleton } from "Controllers";
// Helpers
import {
  EditorHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Styles
import entityLikeListStyles from "Styles/EntityLikeList/entityLikeList.module.scss";
import styles from "./deletedArchive.module.scss";
// Shared Components
import { EntityItem, ListHeader } from "Components";
// Constants
import { EntityConstants } from "Constants";

export const DeletedEntitiesArchive: FC = () => {
  // State
  const [entities, setEntities] = useState<IEntityDTO[]>([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [totalEntityCount, setTotalEntityCount] = useState<number>(0);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);

  // Hooks
  const {
    selectedItems: selectedEntities,
    setSelectedItems: setSelectedEntities,
    areAllItemsSelected: areAllEntitiesSelected,
    isAnyItemSelected: isAnyEntitySelected,
    onSelectAllItems,
    onSelectItem,
  } = useCheckboxedList<IEntityDTO>(
    entities,
    "Entity",
    "Entities",
    (entity) => ({
      id: entity.id,
      type: "Entity",
      name: entity.title,
      objectType: ObjectTypeEnum.Entity,
      description: entity.description,
    })
  );
  const {
    referenceModal,
    closeReferenceModal,
    referenceModalProps,
    setReferenceModalProps,
  } = useObjectReferenceModal({
    title: "Restore",
    icon: faTrashRestore,
    onClick: () => {
      onRestoreButtonClick(
        referenceModalProps.id,
        entities.find((entity) => entity.id === referenceModalProps.id)
          ?.description
      );
    },
  });

  const refreshEntitiesAsync = async (
    fromDate: Date | undefined,
    currentSortType: SortTypeEnum
  ): Promise<void> => {
    const orderByType =
      currentSortType === SortTypeEnum.Oldest
        ? OrderByEnum.Ascending
        : OrderByEnum.Descending;
    const newEntities: TEntitiesDTO =
      await EntityControllerSingleton.getDeletedAsync(orderByType, fromDate);

    // set state variables
    setEntities(newEntities.entities);
    setTotalEntityCount(newEntities.totalEntitiesCount);
  };

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    onSelectAllItems(isChecked, entities);
  };

  const onEntityCheckboxChange = (isChecked: boolean, id: string): void => {
    const currentEntity = entities.find((entity) => entity.id === id);
    if (!currentEntity) {
      return;
    }
    onSelectItem(isChecked, currentEntity, id);
  };

  const onPaginatePreviousAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (lastPaginationFromDates && lastPaginationFromDates.length > 0) {
      const lastPaginationFromDate: Date | undefined =
        lastPaginationFromDates.pop();
      if (lastPaginationFromDates.length > 1) {
        if (lastPaginationFromDate) {
          fromDate = lastPaginationFromDate;
        }
      }
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update entities list
    await refreshEntitiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToPreviousDeletedEntitiesArchivePage");
  };

  const onPaginateNextAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (entities && entities.length > 0) {
      const lastPaginationFromDate: Date =
        entities[entities.length - 1].dateAdded;
      fromDate = lastPaginationFromDate;
      lastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates([...lastPaginationFromDates]);
    }

    // update entities list
    await refreshEntitiesAsync(fromDate, sortType);

    // log
    LogHelperSingleton.log("GoToNextDeletedEntitiesArchivePage");
  };

  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // update entities list
    await refreshEntitiesAsync(undefined, newSortType);

    // reset selected entities
    setSelectedEntities([]);

    // log
    LogHelperSingleton.log("SortDeletedEntitiesArchive");
  };

  const onEntityClick = (entity: IEntityDTO): void => {
    setReferenceModalProps({
      isOpen: true,
      id: entity.id,
      type: ObjectTypeEnum.Entity,
      doIgnoreIsDeleted: true,
    });
  };

  const onRestoreButtonClick = useCallback(
    async (id?: string, description?: string): Promise<void> => {
      const undeleteObjects: IUndeleteObjectsDTO = {
        objectsToUndelete: [],
      };
      if (id) {
        undeleteObjects.objectsToUndelete.push({
          id: id,
          contentAsPlainText:
            EditorHelperSingleton.getContentAsPlainText(description),
        });
      } else {
        undeleteObjects.objectsToUndelete = selectedEntities.map(
          (selectedEntity) => ({
            id: selectedEntity.id,
            contentAsPlainText: EditorHelperSingleton.getContentAsPlainText(
              selectedEntity.description
            ),
          })
        );
      }

      // Check if any entities are selected
      if (undeleteObjects.objectsToUndelete.length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "No entities selected to restore"
        );
        return;
      }

      // restore entities
      const isSuccess = await EntityControllerSingleton.undeleteAsync(
        undeleteObjects
      );

      // If not successful, show error toast
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to restore entities"
        );
        return;
      }

      // refresh entities list
      refreshEntitiesAsync(undefined, SortTypeEnum.Newest);

      // reset selected entities
      setSelectedEntities([]);

      // Close referenceModal if entity is restored from the modal
      if (id) {
        closeReferenceModal();
      }
    },
    [closeReferenceModal, selectedEntities, setSelectedEntities]
  );

  const studiesArchiveButtons = useMemo(() => {
    return [
      {
        title: "Restore",
        onClick: () => {
          onRestoreButtonClick(undefined);
        },
        icon: faTrashRestore,
        className: styles.restoreButton,
      },
    ] as TButtonDefinition[];
  }, [onRestoreButtonClick]);

  useEffect(() => {
    refreshEntitiesAsync(undefined, SortTypeEnum.Newest);
  }, []);

  return (
    <div className={entityLikeListStyles.entityLikeContainer}>
      <div className={styles.listItemsContainer}>
        <ListHeader
          isAllListItemsSelected={areAllEntitiesSelected}
          isAnyListItemSelected={isAnyEntitySelected}
          listItemCountInterval={EntityConstants.MAXIMUM_ENTITIES_TO_RETRIEVE}
          totalListItemCount={totalEntityCount}
          sortType={sortType}
          updateSortType={updateSortTypeAsync}
          onPaginateNext={onPaginateNextAsync}
          onPaginatePrevious={onPaginatePreviousAsync}
          onSelectAllCheckboxChange={onSelectAllCheckboxChange}
          buttonDefinitions={studiesArchiveButtons}
        />
        <div className={entityLikeListStyles.entityLikeItemsList}>
          {entities.map((entity: IEntityDTO) => {
            const isSelected =
              selectedEntities.find(
                (selectedEntity) => selectedEntity.id === entity.id
              ) !== undefined;
            return (
              <EntityItem
                key={`entityitem-${entity.id}`}
                entity={entity}
                isSelected={isSelected}
                onCheckboxChange={onEntityCheckboxChange}
                onItemClickOverride={onEntityClick}
                hideMoreActionsDropdownButton
              />
            );
          })}
        </div>
      </div>
      {referenceModal}
    </div>
  );
};
