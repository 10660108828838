// node_modules
import axios, { AxiosResponse } from "axios";
// Helpers
import {
  AxiosHelperSingleton,
  EditorHelperSingleton,
  LogHelperSingleton,
} from "Helpers";
// Types
import {
  TCreateHighlightReferenceDTO,
  TCreateLinkedEmptyHighlightDTO,
  TDocumentDTO,
  THighlightDTO,
  THeaderContentDTO,
  THighlightContentResponse,
} from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";

export class HighlightController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/highlight`;

  public async addEmptyHighlightToDocumentAsync(
    objectId: string,
    objectType: ObjectTypeEnum,
    logProperties: { ActionOrigin: string } | undefined = undefined
  ): Promise<THighlightDTO | undefined> {
    try {
      LogHelperSingleton.logWithProperties(
        "AddHighlightToDocument",
        logProperties ? logProperties : {}
      );

      const createLinkedEmptyHighlight: TCreateLinkedEmptyHighlightDTO = {
        objectId,
        objectType,
      };

      // add empty highlight to object
      const response = await axios.post<THighlightDTO>(
        `${this._resourcePath}/addtoobject`,
        createLinkedEmptyHighlight
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async addHighlightToDocumentAsync(
    highlight: string,
    document: TDocumentDTO,
    logProperties: { ActionOrigin: string } | undefined = undefined
  ): Promise<THighlightDTO | undefined> {
    try {
      const createDocumentHighlight: TCreateHighlightReferenceDTO = {
        description: highlight,
        currentDocument: document,
        contentAsPlainText:
          EditorHelperSingleton.getContentAsPlainText(highlight),
      };

      // add empty highlight to object
      const response = await axios.post<THighlightDTO>(
        this._resourcePath,
        createDocumentHighlight
      );

      if (response && response.data) {
        LogHelperSingleton.logWithProperties(
          "AddHighlightToDocument",
          logProperties ? logProperties : {}
        );
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async updateHighlight(
    highlightId: string,
    highlight: THighlightDTO
  ): Promise<THighlightDTO | undefined> {
    try {
      LogHelperSingleton.log("UpdateHighlight");

      highlight.contentAsPlainText =
        EditorHelperSingleton.getContentAsPlainText(highlight.description);

      const response: AxiosResponse<THighlightDTO> =
        await axios.put<THighlightDTO>(
          `${this._resourcePath}/${highlightId}`,
          highlight
        );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async getLinkedToObject(
    objectId: string
  ): Promise<THighlightDTO[] | undefined> {
    try {
      const response = await axios.get<THighlightDTO[]>(
        `${this._resourcePath}/linkedto/${objectId}`
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async deleteAsync(highlightId: string): Promise<boolean> {
    try {
      LogHelperSingleton.log("DeleteHighlight");

      const response = await axios.delete(
        `${this._resourcePath}/${highlightId}`
      );
      return response.status === 200;
    } catch {
      return false;
    }
  }

  public async getHighlightContentAsync(
    queryId: string,
    documentId: string,
    contents: THeaderContentDTO[]
  ): Promise<THighlightContentResponse | undefined> {
    try {
      const response = await axios.post<THighlightContentResponse>(
        `${this._resourcePath}/content`,
        {
          queryId,
          documentId,
          contents,
        }
      );

      if (response && response.data) {
        return response.data;
      }
    } catch {
      return undefined;
    }
  }
}

export const HighlightControllerSingleton = new HighlightController();
