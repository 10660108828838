import { mergeAttributes, ReactNodeViewRenderer } from "@tiptap/react";
import { Node } from "@tiptap/core";
import { IntakeSheet } from "Components";
import styles from "./intakeSheetNodeExtension.module.scss";

export const IntakeSheetNodeExtension = Node.create({
  name: "intakeSheet",
  group: "block",
  draggable: true,

  addAttributes() {
    return {
      confirmed: {
        default: false,
      },
      confirmedBy: {
        default: "",
      },
      date: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "intake-sheet",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["intake-sheet", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IntakeSheet, {
      className: styles.intakeSheetContainer,
    });
  },
});
