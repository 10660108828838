// React
import { FC, useCallback, useEffect, useState } from "react";
// Font awesome
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styles
import styles from "./numericStepper.module.scss";

type TNumericStepperProps = {
  initialValue: number;
  minValue?: number;
  maxValue?: number;
  onValueChanged?: (newValue: number) => void;
};

export const NumericStepper: FC<TNumericStepperProps> = ({
  initialValue,
  minValue,
  maxValue,
  onValueChanged,
}: TNumericStepperProps) => {
  // States
  const [currentValue, setCurrentValue] = useState<number>(initialValue);

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const onMinusClick = useCallback(() => {
    // Do nothing if minvalue is set and currentValue is equal to it
    if (minValue && currentValue === minValue) return;

    // init new currentValue
    const newCurrentValue = currentValue - 1;

    // set new currentValue
    setCurrentValue(newCurrentValue);

    // call onValueChanged if set
    if (onValueChanged) onValueChanged(newCurrentValue);
  }, [currentValue, minValue, onValueChanged]);

  const onPlusClick = useCallback(() => {
    // Do nothing if maxvalue is set and currentValue is equal to it
    if (maxValue && currentValue === maxValue) return;

    // init new currentValue
    const newCurrentValue = currentValue + 1;

    // set new currentValue
    setCurrentValue(newCurrentValue);

    // call onValueChanged if set
    if (onValueChanged) onValueChanged(newCurrentValue);
  }, [currentValue, maxValue, onValueChanged]);

  return (
    <div className={styles.numericStepper}>
      <div
        className={`${styles.box} ${styles.left} ${styles.clickable}`}
        onClick={onMinusClick}
      >
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <div className={`${styles.box} ${styles.middle}`}>{currentValue}</div>
      <div
        className={`${styles.box} ${styles.right} ${styles.clickable}`}
        onClick={onPlusClick}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
};
