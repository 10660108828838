// node_modules
import {
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import {
  DragEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Handle, NodeProps, Position } from "reactflow";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";
// Components
import {
  EditableInput,
  ExpandableActionButtons,
  MoreActionsDropdownButton,
} from "Components";
// Constants
import { EventConstants, LinkingConstants } from "Constants";
// Controllers
import {
  EntityControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
// Hooks
import { useClickOutsideRef } from "Hooks";
// Types
import {
  TExpandableActionButtonsListData,
  TIdNameTypeObjectType,
  TReactFlowNode,
} from "Types";
// Contexts
import { LinkGraphContext } from "Providers";
// Styles
import styles from "./customNode.module.scss";

type TCustomNodeProps = NodeProps<TReactFlowNode> & {
  onLoadMoreClickAsync: (
    nodeId: string,
    nodeType: ObjectTypeEnum
  ) => Promise<void>;
  onAddNewLinkClick: (
    data: TReactFlowNode,
    defaultLinkTypeValue: string
  ) => void;
  onCollapseOrExpandClick: (isCollapsed: boolean, id: string) => void;
  isViewOnlyMode: boolean;
  onDragOver: (
    dragEvent: DragEvent,
    newDraggedOverObject: TIdNameTypeObjectType
  ) => void;
  onDragLeave: (dragEvent: DragEvent) => void;
};

export const CustomNode: FC<TCustomNodeProps> = ({
  id,
  data,
  type,
  selected,
  sourcePosition,
  targetPosition,
  onLoadMoreClickAsync,
  onAddNewLinkClick,
  onCollapseOrExpandClick,
  isViewOnlyMode,
  onDragOver,
  onDragLeave,
}) => {
  // State
  const [loadMoreCount, setLoadMoreCount] = useState<number>(
    data.loadMoreCount
  );
  const [customNodeText] = useState<string>(data.text);
  const [areNodeOptionsVisible, setAreNodeOptionsVisible] =
    useState<boolean>(false);

  // Ref
  const optionsRef = useRef<HTMLLIElement>(null);

  // Hooks
  useClickOutsideRef(optionsRef, () => {
    if (areNodeOptionsVisible) {
      setAreNodeOptionsVisible(false);
    }
  });

  // Const
  const { isCollapsed, collapsedDirectChildrenCount } = data;

  // Context
  const { openObjectReference, focusedNodeId } = useContext(LinkGraphContext);

  // Memo
  const expandableActionButtonsListData =
    useMemo((): TExpandableActionButtonsListData => {
      const returnValue: TExpandableActionButtonsListData = [];
      if (
        data.directChildrenCount !== undefined &&
        data.directChildrenCount > 0
      ) {
        returnValue.push({
          title: data.isCollapsed ? "Expand" : "Collapse",
          icon: data.isCollapsed ? faSquarePlus : faSquareMinus,
          onClick: () => {
            onCollapseOrExpandClick(data.isCollapsed || false, id);
          },
        });
      }
      return returnValue;
    }, [data, id, onCollapseOrExpandClick]);

  // Logic
  useEffect(() => {
    // the loadMoreCount could be updated when clicking on a load more count indicator
    setLoadMoreCount(data.loadMoreCount);
  }, [data.loadMoreCount]);

  useEffect(() => {
    if (!selected && areNodeOptionsVisible) {
      setAreNodeOptionsVisible(false);
    }
  }, [selected, areNodeOptionsVisible]);

  const openNodeInModal = useCallback(() => {
    // call open object reference prop to open object in modal
    openObjectReference(id, data.objectTypeEnum);

    // log
    LogHelperSingleton.log("StructureGraphClickNavigateToItem");
  }, [data.objectTypeEnum, id, openObjectReference]);

  const handleNewObjectTitleAsync = useCallback(
    async (
      objectId: string,
      objectTypeEnum: ObjectTypeEnum,
      newTitle: string,
      isReadOnly: boolean
    ): Promise<void> => {
      // if the user is readonly or new title is not defined
      if (isReadOnly || !newTitle) {
        // stop execution, return
        return;
      }

      // depending on object type
      switch (objectTypeEnum) {
        // if entity
        case ObjectTypeEnum.Entity:
          // update entity title in database
          await EntityControllerSingleton.updateTitleAsync(objectId, newTitle);
          // log
          LogHelperSingleton.log("UpdateEntityTitle");
          break;
        // if study
        case ObjectTypeEnum.Study:
          // update study title in database
          await StudyControllerSingleton.updateTitleAsync(objectId, newTitle);

          // log
          LogHelperSingleton.log("UpdateStudyTitle");
          break;
        // otherwise
        default:
          // stop execution, return
          return;
      }
    },
    []
  );

  // debounce the handleNewObjectTitleAsync function
  const debouncedHandleNewObjectTitleAsync = useMemo(
    () =>
      debounce(
        handleNewObjectTitleAsync,
        EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY
      ),
    [handleNewObjectTitleAsync]
  );

  const onDragOverHandler = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      onDragOver(event, {
        ...data,
        objectType: data.objectTypeEnum,
        name: customNodeText,
        type: "",
      });
    },
    [data, onDragOver, customNodeText]
  );

  const handleOptionsClick = () => {
    setAreNodeOptionsVisible(true);
  };

  // Render
  return (
    <div
      onDragLeave={onDragLeave}
      onDragOver={onDragOverHandler}
      className={`${styles.customNodeContainer} ${styles[type.toLowerCase()]} ${
        selected ? styles.selected : ""
      } ${targetPosition === "top" ? styles.vertical : styles.horizontal} ${
        data.availableConnectionType
          ? styles[`${data.availableConnectionType}HandleAvailable`]
          : ""
      }`}
    >
      <div className={styles.customNode}>
        <Handle
          isConnectable={!isViewOnlyMode}
          title="Add parent link"
          onClick={() => {
            onAddNewLinkClick(data, LinkingConstants.CHILD_LINK_TYPE.value);
          }}
          className={isViewOnlyMode ? undefined : styles.targetHandle}
          type="target"
          position={targetPosition === "top" ? Position.Top : Position.Left}
          id={`${id}_targetHandle`}
        />
        <div>
          <div
            title={data.objectType}
            className={`${styles.typeIndicator} ${styles[type.toLowerCase()]}`}
          >
            <FontAwesomeIcon
              className={styles.objectTypeIcon}
              icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                data.objectTypeEnum
              )}
            />
            <h6>{data.objectType}</h6>
          </div>
          <div
            className={`${styles.nodeTextContainer} ${
              customNodeText.length > 28 ? styles.small : ""
            }`}
          >
            {isViewOnlyMode ? (
              <span className={styles.nodeText} onDoubleClick={openNodeInModal}>
                {customNodeText}
              </span>
            ) : (
              <EditableInput
                inputTitle={customNodeText.length > 28 ? customNodeText : ""}
                className={styles.nodeText}
                value={customNodeText}
                setValue={(newValue: string) =>
                  debouncedHandleNewObjectTitleAsync(
                    data.id,
                    data.objectTypeEnum,
                    newValue,
                    isViewOnlyMode
                  )
                }
                onDoubleClick={openNodeInModal}
              />
            )}
          </div>
        </div>
        {(isCollapsed ||
          (collapsedDirectChildrenCount !== undefined &&
            collapsedDirectChildrenCount > 0)) && (
          <span
            onClick={() => {
              onCollapseOrExpandClick(true, id);
            }}
            className={styles.collapsedNodeIndicator}
          >{`Collapsed (${collapsedDirectChildrenCount})`}</span>
        )}
        <Handle
          isConnectable={!isViewOnlyMode}
          title="Add child link"
          onClick={() => {
            onAddNewLinkClick(data, LinkingConstants.PARENT_LINK_TYPE.value);
          }}
          className={isViewOnlyMode ? undefined : styles.sourceHandle}
          type="source"
          position={
            sourcePosition === "bottom" ? Position.Bottom : Position.Right
          }
          id={`${id}_sourceHandle`}
        />
      </div>
      {loadMoreCount && loadMoreCount > 0 ? (
        <div
          className={styles.loadMoreIndicator}
          onClick={() => onLoadMoreClickAsync(id, data.objectTypeEnum)}
        >
          <div className={styles.loadMoreCount}>+ {loadMoreCount}</div>
          <div className={styles.loadMoreDescription}>
            <span className={styles.loadMoreText}>{"Load more"}</span>
            <span className={styles.loadMoreIndicatorIcons}>
              <FontAwesomeIcon
                className={styles.loadMoreIcon}
                icon={faChevronUp}
              />
              <FontAwesomeIcon
                className={styles.loadMoreIcon}
                icon={faChevronDown}
              />
            </span>
          </div>
        </div>
      ) : null}
      {selected && (
        <ExpandableActionButtons
          listData={expandableActionButtonsListData}
          extraClassNames={{ container: styles.nodeControls }}
        >
          {focusedNodeId !== data.id ? (
            <li
              ref={optionsRef}
              className={`${styles.customNodeMoreActionsDropdownButton} ${
                areNodeOptionsVisible ? styles.isActive : ""
              }`}
            >
              <MoreActionsDropdownButton
                objectType={data.objectTypeEnum}
                objectId={data.id}
                buttonText="Options"
                extraClassNames={{
                  button: styles.moreActionsDropdownButton,
                  buttonText: styles.moreActionsButtonText,
                  buttonIconContainer: styles.moreActionsButtonIconContainer,
                  optionsPopover: styles.moreActionsOptionsPopover,
                }}
                onButtonClick={handleOptionsClick}
              />
            </li>
          ) : (
            <></>
          )}
        </ExpandableActionButtons>
      )}
    </div>
  );
};
