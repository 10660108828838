/* eslint-disable no-case-declarations */
// node_modules
import {
  Fragment,
  Node,
  NodeType,
  ResolvedPos,
  Slice,
} from "prosemirror-model";
import {
  EditorState,
  Selection,
  TextSelection,
  Transaction,
} from "prosemirror-state";
import { ReplaceStep } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";
import { NavigateFunction } from "react-router";
// Types
import {
  NullableProseMirrorNode,
  TIdNameTypeObjectType,
  TImageDTO,
  TOverallTIAutomationResponseDTO,
  TProseMirrorNodeData,
  TRatingsData,
  TReferenceTextAndLinkDTO,
  TSelectedTextData,
} from "Types";
// Components
import { findestMarkdownParser, findestSchema } from "Components";
// Enums
import {
  AskIgorMenuItemEnum,
  CustomBlockIdAttributeEnum,
  CustomDOMAttributes,
  CustomDOMTag,
  CustomInlineIdAttributeEnum,
  EditorTableDOMTag,
  EditorTableDOMTagValues,
  ObjectTypeEnum,
  OtherMarkdownCustomBlockNameEnum,
  OtherTemplateTypeEnum,
  SpecialBlockClassNameEnum,
  ToastTypeEnum,
  TopDepthMarkdownCustomBlockNameEnum,
  TopDepthMarkdownCustomBlockNameEnumStrings,
} from "Enums";
// Controllers
import {
  ImageControllerSingleton,
  TemplateControllerSingleton,
} from "Controllers";
// Helpers
import {
  FileHelperSingleton,
  ObjectTypeHelperSingleton,
  StringHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Constants
import {
  AiConstants,
  EditorConstants,
  FeatureToggleConstants,
  ProseMirrorConstants,
} from "Constants";
// Classes
import {
  EntityReference,
  FileReference,
  FileReferenceLink,
  FileReferenceLinkExtension,
  FileReferenceLinkTitle,
  HighlightReference,
  ImageReferenceFigcaptionText,
  ImageReferenceFigcaptionTextContainer,
  InlineReference,
  IntakeSheetConfirmation,
  IntakeSheetConfirmationAccepted,
  IntakeSheetConfirmationNotAccepted,
  ReferenceLink,
  StudyReference,
} from "Classes";

export class ProseMirrorHelper {
  private equalNodeType = (nodeType: NodeType, node: Node) => {
    return (
      (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
      node.type === nodeType
    );
  };

  public findParentNodeOfType =
    (nodeType: NodeType) => (selection: Selection) => {
      return this.findParentNode((node: Node) =>
        this.equalNodeType(nodeType, node)
      )(selection);
    };

  private findParentNode =
    (predicate: (node: Node) => boolean) =>
    ({ $from }: { $from: ResolvedPos }) =>
      this.findParentNodeClosestToPos($from, predicate);

  private findParentNodeClosestToPos = (
    $pos: ResolvedPos,
    predicate: (node: Node) => boolean
  ) => {
    for (let i = $pos.depth; i > 0; i--) {
      const node = $pos.node(i);
      if (predicate(node)) {
        return {
          pos: i > 0 ? $pos.before(i) : 0,
          start: $pos.start(i),
          depth: i,
          node,
        };
      }
    }
  };

  // know from the editor view if several paragraphs are selected
  public areSeveralParagraphsSelected = (editorView: EditorView): boolean => {
    // init paragraps selected count to 0
    let paragraphsSelectedCount = 0;

    editorView.state.doc.nodesBetween(
      editorView.state.selection.from,
      editorView.state.selection.to,
      (node: Node) => {
        // if node is paragraph
        if (node.type.name === findestSchema.nodes.paragraph.name) {
          // increment paragraphs selected count
          paragraphsSelectedCount++;
        }
      }
    );

    // return if paragraphs selected count is greater than 1
    return paragraphsSelectedCount > 1;
  };

  // get selected text data in paragraph
  public getSelectedTextDataInParagraph = (
    editorView: EditorView
  ): TSelectedTextData => {
    // init default selected text data
    const selectedTextData: TSelectedTextData = {
      selectedText: undefined,
      isFullNodeTextContent: false,
    };

    // get are several paragraphs selected
    const areSeveralParagraphsSelected =
      this.areSeveralParagraphsSelected(editorView);

    // get current node at selection
    const currentNodeAtSelection = this.getCurrentNodeDataAtSelection(
      editorView.state
    );
    // get selection
    const { from, to } = editorView.state.selection;
    // get text in selection
    const textInSelection = editorView.state.doc.textBetween(from, to);
    // get trimmed text in selection
    const trimmedTextInSelection = textInSelection.trim();

    // if several paragraphs are selected
    // or from and to are the same (no selection)
    // or current node at selection is not a paragraph or a heading
    // or text in selection is empty
    if (
      areSeveralParagraphsSelected ||
      from === to ||
      (currentNodeAtSelection?.node?.type.name !== "paragraph" &&
        currentNodeAtSelection?.node?.type.name !== "heading") ||
      !textInSelection ||
      !trimmedTextInSelection
    ) {
      // return default selected text data
      return selectedTextData;
    }

    // init boolean to know if selection contains inline reference mark
    let doesSelectionContainInlineReferenceMark = false;
    // go through all nodes in selection
    editorView.state.selection.content().content.descendants((node: Node) => {
      // if one of the node in selection has inline reference mark
      if (
        node.marks.some(
          (mark) => mark.type.name === findestSchema.marks.inlineReference.name
        )
      ) {
        // set doesSelectionContainInlineReferenceMark to true
        doesSelectionContainInlineReferenceMark = true;
      }
    });
    // if selection contains inline reference mark
    if (doesSelectionContainInlineReferenceMark) {
      // return default selected text data
      return selectedTextData;
    }

    // set selected text data
    selectedTextData.selectedText = trimmedTextInSelection;
    selectedTextData.isFullNodeTextContent =
      trimmedTextInSelection === currentNodeAtSelection.node.textContent.trim();

    // return text in selection
    return selectedTextData;
  };

  public getIsLinkInfo = (
    editorView: EditorView
  ): {
    isCurrentLink: boolean;
    isNextLink: boolean;
  } => {
    // get selection data
    const { parent, from, to } = this.getSelectionData(editorView);

    // return is link info
    return {
      isCurrentLink:
        parent.rangeHasMark(from, to, findestSchema.marks.link) &&
        Math.abs(from - to) === 1,
      isNextLink:
        to + 1 > parent.content.size
          ? false
          : parent.rangeHasMark(from + 1, to + 1, findestSchema.marks.link),
    };
  };

  public getSelectionData = (
    editorView: EditorView
  ): {
    parent: Node;
    from: number;
    to: number;
  } => {
    // determine the start and end position of the current selection
    const selectionParent = editorView.state.selection.$from.parent;
    const fromPosition = editorView.state.selection.$from.parentOffset - 1;
    const toPosition = editorView.state.selection.$to.parentOffset;

    // return selection data
    return {
      parent: selectionParent,
      from: fromPosition,
      to: toPosition,
    };
  };

  public getCustomBlockId = (
    node: NullableProseMirrorNode,
    nodeType: NodeType
  ): string | undefined => {
    // safety-checks
    if (!node || !nodeType || !nodeType.name || !node.attrs) {
      return undefined;
    }

    // depending on node type
    switch (nodeType.name) {
      case TopDepthMarkdownCustomBlockNameEnum.HighlightReference:
        return node.attrs[`${CustomBlockIdAttributeEnum.HighlightReference}`];
      case TopDepthMarkdownCustomBlockNameEnum.ImageReference:
        return node.attrs[`${CustomBlockIdAttributeEnum.ImageReference}`];
      case TopDepthMarkdownCustomBlockNameEnum.FileReference:
        return node.attrs[`${CustomBlockIdAttributeEnum.FileReference}`];
      case TopDepthMarkdownCustomBlockNameEnum.EntityReference:
        return node.attrs[`${CustomBlockIdAttributeEnum.EntityReference}`];
      case TopDepthMarkdownCustomBlockNameEnum.StudyReference:
        return node.attrs[`${CustomBlockIdAttributeEnum.StudyReference}`];
      case EditorTableDOMTag.Table:
        return node.attrs[`${CustomBlockIdAttributeEnum.Table}`];
      case TopDepthMarkdownCustomBlockNameEnum.IntakeSheetConfirmation:
        return node.attrs[
          `${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`
        ];
      default:
        return undefined;
    }
  };

  public fixCursorPosition = (editorView: EditorView): EditorState => {
    // get current selection
    const { $from, from, to } = editorView.state.selection;

    // if selection is more than one position
    // do nothing
    if (from !== to) {
      return editorView.state;
    }

    // get current node
    const currentNode = $from.node();

    // if current node is a reference_link or file_reference_link_extension
    // we prevent cursor from being in the ref part of a custom block
    if (
      currentNode.type.name ===
        OtherMarkdownCustomBlockNameEnum.ReferenceLink ||
      currentNode.type.name ===
        OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension
    ) {
      // get parent node
      const parentNode = $from.node(-1);

      // get parent node first child
      const parentNodeFirstChild = parentNode.firstChild;

      // safety-check
      if (!parentNodeFirstChild) {
        return editorView.state;
      }

      // get pos of parent node first child
      const pos = $from.before(-1);

      // get content length of parent node first child
      const contentLength = parentNodeFirstChild.textContent.length;

      // get resolved pos being the end of parent node first child
      const resolvedPos = editorView.state.doc.resolve(pos + contentLength + 2);

      // set cursor at the end of the parent node first child
      const selection = new TextSelection(resolvedPos);

      // apply selection to editor view state
      return editorView.state.apply(
        editorView.state.tr
          .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
          .setSelection(selection)
      );
    }

    // return editor view state
    return editorView.state;
  };

  public cleanDoc = (editorView: EditorView): EditorState => {
    // get all custom block nodes in doc
    const customBlockNodes: { node: Node; pos: number }[] = [];
    editorView.state.doc.descendants((node: Node, pos: number) => {
      // if node is a top depth custom block
      if (TopDepthMarkdownCustomBlockNameEnumStrings.includes(node.type.name)) {
        // add node to customBlockNodes
        customBlockNodes.push({ node, pos });
      }
    });

    let newState: EditorState = editorView.state;
    let firstChild: NullableProseMirrorNode = undefined;
    let firstChildFirstChild: NullableProseMirrorNode = undefined;
    let firstChildSecondChild: NullableProseMirrorNode = undefined;
    let secondChild: NullableProseMirrorNode = undefined;
    let secondChildSecondChild: NullableProseMirrorNode = undefined;
    // go over customBlockNodes found
    for (const { node: customBlockNode, pos } of customBlockNodes) {
      // switch depending on custom block node type
      switch (customBlockNode.type.name) {
        case TopDepthMarkdownCustomBlockNameEnum.HighlightReference:
          // get first child
          firstChild = customBlockNode.firstChild;
          // get second child
          secondChild = customBlockNode.child(1);

          // if first child is a paragraph node and second child is a reference_link node
          // and first child text content is empty or second child text content is empty or second child href is empty
          if (
            firstChild &&
            firstChild.type.name === "paragraph" &&
            secondChild &&
            secondChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.ReferenceLink &&
            (firstChild.textContent === "" ||
              secondChild.textContent === "" ||
              secondChild.attrs.href === "")
          ) {
            // delete custom block node
            newState = editorView.state.apply(
              editorView.state.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .delete(pos, pos + customBlockNode.nodeSize)
            );
          }
          break;
        case TopDepthMarkdownCustomBlockNameEnum.ImageReference:
          // get first child
          firstChild = customBlockNode.firstChild;
          // get second child
          secondChild = customBlockNode.child(1);
          // get second child's second child
          secondChild.childCount > 1 &&
            (secondChildSecondChild = secondChild?.child(1));

          // init doDelete to false
          let doDelete = false;

          // if first child is a image_reference_img node and second child is a image_reference_figcaption node
          // and second child's first child is a paragraph node
          // and first child src is empty or second child's first child text content is empty
          if (
            firstChild &&
            firstChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.ImageReferenceImage &&
            secondChild &&
            secondChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption &&
            firstChild.attrs.src === ""
          ) {
            doDelete = true;
          }

          // second child's second child can be undefined
          // second child's second child is a reference_link node
          // and second child's second child text content is empty or second child's second child href is empty
          if (
            !doDelete &&
            secondChildSecondChild &&
            secondChildSecondChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.ReferenceLink &&
            (secondChildSecondChild.textContent === "" ||
              secondChildSecondChild.attrs.href === "")
          ) {
            doDelete = true;
          }

          // if doDelete is true
          if (doDelete) {
            // delete custom block node
            newState = editorView.state.apply(
              editorView.state.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .delete(pos, pos + customBlockNode.nodeSize)
            );
          }
          break;
        case TopDepthMarkdownCustomBlockNameEnum.FileReference:
          // get first child
          firstChild = customBlockNode.firstChild;
          // get first child's first child
          firstChildFirstChild = firstChild?.firstChild;
          // get first child's second child
          firstChildSecondChild = firstChild?.child(1);

          // if first child is a file_reference_link node and first child's first child is a file_reference_link_title node and first child's second child is a file_reference_link_extension node
          // and first child href is empty or first child's first child text content is empty or first child's second child text content is empty
          if (
            firstChild &&
            firstChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.FileReferenceLink &&
            firstChildFirstChild &&
            firstChildFirstChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle &&
            firstChildSecondChild &&
            firstChildSecondChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension &&
            (firstChild.attrs.href === "" ||
              firstChildFirstChild.textContent === "" ||
              firstChildSecondChild.textContent === "")
          ) {
            // delete custom block node
            newState = editorView.state.apply(
              editorView.state.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .delete(pos, pos + customBlockNode.nodeSize)
            );
          }
          break;
        case TopDepthMarkdownCustomBlockNameEnum.EntityReference:
        case TopDepthMarkdownCustomBlockNameEnum.StudyReference:
          // get first child
          firstChild = customBlockNode.firstChild;

          // if first child is an reference_link node
          // and first child text content is empty or first child href is empty
          if (
            firstChild &&
            firstChild.type.name ===
              OtherMarkdownCustomBlockNameEnum.ReferenceLink &&
            (firstChild.textContent === "" || firstChild.attrs.href === "")
          ) {
            // delete custom block node
            newState = editorView.state.apply(
              editorView.state.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .delete(pos, pos + customBlockNode.nodeSize)
            );
          }
          break;
      }
    }

    // if doc first child is a custom block, a table or a list, add an empty paragraph before it
    // to make content before custom block always editable
    if (
      newState.doc.firstChild &&
      [
        ...TopDepthMarkdownCustomBlockNameEnumStrings,
        EditorTableDOMTag.Table.toString(),
        findestSchema.nodes.ordered_list.name,
        findestSchema.nodes.bullet_list.name,
      ].includes(newState.doc.firstChild.type.name)
    ) {
      newState = newState.apply(
        newState.tr
          .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
          .insert(0, findestSchema.nodes.paragraph.create())
      );
    }

    // if doc last child is a custom block, a table or a list, add an empty paragraph after it
    // to make content after custom block always editable
    if (
      newState.doc.lastChild &&
      [
        ...TopDepthMarkdownCustomBlockNameEnumStrings,
        EditorTableDOMTag.Table.toString(),
        findestSchema.nodes.ordered_list.name,
        findestSchema.nodes.bullet_list.name,
      ].includes(newState.doc.lastChild.type.name)
    ) {
      newState = newState.apply(
        newState.tr
          .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
          .insert(
            newState.doc.nodeSize - 2,
            findestSchema.nodes.paragraph.create()
          )
      );
    }

    // go through all nodes in doc
    // keep track of the last node having the doc node as parent
    let lastNode: Node | undefined;
    newState.doc.descendants(
      (node: Node, pos: number, parentNode: Node | null) => {
        // if parent node is the doc node
        if (parentNode && parentNode.type.name === "doc") {
          // if current and last nodes are tables
          if (
            node.type.name === EditorTableDOMTag.Table &&
            lastNode &&
            lastNode.type.name === EditorTableDOMTag.Table
          ) {
            // insert a paragraph between them
            newState = newState.apply(
              newState.tr
                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                .insert(pos, findestSchema.nodes.paragraph.create())
            );
          }
          // set last node to current node
          lastNode = node;
        }
      }
    );

    // return clean state
    return newState;
  };

  // get first mark node data with types from selection
  public getFirstMarkNodeDataWithTypesFromSelection = (
    editorState: EditorState,
    types: string[]
  ): TProseMirrorNodeData => {
    // init mark node data to return
    let markNodeDataToReturn: TProseMirrorNodeData = {
      node: undefined,
      depth: undefined,
    };

    // get parent node at selection anchor
    const parentNode: Node = editorState.selection.$anchor.parent;
    // get pos of parent node at selection anchor
    const parentNodePos: number = editorState.selection.$anchor.start();
    // get selection anchor position in doc
    const selectionAnchorPosInDoc: number = editorState.selection.anchor - 1;
    // get selection head position in doc
    const selectionHeadPosInDoc: number = editorState.selection.head - 1;

    // if parentNode is not a paragraph
    if (parentNode.type.name !== findestSchema.nodes.paragraph.name) {
      // return mark node data to return
      return markNodeDataToReturn;
    }

    // otherwise go through descendants of parentNode
    parentNode.descendants(
      (parentNodeDescendant: Node, parentNodeDescendantPos: number) => {
        // if parentNodeDescendant has researched mark types
        if (
          parentNodeDescendant.marks.some((mark) =>
            types.includes(mark.type.name)
          )
        ) {
          // and if selection anchor and selection head are in parentNodeDescendant
          // (depending on parentNodeDescendant being the first descendant of parentNode (parentNodeDescendantPos === 0), the conditions are slightly different)
          if (
            selectionAnchorPosInDoc <
              parentNodePos +
                parentNodeDescendantPos +
                parentNodeDescendant.nodeSize &&
            selectionHeadPosInDoc <
              parentNodePos +
                parentNodeDescendantPos +
                parentNodeDescendant.nodeSize &&
            ((parentNodeDescendantPos === 0 &&
              selectionAnchorPosInDoc + 1 >=
                parentNodePos + parentNodeDescendantPos &&
              selectionHeadPosInDoc + 1 >=
                parentNodePos + parentNodeDescendantPos) ||
              (parentNodeDescendantPos !== 0 &&
                selectionAnchorPosInDoc >=
                  parentNodePos + parentNodeDescendantPos &&
                selectionHeadPosInDoc >=
                  parentNodePos + parentNodeDescendantPos))
          ) {
            // set markNodeDataToReturn to parentNodeDescendant and depth of $anchor
            markNodeDataToReturn = {
              node: parentNodeDescendant,
              depth: editorState.selection.$anchor.depth,
            };
          }
        }
      }
    );

    // return mark node data to return
    return markNodeDataToReturn;
  };

  // get first node data with types from selection
  public getFirstNodeDataWithTypesFromSelection = (
    editorState: EditorState,
    types: string[]
  ): TProseMirrorNodeData => {
    // get current node data at selection
    const currentNodeData: TProseMirrorNodeData =
      this.getCurrentNodeDataAtSelection(editorState);

    // safety-checks
    if (!currentNodeData || !currentNodeData.node || !currentNodeData.depth) {
      return { node: undefined, depth: undefined };
    }

    // get first node data with types
    return this.getFirstNodeDataWithTypes(
      editorState,
      currentNodeData.node,
      currentNodeData.depth,
      types
    );
  };

  // Get first node with specific types or document node
  public getFirstNodeDataWithTypes = (
    state: EditorState,
    node: Node,
    depth: number,
    types: string[]
  ): TProseMirrorNodeData => {
    // safety-checks
    if (!node) {
      return { node: undefined, depth: undefined };
    }
    // if the node is a document node, return it
    if (node.type.name === "doc") {
      return { node, depth };
    }
    // if the node is one of the types, return it
    if (types.includes(node.type.name)) {
      return { node, depth };
    }

    // Get the parent node using a reduced depth
    const parentNode = state.selection.$anchor.node(--depth);
    // if the node has a parent, return the parent
    if (parentNode) {
      return this.getFirstNodeDataWithTypes(state, parentNode, depth, types);
    }
    // otherwise, return undefined
    return { node: undefined, depth: undefined };
  };

  public mapChildren = (
    node: Node,
    callback: (child: Node, index: number, node: Fragment) => Node
  ) => {
    const array = [];
    for (let i = 0; i < node.childCount; i++) {
      array.push(
        callback(
          node.child(i),
          i,
          node instanceof Fragment ? node : node.content
        )
      );
    }
    return array;
  };

  public convertDocumentToParentTree = (state: EditorState) => {
    const parentTree = new Map<Node, Node>();

    state.doc.descendants(
      (node: Node, pos: number, parent: Node | undefined | null) => {
        if (parent) {
          parentTree.set(node, parent);
        }
      }
    );

    return parentTree;
  };

  public getCurrentNodeDataAtSelection = (
    state: EditorState
  ): TProseMirrorNodeData => {
    // get $from ResolvedPos from selection
    const { $from } = state.selection;

    // get current depth from ResolvedPos $from
    const depth = $from.depth;

    // get current node from depth
    return { node: $from.node(depth), depth };
  };

  public getCustomNodeAtSelection = (
    editorView: EditorView | undefined
  ): Node | undefined => {
    // safety-checks
    if (!editorView) return;

    // get the current depth of the cursor
    const currentDepth = editorView.state.selection.$anchor.depth;

    // get the current node
    const startNode = editorView.state.selection.$anchor.node(currentDepth);

    // get the first node with a custom type if possible
    const foundNode = ProseMirrorHelperSingleton.getFirstNodeDataWithTypes(
      editorView.state,
      startNode,
      currentDepth,
      TopDepthMarkdownCustomBlockNameEnumStrings
    );

    // check if a node was found or the end of the document was reached
    if (!foundNode.node || foundNode.node.type.name === "doc") return undefined;

    // check if the found node is a top depth custom block
    if (
      !TopDepthMarkdownCustomBlockNameEnumStrings.includes(
        foundNode.node.type.name
      )
    )
      return undefined;

    // return found node
    return foundNode.node;
  };

  public moveNode = (
    type: NodeType,
    dir: "UP" | "DOWN",
    isSpecialBlock: boolean
  ) => {
    const isDown = dir === "DOWN";
    return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
      const { $from, $anchor } = state.selection;

      let relatedNode: NullableProseMirrorNode = undefined;
      let relatedNodeDepth: number | undefined = undefined;

      if (isSpecialBlock) {
        // get the current depth of the cursor
        const currentDepth = $anchor.depth;

        // get the current node
        const startNode = state.selection.$anchor.node(currentDepth);

        // set special block types to get node data for
        const types: string[] = [];
        if (TopDepthMarkdownCustomBlockNameEnumStrings.includes(type.name)) {
          types.push(...TopDepthMarkdownCustomBlockNameEnumStrings);
        } else if (type.name === EditorTableDOMTag.Table) {
          types.push(EditorTableDOMTag.Table);
        }

        // get the first node with a custom type if possible
        const foundNodeData = this.getFirstNodeDataWithTypes(
          state,
          startNode,
          currentDepth,
          types
        );

        // safety-checks
        if (!foundNodeData.node || !foundNodeData.depth) {
          return false;
        }

        // set the related node and depth
        relatedNode = foundNodeData.node;
        relatedNodeDepth = foundNodeData.depth;
      } else {
        const currentResolved = this.findParentNodeOfType(type)(
          state.selection
        );

        if (!currentResolved) {
          return false;
        }

        relatedNode = currentResolved.node;
        relatedNodeDepth = currentResolved.depth;
      }

      // safety-checks
      if (
        !relatedNode ||
        !relatedNodeDepth ||
        (!isSpecialBlock && relatedNode.type !== type)
      ) {
        return false;
      }

      const parentDepth = relatedNodeDepth - 1;
      const parent = $from.node(parentDepth);
      const parentPos = $from.start(parentDepth);

      const arr = this.mapChildren(parent, (node: Node) => node);
      const index = arr.indexOf(relatedNode);

      const swapWith = isDown ? index + 1 : index - 1;

      // If swap is out of bound
      if (swapWith >= arr.length || swapWith < 0) {
        return false;
      }

      const swapWithNodeSize = arr[swapWith].nodeSize;
      [arr[index], arr[swapWith]] = [arr[swapWith], arr[index]];

      let tr = state.tr;
      const replaceStart = parentPos;
      const replaceEnd = $from.end(parentDepth);

      const slice = new Slice(Fragment.fromArray(arr), 0, 0); // the zeros  lol -- are not depth they are something that represents the opening closing
      // .toString on slice gives you an idea. for this case we want them balanced
      tr = tr.step(new ReplaceStep(replaceStart, replaceEnd, slice, false));

      tr = tr.setSelection(
        Selection.near(
          tr.doc.resolve(
            isDown ? $from.pos + swapWithNodeSize : $from.pos - swapWithNodeSize
          )
        )
      );

      if (dispatch) {
        dispatch(tr.scrollIntoView());
      }
      return true;
    };
  };

  public isSourceEmpty(source: string): boolean {
    if (source.trim().length === 0) return true;
    // Remove things which could be added by the editor when empty
    const cleanedsource = source
      .replaceAll(":::", "")
      .replaceAll("break", "")
      .replaceAll("#", "");
    // Check if the source would be empty when cleaned
    return cleanedsource.trim().length === 0;
  }

  public getPositionWhereToInsertNewElement(
    selection: Selection,
    doGetPositionAfterCurrentNode?: boolean
  ): { from: number; to: number } {
    // get $from ResolvedPos from selection
    const { $from } = selection;

    // get current depth from ResolvedPos $from
    let depth = $from.depth;

    // get current node from depth
    let currentNode = $from.node(depth);

    // if current node is a paragraph and is empty
    if (
      (doGetPositionAfterCurrentNode === undefined ||
        doGetPositionAfterCurrentNode === false) &&
      currentNode.type.name === "paragraph" &&
      currentNode.textContent === ""
    ) {
      // return the position just before the paragraph
      return {
        from: $from.pos - 1,
        to: $from.pos + 1,
      };
    }

    // otherwise get currentNode's parent node
    let nextParentNode: Node = $from.node(depth - 1);
    // while currentNode's parent node is not a doc node
    while (nextParentNode.type.name !== "doc") {
      // set currentNode to nextParentNode
      currentNode = nextParentNode;
      // get nextParentNode's parent node and update depth
      depth = depth - 1;
      nextParentNode = $from.node(depth);
    }

    // get end position of currentNode
    let currentNodeEndPos = $from.before(depth + 1);
    // since before() returns an absolute position, if currentNode is a text block
    // then change currentNodeEndPos
    if (currentNode.isTextblock) {
      currentNodeEndPos = $from.end(depth) + 1;
    }

    // return the position just after the currentNode
    return {
      from: currentNodeEndPos,
      to: currentNodeEndPos,
    };
  }

  public setCursorAtNewCustomBlockEnd(
    newCustomBlockTransaction: Transaction,
    customBlockTypeName: string,
    customBlockId: string,
    positionNewCustomBlock: { from: number; to: number }
  ): Transaction {
    // go through doc from transaction
    newCustomBlockTransaction.doc.descendants((node: Node, pos: number) => {
      // if node is the one we just inserted
      if (
        node.type.name === customBlockTypeName &&
        node.attrs["data-highlight-id"] === customBlockId &&
        pos === positionNewCustomBlock.from
      ) {
        // get highlight reference first child
        const paragraph: Node | null = node.firstChild;

        // safety-checks
        if (!paragraph || paragraph.type.name !== "paragraph") {
          return;
        }

        // get content length of paragraph
        const contentLength: number = paragraph.textContent.length;

        // get pos at the end of the paragraph
        // pos of highlight reference + length of paragraph + 2 (start and end of paragraph)
        const resolvedPos: ResolvedPos = newCustomBlockTransaction.doc.resolve(
          pos + contentLength + 2
        );

        // set cursor at the end of the paragraph
        const selection = new TextSelection(resolvedPos);

        // update transaction
        newCustomBlockTransaction =
          newCustomBlockTransaction.setSelection(selection);
      } else if (
        node.type.name === customBlockTypeName &&
        node.attrs["data-image-id"] === customBlockId &&
        pos === positionNewCustomBlock.from
      ) {
        // get image reference second child
        const imageReferenceFigcaption: Node | null = node.child(1);

        // safety-checks
        if (
          !imageReferenceFigcaption ||
          imageReferenceFigcaption.type.name !==
            OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption
        ) {
          return;
        }

        // get image reference figcaption first child
        const imageReferenceFigcaptionTextContainer: Node | null =
          imageReferenceFigcaption.firstChild;

        // safety-checks
        if (
          !imageReferenceFigcaptionTextContainer ||
          imageReferenceFigcaptionTextContainer.type.name !==
            OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer
        ) {
          return;
        }

        // get image reference figcaption text container first child
        const imageReferenceFigcaptionText: Node | null =
          imageReferenceFigcaptionTextContainer.firstChild;

        // safety-checks
        if (
          !imageReferenceFigcaptionText ||
          imageReferenceFigcaptionText.type.name !==
            OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionText
        ) {
          return;
        }

        // get content length of imageReferenceFigcaptionText
        const contentLength: number =
          imageReferenceFigcaptionText.textContent.length;

        // get pos at the end of the imageReferenceFigcaptionText
        // pos of image reference + length of imageReferenceFigcaptionText + 5 (start and end of ImageReferenceFigcaption and ImageReferenceFigcaptionTextContainer + start of ImageReferenceFigcaptionText)
        const resolvedPos: ResolvedPos = newCustomBlockTransaction.doc.resolve(
          pos + contentLength + 5
        );

        // set cursor at the end of the imageReferenceFigcaptionText
        const selection = new TextSelection(resolvedPos);

        // update transaction
        newCustomBlockTransaction =
          newCustomBlockTransaction.setSelection(selection);
      } else if (
        node.type.name === customBlockTypeName &&
        node.attrs["data-file-id"] === customBlockId &&
        pos === positionNewCustomBlock.from
      ) {
        // get file reference first child
        const fileReferenceLink: Node | null = node.firstChild;

        // safety-checks
        if (
          !fileReferenceLink ||
          fileReferenceLink.type.name !==
            OtherMarkdownCustomBlockNameEnum.FileReferenceLink
        ) {
          return;
        }

        // get file reference link first child
        const fileReferenceLinkTitle: Node | null =
          fileReferenceLink.firstChild;

        // safety-checks
        if (
          !fileReferenceLinkTitle ||
          fileReferenceLinkTitle.type.name !==
            OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle
        ) {
          return;
        }

        // get content length of span
        const contentLength: number = fileReferenceLinkTitle.textContent.length;

        // get pos at the end of the span
        // pos of file reference + length of span + 4 (start and end of file_reference_link and file_reference_link_title)
        const resolvedPos: ResolvedPos = newCustomBlockTransaction.doc.resolve(
          pos + contentLength + 4
        );

        // set cursor at the end of the span
        const selection = new TextSelection(resolvedPos);

        // update transaction
        newCustomBlockTransaction =
          newCustomBlockTransaction.setSelection(selection);
      } else if (
        node.type.name === customBlockTypeName &&
        (node.attrs[`${CustomBlockIdAttributeEnum.EntityReference}`] ===
          customBlockId ||
          node.attrs[`${CustomBlockIdAttributeEnum.StudyReference}`] ===
            customBlockId) &&
        pos === positionNewCustomBlock.from
      ) {
        // get entity/study reference first child
        const referenceLink: Node | null = node.firstChild;

        // safety-checks
        if (
          !referenceLink ||
          referenceLink.type.name !==
            OtherMarkdownCustomBlockNameEnum.ReferenceLink
        ) {
          return;
        }

        // get content length of referenceLink
        const contentLength: number = referenceLink.textContent.length;

        // get pos at the end of the referenceLink
        // pos of entity/study reference + length of referenceLink + 2 (start and end of referenceLink)
        const resolvedPos: ResolvedPos = newCustomBlockTransaction.doc.resolve(
          pos + contentLength + 2
        );

        // set cursor at the end of the span
        const selection = new TextSelection(resolvedPos);

        // update transaction
        newCustomBlockTransaction =
          newCustomBlockTransaction.setSelection(selection);
      }
    });

    // return updated transaction
    return newCustomBlockTransaction;
  }

  public insertHighlight(
    highlightId: string,
    highlightUrl: string,
    highlightText: string,
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean {
    // get position where to insert the highlight
    const position = this.getPositionWhereToInsertNewElement(state.selection);

    // insert highlight at the position
    let tr = state.tr.replaceWith(
      position.from,
      position.to,
      findestSchema.nodes.highlight_reference.create(
        {
          id: crypto.randomUUID(),
          "data-highlight-id": highlightId,
          selected: "false",
        },
        Fragment.fromArray([
          findestSchema.nodes.paragraph.create(
            undefined,
            highlightText ? findestSchema.text(highlightText) : undefined
          ),
          findestSchema.nodes.reference_link.create(
            {
              href: highlightUrl,
              rel: "noopener noreferrer",
              target: "_blank",
              class: `${SpecialBlockClassNameEnum.ReferenceLink}`,
            },
            findestSchema.text("[Ref]")
          ),
        ])
      )
    );

    // set cursor at the end of the highlight reference's paragraph
    tr = this.setCursorAtNewCustomBlockEnd(
      tr,
      TopDepthMarkdownCustomBlockNameEnum.HighlightReference,
      highlightId,
      position
    );

    // dispatch transaction
    if (dispatch) {
      dispatch(tr);
    }

    // transaction dispatched
    return true;
  }

  public insertImage(
    imageId: string,
    imageUrl: string,
    imageCaption: string,
    referenceUrl: string,
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean {
    // get position where to insert the image
    const position = this.getPositionWhereToInsertNewElement(state.selection);

    // init imageReferenceCaptionContent
    const imageReferenceCaptionContent: Node[] = [
      findestSchema.nodes.image_reference_figcaption_text_container.create(
        undefined,
        findestSchema.nodes.image_reference_figcaption_text.create(
          {
            class: `${SpecialBlockClassNameEnum.ImageReferenceFigcaptionText}`,
          },
          imageCaption ? findestSchema.text(imageCaption) : undefined
        )
      ),
    ];

    // if referenceUrl is not empty
    if (referenceUrl) {
      imageReferenceCaptionContent.push(
        findestSchema.nodes.reference_link.create(
          {
            href: referenceUrl,
            rel: "noopener noreferrer",
            target: "_blank",
            class: `${SpecialBlockClassNameEnum.ReferenceLink}`,
          },
          findestSchema.text("[Ref]")
        )
      );
    }
    // insert image at the position
    let tr = state.tr.replaceWith(
      position.from,
      position.to,
      findestSchema.nodes.image_reference.create(
        {
          id: crypto.randomUUID(),
          "data-image-id": imageId,
          selected: "false",
        },
        Fragment.fromArray([
          findestSchema.nodes.image_reference_img.create({
            src: imageUrl,
            alt: imageCaption,
          }),
          findestSchema.nodes.image_reference_figcaption.create(
            {
              class: !imageCaption
                ? `${SpecialBlockClassNameEnum.ImageReferenceFigcaption} empty-caption`
                : `${SpecialBlockClassNameEnum.ImageReferenceFigcaption}`,
            },
            Fragment.fromArray(imageReferenceCaptionContent)
          ),
        ])
      )
    );

    // set cursor at the end of the image reference's caption
    tr = this.setCursorAtNewCustomBlockEnd(
      tr,
      "image_reference",
      imageId,
      position
    );

    // dispatch transaction
    if (dispatch) {
      dispatch(tr);
    }

    // transaction dispatched
    return true;
  }

  public insertFile(
    fileId: string,
    fileUrl: string,
    fileTitle: string,
    fileExtension: string,
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean {
    // get position where to insert the file
    const position = this.getPositionWhereToInsertNewElement(state.selection);

    // insert file at the position
    let tr = state.tr.replaceWith(
      position.from,
      position.to,
      findestSchema.nodes.file_reference.create(
        {
          id: crypto.randomUUID(),
          "data-file-id": fileId,
          selected: "false",
        },
        findestSchema.nodes.file_reference_link.create(
          {
            href: fileUrl,
            rel: "noopener noreferrer",
            target: "_blank",
            class: `${SpecialBlockClassNameEnum.FileReferenceLink}`,
          },
          Fragment.fromArray([
            findestSchema.nodes.file_reference_link_title.create(
              undefined,
              fileTitle ? findestSchema.text(fileTitle) : undefined
            ),
            findestSchema.nodes.file_reference_link_extension.create(
              undefined,
              fileExtension
                ? findestSchema.text(`.${fileExtension}`)
                : undefined
            ),
          ])
        )
      )
    );

    // set cursor at the end of the file reference's title
    tr = this.setCursorAtNewCustomBlockEnd(
      tr,
      TopDepthMarkdownCustomBlockNameEnum.FileReference,
      fileId,
      position
    );

    // dispatch transaction
    if (dispatch) {
      dispatch(tr);
    }

    // transaction dispatched
    return true;
  }

  public async getAddIntakeSheetTransactionAsync(
    editorView: EditorView
  ): Promise<Transaction | undefined> {
    // get intake sheet template
    const intakeSheetTemplate: string | undefined =
      await TemplateControllerSingleton.getOtherTemplateAsync(
        OtherTemplateTypeEnum.IntakeSheet
      );

    // safety-checks
    if (!intakeSheetTemplate) {
      // return undefined
      return undefined;
    }

    // check if there is already an intake sheet confirmation in the document
    if (this.isIntakeSheetConfirmationAlreadyInDocument(editorView.state.doc)) {
      // notifiy user
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "An intake sheet is already in the document."
      );
      // return undefined
      return undefined;
    }

    // insert intake sheet template at the beginning of the editor
    // get intake sheet node
    const intakeSheetNode: Node | null =
      findestMarkdownParser.parse(intakeSheetTemplate);

    // safety-checks
    if (!intakeSheetNode) {
      // return undefined
      return undefined;
    }

    // init intake sheet fragment to insert
    let intakeSheetFragment = Fragment.empty;
    // go through intake sheet node content
    intakeSheetNode.content.forEach((node: Node) => {
      // add the node to the fragment
      intakeSheetFragment = intakeSheetFragment.addToEnd(node);
    });

    // create a transaction which inserts the intake sheet fragment at the beginning of the editor
    const transaction: Transaction = editorView.state.tr.insert(
      0,
      intakeSheetFragment
    );

    // return transaction
    return transaction;
  }

  public isIntakeSheetConfirmationAlreadyInDocument(doc: Node): boolean {
    // init isIntakeSheetAlreadyInDocument to false
    let isIntakeSheetAlreadyInDocument = false;

    // go through all nodes of the document
    doc.descendants((node: Node) => {
      if (
        node.type.name === findestSchema.nodes.intake_sheet_confirmation.name
      ) {
        // set isIntakeSheetAlreadyInDocument to true
        isIntakeSheetAlreadyInDocument = true;
        // return false to stop the loop
        return false;
      }
    });

    // return isIntakeSheetAlreadyInDocument
    return isIntakeSheetAlreadyInDocument;
  }

  public isResultsOverviewTableAlreadyInDocument(doc: Node): boolean {
    // init isResultsOverviewTableAlreadyInDocument to false
    let isResultsOverviewTableAlreadyInDocument = false;

    // go through all nodes of the document
    doc.descendants((node: Node) => {
      if (
        (node.type.name === findestSchema.nodes.heading.name &&
          node.textContent
            .toLowerCase()
            .includes(
              EditorConstants.OVERVIEW_TABLE_HEADING_TEXT.toLowerCase()
            )) ||
        node.marks.some(
          (mark) => mark.type.name === findestSchema.marks.inlineStars.name
        )
      ) {
        // set isResultsOverviewTableAlreadyInDocument to true
        isResultsOverviewTableAlreadyInDocument = true;
        // return false to stop the loop
        return false;
      }
    });

    // return isResultsOverviewTableAlreadyInDocument
    return isResultsOverviewTableAlreadyInDocument;
  }

  public getRatingsDataInResultsOverviewTable(doc: Node): TRatingsData {
    // init ratings data
    const ratingsData: TRatingsData = {
      totalNumberOfRatings: 0,
      numberOfRatingsDone: 0,
    };

    // go through all nodes of the document
    doc.descendants((node: Node) => {
      if (
        node.marks.some(
          (mark) => mark.type.name === findestSchema.marks.inlineStars.name
        )
      ) {
        // increase total number of ratings
        ratingsData.totalNumberOfRatings++;
        // if the rating is not needed
        if (
          node.marks.some(
            (mark) =>
              mark.attrs[`${CustomDOMAttributes.StarsIsRatingNeeded}`] ===
              "false"
          )
        ) {
          // increase number of ratings done
          ratingsData.numberOfRatingsDone++;
        }
      }
    });

    // return ratings data
    return ratingsData;
  }

  public async handlePasteImagesAsync(
    editorView: EditorView,
    updateEditorView: (
      editorViewToUpdate: EditorView,
      transaction: Transaction,
      doCallOnSourceChangeCallback?: boolean,
      callbackBeforeSetState?: ((newState: EditorState) => void) | undefined
    ) => void,
    event: Event,
    objectIdEdited: string,
    objectTypeEdited: ObjectTypeEnum,
    applyInsertImage: (
      imageId: string,
      imageUrl: string,
      imageCaption: string,
      referenceUrl: string
    ) => void
  ): Promise<void> {
    // get files from event (custom event, properties in detail)
    // either files or filesWithUrls and fragmentToPaste are defined
    const files: File[] | undefined = (event as CustomEvent).detail.files;
    const filesWithUrls: { src: string; file: File }[] | undefined = (
      event as CustomEvent
    ).detail.filesWithUrls;
    const fragmentToPaste: Fragment | undefined = (event as CustomEvent).detail
      .fragmentToPaste;

    // if only files are defined
    // means we are only pasting images copied from local system (not from the web)
    if (files !== undefined) {
      // go through the files
      for (const file of files) {
        // validate image file format
        if (!FileHelperSingleton.isAcceptedImageFileFormat(file)) {
          continue;
        }

        // for each of them, add (and upload) them to the object edited
        const newImage: TImageDTO | undefined =
          await ImageControllerSingleton.addImageToObjectAsync(
            file,
            objectIdEdited,
            objectTypeEdited,
            ""
          );

        // safety-checks
        if (!newImage) {
          continue;
        }

        // then insert the image reference in the editor
        applyInsertImage(newImage.id, newImage.path, "", "");
      }
    } else if (filesWithUrls !== undefined && fragmentToPaste !== undefined) {
      // if both filesWithUrls and fragmentToPaste are defined
      // means we are pasting content from the web (containing images)

      // get nodes from fragment to paste
      const nodesToPaste: Node[] = [];
      fragmentToPaste.forEach((node: Node) => {
        nodesToPaste.push(node);
      });

      // build fragment to paste
      let newFragment = Fragment.empty;
      // go through the nodes to paste
      for (const node of nodesToPaste) {
        // if the node is of type image_reference_img
        // and it has a src attribute
        // and no id attribute
        // it means we are pasting an image from outside the editor
        if (
          node.type.name === findestSchema.nodes.image_reference_img.name &&
          node.attrs.src &&
          !node.attrs.id
        ) {
          // get the related item in filesWithUrls
          const relatedFileWithUrl = filesWithUrls.find(
            (fileWithUrl) => fileWithUrl.src === node.attrs.src
          );

          // safety-checks
          if (relatedFileWithUrl) {
            // add (and upload) them to the object edited
            const newImage: TImageDTO | undefined =
              await ImageControllerSingleton.addImageToObjectAsync(
                relatedFileWithUrl.file,
                objectIdEdited,
                objectTypeEdited,
                ""
              );

            // safety-checks
            if (newImage) {
              // init image reference to insert
              const imageReference = findestSchema.nodes.image_reference.create(
                {
                  id: crypto.randomUUID(),
                  "data-image-id": newImage.id,
                  selected: "false",
                },
                Fragment.fromArray([
                  findestSchema.nodes.image_reference_img.create({
                    src: newImage.path,
                    alt: "",
                  }),
                  findestSchema.nodes.image_reference_figcaption.create(
                    {
                      class: `${SpecialBlockClassNameEnum.ImageReferenceFigcaption} empty-caption`,
                    },
                    Fragment.fromArray([
                      findestSchema.nodes.image_reference_figcaption_text_container.create(
                        undefined,
                        findestSchema.nodes.image_reference_figcaption_text.create(
                          {
                            class: `${SpecialBlockClassNameEnum.ImageReferenceFigcaptionText}`,
                          },
                          undefined
                        )
                      ),
                    ])
                  ),
                ])
              );
              // add image reference to fragment
              newFragment = newFragment.addToEnd(imageReference);
            }
          }
        } else {
          // add the node to the fragment
          newFragment = newFragment.addToEnd(node);
        }
      }

      // get position where to insert the fragment
      const position = this.getPositionWhereToInsertNewElement(
        editorView.state.selection
      );

      // create transaction to insert the fragment
      let tr = editorView.state.tr.insert(position.from, newFragment);

      // get the transaction to update the node markups for the pasted content
      tr = ProseMirrorHelperSingleton.getPostPasteNodeMarkupsUpdateTransaction(
        tr,
        editorView
      );

      // update editor view source
      updateEditorView(editorView, tr, true);
    }
  }

  public getPostPasteNodeMarkupsUpdateTransaction(
    tr: Transaction | undefined,
    editorView: EditorView
  ): Transaction {
    // init custom node reference block names
    const customNodeReferenceBlockNames: string[] = [
      findestSchema.nodes.highlight_reference.name,
      findestSchema.nodes.image_reference.name,
      findestSchema.nodes.file_reference.name,
      findestSchema.nodes.entity_reference.name,
      findestSchema.nodes.study_reference.name,
    ];

    // get transaction from editor view if not defined
    tr = tr ?? editorView.state.tr;
    // for each descendant of the new doc from the transaction
    tr.doc.descendants((node, pos) => {
      // if the node is a heading
      if (node.type.name === findestSchema.nodes.heading.name) {
        // remove all marks from the heading
        // and set the id attribute to a new uuid (to prevent duplicates)
        tr = tr ?? editorView.state.tr;
        tr = tr
          .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
          .setNodeMarkup(pos, undefined, {
            ...node.attrs,
            id: crypto.randomUUID(),
          })
          .removeMark(pos, pos + node.nodeSize);
      }

      // on tables, table heads/rows/cells
      if (EditorTableDOMTagValues.includes(node.type.name)) {
        // set the id attribute to a new uuid (to prevent duplicates)
        tr = tr ?? editorView.state.tr;
        tr = tr
          .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
          .setNodeMarkup(pos, undefined, {
            ...node.attrs,
            id: crypto.randomUUID(),
          });
      }

      // on custom node reference blocks
      if (customNodeReferenceBlockNames.includes(node.type.name)) {
        // set the id attribute to a new uuid (to prevent duplicates)
        // and set the selected attribute to false
        tr = tr ?? editorView.state.tr;
        tr = tr
          .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
          .setNodeMarkup(pos, undefined, {
            ...node.attrs,
            id: crypto.randomUUID(),
            selected: "false",
          });
      }
    });

    // return updated transaction
    return tr;
  }

  public insertObjectAsReference(
    objectInformation: TIdNameTypeObjectType,
    state: EditorState,
    dispatch: (tr: Transaction) => void,
    doInsertAsBlock = false
  ): boolean {
    const insertAsBlock = !FeatureToggleConstants.BlockReferencing
      ? false
      : doInsertAsBlock;

    if (insertAsBlock) {
      // build block reference node
      const blockReferenceNode: Node =
        ProseMirrorHelperSingleton.buildBlockReferenceNode(
          objectInformation.id,
          objectInformation.objectType,
          objectInformation.name
        );

      // get position where to insert the block reference node
      const position =
        ProseMirrorHelperSingleton.getPositionWhereToInsertNewElement(
          state.selection
        );

      // get transaction to insert the block reference node
      let tr = state.tr.replaceWith(
        position.from,
        position.to,
        blockReferenceNode
      );

      // set cursor at the end of the block reference node
      tr = ProseMirrorHelperSingleton.setCursorAtNewCustomBlockEnd(
        tr,
        objectInformation.objectType === ObjectTypeEnum.Entity
          ? TopDepthMarkdownCustomBlockNameEnum.EntityReference
          : TopDepthMarkdownCustomBlockNameEnum.StudyReference,
        objectInformation.id,
        { from: position.from, to: position.to }
      );

      // dispatch transaction (insert block reference node)
      dispatch(tr);
    } else {
      // create inlineReference attrs
      const inlineReferenceAttrs: { [key: string]: string } = {
        id: crypto.randomUUID(),
        [`${CustomInlineIdAttributeEnum.InlineReference}`]: `${objectInformation.id}`,
        [`${CustomDOMAttributes.DataInlineReferenceType}`]: `${objectInformation.objectType}`,
        class: `${SpecialBlockClassNameEnum.InlineReference}`,
      };

      // create mark node
      const inlineReferenceNode = findestSchema.text(objectInformation.name, [
        findestSchema.marks.inlineReference.create(inlineReferenceAttrs),
      ]);

      // create insert mark node transaction
      const tr = state.tr.insert(
        state.selection.$from.pos,
        inlineReferenceNode
      );

      // dispatch transaction
      dispatch(tr);
    }

    // transaction dispatched
    return true;
  }

  public buildBlockReferenceNode(
    referenceId: string,
    referenceType: ObjectTypeEnum,
    referenceName: string
  ): Node {
    // build block reference url
    const url = `/library/${
      referenceType === ObjectTypeEnum.Study ? "studies" : "entities"
    }/${referenceId}`;

    // build block reference node content
    const objectReferenceLink = findestSchema.nodes.reference_link.create(
      {
        href: url,
        rel: "noopener noreferrer",
        target: "_blank",
        class: `${SpecialBlockClassNameEnum.ReferenceLink}`,
      },
      findestSchema.text(referenceName)
    );

    // build block reference node
    let typeObjectReferenceLink: Node;
    if (referenceType === ObjectTypeEnum.Study) {
      typeObjectReferenceLink = findestSchema.nodes.study_reference.create(
        {
          id: crypto.randomUUID(),
          [`${CustomBlockIdAttributeEnum.StudyReference}`]: referenceId,
          selected: "false",
        },
        Fragment.fromArray([objectReferenceLink])
      );
    } else {
      typeObjectReferenceLink = findestSchema.nodes.entity_reference.create(
        {
          id: crypto.randomUUID(),
          [`${CustomBlockIdAttributeEnum.EntityReference}`]: referenceId,
          selected: "false",
        },
        Fragment.fromArray([objectReferenceLink])
      );
    }

    // return block reference node
    return typeObjectReferenceLink;
  }

  // handle click event
  public handleClickEvent(event: MouseEvent): boolean {
    // get element
    const element = event.target as HTMLElement;

    // get necessary attributes
    let href: string | null = element.getAttribute("href");
    let target: string | null = element.getAttribute("target");
    let rel: string | null = element.getAttribute("rel");

    // if one of them is null, try to get them from parent
    // (in the case of a click on file-reference-link-title or file-reference-link-extension)
    if (!href || !target || !rel) {
      // get parent element
      const parentElement = element.parentElement;
      // safety-checks
      if (!parentElement) {
        // return false (click not handled)
        return false;
      }

      // get necessary attributes again
      href = parentElement.getAttribute("href");
      target = parentElement.getAttribute("target");
      rel = parentElement.getAttribute("rel");
    }

    // safety-checks
    if (!href || !target || !rel) {
      // return false (click not handled)
      return false;
    }

    // open the link
    window.open(href, target, rel);

    // return true (click handled)
    return true;
  }

  public defineCustomDOMElements(): void {
    // define custom dom elements
    customElements.define(`${CustomDOMTag.EntityReference}`, EntityReference);
    customElements.define(`${CustomDOMTag.FileReference}`, FileReference);
    customElements.define(
      `${CustomDOMTag.FileReferenceLink}`,
      FileReferenceLink
    );
    customElements.define(
      `${CustomDOMTag.FileReferenceLinkExtension}`,
      FileReferenceLinkExtension
    );
    customElements.define(
      `${CustomDOMTag.FileReferenceLinkTitle}`,
      FileReferenceLinkTitle
    );
    customElements.define(
      `${CustomDOMTag.HighlightReference}`,
      HighlightReference
    );
    customElements.define(
      `${CustomDOMTag.ImageReferenceFigcaptionText}`,
      ImageReferenceFigcaptionText
    );
    customElements.define(
      `${CustomDOMTag.ImageReferenceFigcaptionTextContainer}`,
      ImageReferenceFigcaptionTextContainer
    );
    customElements.define(`${CustomDOMTag.InlineReference}`, InlineReference);
    customElements.define(
      `${CustomDOMTag.IntakeSheetConfirmation}`,
      IntakeSheetConfirmation
    );
    customElements.define(
      `${CustomDOMTag.IntakeSheetConfirmationAccepted}`,
      IntakeSheetConfirmationAccepted
    );
    customElements.define(
      `${CustomDOMTag.IntakeSheetConfirmationNotAccepted}`,
      IntakeSheetConfirmationNotAccepted
    );
    customElements.define(`${CustomDOMTag.ReferenceLink}`, ReferenceLink);
    customElements.define(`${CustomDOMTag.StudyReference}`, StudyReference);
  }

  // get inline reference data from node
  public getInlineReferenceData(node: HTMLElement): {
    id?: string;
    type?: ObjectTypeEnum;
  } {
    // safety-checks
    if (!node) {
      // return default values
      return {
        id: undefined,
        type: undefined,
      };
    }

    // get node name in lower case
    const nodeName = node.nodeName.toLowerCase();

    // depending on node type
    switch (nodeName) {
      case CustomDOMTag.InlineReference:
        return {
          id:
            node.getAttribute(
              `${CustomInlineIdAttributeEnum.InlineReference}`
            ) ?? undefined,
          type: parseInt(
            node.getAttribute(
              `${CustomDOMAttributes.DataInlineReferenceType}`
            ) ?? "0",
            10
          ),
        };
      default:
        return {
          id: undefined,
          type: undefined,
        };
    }
  }

  // get inline stars data from node
  public getInlineStarsData(node: HTMLElement): { forTargetId?: string } {
    // safety-checks
    if (!node) {
      // return default values
      return {
        forTargetId: undefined,
      };
    }

    // get node name in lower case
    const nodeName = node.nodeName.toLowerCase();

    // depending on node type
    switch (nodeName) {
      case CustomDOMTag.Meter:
        return {
          forTargetId:
            node.getAttribute(`${CustomDOMAttributes.StarsTargetId}`) ??
            undefined,
        };
      default:
        return {
          forTargetId: undefined,
        };
    }
  }

  // helper function to know if an element is an inline reference node
  public isInlineReferenceNode(element: HTMLElement): boolean {
    // return true if node name is the inline reference dom tag
    return CustomDOMTag.InlineReference === element.nodeName.toLowerCase();
  }

  // helper function to know if an element is an inline stars node
  public isInlineStarsNode(element: HTMLElement): boolean {
    // return true if node name is the meter dom tag
    return CustomDOMTag.Meter === element.nodeName.toLowerCase();
  }

  public onMouseOverInlineReferenceHandler(
    event: Event,
    showReferencePopover: (
      id: string,
      type: ObjectTypeEnum,
      referenceElement: HTMLElement
    ) => void,
    hideReferencePopover: () => void
  ): void {
    // TODO: find a better way to manage the is open state of the popovers
    // since the popover is not a child of the element from which we open it (because it is in the MarkdownIt render or the ProseMirror render)
    // we need this hacky way to manage the is open state of the popovers
    // get event target

    const target: HTMLElement = event.target as HTMLElement;

    // if node name is an inline reference
    if (this.isInlineReferenceNode(target)) {
      // get inline reference data
      const inlineReferenceData: {
        id?: string;
        type?: ObjectTypeEnum;
      } = ProseMirrorHelperSingleton.getInlineReferenceData(target);

      // if inline reference id and inline reference type are set
      if (
        inlineReferenceData.id !== undefined &&
        inlineReferenceData.type !== undefined
      ) {
        // show reference popover
        showReferencePopover(
          inlineReferenceData.id,
          inlineReferenceData.type,
          target
        );
      }
    } else {
      // hide reference popover
      hideReferencePopover();
    }
  }

  public onMouseOverInlineStarsHandler(
    event: Event,
    showRatingsPopover: (
      forTargetId: string,
      meterElement: HTMLElement
    ) => void,
    hideRatingsPopover: () => void
  ): void {
    // TODO: find a better way to manage the is open state of the popovers
    // since the popover is not a child of the element from which we open it (because it is in the MarkdownIt render or the ProseMirror render)
    // we need this hacky way to manage the is open state of the popovers

    // get event target
    const target: HTMLElement = event.target as HTMLElement;

    // if node name is an inline stars node
    if (this.isInlineStarsNode(target)) {
      // get inline stars data
      const inlineStarsData: {
        forTargetId?: string;
      } = ProseMirrorHelperSingleton.getInlineStarsData(target);

      // if inline stars for target id is set
      if (inlineStarsData.forTargetId !== undefined) {
        // show ratings popover
        showRatingsPopover(inlineStarsData.forTargetId, target);
      }
    } else {
      // if target is not in a popover node
      if (!this.isInPopoverNode(target)) {
        // hide ratings popover
        hideRatingsPopover();
      }
    }
  }

  public onClickInlineReferenceHandler(
    event: Event,
    navigate: NavigateFunction
  ) {
    // get event target
    const target: HTMLElement = event.target as HTMLElement;

    // if node name is an inline reference
    if (this.isInlineReferenceNode(target)) {
      // get inline reference data
      const inlineReferenceData: {
        id?: string;
        type?: ObjectTypeEnum;
      } = ProseMirrorHelperSingleton.getInlineReferenceData(target);

      // if inline reference id and inline reference type are set
      if (
        inlineReferenceData.id !== undefined &&
        inlineReferenceData.type !== undefined
      ) {
        // navigate to the object's page based on its object type
        ObjectTypeHelperSingleton.navigateBasedOnObjectType(
          inlineReferenceData.type,
          inlineReferenceData.id,
          navigate
        );
      }
    }
  }

  public isInPopoverNode = (element: HTMLElement): boolean => {
    // if element has attribute data-is-popover
    if (element.getAttribute(`${CustomDOMAttributes.IsPopover}`)) {
      // return true
      return true;
    } else {
      // otherwise, go through all parents of the element until finding attribute data-is-popover or body element
      let parentElement = element.parentElement;
      while (
        parentElement &&
        !parentElement.getAttribute(`${CustomDOMAttributes.IsPopover}`) &&
        parentElement.nodeName.toLowerCase() !== "body"
      ) {
        // get parent element
        parentElement = parentElement.parentElement;
      }

      // if parent element is null or body element or does not have attribute data-is-popover
      if (
        !parentElement ||
        parentElement.nodeName.toLowerCase() === "body" ||
        !parentElement.getAttribute(`${CustomDOMAttributes.IsPopover}`)
      ) {
        // return false
        return false;
      }

      // return true
      return true;
    }
  };

  public isNodeEmpty = (node: Node): boolean => {
    // init is node empty
    let isNodeEmpty = true;

    // go through node descendants
    node.descendants((nodeDescendant: Node) => {
      // if nodeDescendant is not empty
      if (nodeDescendant.textContent !== "") {
        // set isNodeEmpty to false
        isNodeEmpty = false;
        // return false to stop the loop
        return false;
      }
    });

    // return isNodeEmpty
    return isNodeEmpty;
  };

  public replaceReferencesInContent(
    content: string,
    referenceDict?: { [key: string]: TReferenceTextAndLinkDTO }
  ): string {
    // make a copy of the content
    let contentCopy = content;

    // knowing a reference in the content is in the following format [REF_abstract_1_1] or [REF_highlight_1_1]
    // we need to replace it with the actual reference from referenceDict
    // get all references in the content
    const referencesInContent: RegExpMatchArray | null = contentCopy.match(
      /\[REF_(abstract|highlight)_\d+_\d+\]/g
    );
    // safety-checks
    if (referencesInContent && referenceDict) {
      // go through references in the content
      for (const referenceInContent of referencesInContent) {
        // get referenceId
        let referenceId = referenceInContent;
        referenceId = referenceId.replace("[", "");
        referenceId = referenceId.replace("]", "");

        // safety-checks
        if (!referenceDict[referenceId]) {
          // continue
          continue;
        }

        // get reference
        const reference: TReferenceTextAndLinkDTO | undefined =
          referenceDict[referenceId];

        // safety-checks
        if (!reference || !reference.fulltextlink) {
          // continue
          continue;
        }

        // replace reference in the content per a markdown link to the reference
        contentCopy = contentCopy.replace(
          referenceInContent,
          `[[Ref]](${reference.fulltextlink})`
        );
      }
    }

    // return the content copy
    return contentCopy;
  }

  public getTIGenerationContent = (
    result: TOverallTIAutomationResponseDTO,
    selectedModalMenuItem: AskIgorMenuItemEnum
  ): string => {
    // init ti generation content
    let tiGenerationContent = "";

    // depending on selected modal menu item
    // if selected modal menu item is general description
    if (
      selectedModalMenuItem === AskIgorMenuItemEnum.GeneralDescriptionUsingLinks
    ) {
      // safety-checks
      if (
        result.TechnologyDescription &&
        result.TechnologyDescription.Description
      ) {
        // get general description
        tiGenerationContent = `${result.TechnologyDescription.Description}\r\n`;

        // replace references in general description
        tiGenerationContent = this.replaceReferencesInContent(
          tiGenerationContent,
          result.TechnologyDescription.ReferenceDict
        );
      }
    } else if (selectedModalMenuItem === AskIgorMenuItemEnum.ExecutiveSummary) {
      // safety-checks
      if (result.ExecutiveSummary && result.ExecutiveSummary.Summary) {
        // get summary
        tiGenerationContent = `${result.ExecutiveSummary.Summary}\r\n`;

        // replace references in summary
        tiGenerationContent = this.replaceReferencesInContent(
          tiGenerationContent,
          result.ExecutiveSummary.ReferenceDict
        );
      }
    } else if (selectedModalMenuItem === AskIgorMenuItemEnum.Table) {
      // safety-checks
      if (result.Table) {
        // we first need to get each requirement name
        const requirementNames: string[] = [];
        // go through each entry in result.Table
        Object.entries(result.Table).forEach(([keyA, valueA]) => {
          // if keyA is not TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
          if (
            keyA !==
            AiConstants.TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
          ) {
            // go through each entry in valueA
            Object.entries(valueA).forEach(([keyB]) => {
              // if keyB is not TI_GENERATION_TABLE_FEATURE_SUMMARY_KEY and keyB is not in requirementNames
              if (
                keyB !== AiConstants.TI_GENERATION_TABLE_FEATURE_SUMMARY_KEY &&
                !requirementNames.includes(keyB)
              ) {
                // add keyB in requirementNames
                requirementNames.push(keyB);
              }
            });
          }
        });

        // go through each requirement name
        requirementNames.forEach((requirementName: string) => {
          // safety-checks
          if (!result.Table) {
            // stop execution, return
            return;
          }

          // init requirement value in each document
          let requirementValueInEachDocument = "";

          // go through each entry in result.Table
          Object.entries(result.Table).forEach(([key, value]) => {
            // check if the requirement name is defined and key is not TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
            if (
              value[requirementName] &&
              key !==
                AiConstants.TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
            ) {
              // remove dot at the end of value at requirement name
              value[requirementName] = StringHelperSingleton.removeDotAtTheEnd(
                value[requirementName]
              );

              // add value at requirement name to requirement value in each document
              requirementValueInEachDocument += ` ${value[requirementName]} [[Ref]](${key})`;

              // format requirement value in each document
              requirementValueInEachDocument =
                StringHelperSingleton.formatWithDot(
                  requirementValueInEachDocument
                );
            }
          });

          // trim requirement value in each document
          requirementValueInEachDocument =
            requirementValueInEachDocument.trim();
          // if requirement value in each document is not set
          if (!requirementValueInEachDocument) {
            // set requirement value in each document to N/A
            requirementValueInEachDocument = "N/A";
          }

          // add requirement value in each document to ti generation content
          tiGenerationContent += `* **${requirementName}**: ${requirementValueInEachDocument}\r\n`;
        });
      }
    }

    // return ti generation content
    return tiGenerationContent;
  };
}

export const ProseMirrorHelperSingleton = new ProseMirrorHelper();
