import { FloatingPortal, hide } from "@floating-ui/react";
import {
  OffsetOptions,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react-dom";
import {
  Fragment,
  MouseEventHandler,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
// Custom Hooks
import { useClickOutsideRef, useMergeRefs } from "Hooks";
// Styles
import styles from "./popover.module.scss";

type TPopoverProps = {
  children: ReactNode;
  extraClassName?: string;
  referenceEl: Element | null;
  placement?: Placement | undefined;
  isOpen?: boolean;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onClickOutside?: () => void;
  popoverOffset?: OffsetOptions;
  showInPortal?: boolean;
  isSameHeightWithReference?: boolean;
  dynamicPlacement?: boolean;
  dataIdentifier?: string;
  exceptionDataIdentifiter?: string;
};

export const Popover = ({
  children,
  extraClassName = "",
  placement,
  referenceEl,
  isOpen = true,
  onMouseEnter,
  onMouseLeave,
  onClickOutside,
  popoverOffset = 4,
  showInPortal,
  isSameHeightWithReference,
  dynamicPlacement = true,
  dataIdentifier,
  exceptionDataIdentifiter,
}: TPopoverProps) => {
  // Hide popover on click outside
  const onClickOutsidePopover = () => {
    if (onClickOutside) onClickOutside();
  };

  const { x, y, strategy, refs, middlewareData } = useFloating({
    placement: placement ?? "bottom",
    middleware: [
      offset(popoverOffset),
      dynamicPlacement ? flip({ padding: 16 }) : undefined,
      dynamicPlacement ? shift() : undefined,
      size({
        apply({ availableWidth, availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            minWidth: `${rects.reference.width}px`,
            ...(isSameHeightWithReference
              ? { height: `${rects.reference.height}px` }
              : { maxHeight: `${availableHeight}px` }),
          });
        },
        padding: 16,
      }),
      hide(),
    ].filter((m) => m),
    whileElementsMounted: autoUpdate,
  });

  useLayoutEffect(() => {
    refs.setReference(referenceEl);
  }, [refs, referenceEl]);

  const SelectedTag = useMemo(() => {
    return showInPortal ? FloatingPortal : Fragment;
  }, [showInPortal]);

  const popupRef = useRef<HTMLDivElement>(null);
  const ref = useMergeRefs([refs.setFloating, popupRef]);
  useClickOutsideRef(
    popupRef,
    onClickOutsidePopover,
    [],
    exceptionDataIdentifiter ?? undefined
  );

  return (
    <SelectedTag>
      {isOpen && (
        <div
          data-identifier={dataIdentifier ?? null}
          ref={ref}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            visibility: middlewareData.hide?.referenceHidden
              ? "hidden"
              : "visible",
          }}
          className={[styles.popover, extraClassName].join(" ")}
        >
          {children}
        </div>
      )}
    </SelectedTag>
  );
};
