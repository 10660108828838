// node_modules
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons";
// Types
import { TCollapsibleListItem } from "Types";
// Styles
import styles from "./explorerCollapsibleListItem.module.scss";

type TCollapsibleListItemProps = {
  children: React.ReactNode;
  shouldShowOnlyChildObjectIcon?: boolean;
  toggleIsCollapsed: (listItem: TCollapsibleListItem) => void;
  listItem: TCollapsibleListItem;
  extraClassNames?: {
    chevronIconContainer?: string;
    onlyChildIconContainer?: string;
    chevronIcon?: string;
    onlyChildIcon?: string;
  };
};
export const ExplorerCollapsibleListItem: FC<TCollapsibleListItemProps> = ({
  children,
  listItem,
  shouldShowOnlyChildObjectIcon,
  toggleIsCollapsed,
  extraClassNames = {},
}: TCollapsibleListItemProps) => {
  const doesListItemHaveLowerLevelNodes =
    listItem.lowerLevelNodes && listItem.lowerLevelNodes.length > 0;

  const changeIsCollapsed = () => {
    toggleIsCollapsed(listItem);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      changeIsCollapsed();
    }
  };

  return (
    <div className={styles.collapsibleListItem}>
      <div className={styles.linksListItemActionsContainer}>
        {doesListItemHaveLowerLevelNodes && (
          <div
            className={`${styles.actionContainer} ${
              extraClassNames?.chevronIconContainer ?? ""
            }`}
            title={listItem.isCollapsed !== false ? "Uncollapse" : "Collapse"}
            onClick={changeIsCollapsed}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            <FontAwesomeIcon
              className={extraClassNames?.chevronIcon ?? ""}
              icon={
                listItem.isCollapsed !== false ? faChevronRight : faChevronDown
              }
            />
          </div>
        )}
        {!doesListItemHaveLowerLevelNodes && shouldShowOnlyChildObjectIcon && (
          <div className={`${extraClassNames?.onlyChildIconContainer ?? ""}`}>
            <FontAwesomeIcon
              className={extraClassNames?.onlyChildIcon ?? ""}
              icon={faCircle}
            />
          </div>
        )}
      </div>
      <div className={styles.collapsibleListItemContent}>{children}</div>
    </div>
  );
};
