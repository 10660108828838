// node_modules
import { FC, useEffect, useRef } from "react";
// Components
import { FindestButton, Modal } from "Components";
// Styles
import styles from "./fileUploadProgressIndicator.module.scss";

type TFileUploadProgressIndicatorProps = {
    fileUploadProgress?: number,
    onCancelClick?: () => void
};

export const FileUploadProgressIndicator: FC<TFileUploadProgressIndicatorProps> = ({ fileUploadProgress = 0, onCancelClick }: TFileUploadProgressIndicatorProps) => {
    const progressCircleRef = useRef<SVGCircleElement | null>(null);
    const progress = (fileUploadProgress % 100).toString();

    useEffect(() => {
        if (progressCircleRef?.current?.style) {
            progressCircleRef.current.style.setProperty("--percent", progress);
        }
    }, [progress]);

    return (
        <Modal extraClassNames={{ container: styles.fileUploadProgressIndicatorModal, header: styles.header }} isOpen={!!fileUploadProgress} header="Uploading file">
            <div className={styles.body}>
                <div className={styles.percentage}>
                    <svg>
                        <circle cx="40" cy="40" r="30"></circle>
                        <circle ref={progressCircleRef} cx="40" cy="40" r="30"></circle>
                    </svg>
                    <div className={styles.number}>
                        {`${progress}%`}
                    </div>
                </div>
                {!!fileUploadProgress && fileUploadProgress > 98 && (
                    <p className={styles.processingText}>Processing file on server...</p>
                )}
            </div>
            {onCancelClick && <div className={styles.footer}>
                <FindestButton
                    title="Cancel"
                    onClick={onCancelClick} 
                    buttonType="cancel"
                />
            </div>}
        </Modal>
    );
};