// node_modules
import { FC, useMemo } from "react";
// Types
import { TCommentDTO, TDocumentReferencesDTO, TReferenceDTO } from "Types";
// Components
import { LoadingStatusIndicator } from "Components";
import { CommonDocumentReferences } from "./CommonDocumentReferences";
// Styles
import styles from "./referenceSidebarTabs.module.scss";
// Enums
import { ObjectTypeEnum, SearchThroughEnum, WebRequestStatusEnum } from "Enums";
// Controllers
import { LinkingControllerSingleton } from "Controllers";

export type ReferenceSidebarDocumentsTabProps = {
  searchThrough: SearchThroughEnum;
  objectIdEdited: string;
  objectTypeEdited: ObjectTypeEnum;
  searchTextValue: string;
  isSearchRunning: boolean;
  documentReferences: TDocumentReferencesDTO[];
  usedReferenceIds: Set<string>;
  insertDocumentAsReference: (
    documentUrl: string,
    documentId: string,
    documentType: ObjectTypeEnum
  ) => void;
  insertImageAsReference: (
    reference: TReferenceDTO,
    documentId: string,
    documentType: ObjectTypeEnum
  ) => void;
  insertHighlightAsReference: (
    reference: TReferenceDTO,
    documentId: string,
    documentType: ObjectTypeEnum
  ) => void;
  insertHighlightAsText: (text: string) => void;
  onReferenceCommentsUpdated: (
    referenceId: string,
    comments: TCommentDTO[]
  ) => void;
  refreshDocuments?: () => void;
};

export const ReferenceSidebarDocumentsTab: FC<ReferenceSidebarDocumentsTabProps> =
  ({
    searchTextValue,
    objectIdEdited,
    objectTypeEdited,
    isSearchRunning,
    searchThrough,
    documentReferences,
    usedReferenceIds,
    insertDocumentAsReference,
    insertImageAsReference,
    insertHighlightAsReference,
    insertHighlightAsText,
    onReferenceCommentsUpdated,
    refreshDocuments,
  }: ReferenceSidebarDocumentsTabProps) => {
    const explanationText = useMemo(() => {
      switch (searchThrough) {
        case SearchThroughEnum.Inbox:
          return (
            <div className={styles.noReferences}>
              <h4>Documents you have added to your inbox will appear here.</h4>
              <h4>
                You add documents directly to your inbox by adding them using
                the browser extension!
              </h4>
              <h4>
                Highlights and images that you have saved from these documents
                can be easily injected into the current page.
              </h4>
            </div>
          );
        case SearchThroughEnum.Documents:
          return (
            <div className={styles.noReferences}>
              <h4>
                Documents that are saved inside your universe appear here.
              </h4>
              <h4>
                You add document to this view by saving them using the browser
                extension and adding a highlight, image or linking the document.
              </h4>
              <h4>
                Highlights and images that you have saved from these documents
                can be easily injected into the current page.
              </h4>
            </div>
          );
        default:
          return <div className={styles.noReferences}></div>;
      }
    }, [searchThrough]);

    const whileSearchingText = useMemo(() => {
      if (isSearchRunning) {
        return (
          <div className={styles.loadingIndicatorContainer}>
            <LoadingStatusIndicator size={40} status={1} />
          </div>
        );
      } else {
        return (
          <div className={styles.noReferences}>
            <div>
              No{" "}
              {searchThrough === SearchThroughEnum.Highlights
                ? "highlights"
                : "documents"}{" "}
              found for search value: {searchTextValue}
            </div>
          </div>
        );
      }
    }, [isSearchRunning, searchTextValue, searchThrough]);

    const onLinkingIconClickAsync = async (
      isCurrentlyConnected: boolean,
      currentObjectIdEdited: string,
      currentObjectTypeEdited: ObjectTypeEnum,
      documentReference: TDocumentReferencesDTO
    ): Promise<boolean> => {
      // safety-checks
      if (!currentObjectIdEdited || !documentReference) {
        return false;
      }

      // depending on isCurrentlyConnected, call the appropriate function
      if (isCurrentlyConnected) {
        // delete the link
        return await LinkingControllerSingleton.deleteAsync(
          currentObjectIdEdited,
          documentReference.id
        );
      } else {
        // create the link
        const webRequestStatus = await LinkingControllerSingleton.createToAsync(
          documentReference.id,
          documentReference.type,
          currentObjectIdEdited,
          currentObjectTypeEdited
        );

        // return true if the web request was successful
        return webRequestStatus === WebRequestStatusEnum.Success ||
          webRequestStatus === WebRequestStatusEnum.AlreadyExists
          ? true
          : false;
      }
    };

    // Render
    return (
      <div className={styles.referenceSidebarSelectedTab}>
        {documentReferences.map((currentDocumentReferences) => {
          const allReferencesUsed =
            currentDocumentReferences.references.every((reference) =>
              usedReferenceIds.has(reference.id)
            ) && currentDocumentReferences.references.length > 0;

          return (
            <CommonDocumentReferences
              key={currentDocumentReferences.id}
              objectIdEdited={objectIdEdited}
              objectTypeEdited={objectTypeEdited}
              documentId={currentDocumentReferences.id}
              documentType={currentDocumentReferences.type}
              documentTitle={currentDocumentReferences.documentTitle}
              documentUrl={currentDocumentReferences.documentUrl}
              isConnected={currentDocumentReferences.isConnected}
              references={currentDocumentReferences.references}
              allReferencesUsed={allReferencesUsed}
              insertDocumentAsReference={insertDocumentAsReference}
              insertImageAsReference={insertImageAsReference}
              insertHighlightAsReference={insertHighlightAsReference}
              insertHighlightAsText={insertHighlightAsText}
              usedReferenceIds={usedReferenceIds}
              refreshDocuments={refreshDocuments}
              onReferenceCommentsUpdated={onReferenceCommentsUpdated}
              onLinkingIconClick={(isCurrentlyConnected) =>
                onLinkingIconClickAsync(
                  isCurrentlyConnected,
                  objectIdEdited,
                  objectTypeEdited,
                  currentDocumentReferences
                )
              }
            />
          );
        })}

        {documentReferences.length === 0
          ? searchTextValue.length === 0
            ? explanationText
            : whileSearchingText
          : null}
      </div>
    );
  };
