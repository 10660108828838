import { useRef, useState, useEffect } from "react";

export const useTooltipDelay = (delay: number) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const hoverTimer = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    hoverTimer.current = setTimeout(() => {
      setIsTooltipShown(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
    }
    setIsTooltipShown(false);
  };

  useEffect(() => {
    return () => {
      if (hoverTimer.current) {
        clearTimeout(hoverTimer.current);
      }
    };
  }, []);

  return { isTooltipShown, handleMouseEnter, handleMouseLeave };
};