// node_modules
import { faLink, faText } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
// Components
import { FindestButton, FindestTextBox, Heading4 } from "Components";
// Custom hooks
import { useFormLink } from "Hooks";
// Styles
import styles from "./editorFloatingLinkMenu.module.scss";

interface IEditorLinkPanelProps {
  isInsertMode?: boolean;
  initialUrl?: string;
  onSave: (url: string, text?: string) => void;
  onCancel: () => void;
}

export const EditorLinkPanel: FC<IEditorLinkPanelProps> = ({
  isInsertMode,
  initialUrl,
  onSave,
  onCancel,
}: IEditorLinkPanelProps) => {
  const { text, setText, url, setUrl, isValidUrl, handleSubmit } = useFormLink({
    initialUrl,
    onSave,
  });

  const isSubmitButtonDisabled = isInsertMode ? !text || !isValidUrl : !isValidUrl;

  return (
    <form className={styles.editorLinkPanel} onSubmit={handleSubmit}>
      {isInsertMode && (
        <div>
          <Heading4>Text</Heading4>
          <FindestTextBox
            leftIcon={faText}
            type="text"
            value={text}
            onChange={setText}
          />
        </div>
      )}
      <div>
        <Heading4>Link</Heading4>
        <FindestTextBox
          leftIcon={faLink}
          type="url"
          value={url}
          onChange={setUrl}
        />
      </div>
      <div className={styles.footer}>
        <FindestButton
          buttonType="secondary"
          title="Cancel"
          onClick={onCancel}
        />
        <FindestButton
          title={isInsertMode ? "Insert" : "Save"}
          type="submit"
          isDisabled={isSubmitButtonDisabled}
        />
      </div>
    </form>
  );
};
