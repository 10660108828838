// node_modules
import { faEdit, faTrash, faCopy, faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef, useState } from "react";
// Components
import { Tooltip } from "Components";
// Helpers
import { NavigatorHelperSingleton, ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";
// Styles
import styles from "./editorFloatingLinkMenu.module.scss";

interface IEditorLinkPreviewPanelProps {
  url: string;
  onEdit: () => void;
  onRemove: () => void;
}

export const EditorLinkPreviewPanel: FC<IEditorLinkPreviewPanelProps> = ({
  url,
  onEdit,
  onRemove,
}: IEditorLinkPreviewPanelProps) => {
  const [isEditLinkTooltipShown, setIsEditLinkTooltipShown] = useState<boolean>(false);
  const [isRemoveLinkTooltipShown, setIsRemoveLinkTooltipShown] = useState<boolean>(false);
  const [isCopyLinkTooltipShown, setIsCopyLinkTooltipShown] = useState<boolean>(false);

  const editLinkButtonRef = useRef<HTMLButtonElement | null>(null);
  const removeLinkButtonRef = useRef<HTMLButtonElement | null>(null);
  const copyLinkButtonRef = useRef<HTMLButtonElement | null>(null);

  const onCopy = async () => {
    // Copy the selected text to the clipboard
    await NavigatorHelperSingleton.copyToClipboardAsync(url);
    // Show a success message
    ToastHelperSingleton.showToast(
      ToastTypeEnum.Success,
      "Link is copied to clipboard."
    );
  };

  return (
    <div className={styles.editorLinkPreviewPanel}>
      <div className={styles.linkContainer}>
        <FontAwesomeIcon className={styles.globeIcon} icon={faGlobe} />
        <a className={styles.link} href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </div>
      <button
        ref={copyLinkButtonRef}
        type="button"
        title="Copy link"
        onClick={onCopy}
        onMouseEnter={() => {
          setIsCopyLinkTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsCopyLinkTooltipShown(false);
        }}
        className={styles.actionButton}
      >
        <FontAwesomeIcon icon={faCopy} />
        <Tooltip
          referenceEl={copyLinkButtonRef.current}
          isOpen={isCopyLinkTooltipShown}
          tooltipText={"Copy link"}
        />
      </button>
      <button
        ref={editLinkButtonRef}
        type="button"
        title="Edit link"
        onClick={onEdit}
        onMouseEnter={() => {
          setIsEditLinkTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsEditLinkTooltipShown(false);
        }}
        className={styles.actionButton}
      >
        <FontAwesomeIcon icon={faEdit} />
        <Tooltip
          referenceEl={editLinkButtonRef.current}
          isOpen={isEditLinkTooltipShown}
          tooltipText={"Edit link"}
        />
      </button>
      <button
        ref={removeLinkButtonRef}
        type="button"
        title="Remove link"
        onClick={onRemove}
        onMouseEnter={() => {
          setIsRemoveLinkTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsRemoveLinkTooltipShown(false);
        }}
        className={styles.actionButton}
      >
        <FontAwesomeIcon icon={faTrash} />
        <Tooltip
          referenceEl={removeLinkButtonRef.current}
          isOpen={isRemoveLinkTooltipShown}
          tooltipText={"Remove link"}
        />
      </button>
    </div>
  );
};
