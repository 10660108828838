// node_modules
import { useCallback, useContext, useEffect, useRef, useState } from "react";
// Enums
import { AskIgorMenuItemEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  AskIgorMenuItemHelperSingleton,
  LogHelperSingleton,
  StringHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Types
import {
  TAskIgorRequirement,
  TIdNameTypeObjectType,
  TLogEventName,
  TOverallTIAutomationResponseDTO,
  TReferenceTextAndLinkDTO,
  TWriteSectionDTO,
} from "Types";
// Controllers
import {
  GptControllerSingleton,
  LinkingControllerSingleton,
} from "Controllers";
// Constants
import {
  AiConstants,
  ErrorConstants,
  TextConstants,
  WebsocketFunctionNames,
} from "Constants";
// Providers
import { WebsocketContext } from "Providers";
// Interfaces
import { IUseAskIgor } from "Interfaces";

interface IUseAskIgorProps {
  askIgorMenuItem?: AskIgorMenuItemEnum;
  documentsSelected: string[];
}

export const useAskIgor = ({
  askIgorMenuItem,
  documentsSelected,
}: IUseAskIgorProps): IUseAskIgor => {
  const { webSocketController } = useContext(WebsocketContext);

  const [isGeneratingText, setIsGeneratingText] = useState<boolean>(false);
  const [completionId, setCompletionId] =
    useState<string | undefined>(undefined);
  const [aiGeneratedText, setAIGeneratedText] = useState<string>("");

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const onSubmitHandlerAsync = async (
    object: TIdNameTypeObjectType,
    text: string,
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number,
    menuItem?: AskIgorMenuItemEnum,
    requirements?: TAskIgorRequirement[]
  ): Promise<void> => {
    menuItem ??= askIgorMenuItem;
    if (!menuItem) {
      return;
    }

    LogHelperSingleton.logWithProperties("AskIgorAISettingsUpdated", {
      DataPointsAmount: dataPointsAmount,
      DocumentTypes: documentTypes.join(", "),
    });

    if (menuItem === AskIgorMenuItemEnum.Table && requirements) {
      text = requirements
        .map((requirement) => requirement.text.trim())
        .filter((requirement) => requirement)
        .join(", ")
        .trim();

      if (!text) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Please insert at least one detail."
        );
        return;
      }
    }

    await requestIgorAssistanceAsync(
      object,
      text,
      menuItem,
      documentTypes,
      dataPointsAmount
    );
  };

  const onCancelCompletion = useCallback(() => {
    if (completionId) {
      webSocketController.invokeFunction(
        WebsocketFunctionNames.CancelCompletion,
        completionId,
        true
      );

      setCompletionId(undefined);
    } else {
      abortControllerRef.current.abort();
    }
  }, [completionId, webSocketController]);

  const onReceiveCompletionPart = useCallback(
    (completionPart: string) => {
      if (
        completionPart === null ||
        completionPart === undefined ||
        !completionId
      ) {
        onCancelCompletion();

        setIsGeneratingText(false);

        return;
      }

      setAIGeneratedText(
        (prevAIGeneratedText) => prevAIGeneratedText + completionPart
      );
    },
    [completionId, onCancelCompletion]
  );

  const onReceiveCompletionId = useCallback(
    (newCompletionId: string) => {
      setCompletionId(newCompletionId);
    },
    [setCompletionId]
  );

  const replaceReferencesInContent = useCallback(
    (
      content: string,
      referenceDict?: { [key: string]: TReferenceTextAndLinkDTO }
    ): string => {
      let contentCopy = content;

      const referencesInContent: RegExpMatchArray | null = contentCopy.match(
        /\[REF_(abstract|highlight)_\d+_\d+\]/g
      );

      if (referencesInContent && referenceDict) {
        for (const referenceInContent of referencesInContent) {
          let referenceId = referenceInContent;
          referenceId = referenceId.replace("[", "");
          referenceId = referenceId.replace("]", "");

          if (!referenceDict[referenceId]) {
            continue;
          }

          const reference: TReferenceTextAndLinkDTO | undefined =
            referenceDict[referenceId];

          if (!reference || !reference.fulltextlink) {
            continue;
          }

          contentCopy = contentCopy.replace(
            referenceInContent,
            `<a target="_blank" rel="noopener noreferrer" href="${reference.fulltextlink}">[Ref]</a>`
          );
        }
      }

      return contentCopy;
    },
    []
  );

  const getTIGenerationContent = useCallback(
    (result: TOverallTIAutomationResponseDTO): string => {
      let tiGenerationContent = "";

      if (
        askIgorMenuItem === AskIgorMenuItemEnum.GeneralDescriptionUsingLinks
      ) {
        if (
          result.TechnologyDescription &&
          result.TechnologyDescription.Description
        ) {
          tiGenerationContent = `<p>${result.TechnologyDescription.Description}</p>`;

          tiGenerationContent = replaceReferencesInContent(
            tiGenerationContent,
            result.TechnologyDescription.ReferenceDict
          );
        }
      } else if (askIgorMenuItem === AskIgorMenuItemEnum.ExecutiveSummary) {
        if (result.ExecutiveSummary && result.ExecutiveSummary.Summary) {
          tiGenerationContent = `<p>${result.ExecutiveSummary.Summary}</p>`;

          tiGenerationContent = replaceReferencesInContent(
            tiGenerationContent,
            result.ExecutiveSummary.ReferenceDict
          );
        }
      } else if (askIgorMenuItem === AskIgorMenuItemEnum.Table) {
        if (result.Table) {
          const requirementNames: string[] = [];
          Object.entries(result.Table).forEach(([keyA, valueA]) => {
            if (
              keyA !==
              AiConstants.TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
            ) {
              Object.entries(valueA).forEach(([keyB]) => {
                if (
                  keyB !==
                    AiConstants.TI_GENERATION_TABLE_FEATURE_SUMMARY_KEY &&
                  !requirementNames.includes(keyB)
                ) {
                  requirementNames.push(keyB);
                }
              });
            }
          });

          tiGenerationContent = "<ul>";

          requirementNames.forEach((requirementName: string) => {
            if (!result.Table) {
              return;
            }

            let requirementValueInEachDocument = "";

            Object.entries(result.Table).forEach(([key, value]) => {
              if (
                value[requirementName] &&
                key !==
                  AiConstants.TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
              ) {
                value[requirementName] =
                  StringHelperSingleton.removeDotAtTheEnd(
                    value[requirementName]
                  );

                requirementValueInEachDocument += ` ${value[requirementName]} <a target="_blank" rel="noopener noreferrer" href="${key}">[Ref]</a>`;

                requirementValueInEachDocument =
                  StringHelperSingleton.formatWithDot(
                    requirementValueInEachDocument
                  );
              }
            });

            requirementValueInEachDocument =
              requirementValueInEachDocument.trim();
            if (!requirementValueInEachDocument) {
              requirementValueInEachDocument = "N/A";
            }

            tiGenerationContent += `<li><p><b>${requirementName}</b>: ${requirementValueInEachDocument}</p></li>`;
          });

          tiGenerationContent += "</ul>";
        }
      }

      return tiGenerationContent;
    },
    [askIgorMenuItem, replaceReferencesInContent]
  );

  const onReceiveCompletionResult = useCallback(
    (result: TOverallTIAutomationResponseDTO | string | null | undefined) => {
      if (!askIgorMenuItem) {
        setAIGeneratedText(
          `${AiConstants.ERROR_START_MESSAGE}${ErrorConstants.AI_GENERATION_NOT_SUPPORTED}`
        );

        return;
      }

      setCompletionId(undefined);

      setIsGeneratingText(false);

      if (!result) {
        setAIGeneratedText(
          `${
            AiConstants.ERROR_START_MESSAGE
          }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
            askIgorMenuItem
          )}`
        );

        return;
      } else if (typeof result === "string") {
        setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${result}`);

        return;
      }

      setAIGeneratedText(getTIGenerationContent(result));
    },
    [askIgorMenuItem, getTIGenerationContent]
  );

  useEffect(() => {
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionId,
      onReceiveCompletionId
    );
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionPart,
      onReceiveCompletionPart
    );
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionResult,
      onReceiveCompletionResult
    );

    return () => {
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionId,
        onReceiveCompletionId
      );
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionPart,
        onReceiveCompletionPart
      );
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionResult,
        onReceiveCompletionResult
      );
    };
  }, [
    onReceiveCompletionId,
    onReceiveCompletionPart,
    onReceiveCompletionResult,
    webSocketController,
  ]);

  const requestNonWebsocketBasedIgorAssistanceAsync = async (
    object: TIdNameTypeObjectType,
    selectedMenuItem: AskIgorMenuItemEnum,
    documentTypes: ObjectTypeEnum[],
    dataPoints: string[],
    dataPointsAmount?: number
  ) => {
    abortControllerRef.current = new AbortController();

    if (selectedMenuItem === AskIgorMenuItemEnum.GenerateReport) {
      const description: string | undefined =
        await GptControllerSingleton.generateReportFromDocumentsAndHighlightsAsync(
          object.id,
          object.objectType,
          abortControllerRef.current.signal,
          dataPointsAmount,
          documentTypes,
          dataPoints
        );
      if (description === undefined || description === null) {
        setAIGeneratedText(
          `${
            AiConstants.ERROR_START_MESSAGE
          }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
            selectedMenuItem
          )}`
        );

        return;
      }

      setAIGeneratedText(description);
    } else if (
      selectedMenuItem ===
      AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
    ) {
      const description: string | undefined =
        await GptControllerSingleton.generateDescriptionAsync(
          object.name,
          abortControllerRef.current.signal
        );

      if (description === undefined || description === null) {
        setAIGeneratedText(
          `${
            AiConstants.ERROR_START_MESSAGE
          }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
            selectedMenuItem
          )}`
        );

        return;
      }

      setAIGeneratedText(description);
    }
  };

  const requestIgorAssistanceAsync = async (
    object: TIdNameTypeObjectType,
    text: string,
    selectedMenuItem: AskIgorMenuItemEnum,
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number
  ) => {
    onCancelCompletion();

    setIsGeneratingText(false);

    setAIGeneratedText("");

    if (isGeneratingText) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        ErrorConstants.AI_GENERATION_ALREADY_RUNNING
      );
      return;
    }

    const doRequireDocumentsOrHighlights =
      AskIgorMenuItemHelperSingleton.getDoRequireDocumentsOrHighlights(
        selectedMenuItem
      );

    if (doRequireDocumentsOrHighlights && documentsSelected.length <= 0) {
      const hasDocumentsOrHighlights =
        await LinkingControllerSingleton.hasLinksOfTypesAsync(object.id, [
          ObjectTypeEnum.Highlight,
          ...documentTypes,
        ]);

      if (!hasDocumentsOrHighlights) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
        );
        return;
      }
    }

    setIsGeneratingText(true);

    const startLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedStartLogEventName(
        selectedMenuItem
      );

    LogHelperSingleton.log(startLogEventName);

    const isWebsocketBased: boolean = [
      AskIgorMenuItemEnum.QuestionAndAnswer,
      AskIgorMenuItemEnum.GeneralDescriptionUsingLinks,
      AskIgorMenuItemEnum.WriteSection,
      AskIgorMenuItemEnum.Table,
      AskIgorMenuItemEnum.ExecutiveSummary,
    ].includes(selectedMenuItem);

    dataPointsAmount =
      documentsSelected.length > 0 ? undefined : dataPointsAmount;

    if (isWebsocketBased) {
      const webSocketFunctionName: string =
        AskIgorMenuItemHelperSingleton.getRelatedWebsocketFunctionName(
          selectedMenuItem
        );

      if (!webSocketFunctionName) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          ErrorConstants.AI_GENERATION_NOT_SUPPORTED
        );
        return;
      }

      await webSocketController.invokeFunction(webSocketFunctionName, {
        objectId: object.id,
        objectType: object.objectType,
        text,
        dataPointsAmount: dataPointsAmount,
        documentTypes: documentTypes,
        dataPoints: documentsSelected,
      } as TWriteSectionDTO);
    } else {
      await requestNonWebsocketBasedIgorAssistanceAsync(
        object,
        selectedMenuItem,
        documentTypes,
        documentsSelected,
        dataPointsAmount
      );

      setIsGeneratingText(false);
    }
  };

  return {
    isGeneratingText,
    setIsGeneratingText,
    onSubmitHandlerAsync,
    aiGeneratedText,
    setAIGeneratedText,
    onCancelCompletion,
  };
};
