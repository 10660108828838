// node_modules
import { FC } from "react";
// Enums
import { AskIgorMenuItemEnum } from "Enums";
// Helpers
import { AskIgorMenuItemHelperSingleton } from "Helpers/Enums/AskIgorMenuItemHelper";
// Styles
import styles from "./askIgorMenuItem.module.scss";

type TAskIgorMenuItemProps = {
  item: AskIgorMenuItemEnum;
  isSelected: boolean;
  onClick: (item: AskIgorMenuItemEnum) => void;
};

export const AskIgorMenuItem: FC<TAskIgorMenuItemProps> = ({
  item,
  isSelected,
  onClick,
}: TAskIgorMenuItemProps) => {
  return (
    <div className={styles.container}>
      <div
        onClick={() => {
          onClick(item);
        }}
        className={[styles.item, isSelected ? styles.selected : ""].join(" ")}
      >
        {AskIgorMenuItemHelperSingleton.askIgorMenuItemEnumToDisplayString(
          item
        )}
      </div>
    </div>
  );
};
