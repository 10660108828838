// node_modules
import { Editor } from "@tiptap/core";
// Controllers
import {
  EntityControllerSingleton,
  LinkingControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Enums
import {
  EntityTypeEnum,
  LogFeatureNameEnum,
  ObjectTypeEnum,
  ToastTypeEnum,
  WebRequestStatusEnum,
} from "Enums";
// Helpers
import {
  CREATE_ENTITY_COMMAND,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Types
import { TIdNameTypeObjectType } from "Types";

interface IUseCreateEntityProps {
  openLinkingModal?: () => void;
}

export const useCreateEntity = ({
  openLinkingModal,
}: IUseCreateEntityProps) => {
  const onCreateEntityAsync = async (
    objectIdEdited: string,
    objectTypeEdited: ObjectTypeEnum,
    text: string,
    editor: Editor
  ): Promise<void> => {
    const foundEntities: TIdNameTypeObjectType[] =
      await SearchControllerSingleton.searchMultipleObjectsAsync(text, [
        ObjectTypeEnum.Entity,
      ]);

    if (foundEntities && foundEntities.length > 0 && openLinkingModal) {
      openLinkingModal();
    } else {
      const newEntity = {
        title: text,
        type: EntityTypeEnum.Undefined,
      } as IEntityDTO;

      const createdEntity: IEntityDTO | undefined =
        await EntityControllerSingleton.createAsync(newEntity);

      if (!createdEntity) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Error while creating new entity."
        );
        return;
      }

      LogHelperSingleton.log("CreateEntity");
      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-SaveSelectionAsEntity`
      );

      const webRequestStatusEnum: WebRequestStatusEnum =
        await LinkingControllerSingleton.createToAsync(
          createdEntity.id,
          ObjectTypeEnum.Entity,
          objectIdEdited,
          objectTypeEdited
        );

      if (
        webRequestStatusEnum !== WebRequestStatusEnum.Success &&
        webRequestStatusEnum !== WebRequestStatusEnum.AlreadyExists
      ) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Error while linking entity to current page."
        );
        return;
      }

      LogHelperSingleton.log("Link");

      onCreateEntityAsyncCallback(
        {
          id: createdEntity.id,
        } as TIdNameTypeObjectType,
        editor
      );
    }
  };

  const onCreateEntityAsyncCallback = (
    createdEntity: TIdNameTypeObjectType,
    editor: Editor
  ) => {
    const entityURL = `/library/entities/${createdEntity.id}`;

    CREATE_ENTITY_COMMAND.action(editor, {
      url: entityURL,
      objectId: createdEntity.id,
      objectType: ObjectTypeEnum.Entity,
    });

    window.open(entityURL, "_blank", "noopener noreferrer");

    LogHelperSingleton.log(
      `${LogFeatureNameEnum.Reporting}-SaveSelectionAsEntity`
    );
  };

  return { onCreateEntityAsync, onCreateEntityAsyncCallback };
};
