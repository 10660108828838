// node_modules
import { Editor } from "@tiptap/core";
// Interfaces
import { IUseSelectionNode } from "Interfaces";

interface IUseSelectionNodeProps {
  editor: Editor | null;
}

export const useSelectionNode = ({
  editor,
}: IUseSelectionNodeProps): IUseSelectionNode | null => {
  if (editor) {
    return {
      fromNode: editor.state.selection.$from.node(),
      fromNodePos: editor.state.selection.$from.pos,
      toNode: editor.state.selection.$to.node(),
      toNodePos: editor.state.selection.$to.pos,
      selectedText: editor.state.doc.textBetween(
        editor.state.selection.from,
        editor.state.selection.to
      ),
    };
  } else {
    return null;
  }
};
