// node_modules
import { useEditor } from "@tiptap/react";
import { FC, MouseEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Types
import { TCommentDTO, TReferenceDTO } from "Types";
// Styles
import styles from "./referenceSidebar.module.scss";
// Enums
import { ReferenceClickTypeEnum } from "Enums";
// Components
import { EditorContent } from "Components";
import { AddReferencePopover } from "./AddReferencePopover";
// Helpers
import { CompactEditorExtensionsKit, GetCustomLink } from "Helpers";

// Constants
type TCommonReferenceProps = {
  isReferenceUsedAlready: boolean;
  reference: TReferenceDTO;
  handleOnReferenceClick: (
    event: MouseEvent,
    referenceClickType: ReferenceClickTypeEnum,
    reference?: TReferenceDTO
  ) => void;
  onReferenceCommentsUpdated: (
    referenceId: string,
    comments: TCommentDTO[]
  ) => void;
};

export const CommonReference: FC<TCommonReferenceProps> = ({
  isReferenceUsedAlready,
  reference,
  handleOnReferenceClick,
}: TCommonReferenceProps) => {
  // State
  const [refPopoverReferenceElement, setRefPopoverReferenceElement] =
    useState<HTMLHeadElement | null>(null);
  const [isAddReferencePopoverShown, setIsAddReferencePopoverShown] =
    useState<boolean>(false);

  const navigate = useNavigate();

  // Parse the JSON description from the database and provide it to TipTap
  const parsedContent = useMemo(() => {
    if (reference.isImage) return reference.text;

    try {
      return reference.text ? JSON.parse(reference.text) : "";
    } catch (error) {
      return reference.text ?? "";
    }
  }, [reference.isImage, reference.text]);

  const editor = useEditor(
    {
      extensions: [...CompactEditorExtensionsKit, GetCustomLink(navigate)],
      content: parsedContent,
      editable: false,
    },
    [reference]
  );

  // Render
  return (
    <div
      onMouseEnter={() => {
        setIsAddReferencePopoverShown(true);
      }}
      ref={setRefPopoverReferenceElement}
      className={`${styles.reference} ${
        isAddReferencePopoverShown ? styles.hover : ""
      } ${isReferenceUsedAlready ? styles.isUsed : ""}`}
      onMouseLeave={() => {
        setIsAddReferencePopoverShown(false);
      }}
    >
      {isAddReferencePopoverShown && (
        <AddReferencePopover
          reference={reference}
          refPopoverReferenceElement={refPopoverReferenceElement}
          handleOnReferenceClick={handleOnReferenceClick}
        />
      )}
      <div className={`${styles.addLinkContainer}`}></div>
      {reference.isImage ? (
        <div className={styles.referenceImage}>
          <img src={reference.url} alt={reference.text} />
          <h5 title={reference.text}>{reference.text}</h5>
        </div>
      ) : (
        <div className={styles.referenceHighlight}>
          <EditorContent editor={editor} className="compactEditorContent" />
        </div>
      )}
    </div>
  );
};
