export enum ObjectTypeEnum {
  Unknown = 0,
  Entity = 1,
  Document = 2,
  Highlight = 3,
  Study = 4,
  Image = 5,
  ScienceArticle = 6,
  UsPatent = 7,
  Weblink = 8,
  MagPatent = 9,
  Comment = 10,
  File = 11,
  Tenant = 12,
  Organization = 13,
  Case = 14,
  Query = 15,
}

export const DocumentObjectTypeEnums = [
  ObjectTypeEnum.Document,
  ObjectTypeEnum.ScienceArticle,
  ObjectTypeEnum.UsPatent,
  ObjectTypeEnum.MagPatent,
  ObjectTypeEnum.Weblink,
];
