// node_modules
import { faThumbTack } from "@fortawesome/pro-solid-svg-icons";
import { FC, useContext } from "react";
// Contexts
import { PinnedContext } from "Providers";
// Components
import { TopbarDropdownButton } from "../TopbarDropdownButton/TopbarDropdownButton";
// Types
import { TIdNameTypeObjectType } from "Types";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";

// Component
export const Pinned: FC = () => {
  // Contexts
  const { pinnedItems } = useContext(PinnedContext);

  // Render
  return (
    <TopbarDropdownButton
      buttonIcon={faThumbTack}
      emptyItemsMessage="No pinned pages (yet)"
      items={pinnedItems}
      displaySubItemType={(item: TIdNameTypeObjectType) => {
        return ObjectTypeHelperSingleton.getObjectTypeFullDisplayName(
          item.objectType,
          item.type,
          item.customTypeName
        );
      }}
      tooltipText="Pinned"
    />
  );
};
