// node_modules
import { Editor, EditorContent as TiptapEditorContent } from "@tiptap/react";
import { FC, useContext, useRef } from "react";
// Components
import { Ratings, ReferenceCard } from "Components";
import { EditorPopover } from "./EditorPopover/EditorPopover";
// Providers
import { AuthContext } from "Providers";
// Custom hooks
import { useObjectReferenceModal, useRating, useReference } from "Hooks";
// Styles
import "./editor.scss";
import styles from "./editorModule.module.scss";

interface IEditorContentProps {
  editor: Editor | null;
  className?: string;
  content?: string;
}

export const EditorContent: FC<IEditorContentProps> = ({
  editor,
  className,
  content,
}: IEditorContentProps) => {
  const { auth } = useContext(AuthContext);

  const editorContentRef = useRef<HTMLDivElement>(null);

  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();
  const { referenceElement, referenceLink, resetReference } = useReference({
    ref: editorContentRef.current,
  });
  const { ratingElement, ratingData, resetRating, updateRatingInEditor } =
    useRating({
      ref: editorContentRef,
    });
  if (!editor) return null;

  return (
    <>
      <TiptapEditorContent
        editor={editor}
        ref={editorContentRef}
        content={content}
        className={className}
      />
      <EditorPopover
        closePopover={resetReference}
        isOpen={!!(referenceElement && referenceLink)}
        refElement={referenceElement}
        extraClassNames={{ popover: styles.referenceCard }}
      >
        <ReferenceCard
          id={referenceLink?.id}
          type={referenceLink?.objectType}
          url={referenceLink?.name}
          setReferenceModalProps={setReferenceModalProps}
          onClickCallback={resetReference}
        />
      </EditorPopover>
      <EditorPopover
        closePopover={resetRating}
        isOpen={!!(ratingElement && ratingData)}
        refElement={ratingElement}
        extraClassNames={{ popover: styles.ratingPopover, innerContent: styles.innerContent }}
      >
        <Ratings
          hideRatingsPopover={resetRating}
          isOpen={!!(ratingElement && ratingData)}
          onNewAverageRating={(
            forSourceId: string,
            forTargetId: string,
            newCount: number,
            newScore: number,
            newIsRatingNeeded: boolean
          ) => {
            updateRatingInEditor(
              editor,
              forSourceId,
              forTargetId,
              newCount,
              newScore,
              newIsRatingNeeded
            );
          }}
          forSourceId={ratingData?.sourceId}
          forSourceType={ratingData?.sourceType}
          forTargetId={ratingData?.targetId[0]}
          isInPopover={true}
          currentUserEmail={auth.userEmail}
        />
      </EditorPopover>
      {referenceModal}
    </>
  );
};
