// node_modules
import {
  faBookOpenReader,
  faDiceD6,
  faFile,
  faHighlighter,
  faImage,
  faPaperclip,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { FC, useCallback, useContext, useEffect, useState } from "react";
// Controllers
import {
  HighlightControllerSingleton,
  ImageControllerSingleton,
  LinkingControllerSingleton,
  SavedDocumentControllerSingleton,
  SavedFileControllerSingleton,
} from "Controllers";
// Enums
import { EntityTypeEnum, ObjectTypeEnum } from "Enums";
// Helpers
import {
  EntityTypeHelperSingleton,
  LogHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Types
import {
  THighlightDTO,
  TImageDTO,
  TLinkedCountsDTO,
  TLinkedToDTO,
  TListItem,
  TSavedFileDTO,
} from "Types";
// Components
import {
  AttachmentItem,
  Highlight,
  Image,
  ListItem,
  PositionedPopup,
  SavedDocumentItem,
} from "Components";
// Styles
import styles from "./linkedCounts.module.scss";
// Constants
import { GeneralConstants } from "Constants";
// Contexts
import { AuthContext } from "Providers";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";

type TLinkedCountsProps = {
  objectId: string;
  linkedCounts: TLinkedCountsDTO;
};

export const LinkedCounts: FC<TLinkedCountsProps> = ({
  objectId,
  linkedCounts,
}: TLinkedCountsProps) => {
  // Context
  const { auth, isUserExternal } = useContext(AuthContext);
  // Const
  const onMouseEnterDelayInMs = GeneralConstants.DEFAULT_MS_DELAY;

  // State
  const [isHoverPopupShown, setIsHoverPopupShown] = useState(false);
  const [cachedStudies, setCachedStudies] =
    useState<undefined | TLinkedToDTO[]>(undefined);
  const [cachedEntities, setCachedEntities] =
    useState<undefined | TLinkedToDTO[]>(undefined);
  const [cachedDocuments, setCachedDocuments] =
    useState<undefined | ISavedDocumentDTO[]>(undefined);
  const [cachedHighlights, setCachedHighlights] =
    useState<undefined | THighlightDTO[]>(undefined);
  const [cachedImages, setCachedImages] =
    useState<undefined | TImageDTO[]>(undefined);
  const [cachedFiles, setCachedFiles] =
    useState<undefined | TSavedFileDTO[]>(undefined);
  const [currentlyVisibleType, setCurrentlyVisibleType] =
    useState<ObjectTypeEnum>(ObjectTypeEnum.Unknown);
  const [currentlyVisibleLinks, setCurrentlyVisibleLinks] = useState<
    | TLinkedToDTO[]
    | ISavedDocumentDTO[]
    | THighlightDTO[]
    | TImageDTO[]
    | TSavedFileDTO[]
  >([]);

  // Logic
  // Invalidate caches if count or objectId change
  useEffect(() => {
    setCachedStudies(undefined);
    setCachedEntities(undefined);
    setCachedDocuments(undefined);
    setCachedHighlights(undefined);
    setCachedFiles(undefined);
    setCurrentlyVisibleLinks([]);
    setCurrentlyVisibleType(ObjectTypeEnum.Unknown);
  }, [linkedCounts, objectId]);

  const debouncedOnStudyCountMouseEnterAsync = debounce(
    () => onStudyCountMouseEnterAsync(),
    onMouseEnterDelayInMs
  );
  const onStudyCountMouseEnterAsync = useCallback(async () => {
    // safety-checks
    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
      // stop execution, return
      return;
    }

    setCurrentlyVisibleType(ObjectTypeEnum.Study);
    setIsHoverPopupShown(true);
    if (cachedStudies) {
      setCurrentlyVisibleLinks(cachedStudies);
    } else {
      const linkedStudies = await LinkingControllerSingleton.getLinksAsync(
        objectId,
        [ObjectTypeEnum.Study]
      );
      setCachedStudies(linkedStudies);
      setCurrentlyVisibleLinks(linkedStudies ?? []);
    }

    // log
    LogHelperSingleton.log("DisplayStudiesLinkToDocument");
  }, [auth, cachedStudies, objectId]);

  const debouncedOnEntityCountMouseEnterAsync = debounce(
    () => onEntityCountMouseEnterAsync(),
    onMouseEnterDelayInMs
  );
  const onEntityCountMouseEnterAsync = useCallback(async () => {
    // safety-checks
    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
      // stop execution, return
      return;
    }

    setCurrentlyVisibleType(ObjectTypeEnum.Entity);
    setIsHoverPopupShown(true);
    if (cachedEntities) {
      setCurrentlyVisibleLinks(cachedEntities);
    } else {
      const linkedEntities = await LinkingControllerSingleton.getLinksAsync(
        objectId,
        [ObjectTypeEnum.Entity]
      );
      setCachedEntities(linkedEntities);
      setCurrentlyVisibleLinks(linkedEntities ?? []);
    }

    // log
    LogHelperSingleton.log("DisplayEntitiesLinkToDocument");
  }, [auth, cachedEntities, objectId]);

  const debouncedOnDocumentCountMouseEnterAsync = debounce(
    () => onDocumentCountMouseEnterAsync(),
    onMouseEnterDelayInMs
  );
  const onDocumentCountMouseEnterAsync = useCallback(async () => {
    // safety-checks
    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
      // stop execution, return
      return;
    }

    setCurrentlyVisibleType(ObjectTypeEnum.ScienceArticle);
    setIsHoverPopupShown(true);
    if (cachedDocuments) {
      setCurrentlyVisibleLinks(cachedDocuments);
    } else {
      const linkedDocuments =
        await SavedDocumentControllerSingleton.getLinkedToObject(objectId);
      setCachedDocuments(linkedDocuments);
      setCurrentlyVisibleLinks(linkedDocuments ?? []);
    }

    // log
    LogHelperSingleton.log("DisplayDocumentsLinkToDocument");
  }, [auth, cachedDocuments, objectId]);

  const debouncedOnHighlightCountMouseEnterAsync = debounce(
    () => onHighlightCountMouseEnterAsync(),
    onMouseEnterDelayInMs
  );
  const onHighlightCountMouseEnterAsync = async () => {
    setCurrentlyVisibleType(ObjectTypeEnum.Highlight);
    setIsHoverPopupShown(true);
    if (cachedHighlights) {
      setCurrentlyVisibleLinks(cachedHighlights);
    } else {
      const linkedHighlights =
        await HighlightControllerSingleton.getLinkedToObject(objectId);
      setCachedHighlights(linkedHighlights);
      setCurrentlyVisibleLinks(linkedHighlights ?? []);
    }

    // log
    LogHelperSingleton.log("DisplayHighlightsLinkToDocument");
  };

  const debouncedOnImageCountMouseEnterAsync = debounce(
    () => onImageCountMouseEnterAsync(),
    onMouseEnterDelayInMs
  );
  const onImageCountMouseEnterAsync = async () => {
    setCurrentlyVisibleType(ObjectTypeEnum.Image);
    setIsHoverPopupShown(true);
    if (cachedImages) {
      setCurrentlyVisibleLinks(cachedImages);
    } else {
      const linkedImages = await ImageControllerSingleton.getLinkedToObject(
        objectId
      );
      setCachedImages(linkedImages);
      setCurrentlyVisibleLinks(linkedImages ?? []);
    }

    // log
    LogHelperSingleton.log("DisplayImagesLinkToDocument");
  };

  const debouncedOnFileCountMouseEnterAsync = debounce(
    () => onFileCountMouseEnterAsync(),
    onMouseEnterDelayInMs
  );
  const onFileCountMouseEnterAsync = async () => {
    setCurrentlyVisibleType(ObjectTypeEnum.File);
    setIsHoverPopupShown(true);
    if (cachedFiles) {
      setCurrentlyVisibleLinks(cachedFiles);
    } else {
      const linkedFiles = await SavedFileControllerSingleton.getLinkedToObject(
        objectId
      );
      setCachedFiles(linkedFiles);
      setCurrentlyVisibleLinks(linkedFiles ?? []);
    }

    // log
    LogHelperSingleton.log("DisplayFilesLinkToDocument");
  };

  const hidePopup = () => {
    debouncedOnStudyCountMouseEnterAsync.cancel();
    debouncedOnEntityCountMouseEnterAsync.cancel();
    debouncedOnDocumentCountMouseEnterAsync.cancel();
    debouncedOnHighlightCountMouseEnterAsync.cancel();
    debouncedOnImageCountMouseEnterAsync.cancel();
    debouncedOnFileCountMouseEnterAsync.cancel();
    setIsHoverPopupShown(false);
    setCurrentlyVisibleType(ObjectTypeEnum.Unknown);
    setCurrentlyVisibleLinks([]);
  };

  const onEditHighlight = (editedHighlight: THighlightDTO) => {
    if (currentlyVisibleType === ObjectTypeEnum.Highlight) {
      // update highlights
      const newHighlights: THighlightDTO[] = [];
      for (const currentlyVisibleLink of currentlyVisibleLinks) {
        const highlight = currentlyVisibleLink as THighlightDTO;
        if (highlight.id === editedHighlight.id) {
          newHighlights.push(editedHighlight);
        } else {
          newHighlights.push(highlight);
        }
      }

      setCurrentlyVisibleLinks([...newHighlights]);
      setCachedHighlights([...newHighlights]);
    }
  };

  return (
    <div onMouseLeave={hidePopup}>
      <div className={styles.countContainer}>
        {linkedCounts.studyCount > 0 ? (
          <div
            className={`${styles.count} ${styles.study}`}
            onMouseEnter={debouncedOnStudyCountMouseEnterAsync}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faBookOpenReader} />
            </div>
            <h4>{linkedCounts.studyCount}</h4>
          </div>
        ) : null}
        {linkedCounts.entityCount > 0 ? (
          <div
            className={`${styles.count} ${styles.entity}`}
            onMouseEnter={debouncedOnEntityCountMouseEnterAsync}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faDiceD6} />
            </div>
            <h4>{linkedCounts.entityCount}</h4>
          </div>
        ) : null}
        {linkedCounts.documentCount > 0 ? (
          <div
            className={`${styles.count} ${styles.document}`}
            onMouseEnter={debouncedOnDocumentCountMouseEnterAsync}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faFile} />
            </div>
            <h4>{linkedCounts.documentCount}</h4>
          </div>
        ) : null}
        {linkedCounts.highlightCount > 0 ? (
          <div
            className={`${styles.count} ${styles.highlight}`}
            onMouseEnter={debouncedOnHighlightCountMouseEnterAsync}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faHighlighter} />
            </div>
            <h4>{linkedCounts.highlightCount}</h4>
          </div>
        ) : null}
        {linkedCounts.imageCount > 0 ? (
          <div
            className={`${styles.count} ${styles.image}`}
            onMouseEnter={debouncedOnImageCountMouseEnterAsync}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faImage} />
            </div>
            <h4>{linkedCounts.imageCount}</h4>
          </div>
        ) : null}
        {linkedCounts.fileCount > 0 ? (
          <div
            className={`${styles.count} ${styles.attachment}`}
            onMouseEnter={debouncedOnFileCountMouseEnterAsync}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faPaperclip} />
            </div>
            <h4>{linkedCounts.fileCount}</h4>
          </div>
        ) : null}
      </div>
      {isHoverPopupShown && currentlyVisibleLinks.length > 0 ? (
        <PositionedPopup extraClassName={styles.countPopupContainer}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {currentlyVisibleType === ObjectTypeEnum.Study ? (
              <p className={styles.countPopupTitle}>Linked studies</p>
            ) : null}
            {currentlyVisibleType === ObjectTypeEnum.Entity ? (
              <p className={styles.countPopupTitle}>Linked entities</p>
            ) : null}
            {currentlyVisibleType === ObjectTypeEnum.ScienceArticle ? (
              <p className={styles.countPopupTitle}>Linked documents</p>
            ) : null}
            {currentlyVisibleType === ObjectTypeEnum.Highlight ? (
              <p className={styles.countPopupTitle}>Linked highlights</p>
            ) : null}
            {currentlyVisibleType === ObjectTypeEnum.Image ? (
              <p className={styles.countPopupTitle}>Linked images</p>
            ) : null}
            {currentlyVisibleType === ObjectTypeEnum.File ? (
              <p className={styles.countPopupTitle}>Attachments</p>
            ) : null}
            {currentlyVisibleType === ObjectTypeEnum.Study ||
            currentlyVisibleType === ObjectTypeEnum.Entity
              ? currentlyVisibleLinks.map((link) => {
                  const typedLink = link as TLinkedToDTO;
                  // TODO: find a more sustainable way to do this
                  if (
                    typedLink.linkedCounts === undefined ||
                    typedLink.objectType === undefined
                  ) {
                    return null;
                  }

                  // defined top text based on type
                  const topText =
                    EntityTypeHelperSingleton.entityTypeStringToEnum(
                      typedLink.type
                    ) === EntityTypeEnum.Custom && typedLink.customTypeName
                      ? typedLink.customTypeName
                      : typedLink.type;

                  const listItem: TListItem = {
                    id: typedLink.id,
                    title: typedLink.name,
                    objectType: typedLink.objectType,
                    description: typedLink.description,
                    conclusion: typedLink.conclusion,
                    linkedCounts: typedLink.linkedCounts,
                    dateAdded: typedLink.dateAdded,
                    topText: topText,
                    images: typedLink.images,
                    createdByUsername: typedLink.createdByUsername
                      ? typedLink.createdByUsername
                      : "",
                  };
                  return (
                    <ListItem<TLinkedToDTO>
                      key={typedLink.id}
                      itemObject={typedLink}
                      listItem={listItem}
                    />
                  );
                })
              : null}
            {currentlyVisibleType === ObjectTypeEnum.ScienceArticle
              ? currentlyVisibleLinks.map((document) => {
                  const typedDocument = document as ISavedDocumentDTO;
                  if (typedDocument.dateAdded === undefined) return null;
                  return (
                    <SavedDocumentItem
                      key={typedDocument.id}
                      savedDocument={typedDocument}
                      isSelected={false}
                    />
                  );
                })
              : null}
            {currentlyVisibleType === ObjectTypeEnum.Highlight
              ? currentlyVisibleLinks.map((highlight) => {
                  const typedHighlight = highlight as THighlightDTO;
                  // TODO: fix this in a better way
                  if (typedHighlight.comments === undefined) return null;
                  return (
                    <Highlight
                      key={typedHighlight.id}
                      highlight={typedHighlight}
                      extraClassNames={{
                        highlightContainer: styles.highlightContainer,
                      }}
                      doShowType={true}
                      onEditHighlight={
                        isUserExternal ? undefined : onEditHighlight
                      }
                    />
                  );
                })
              : null}
            {currentlyVisibleType === ObjectTypeEnum.Image
              ? currentlyVisibleLinks.map((image) => {
                  const typedImage = image as TImageDTO;
                  if (typedImage.path === undefined) return null;
                  return (
                    <div
                      key={`imageContainer_${image.id}`}
                      className={styles.imageContainer}
                    >
                      <div className={styles.entityLikeImageContainer}>
                        <div className={styles.entityLikeImageAspectRatioBox}>
                          <div
                            className={
                              styles.entityLikeImageAspectRatioBoxContent
                            }
                          >
                            <Image key={typedImage.id} image={typedImage} />
                          </div>
                        </div>
                      </div>
                      <h4 className={styles.entityLikeImageCaption}>
                        {typedImage.caption}
                      </h4>
                    </div>
                  );
                })
              : null}
            {currentlyVisibleType === ObjectTypeEnum.File
              ? currentlyVisibleLinks.map((file) => {
                  const typedFile = file as TSavedFileDTO;
                  if (typedFile.fileExtension === undefined) return null;
                  return (
                    <AttachmentItem savedFile={typedFile} key={typedFile.id} />
                  );
                })
              : null}
          </div>
        </PositionedPopup>
      ) : null}
    </div>
  );
};
