// node_modules
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { Link } from "react-router-dom";
// Styles
import styles from "./listItemLink.module.scss";
// Components
import { ListObjectItem } from "./ListObjectItem";
// Types
import { TIdNameTypeObjectType } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";

type TListItemLinkProps = {
  onClick?: () => void;
  navigateTo: string;
  iconHasColor: boolean;
  object: TIdNameTypeObjectType;
  subItemType?: string;
  icon?: IconDefinition;
  extraClassNames?: {
    listItem?: string;
    listItemContent?: string;
    listItemIconContainer?: string;
    itemText?: string;
    moreActionsButton?: string;
    rightContent?: string;
  };
  openReferenceModal?: (objectId: string, objectType: ObjectTypeEnum) => void;
  navigateCallback?: () => void;
  moreActionsDropdownPopoverDataIdentifier?: string;
  onMoreActionsOptionClick?: () => void;
  moreActionsDropdownOnCloseCallback?: () => void;
  theme?: string[];
  children?: JSX.Element | JSX.Element[];
};

export const ListItemLink: FC<TListItemLinkProps> = ({
  onClick,
  navigateTo,
  iconHasColor,
  object,
  subItemType,
  icon,
  extraClassNames = {},
  openReferenceModal,
  navigateCallback,
  moreActionsDropdownPopoverDataIdentifier,
  onMoreActionsOptionClick,
  moreActionsDropdownOnCloseCallback,
  theme,
  children,
}: TListItemLinkProps) => {
  const SelectedLinkTag = navigateTo.startsWith("https://") ? "a" : Link;

  // Render
  return (
    <SelectedLinkTag
      onClick={onClick}
      className={`${styles.listItem} ${extraClassNames.listItem || ""}`}
      to={navigateTo}
      {...(SelectedLinkTag === "a"
        ? {
            rel: "noopener noreferrer",
            target: "_blank",
            href: navigateTo,
          }
        : {})}
    >
      <ListObjectItem
        iconHasColor={iconHasColor}
        object={object}
        subItemType={subItemType}
        icon={icon}
        openReferenceModal={openReferenceModal}
        moreActionsDropdownPopoverDataIdentifier={
          moreActionsDropdownPopoverDataIdentifier
        }
        moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
        navigateCallback={navigateCallback}
        onMoreActionsOptionClick={onMoreActionsOptionClick}
        extraClassNames={{
          listItemContent: extraClassNames.listItemContent,
          listItemIconContainer: extraClassNames.listItemIconContainer,
          moreActionsButton: extraClassNames.moreActionsButton,
          itemText: extraClassNames.itemText,
          rightContent: extraClassNames.rightContent,
        }}
        theme={theme}
      >
        {children}
      </ListObjectItem>
    </SelectedLinkTag>
  );
};
