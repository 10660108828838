// node_modules
import { FC, useContext, useEffect, useState } from "react";
// Components
import {
  EntityDetails,
  FindestButton,
  Modal,
  NavigateToButton,
  ObjectTopbar,
  RightSidebar,
  StudyDetails,
} from "Components";
// Enums
import { ObjectTypeEnum } from "Enums";
// Contexts
import {
  AuthContext,
  ClaimEditOnObjectProvider,
  EditorProvider,
  ElementVisibilityProvider,
  LinksProvider,
} from "Providers";
// Types
import { TButtonDefinition, TReferenceModalProps } from "Types";
// Styles
import referenceModalStyles from "./referenceModal.module.scss";

type TReferenceModalComponentProps = TReferenceModalProps & {
  hideReferenceModal: (callback?: () => void) => void;
  buttonDefintion?: TButtonDefinition;
};

export const ReferenceModal: FC<TReferenceModalComponentProps> = ({
  isOpen,
  id,
  type,
  doIgnoreIsDeleted,
  buttonDefintion,
  hideReferenceModal,
}: TReferenceModalComponentProps) => {
  // Contexts
  const { isUserExternal } = useContext(AuthContext);

  // State
  const [isNavigateToButtonShown, setIsNavigateToButtonShown] =
    useState<boolean>(false);
  useEffect(() => {
    // Set isNavigateToButtonShown on true is there is no buttonDefintion or the buttonDefintion title is not Restore
    if (!buttonDefintion || buttonDefintion.title !== "Restore") {
      setIsNavigateToButtonShown(true);
    }
  }, [buttonDefintion]);

  const shouldShowObjectTopbar =
    !isUserExternal &&
    !buttonDefintion &&
    id &&
    type &&
    [ObjectTypeEnum.Entity, ObjectTypeEnum.Study].indexOf(type) !== -1;

  // Render
  return (
    <Modal
      extraClassNames={{
        overlay: referenceModalStyles.referenceModalOverlay,
        container: referenceModalStyles.referenceModal,
        header: referenceModalStyles.header,
      }}
      stylingProps={{ doesContainerHaveInset: true }}
      isOpen={isOpen}
      onClose={() => {
        hideReferenceModal();
      }}
      id={id}
    >
      <ClaimEditOnObjectProvider>
        <EditorProvider>
          <LinksProvider>
            <ElementVisibilityProvider isInReferenceModal>
              {id && type && isNavigateToButtonShown && (
                <NavigateToButton
                  id={id}
                  type={type}
                  closeOnClick={hideReferenceModal}
                  extraClassName={referenceModalStyles.navigateToButton}
                />
              )}
              <div
                className={`${
                  referenceModalStyles.referenceModalMainContentContainer
                } ${isUserExternal ? referenceModalStyles.fullWidth : ""}`}
              >
                {shouldShowObjectTopbar && (
                  <ObjectTopbar
                    extraClassNames={{
                      container: `${referenceModalStyles.objectTopbarContainer}`,
                    }}
                    hideReferenceModal={hideReferenceModal}
                  />
                )}
                {buttonDefintion && (
                  <div className={referenceModalStyles.restoreContainer}>
                    <FindestButton
                      title={buttonDefintion.title}
                      leftIconName={buttonDefintion.icon}
                      onClick={buttonDefintion.onClick}
                      extraClassName={`${buttonDefintion.className} ${referenceModalStyles.restoreButton}`}
                    />
                  </div>
                )}
                <div
                  className={`${
                    referenceModalStyles.referenceModalMainContent
                  } ${
                    isUserExternal && !buttonDefintion
                      ? referenceModalStyles.fullHeight
                      : undefined
                  }`}
                >
                  {type === ObjectTypeEnum.Entity && (
                    <EntityDetails
                      id={id}
                      doIgnoreIsDeleted={doIgnoreIsDeleted}
                    />
                  )}
                  {type === ObjectTypeEnum.Study && (
                    <StudyDetails
                      id={id}
                      doIgnoreIsDeleted={doIgnoreIsDeleted}
                    />
                  )}
                  {!buttonDefintion && <RightSidebar isInReferenceModal />}
                </div>
              </div>
            </ElementVisibilityProvider>
          </LinksProvider>
        </EditorProvider>
      </ClaimEditOnObjectProvider>
    </Modal>
  );
};
