// node_modules
import { faLink, faTrashCan, faUnlink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, useEditor } from "@tiptap/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Styles
import entityLikeItemStyle from "Styles/EntityLikeList/entityLikeItem.module.scss";
// Components
import {
  Checkbox,
  CreatedByAccount,
  EditorContent,
  LinkedCounts,
  MarkdownItComponent,
  MoreActionsDropdownButton,
} from "Components";
// Helpers
import {
  DateHelperSingleton,
  ExtensionsKit,
  GetCustomLink,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  SET_CONTENT_COMMAND,
} from "Helpers";
// Hooks
import { useLoadURL } from "Hooks";
// Types
import { TListItem } from "Types";

type TListItemProps<T> = {
  itemObject: T;
  listItem: TListItem;
  isSelected?: boolean;
  onCheckboxChange?: (isChecked: boolean, title: string) => void;
  onDeleteItemClick?: (objectToDelete: T) => void;
  onLinkItemClick?: (objectToLink: T) => void;
  onUnlinkItemClick?: (objectToLink: T) => void;
  onItemClickOverride?: (objectToLink: T) => void;
  hideMoreActionsDropdownButton?: boolean;
};

export function ListItem<T>({
  itemObject,
  listItem,
  isSelected,
  onCheckboxChange,
  onDeleteItemClick,
  onLinkItemClick,
  onUnlinkItemClick,
  onItemClickOverride,
  hideMoreActionsDropdownButton,
}: TListItemProps<T>) {
  const { loadImagesAsync, loadFilesAsync, loadRatingsAsync } = useLoadURL();
  const navigate = useNavigate();
  const listItemEditor = useEditor(
    {
      extensions: [...ExtensionsKit, GetCustomLink(navigate)],
      editable: false,
    },
    [listItem.description]
  );

  useEffect(() => {
    if (!listItemEditor) {
      return;
    }

    let newContent: Content = "";
    try {
      newContent = listItem.description ? JSON.parse(listItem.description) : "";
    } catch (error) {
      newContent = listItem.description ?? "";
    }

    SET_CONTENT_COMMAND.action(listItemEditor, {
      content: newContent,
    });

    (async () => {
      await loadImagesAsync(listItemEditor);
      await loadFilesAsync(listItemEditor);
      await loadRatingsAsync(listItemEditor, listItem.id, listItem.objectType);
    })();
  }, [
    listItem.description,
    listItem.id,
    listItem.objectType,
    listItemEditor,
    loadFilesAsync,
    loadImagesAsync,
    loadRatingsAsync,
  ]);

  const navigateToItem = () => {
    if (onItemClickOverride) {
      onItemClickOverride(itemObject);
      return;
    }

    // log
    LogHelperSingleton.log("OpenItem");

    // navigate to item
    navigate(
      `/library/${ObjectTypeHelperSingleton.getObjectTypeReactRouteName(
        listItem.objectType
      )}/${listItem.id}`
    );
  };

  return (
    <div
      className={[
        entityLikeItemStyle.entityLikeItemContainer,
        isSelected ? entityLikeItemStyle.selected : "",
      ].join(" ")}
    >
      <div
        onClick={navigateToItem}
        className={`${entityLikeItemStyle.entityLikeItem} ${
          !hideMoreActionsDropdownButton
            ? entityLikeItemStyle.hasMoreActions
            : ""
        }`}
      >
        {isSelected !== undefined && onCheckboxChange ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={entityLikeItemStyle.checkboxContainer}
          >
            <Checkbox
              isChecked={isSelected}
              onCheckboxChange={(isChecked) => {
                onCheckboxChange(isChecked, listItem.id);
              }}
              theme="black"
            />
          </div>
        ) : null}
        <div className={entityLikeItemStyle.entityLikeProperties}>
          <span className={entityLikeItemStyle.entityLikeTypeContainer}>
            <FontAwesomeIcon
              className={`${entityLikeItemStyle.objectIcon} ${
                entityLikeItemStyle[
                  ObjectTypeHelperSingleton.getObjectTypeClassName(
                    listItem.objectType
                  )
                ]
              }`}
              icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                listItem.objectType
              )}
            />
            {listItem.topText ? (
              <p className={entityLikeItemStyle.entityLikeType}>
                {listItem.topText}
              </p>
            ) : null}
          </span>
          <h3 className={entityLikeItemStyle.entityLikeTitle}>
            {listItem.title}
          </h3>
          <div className={entityLikeItemStyle.entityLikeDescription}>
            {listItemEditor ? (
              <EditorContent
                editor={listItemEditor}
                className="compactEditorContent"
              />
            ) : null}
          </div>
          {listItem.conclusion ? (
            <div className={entityLikeItemStyle.conclusion}>
              <p className={entityLikeItemStyle.conclusionTitle}>Conclusion</p>
              <MarkdownItComponent source={listItem.conclusion} />
            </div>
          ) : null}
          <div className={entityLikeItemStyle.entityLikeItemCountsContainer}>
            {listItem.linkedCounts ? (
              <LinkedCounts
                objectId={listItem.id}
                linkedCounts={listItem.linkedCounts}
              />
            ) : null}
          </div>
        </div>
        <div className={entityLikeItemStyle.entityLikeImageContainer}>
          {listItem.images && listItem.images.length > 0 ? (
            <div className={entityLikeItemStyle.entityLikeImageAspectRatioBox}>
              <div
                className={
                  entityLikeItemStyle.entityLikeImageAspectRatioBoxContent
                }
              >
                {
                  <img
                    className={entityLikeItemStyle.entityLikeImage}
                    src={listItem.images[0].path}
                    alt={listItem.images[0].caption}
                  />
                }
              </div>
            </div>
          ) : null}
        </div>
        <div className={entityLikeItemStyle.creationInformation}>
          <div className={entityLikeItemStyle.dateAdded}>
            {DateHelperSingleton.getShortenedDate(listItem.dateAdded)}
          </div>
          {listItem.createdByUsername && (
            <CreatedByAccount
              email={listItem.createdByUsername}
              extraClassNames={{
                createdByAccountContainer:
                  entityLikeItemStyle.createdByAccountContainer,
              }}
            />
          )}
          {!hideMoreActionsDropdownButton && (
            <MoreActionsDropdownButton
              objectType={listItem.objectType}
              objectId={listItem.id}
              extraClassNames={{
                dropdownButton: entityLikeItemStyle.moreActionsDropdownButton,
              }}
            />
          )}
        </div>
      </div>
      <div className={entityLikeItemStyle.entityLikeItemActions}>
        {onLinkItemClick ? (
          <FontAwesomeIcon
            title="Link"
            icon={faLink}
            className={entityLikeItemStyle.entityLikeLinkIcon}
            onClick={() => {
              onLinkItemClick(itemObject);
            }}
          />
        ) : null}
        {onUnlinkItemClick ? (
          <FontAwesomeIcon
            title="Unlink"
            icon={faUnlink}
            className={entityLikeItemStyle.entityLikeLinkIcon}
            onClick={() => {
              onUnlinkItemClick(itemObject);
            }}
          />
        ) : null}
        {onDeleteItemClick ? (
          <FontAwesomeIcon
            title="Delete"
            icon={faTrashCan}
            className={entityLikeItemStyle.entityLikeTrashIcon}
            onClick={() => {
              onDeleteItemClick(itemObject);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
