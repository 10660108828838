// node_modules
import {
  faCancel,
  faCircleInfo,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// Helpers
import {
  AskIgorMenuItemHelperSingleton,
  LogHelperSingleton,
  ScoutingServiceTableHelperSingleton,
  StringHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Components
import {
  FindestButton,
  LayerSelectionDropdown,
  MainTitle,
  MaturityLevelScale,
  Modal,
  NumericStepper,
  OverviewTableOption,
  ScoutingServiceOptionTable,
  TextArea,
} from "Components";
// Providers
import { EditorContext, WebsocketContext } from "Providers";
// Enums
import {
  AskIgorMenuItemEnum,
  ObjectTypeEnum,
  ScoutingServiceTableTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Types
import {
  TInitialScoutingServiceTableProps,
  TLinkGraphDTO,
  TLogEventName,
  TOverallTIAutomationResponseDTO,
  TScoutingServiceTableObject,
  TUpdateAssessmentScoreDTO,
  TWriteSectionDTO,
} from "Types";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Styles
import styles from "./addScoutingServiceItemModal.module.scss";
// Constants
import { AiConstants, WebsocketFunctionNames } from "Constants";

type TAddScoutingServiceItemModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showNumberOfLayers?: boolean;
  showLayerSelectionDropdown?: boolean;
  scoutingServiceItemTitle?: string;
  type: ScoutingServiceTableTypeEnum;
  handleInsertScoutingServiceItem: (
    modalTitle: string,
    selectedLayer: number,
    isNumbered: boolean,
    isRatingEnabled: boolean,
    tableObjectData: (TScoutingServiceTableObject | null)[][],
    headerTitles: string[],
    callback: () => void,
    maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>,
    description?: string
  ) => void;
  handleUpdateScoutingServiceItem: (
    itemId: string,
    modalTitle: string,
    selectedLayer: number,
    isNumbered: boolean,
    isRatingEnabled: boolean,
    tableObjectData: (TScoutingServiceTableObject | null)[][],
    headerTitles: string[],
    callback: () => void,
    maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>,
    description?: string
  ) => void;
  onDeleteScoutingServiceItemClickAsync?: (
    id: string,
    callback: () => void
  ) => Promise<void>;
  newColumnHeaderPlaceholder?: string;
  isEditing?: boolean;
  initialTableProps?: TInitialScoutingServiceTableProps;
  insertButtonTitle: string;
  updateButtonTitle: string;
  optionsTitle: string;
  extraClassNames?: { cellActionsContainer?: string };
};

export function AddScoutingServiceItemModal({
  isOpen,
  setIsOpen,
  showNumberOfLayers,
  type,
  showLayerSelectionDropdown,
  scoutingServiceItemTitle,
  handleInsertScoutingServiceItem,
  handleUpdateScoutingServiceItem,
  onDeleteScoutingServiceItemClickAsync,
  newColumnHeaderPlaceholder,
  isEditing = false,
  initialTableProps,
  insertButtonTitle,
  updateButtonTitle,
  optionsTitle,
  extraClassNames = {},
}: TAddScoutingServiceItemModalProps) {
  // Context
  const { objectEdited } = useContext(EditorContext);
  const { webSocketController } = useContext(WebsocketContext);

  // State
  const [numberOfLayers, setNumberOfLayers] = useState<number>(0);
  const [isNumbered, setIsNumbered] = useState<boolean>(true);
  const [isRatingEnabled, setIsRatingEnabled] = useState<boolean>(true);
  const [headerTitles, setHeaderTitles] = useState<string[]>([]);
  const [shownHeaderTitles, setShownHeaderTitles] = useState<string[]>([]);
  const [tableObjectData, setTableObjectData] = useState<
    (TScoutingServiceTableObject | null)[][]
  >([]);
  const [shownTableObjectData, setShownTableObjectData] = useState<
    (TScoutingServiceTableObject | null)[][]
  >([]);
  const [isRatingEnabledPerLayerIndex, setIsRatingEnabledPerLayerIndex] =
    useState<Map<number, boolean>>(new Map<number, boolean>());
  const [modalTitle, setModalTitle] = useState<string | undefined>(
    scoutingServiceItemTitle
  );
  const [selectedLayer, setSelectedLayer] = useState<number>(1);
  const [requirementSummariesRequested, setRequirementSummariesRequested] =
    useState<Map<string, TWriteSectionDTO>>(
      new Map<string, TWriteSectionDTO>()
    );
  const [description, setDescription] = useState<string>("");
  const [maturityLevelPerEntityId, setMaturityLevelPerEntityId] = useState<
    Map<string, TUpdateAssessmentScoreDTO>
  >(
    initialTableProps && initialTableProps.maturityLevelPerEntityId
      ? initialTableProps.maturityLevelPerEntityId
      : new Map<string, TUpdateAssessmentScoreDTO>()
  );

  // Memo
  const isRequirementsTable = useMemo((): boolean => {
    return type === ScoutingServiceTableTypeEnum.RequirementsTable;
  }, [type]);

  const isMaturityRadar = useMemo((): boolean => {
    return type === ScoutingServiceTableTypeEnum.MaturityRadar;
  }, [type]);

  const isRequestingRequirementSummary = useMemo((): boolean => {
    // if there is at least one requirement summary requested
    return requirementSummariesRequested.size > 0;
  }, [requirementSummariesRequested.size]);

  const isLastColumnEmpty = useMemo(() => {
    // get last column
    const lastColumn = shownHeaderTitles[shownHeaderTitles.length - 1];

    // check if last column is empty
    if (lastColumn) {
      // return false
      return false;
    } else {
      // otherwise return true
      return true;
    }
  }, [shownHeaderTitles]);

  const isAtLeastOneObjectChecked = useMemo((): boolean => {
    // init newIsAtLeastOneObjectChecked
    let newIsAtLeastOneObjectChecked = false;

    // check if at least one object is checked
    // go through each row in table object data
    for (const shownTableObjectDataRow of shownTableObjectData) {
      // go through each cell in row
      for (const shownTableObjectDataCell of shownTableObjectDataRow) {
        // if shown table object data cell is defined and is checked
        if (shownTableObjectDataCell && shownTableObjectDataCell.isChecked) {
          // update newIsAtLeastOneObjectChecked
          newIsAtLeastOneObjectChecked = newIsAtLeastOneObjectChecked || true;
        } else {
          // otherwise, update newIsAtLeastOneObjectChecked
          newIsAtLeastOneObjectChecked = newIsAtLeastOneObjectChecked || false;
        }
      }
    }

    // return newIsAtLeastOneObjectChecked
    return newIsAtLeastOneObjectChecked;
  }, [shownTableObjectData]);

  const areAllRequirementNameHeadersFilled = useMemo((): boolean => {
    // init newAreAllRequirementNameHeadersFilled
    let newAreAllRequirementNameHeadersFilled = true;
    // check if all requirement name headers are filled
    // go through each shown header title
    for (const shownHeaderTitle of shownHeaderTitles) {
      // if shown header title is empty
      if (!shownHeaderTitle) {
        // set newAreAllRequirementNameHeadersFilled to false
        newAreAllRequirementNameHeadersFilled = false;
      }
    }

    // return newAreAllRequirementNameHeadersFilled
    return newAreAllRequirementNameHeadersFilled;
  }, [shownHeaderTitles]);

  const doRequirementNameHeadersHaveDuplicates = useMemo((): boolean => {
    // check if at least one requirement name header has duplicates
    // go through each shown header title
    for (const shownHeaderTitle of shownHeaderTitles) {
      // if shown header title is empty
      if (!shownHeaderTitle) {
        // continue
        continue;
      }

      // get number of occurrences of header title in shown header titles
      const numberOfOccurrences = shownHeaderTitles.filter(
        (headerTitle) => headerTitle === shownHeaderTitle
      ).length;

      // if number of occurrences is greater than 1
      if (numberOfOccurrences > 1) {
        // return true
        return true;
      }
    }

    // return false
    return false;
  }, [shownHeaderTitles]);

  const isOnlyObjectsLayerShown = useMemo((): boolean => {
    // init newIsOnlyObjectsLayerShown
    let newIsOnlyObjectsLayerShown = true;
    // check if only objects layer is shown
    // go through each row in table object data
    for (const shownTableObjectDataRow of shownTableObjectData) {
      // if shown table object data row length is greater than 1
      if (shownTableObjectDataRow.length > 1) {
        // set newIsOnlyObjectsLayerShown to false
        newIsOnlyObjectsLayerShown = false;
      }
    }

    // return newIsOnlyObjectsLayerShown
    return newIsOnlyObjectsLayerShown;
  }, [shownTableObjectData]);

  const isInsertOrUpdateTableButtonDisabled = useMemo(() => {
    // if is requirements table
    if (isRequirementsTable) {
      // disable or not
      return (
        !isAtLeastOneObjectChecked ||
        !areAllRequirementNameHeadersFilled ||
        isOnlyObjectsLayerShown ||
        doRequirementNameHeadersHaveDuplicates ||
        isRequestingRequirementSummary
      );
    } else if (isMaturityRadar) {
      // otherwise if is maturity radar
      // disable or not
      return !isAtLeastOneObjectChecked;
    } else {
      // otherwise, return false
      return false;
    }
  }, [
    isRequirementsTable,
    isMaturityRadar,
    isAtLeastOneObjectChecked,
    areAllRequirementNameHeadersFilled,
    isOnlyObjectsLayerShown,
    doRequirementNameHeadersHaveDuplicates,
    isRequestingRequirementSummary,
  ]);

  const isDeleteButtonDisabled = useMemo((): boolean => {
    // if is requesting requirement summary
    if (isRequestingRequirementSummary) {
      // return true
      return true;
    } else {
      // otherwise, return false
      return false;
    }
  }, [isRequestingRequirementSummary]);

  const isRefreshButtonDisabled = useMemo((): boolean => {
    // if is requirements table
    if (isRequirementsTable) {
      // disable or not
      return (
        !isAtLeastOneObjectChecked ||
        !areAllRequirementNameHeadersFilled ||
        isOnlyObjectsLayerShown ||
        doRequirementNameHeadersHaveDuplicates ||
        isRequestingRequirementSummary
      );
    } else if (isMaturityRadar) {
      // otherwise if is maturity radar
      // disable
      return true;
    } else {
      // otherwise, return false
      return false;
    }
  }, [
    isRequirementsTable,
    isMaturityRadar,
    isAtLeastOneObjectChecked,
    areAllRequirementNameHeadersFilled,
    isOnlyObjectsLayerShown,
    doRequirementNameHeadersHaveDuplicates,
    isRequestingRequirementSummary,
  ]);

  const isRefreshButtonShown = useMemo((): boolean => {
    // if is requesting requirement summary or show number of layers
    if (isRequestingRequirementSummary || showNumberOfLayers) {
      // return false
      return false;
    } else {
      // otherwise, return true
      return true;
    }
  }, [isRequestingRequirementSummary, showNumberOfLayers]);

  const isCancelButtonShown = useMemo((): boolean => {
    // if is requesting requirement summary
    if (isRequestingRequirementSummary) {
      // return true
      return true;
    } else {
      // otherwise, return false
      return false;
    }
  }, [isRequestingRequirementSummary]);

  const isAddColumnButtonDisabled = useMemo((): boolean => {
    // if is last column empty or is requesting requirement summary
    if (isLastColumnEmpty || isRequestingRequirementSummary) {
      // return true
      return true;
    } else {
      // otherwise, return false
      return false;
    }
  }, [isLastColumnEmpty, isRequestingRequirementSummary]);

  // Logic
  // when initial table props maturity level per entity id changes
  useEffect(() => {
    // safety-checks
    if (!initialTableProps || !initialTableProps.maturityLevelPerEntityId) {
      // stop execution, return
      return;
    }

    // set maturity level per entity id
    setMaturityLevelPerEntityId(
      new Map<string, TUpdateAssessmentScoreDTO>(
        initialTableProps.maturityLevelPerEntityId
      )
    );
  }, [initialTableProps]);

  const getNumberOfRequirementValues = useCallback(
    (ofTableObjectData: (TScoutingServiceTableObject | null)[][]) => {
      // if of table object data is empty
      if (ofTableObjectData.length === 0) {
        // return 0
        return 0;
      }

      // get first row of table object data
      const firstRow = ofTableObjectData[0];

      // get number of requirement values
      return firstRow.filter(
        (tableObject) => tableObject !== null && tableObject.isRequirementValue
      ).length;
    },
    []
  );

  // Logic
  useEffect(() => {
    // if is maturity radar
    if (isMaturityRadar) {
      // stop execution, return
      return;
    }

    // when isRatingEnabled and numberOfLayers change, set the isRatingEnabledPerLayerIndex
    const newIsRatingEnabledPerLayerIndex = new Map<number, boolean>();

    // if isRatingEnabled is true
    if (isRatingEnabled) {
      // for each layer
      for (let i = 0; i < numberOfLayers; i++) {
        // if layer is the first layer
        if (i === 0) {
          // set isRatingEnabledPerLayerIndex to false
          newIsRatingEnabledPerLayerIndex.set(
            i,
            isRequirementsTable ? true : false
          );
        } else {
          // otherwise set isRatingEnabledPerLayerIndex to true
          newIsRatingEnabledPerLayerIndex.set(
            i,
            isRequirementsTable ? false : true
          );
        }
      }

      // set the isRatingEnabledPerLayerIndex
      setIsRatingEnabledPerLayerIndex(newIsRatingEnabledPerLayerIndex);
    } else {
      // otherwise if isRatingEnabled is false
      // reset the isRatingEnabledPerLayerIndex
      setIsRatingEnabledPerLayerIndex(newIsRatingEnabledPerLayerIndex);
    }
  }, [isMaturityRadar, isRatingEnabled, isRequirementsTable, numberOfLayers]);

  // update is rating enabled per layer index
  const updateIsRatingEnabledPerLayerIndex = useCallback(
    (layerIndex: number, newIsRatingEnabledForLayerIndex: boolean) => {
      // set the isRatingEnabledPerLayerIndex
      setIsRatingEnabledPerLayerIndex((prevIsRatingEnabledPerLayerIndex) => {
        // create new map from prevIsRatingEnabledPerLayerIndex
        const newIsRatingEnabledPerLayerIndex = new Map<number, boolean>(
          prevIsRatingEnabledPerLayerIndex
        );

        // safety check
        if (!newIsRatingEnabledPerLayerIndex.has(layerIndex)) {
          // stop here, return
          return newIsRatingEnabledPerLayerIndex;
        }

        // set the isRatingEnabledPerLayerIndex
        newIsRatingEnabledPerLayerIndex.set(
          layerIndex,
          newIsRatingEnabledForLayerIndex
        );

        // return the newIsRatingEnabledPerLayerIndex
        return newIsRatingEnabledPerLayerIndex;
      });
    },
    []
  );

  const resetShownTableObjectData = useCallback(
    (
      withSelectedLayer: number,
      withTableObjectData: (TScoutingServiceTableObject | null)[][]
    ): (TScoutingServiceTableObject | null)[][] => {
      // get new shown table object data
      const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
        ScoutingServiceTableHelperSingleton.getShownTableObjectData(
          withSelectedLayer,
          withTableObjectData,
          isRequirementsTable || isMaturityRadar
        );

      // set shown table object data
      setShownTableObjectData([...newShownTableObjectData]);

      // return new shown table object data
      return [...newShownTableObjectData];
    },
    [isMaturityRadar, isRequirementsTable]
  );

  const resetTableObjectData = useCallback(
    (
      withLinkGraphForFocusedNode: TLinkGraphDTO,
      withNumberOfLayers: number
    ): (TScoutingServiceTableObject | null)[][] => {
      // get new table object data
      const newTableObjectData: (TScoutingServiceTableObject | null)[][] =
        ScoutingServiceTableHelperSingleton.getTableObjectData(
          withLinkGraphForFocusedNode,
          withNumberOfLayers,
          isRequirementsTable || isMaturityRadar
        );

      // set table object data
      setTableObjectData(newTableObjectData);

      // return new table object data
      return newTableObjectData;
    },
    [isMaturityRadar, isRequirementsTable]
  );

  const resetShownHeaderTitles = useCallback(
    (
      withSelectedLayer: number,
      withHeaderTitles: string[],
      withShownTableObjectData: (TScoutingServiceTableObject | null)[][]
    ) => {
      // if is requirements table or is maturity radar
      if (isRequirementsTable || isMaturityRadar) {
        // init new shown header titles
        const newShownHeaderTitles: string[] = [];

        // add header title at selected layer
        newShownHeaderTitles.push(withHeaderTitles[withSelectedLayer - 1]);

        // get number of requirement values
        const numberOfRequirementValues: number = getNumberOfRequirementValues(
          withShownTableObjectData
        );

        // if number of requirement values is greater than 0
        if (numberOfRequirementValues > 0) {
          // add all header titles after index of last object data in a row
          newShownHeaderTitles.push(
            ...withHeaderTitles.slice(
              withHeaderTitles.length - numberOfRequirementValues
            )
          );
        }

        // set new shown header titles
        setShownHeaderTitles(newShownHeaderTitles);
      } else {
        // otherwise, set new shown header titles to header titles
        setShownHeaderTitles([...withHeaderTitles]);
      }
    },
    [getNumberOfRequirementValues, isMaturityRadar, isRequirementsTable]
  );

  // handle number of layers changed
  const onNumberOfLayersChangedAsync = useCallback(
    async (newNumberOfLayers: number): Promise<void> => {
      // if number of layers is greater than header titles length or number of layers is lower than header titles length
      if (
        (newNumberOfLayers > headerTitles.length ||
          newNumberOfLayers < headerTitles.length) &&
        objectEdited
      ) {
        // get link graph async for the focused node
        const linkGraphForFocusedNode: TLinkGraphDTO | undefined =
          await LinkingControllerSingleton.getLinkGraphAsync(
            objectEdited.id,
            objectEdited.objectType,
            newNumberOfLayers
          );

        // safety-checks
        if (!linkGraphForFocusedNode) {
          // show error message
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not get scouting service item."
          );
          // stop here, return
          return;
        }

        if (newNumberOfLayers > headerTitles.length) {
          // add new header titles
          const newHeaderTitles = [...headerTitles];
          for (let i = headerTitles.length; i < newNumberOfLayers; i++) {
            newHeaderTitles.push(`Layer ${i + 1}`);
          }
          // set header titles
          setHeaderTitles(newHeaderTitles);

          // reset table object data
          const newTableObjectData: (TScoutingServiceTableObject | null)[][] =
            resetTableObjectData(linkGraphForFocusedNode, newNumberOfLayers);

          // reset shown table object data
          const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
            resetShownTableObjectData(newNumberOfLayers, newTableObjectData);

          // reset shown header titles
          resetShownHeaderTitles(
            newNumberOfLayers,
            newHeaderTitles,
            newShownTableObjectData
          );
        } else if (newNumberOfLayers < headerTitles.length) {
          // remove header titles
          const newHeaderTitles = [...headerTitles];
          for (let i = headerTitles.length; i > newNumberOfLayers; i--) {
            newHeaderTitles.pop();
          }
          // set header titles
          setHeaderTitles(newHeaderTitles);

          // reset table object data
          const newTableObjectData: (TScoutingServiceTableObject | null)[][] =
            resetTableObjectData(linkGraphForFocusedNode, newNumberOfLayers);

          // reset shown table object data
          const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
            resetShownTableObjectData(newNumberOfLayers, newTableObjectData);

          // reset shown header titles
          resetShownHeaderTitles(
            newNumberOfLayers,
            newHeaderTitles,
            newShownTableObjectData
          );
        }
      }
    },
    [
      headerTitles,
      objectEdited,
      resetShownHeaderTitles,
      resetShownTableObjectData,
      resetTableObjectData,
    ]
  );

  const resetModalContentWithProvidedTableProps = useCallback(() => {
    // safety-checks
    if (
      initialTableProps &&
      initialTableProps.shownHeaderTitles &&
      initialTableProps.shownHeaderTitles.length > 0 &&
      initialTableProps.shownContent &&
      initialTableProps.shownContent.length > 0
    ) {
      // set modal state depending on initial values provided by table props
      setIsNumbered(initialTableProps.isNumbered);
      setIsRatingEnabled(initialTableProps.isRatingEnabled);
      setModalTitle(initialTableProps.title);
      setSelectedLayer(initialTableProps.selectedLayerNumber);
      setDescription(initialTableProps.description ?? "");

      // set new header titles
      setHeaderTitles(initialTableProps.headerTitles);

      // set new shown header titles
      setShownHeaderTitles(initialTableProps.shownHeaderTitles);

      // init new table object data
      const newTableObjectData: (TScoutingServiceTableObject | null)[][] = [];
      // go through each row in table object data
      initialTableProps.content.forEach(
        (tableObjectDataRow: (TScoutingServiceTableObject | null)[]) => {
          // push new table object data row
          newTableObjectData.push([...tableObjectDataRow]);
        }
      );
      // set new table object data
      setTableObjectData([...newTableObjectData]);

      // init new shown table object data
      const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
        [];
      // go through each row in table object data
      initialTableProps.shownContent.forEach(
        (tableObjectDataRow: (TScoutingServiceTableObject | null)[]) => {
          // push new table object data row
          newShownTableObjectData.push([...tableObjectDataRow]);
        }
      );
      // set new shown table object data
      setShownTableObjectData([...newShownTableObjectData]);
    }
  }, [initialTableProps]);

  const retrieveLayersDataAsync = useCallback(
    async (
      objectId: string,
      objectType: ObjectTypeEnum,
      layersNumber: number,
      forSelectedLayer: number
    ) => {
      // if initialTableProps defined,
      // content and headerTitles are defined and length greater than 0
      if (
        initialTableProps &&
        initialTableProps.shownContent &&
        initialTableProps.shownContent.length > 0 &&
        initialTableProps.shownHeaderTitles &&
        initialTableProps.shownHeaderTitles.length > 0
      ) {
        // reset modal content with provided table props
        resetModalContentWithProvidedTableProps();
      } else {
        // get link graph async for the focused node
        const linkGraphForFocusedNode: TLinkGraphDTO | undefined =
          await LinkingControllerSingleton.getLinkGraphAsync(
            objectId,
            objectType,
            layersNumber
          );

        // safety-checks
        if (!linkGraphForFocusedNode) {
          // show error message
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not get scouting service item."
          );
          // stop here, return
          return;
        }

        // set number of layers
        const numberOfColumns =
          ScoutingServiceTableHelperSingleton.getNumberOfColumns(
            linkGraphForFocusedNode
          );
        setNumberOfLayers(numberOfColumns);

        // set header titles
        const newHeaderTitles =
          ScoutingServiceTableHelperSingleton.generateHeaderTitlesFromNumberOfColumns(
            numberOfColumns
          );
        setHeaderTitles(newHeaderTitles);

        // reset table object data
        const newTableObjectData: (TScoutingServiceTableObject | null)[][] =
          resetTableObjectData(linkGraphForFocusedNode, numberOfColumns);

        // reset shown table object data
        const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
          resetShownTableObjectData(forSelectedLayer, newTableObjectData);

        // reset shown header titles
        resetShownHeaderTitles(
          forSelectedLayer,
          newHeaderTitles,
          newShownTableObjectData
        );
      }
    },
    [
      resetModalContentWithProvidedTableProps,
      resetShownHeaderTitles,
      resetShownTableObjectData,
      resetTableObjectData,
      initialTableProps,
    ]
  );

  useEffect(() => {
    // safety-checks
    if (!isOpen || !objectEdited) {
      // do nothing, return
      return;
    }

    (async () => {
      // get maximum lower levels
      const maximumLowerLevels: number =
        await LinkingControllerSingleton.getMaximumLowerLevelsAsync(
          objectEdited.id
        );

      // set number of layers
      setNumberOfLayers(maximumLowerLevels);

      // retrieve the layers data
      retrieveLayersDataAsync(
        objectEdited.id,
        objectEdited.objectType,
        maximumLowerLevels,
        1
      );
    })();
  }, [isOpen, objectEdited, retrieveLayersDataAsync]);

  const cancelAllRequirementSummaryRequests = useCallback(() => {
    // cancel all on going requirement summary requests
    requirementSummariesRequested.forEach(
      (_: TWriteSectionDTO, completionId: string) => {
        // invoke cancel requirement summary request
        webSocketController.invokeFunction(
          WebsocketFunctionNames.CancelCompletion,
          completionId,
          true
        );
      }
    );

    // reset requirement summaries requested
    setRequirementSummariesRequested(new Map<string, TWriteSectionDTO>());
  }, [requirementSummariesRequested, webSocketController]);

  const resetModalStateAndCloseAsync = useCallback(async () => {
    // cancel all on going requirement summary requests
    cancelAllRequirementSummaryRequests();

    setIsOpen(false);
    setNumberOfLayers(0);
    setIsNumbered(true);
    setIsRatingEnabled(true);
    setHeaderTitles([]);
    setShownHeaderTitles([]);
    setTableObjectData([]);
    setShownTableObjectData([]);
    setIsRatingEnabledPerLayerIndex(new Map<number, boolean>());
    setModalTitle(scoutingServiceItemTitle);
    setSelectedLayer(1);
    setDescription("");
    setMaturityLevelPerEntityId(new Map<string, TUpdateAssessmentScoreDTO>());
  }, [
    cancelAllRequirementSummaryRequests,
    setIsOpen,
    scoutingServiceItemTitle,
  ]);

  const recursivelyGetAllBottomChildrenCount = useCallback(
    (tableObject: TScoutingServiceTableObject, doSkipIsNotChecked = false) => {
      // if do skip is not checked and table object is not checked, return 0
      if (doSkipIsNotChecked && !tableObject.isChecked) return 0;

      // if table object has no children, return 1
      if (tableObject.children?.length === 0) return 1;

      // init recursive count
      let count = 0;
      // go over each child
      if (tableObject.children) {
        for (const child of tableObject.children) {
          // get recursively all bottom children count
          count += recursivelyGetAllBottomChildrenCount(
            child,
            doSkipIsNotChecked
          );
        }
      }

      // if do skip is not checked and count is 0 and table object has children and is checked, set count to 1
      // because then the current table object is the only checked object in the tree at this point
      if (
        doSkipIsNotChecked &&
        count === 0 &&
        tableObject.children &&
        tableObject.children.length > 0 &&
        tableObject.isChecked
      ) {
        count = 1;
      }

      // return count
      return count;
    },
    []
  );

  const onInsertTable = useCallback((): void => {
    // safety-checks
    if (!modalTitle) {
      // stop execution, return
      return;
    }

    // call handle insert table prop
    handleInsertScoutingServiceItem(
      modalTitle,
      selectedLayer,
      isNumbered,
      isRatingEnabled,
      shownTableObjectData,
      shownHeaderTitles,
      resetModalStateAndCloseAsync,
      maturityLevelPerEntityId,
      description
    );
  }, [
    modalTitle,
    handleInsertScoutingServiceItem,
    selectedLayer,
    isNumbered,
    isRatingEnabled,
    shownTableObjectData,
    shownHeaderTitles,
    resetModalStateAndCloseAsync,
    maturityLevelPerEntityId,
    description,
  ]);

  const cancelRequirementSummaryRequest = useCallback(
    (forRequirementName: string): void => {
      // cancel all on going requirement summary requests
      requirementSummariesRequested.forEach(
        (requirementSummaryRequest: TWriteSectionDTO, completionId: string) => {
          // if forRequirementName is the same as requirementSummaryRequest.text
          if (forRequirementName === requirementSummaryRequest.text) {
            // invoke cancel requirement summary request
            webSocketController.invokeFunction(
              WebsocketFunctionNames.CancelCompletion,
              completionId,
              true
            );
          }
        }
      );
    },
    [requirementSummariesRequested, webSocketController]
  );

  const onUpdateTable = useCallback((): void => {
    // safety-checks
    if (!modalTitle || !initialTableProps || !initialTableProps.id) {
      // stop execution, return
      return;
    }

    // call handle update table prop
    handleUpdateScoutingServiceItem(
      initialTableProps.id,
      modalTitle,
      selectedLayer,
      isNumbered,
      isRatingEnabled,
      shownTableObjectData,
      shownHeaderTitles,
      resetModalStateAndCloseAsync,
      maturityLevelPerEntityId,
      description
    );
  }, [
    modalTitle,
    initialTableProps,
    handleUpdateScoutingServiceItem,
    selectedLayer,
    isNumbered,
    isRatingEnabled,
    shownTableObjectData,
    shownHeaderTitles,
    resetModalStateAndCloseAsync,
    maturityLevelPerEntityId,
    description,
  ]);

  const onDeleteScoutingServiceItemClickAsyncHandler =
    useCallback(async (): Promise<void> => {
      // safety-checks
      if (
        !initialTableProps ||
        !initialTableProps.id ||
        !onDeleteScoutingServiceItemClickAsync
      ) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete scouting service item."
        );
        // stop execution, return
        return;
      }

      // call on delete scouting service item click async prop
      await onDeleteScoutingServiceItemClickAsync(
        initialTableProps.id,
        resetModalStateAndCloseAsync
      );
    }, [
      onDeleteScoutingServiceItemClickAsync,
      resetModalStateAndCloseAsync,
      initialTableProps,
    ]);

  const changeHeaderTitle = useCallback(
    (newValue: string, index: number) => {
      // init new hearder titles
      const newHeaderTitles: string[] = [...headerTitles];
      // init new shown header titles
      const newShownHeaderTitles: string[] = [...shownHeaderTitles];

      // if is requirements table or is maturity radar
      if (isRequirementsTable || isMaturityRadar) {
        // if index is 0 (then it is header title of selected layer)
        if (index === 0) {
          // set new header title at index of selected layer
          newHeaderTitles[selectedLayer - 1] = newValue;
        } else {
          // otherwise, it is requirement name
          // get number of requirement values
          const numberOfRequirementValues: number =
            getNumberOfRequirementValues(shownTableObjectData);

          // set new header title at correct index (length of new header titles minus number of requirement values plus index minus 1)
          newHeaderTitles[
            newHeaderTitles.length - numberOfRequirementValues + index - 1
          ] = newValue;
        }
      } else {
        // set new header title at index in new header titles
        newHeaderTitles[index] = newValue;
      }
      // set new header titles
      setHeaderTitles(newHeaderTitles);

      // set new shown header title at index
      newShownHeaderTitles[index] = newValue;

      // set new shown header titles
      setShownHeaderTitles(newShownHeaderTitles);
    },
    [
      getNumberOfRequirementValues,
      headerTitles,
      isMaturityRadar,
      isRequirementsTable,
      selectedLayer,
      shownHeaderTitles,
      shownTableObjectData,
    ]
  );

  const getRatingStarRating = useCallback(
    (layerIndex: number) => {
      // get is rating enabled for layer index
      const isRatingEnabledForLayerIndex =
        isRatingEnabledPerLayerIndex.get(layerIndex);

      // if is rating enabled for layer index, return 1
      if (isRatingEnabledForLayerIndex) {
        return 1;
      } else {
        // otherwise, return 0
        return 0;
      }
    },
    [isRatingEnabledPerLayerIndex]
  );

  const changeModalTitle = useCallback((newValue: string) => {
    setModalTitle(newValue);
  }, []);

  const onAddColumn = useCallback(() => {
    // don't add a new column if the last column is empty
    // or is maturity radar
    if (isLastColumnEmpty || isMaturityRadar) {
      // stop execution, return
      return;
    }

    // get new table object data
    const newShownTableObjectData = shownTableObjectData.map(
      (tableObjectDataRow: (TScoutingServiceTableObject | null)[]) => {
        // init lastObjectOfRow
        let lastObjectOfRow: TScoutingServiceTableObject | null = null;

        for (let i = 0; i < tableObjectDataRow.length; i++) {
          // get current object
          const currentObject: TScoutingServiceTableObject | null =
            tableObjectDataRow[i];

          // if current object is null then break
          if (currentObject === null) {
            break;
          }

          // otherwise set last object of row to current object
          lastObjectOfRow = currentObject;
        }

        // if last object of row is set and is requirements table
        if (lastObjectOfRow && isRequirementsTable) {
          // init new scouting service table object
          const overviewTableObject: TScoutingServiceTableObject = {
            isRequirementValue: true,
            name: "",
            numbering: "",
            isChecked: lastObjectOfRow.isChecked,
          };

          // add overviewTableObject has child to all children of each object in row
          tableObjectDataRow.forEach(
            (tableObject: TScoutingServiceTableObject | null) => {
              // if table object is defined
              if (tableObject) {
                // if table object has no children, init children array
                tableObject.children = [
                  ...(tableObject.children ?? []),
                  overviewTableObject,
                ];
              }
            }
          );

          // return row plus new scouting service table object
          return [...tableObjectDataRow, overviewTableObject];
        } else {
          // add null to row
          return [...tableObjectDataRow, null];
        }
      }
    );

    // set new shown table object data
    setShownTableObjectData(newShownTableObjectData);

    // set new header titles
    setHeaderTitles((prevHeaderTitles) => [...prevHeaderTitles, ""]);

    // set new shown header titles
    setShownHeaderTitles((prevShownHeaderTitles) => [
      ...prevShownHeaderTitles,
      "",
    ]);
  }, [
    isLastColumnEmpty,
    isMaturityRadar,
    isRequirementsTable,
    shownTableObjectData,
  ]);

  const onSelectedLayerChange = useCallback(
    (layerNumber: number) => {
      // set selected layer
      setSelectedLayer(layerNumber);

      // reset shown table object data
      const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
        resetShownTableObjectData(layerNumber, tableObjectData);

      // get number of requirement values
      const numberOfRequirementValues: number = getNumberOfRequirementValues(
        newShownTableObjectData
      );

      // init new header titles
      const newHeaderTitles: string[] = [...headerTitles];

      // remove all header titles after index of last object data in a row
      newHeaderTitles.splice(
        newHeaderTitles.length - numberOfRequirementValues
      );

      // set new header titles
      setHeaderTitles(newHeaderTitles);

      // reset shown header titles
      resetShownHeaderTitles(
        layerNumber,
        newHeaderTitles,
        newShownTableObjectData
      );
    },
    [
      getNumberOfRequirementValues,
      headerTitles,
      resetShownHeaderTitles,
      resetShownTableObjectData,
      tableObjectData,
    ]
  );

  const requestRequirementSummaryAsync = useCallback(
    async (
      requirementValue: string,
      objectId: string,
      objectType: ObjectTypeEnum
    ): Promise<void> => {
      // get has documents or highlights
      const hasDocumentsOrHighlights =
        await LinkingControllerSingleton.hasLinksOfTypesAsync(objectId, [
          ObjectTypeEnum.Highlight,
          ObjectTypeEnum.ScienceArticle,
          ObjectTypeEnum.UsPatent,
          ObjectTypeEnum.MagPatent,
          ObjectTypeEnum.Weblink,
        ]);

      // if does not have documents or highlights
      if (!hasDocumentsOrHighlights) {
        // go through each shown header title
        for (
          let columnIndex = 0;
          columnIndex < shownHeaderTitles.length;
          columnIndex++
        ) {
          // get shown header title
          const shownHeaderTitle: string = shownHeaderTitles[columnIndex];
          // if shown header title is same as requirement value
          if (shownHeaderTitle === requirementValue) {
            // set new shown table object data
            setShownTableObjectData((prevShownTableObjectData) => {
              // init new shown table object data
              const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
                [...prevShownTableObjectData];

              // go through each row in new shown table object data
              for (
                let rowIndex = 0;
                rowIndex < newShownTableObjectData.length;
                rowIndex++
              ) {
                // get row
                const row: (TScoutingServiceTableObject | null)[] =
                  newShownTableObjectData[rowIndex];

                // safety-checks
                if (!row || row.length === 0) {
                  // stop loop, continue
                  continue;
                }

                // get object data
                const objectData: TScoutingServiceTableObject =
                  row[0] as TScoutingServiceTableObject;

                // get requirement data
                const requirementData: TScoutingServiceTableObject = row[
                  columnIndex
                ] as TScoutingServiceTableObject;

                // safety-checks
                if (
                  !requirementData ||
                  !objectData ||
                  !objectData.objectId ||
                  !requirementData.isRequirementValue
                ) {
                  // stop loop, continue
                  continue;
                }

                // if object data object id is same as object id
                if (objectData.objectId === objectId) {
                  // set requirement data name to "N/A"
                  requirementData.name = "N/A";
                }
              }

              // return new shown table object data
              return newShownTableObjectData;
            });
          }
        }

        // stop execution, return
        return;
      }

      // get related web socket function
      const webSocketFunctionName: string =
        AskIgorMenuItemHelperSingleton.getRelatedWebsocketFunctionName(
          AskIgorMenuItemEnum.Table
        );

      // safety-checks
      if (!webSocketFunctionName) {
        // stop execution, return
        return;
      }

      // get related start log event name
      const startLogEventName: TLogEventName =
        AskIgorMenuItemHelperSingleton.getRelatedStartLogEventName(
          AskIgorMenuItemEnum.RequirementsSummary
        );

      // log
      LogHelperSingleton.log(startLogEventName);

      // invoke function
      await webSocketController.invokeFunction(webSocketFunctionName, {
        objectId,
        objectType,
        text: requirementValue,
        documentTypes: [
          ObjectTypeEnum.ScienceArticle,
          ObjectTypeEnum.UsPatent,
          ObjectTypeEnum.MagPatent,
          ObjectTypeEnum.Weblink,
        ],
      } as TWriteSectionDTO);
    },
    [shownHeaderTitles, webSocketController]
  );

  const getFirstLayerObjects = useCallback(
    (
      fromTableObjectData: (TScoutingServiceTableObject | null)[][],
      isChecked = true
    ): (TScoutingServiceTableObject | null)[] => {
      // init first layer objects
      const firstLayerObjects: (TScoutingServiceTableObject | null)[] = [];

      // go through each row in table object data
      for (const fromTableObjectDataRow of fromTableObjectData) {
        // if fromTableObjectDataRow has at least one object, it is defined and same isChecked value
        if (
          fromTableObjectDataRow.length > 0 &&
          fromTableObjectDataRow[0] &&
          fromTableObjectDataRow[0].isChecked === isChecked
        ) {
          // add it to the first layer objects list
          firstLayerObjects.push(fromTableObjectDataRow[0]);
        }
      }

      // return first layer objects
      return firstLayerObjects;
    },
    []
  );

  const getFirstLayerObjectAtRowIndex = useCallback(
    (
      fromTableObjectData: (TScoutingServiceTableObject | null)[][],
      rowIndex: number,
      isChecked = true
    ): TScoutingServiceTableObject | null => {
      // init first layer object at index
      let firstLayerObjectAtIndex: TScoutingServiceTableObject | null = null;

      // go through each row in table object data
      for (let index = 0; index < fromTableObjectData.length; index++) {
        // if index is same as rowIndex
        if (index === rowIndex) {
          // get row
          const fromTableObjectDataRow: (TScoutingServiceTableObject | null)[] =
            fromTableObjectData[index];
          // if fromTableObjectDataRow has at least one object, it is defined and same isChecked value
          if (
            fromTableObjectDataRow.length > 0 &&
            fromTableObjectDataRow[0] &&
            fromTableObjectDataRow[0].isChecked === isChecked
          ) {
            // set first layer object at index
            firstLayerObjectAtIndex = fromTableObjectDataRow[0];
          }
        }
      }

      // return first layer object at index
      return firstLayerObjectAtIndex;
    },
    []
  );

  const getRequirementNames = useCallback((): string[] => {
    // init requirementNames
    const requirementNames: string[] = [];

    // go through shown header titles (starting after first layer objects to go through requirement names
    for (let index = 1; index < shownHeaderTitles.length; index++) {
      // get requirement name
      const requirementName: string = shownHeaderTitles[index];

      // if requirement name is defined
      if (requirementName) {
        // requirement name to requirementNames
        requirementNames.push(requirementName);
      }
    }

    // return requirementNames
    return requirementNames;
  }, [shownHeaderTitles]);

  const onRefreshClick = useCallback(() => {
    // get first layer objects
    const firstLayerObjects: (TScoutingServiceTableObject | null)[] =
      getFirstLayerObjects(shownTableObjectData);

    // get requirement names
    const requirementNames: string[] = getRequirementNames();

    // safety-checks
    // if firstLayerObjects is empty or all firstLayerObjects entries are null or all firstLayerObjects entries are not checked or requirementNames is empty
    if (
      firstLayerObjects.length === 0 ||
      firstLayerObjects.every(
        (firstLayerObject: TScoutingServiceTableObject | null) =>
          firstLayerObject === null
      ) ||
      firstLayerObjects.every(
        (firstLayerObject: TScoutingServiceTableObject | null) =>
          firstLayerObject && !firstLayerObject.isChecked
      ) ||
      requirementNames.length === 0
    ) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not refresh values of requirements table."
      );

      // stop execution, return
      return;
    }

    // go through each first layer object
    for (const firstLayerObject of firstLayerObjects) {
      // safety-checks
      if (
        !firstLayerObject ||
        !firstLayerObject.objectId ||
        !firstLayerObject.objectType
      ) {
        // stop loop, continue
        continue;
      }

      // go through each requirement name
      for (const requirementName of requirementNames) {
        // request requirement summary async
        requestRequirementSummaryAsync(
          requirementName,
          firstLayerObject.objectId,
          firstLayerObject.objectType
        );
      }
    }
  }, [
    getFirstLayerObjects,
    getRequirementNames,
    requestRequirementSummaryAsync,
    shownTableObjectData,
  ]);

  const onRefreshColumnClick = useCallback(
    (headerTitle: string): void => {
      // get first layer objects
      const firstLayerObjects: (TScoutingServiceTableObject | null)[] =
        getFirstLayerObjects(shownTableObjectData);

      // safety-checks
      // if firstLayerObjects is empty or all firstLayerObjects entries are null or all firstLayerObjects entries are not checked
      if (
        firstLayerObjects.length === 0 ||
        firstLayerObjects.every(
          (firstLayerObject: TScoutingServiceTableObject | null) =>
            firstLayerObject === null
        ) ||
        firstLayerObjects.every(
          (firstLayerObject: TScoutingServiceTableObject | null) =>
            firstLayerObject && !firstLayerObject.isChecked
        )
      ) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not refresh values of the selected requirement."
        );

        // stop execution, return
        return;
      }

      // go through each first layer object
      for (const firstLayerObject of firstLayerObjects) {
        // safety-checks
        if (
          !firstLayerObject ||
          !firstLayerObject.objectId ||
          !firstLayerObject.objectType
        ) {
          // stop loop, continue
          continue;
        }

        // request requirement summary async
        requestRequirementSummaryAsync(
          headerTitle,
          firstLayerObject.objectId,
          firstLayerObject.objectType
        );
      }
    },
    [getFirstLayerObjects, requestRequirementSummaryAsync, shownTableObjectData]
  );

  const onRefreshRowClick = useCallback(
    (objectData: TScoutingServiceTableObject | null): void => {
      // get requirement names
      const requirementNames: string[] = getRequirementNames();

      // if requirementNames is empty
      if (
        requirementNames.length === 0 ||
        !objectData ||
        !objectData.objectId ||
        !objectData.objectType
      ) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not refresh values of the selected object."
        );

        // stop execution, return
        return;
      }

      // go through each requirement name
      for (const requirementName of requirementNames) {
        // request requirement summary async
        requestRequirementSummaryAsync(
          requirementName,
          objectData.objectId,
          objectData.objectType
        );
      }
    },
    [getRequirementNames, requestRequirementSummaryAsync]
  );

  const onRefreshCellClickAsync = useCallback(
    async (headerTitle: string, rowIndex: number): Promise<void> => {
      // get first layer object at index
      const firstLayerObjectAtIndex: TScoutingServiceTableObject | null =
        getFirstLayerObjectAtRowIndex(shownTableObjectData, rowIndex);

      // if firstLayerObjectAtIndex is null, firstLayerObjectAtIndex.objectId is null or firstLayerObjectAtIndex.objectType is null
      if (
        !firstLayerObjectAtIndex ||
        !firstLayerObjectAtIndex.objectId ||
        !firstLayerObjectAtIndex.objectType
      ) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not refresh value of the selected requirement."
        );

        // stop execution, return
        return;
      }

      // request requirement summary async
      await requestRequirementSummaryAsync(
        headerTitle,
        firstLayerObjectAtIndex.objectId,
        firstLayerObjectAtIndex.objectType
      );
    },
    [
      getFirstLayerObjectAtRowIndex,
      requestRequirementSummaryAsync,
      shownTableObjectData,
    ]
  );

  const onDeleteColumnClick = useCallback(
    (headerTitle: string, columnIndex: number): void => {
      // cancel requirement summary request
      cancelRequirementSummaryRequest(headerTitle);

      // set shown header titles
      setShownHeaderTitles((prevShownHeaderTitles) => {
        // init new shown header titles
        const newShownHeaderTitles: string[] = [...prevShownHeaderTitles];

        // remove header title at index
        newShownHeaderTitles.splice(columnIndex, 1);

        // return new shown header titles
        return newShownHeaderTitles;
      });

      // get number of requirement values
      const numberOfRequirementValues: number =
        getNumberOfRequirementValues(shownTableObjectData);

      // set header titles
      setHeaderTitles((prevHeaderTitles) => {
        // init new header titles
        const newHeaderTitles: string[] = [...prevHeaderTitles];

        // remove header title at index
        newHeaderTitles.splice(
          newHeaderTitles.length - numberOfRequirementValues - 1 + columnIndex,
          1
        );

        // return new header titles
        return newHeaderTitles;
      });

      // init new shown table object data
      const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
        [];

      // go trough each row in shown table object data
      shownTableObjectData.forEach(
        (tableObjectDataRow: (TScoutingServiceTableObject | null)[]) => {
          // remove column at index
          tableObjectDataRow.splice(columnIndex, 1);

          // add modified row to new shown table object data
          newShownTableObjectData.push([...tableObjectDataRow]);
        }
      );

      // set new shown table object data
      setShownTableObjectData([...newShownTableObjectData]);
    },
    [
      getNumberOfRequirementValues,
      shownTableObjectData,
      cancelRequirementSummaryRequest,
    ]
  );

  // receive completion id handler
  const onReceiveCompletionId = useCallback(
    (
      completionId: string,
      forObjectData: TWriteSectionDTO | undefined | null
    ) => {
      // safety-checks
      if (!completionId || !forObjectData) {
        // stop execution, return
        return;
      }

      setRequirementSummariesRequested((prevRequirementSummariesRequested) => {
        // add entry in requirement summaries requested map
        prevRequirementSummariesRequested.set(completionId, forObjectData);
        // return prevRequirementSummariesRequested;
        return new Map<string, TWriteSectionDTO>(
          prevRequirementSummariesRequested
        );
      });
    },
    []
  );

  // receive completion result handler
  const onReceiveCompletionResult = useCallback(
    (result: TOverallTIAutomationResponseDTO | string | null | undefined) => {
      // if requirements summary requested is null, undefined or empty
      if (
        !requirementSummariesRequested ||
        requirementSummariesRequested.size === 0
      ) {
        // stop execution, return
        return;
      }

      if (typeof result === "string") {
        // otherwise if result is string, show error message
        ToastHelperSingleton.showToast(ToastTypeEnum.Error, result);
        // stop execution, return
        return;
      }

      if (
        result &&
        result.completionId &&
        !requirementSummariesRequested.has(result.completionId)
      ) {
        return;
      }

      if (!result || !result.completionId || !result.Table) {
        // safety-checks
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not generate requirement summary with the provided information, please contact the support."
        );
        // stop execution, return
        return;
      }

      // get requirement summaries requested related entry
      const requirementSummary: TWriteSectionDTO | undefined =
        requirementSummariesRequested.get(result.completionId);

      // safety-checks
      if (!requirementSummary) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not generate requirement summary with the provided information, please contact the support."
        );
        // stop execution, return
        return;
      }

      // go through each shown header title
      for (
        let columnIndex = 0;
        columnIndex < shownHeaderTitles.length;
        columnIndex++
      ) {
        // get shown header title
        const shownHeaderTitle: string = shownHeaderTitles[columnIndex];
        // if shown header title is same as requirement summary text
        if (shownHeaderTitle === requirementSummary.text) {
          // set new shown table object data
          setShownTableObjectData((prevShownTableObjectData) => {
            // init new shown table object data
            const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] =
              [...prevShownTableObjectData];

            // safety-checks
            if (!result.Table) {
              // return newShownTableObjectData;
              return newShownTableObjectData;
            }

            // go through each row in new shown table object data
            for (
              let rowIndex = 0;
              rowIndex < newShownTableObjectData.length;
              rowIndex++
            ) {
              // get row
              const row: (TScoutingServiceTableObject | null)[] =
                newShownTableObjectData[rowIndex];

              // safety-checks
              if (!row || row.length === 0) {
                // stop loop, continue
                continue;
              }

              // get object data
              const objectData: TScoutingServiceTableObject =
                row[0] as TScoutingServiceTableObject;

              // get requirement data
              const requirementData: TScoutingServiceTableObject = row[
                columnIndex
              ] as TScoutingServiceTableObject;

              // safety-checks
              if (
                !requirementData ||
                !objectData ||
                !objectData.objectId ||
                !requirementData.isRequirementValue
              ) {
                // stop loop, continue
                continue;
              }

              // if object data object id is same as requirement summary object id
              if (objectData.objectId === requirementSummary.objectId) {
                // init requirement value in each document
                let requirementValueInEachDocument = "";

                // go through each entry in result.Table
                Object.entries(result.Table).forEach(([key, value]) => {
                  // check if the required text is defined and key is not TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
                  if (
                    value[requirementSummary.text] &&
                    key !==
                      AiConstants.TI_GENERATION_TABLE_FEATURE_COLUMN_SUMMARIES_KEY
                  ) {
                    // remove dot at the end of value at requirement summary text
                    value[requirementSummary.text] =
                      StringHelperSingleton.removeDotAtTheEnd(
                        value[requirementSummary.text]
                      );

                    // add value at requirement summary text to requirement value in each document
                    requirementValueInEachDocument += ` ${
                      value[requirementSummary.text]
                    } [[Ref]](${key})`;

                    // format requirement value in each document
                    requirementValueInEachDocument =
                      StringHelperSingleton.formatWithDot(
                        requirementValueInEachDocument
                      );
                  }
                });

                // set requirement data name
                requirementData.name = requirementValueInEachDocument;

                // trim requirement data name
                requirementData.name = requirementData.name.trim();
                // if requirement data name is not set
                if (!requirementData.name) {
                  // set requirement data name to "N/A"
                  requirementData.name = "N/A";
                }
              }
            }

            // return new shown table object data
            return newShownTableObjectData;
          });
        }
      }

      // remove entry from requirement summaries requested map
      setRequirementSummariesRequested((prevRequirementSummariesRequested) => {
        // safety-checks
        if (!result.completionId) {
          // return prevRequirementSummariesRequested;
          return prevRequirementSummariesRequested;
        }

        // remove entry in requirement summaries requested map
        prevRequirementSummariesRequested.delete(result.completionId);

        // return prevRequirementSummariesRequested;
        return new Map<string, TWriteSectionDTO>(
          prevRequirementSummariesRequested
        );
      });
    },
    [requirementSummariesRequested, shownHeaderTitles]
  );

  // add and remove web socket handlers
  useEffect(() => {
    // add web socket handlers
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionId,
      onReceiveCompletionId
    );
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionResult,
      onReceiveCompletionResult
    );

    // remove web socket handlers
    return () => {
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionId,
        onReceiveCompletionId
      );
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionResult,
        onReceiveCompletionResult
      );
    };
  }, [onReceiveCompletionId, onReceiveCompletionResult, webSocketController]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetModalStateAndCloseAsync}
      isFullscreen={true}
      extraClassNames={{
        container: styles.overviewTableModal,
        header: styles.header,
      }}
    >
      <div className={styles.tableOptionsContainer}>
        <h3>{optionsTitle}</h3>
        {showLayerSelectionDropdown && (
          <div className={styles.firstOption}>
            <p className={styles.optionTitle}>Select layer:</p>
            <LayerSelectionDropdown
              initialSelectedLayer={initialTableProps?.selectedLayerNumber ?? 1}
              allLayersData={tableObjectData}
              numberOfLayers={numberOfLayers}
              onChange={onSelectedLayerChange}
            />
            <div className={styles.information}>
              <FontAwesomeIcon icon={faCircleInfo} />
              <p className={styles.informationText}>
                Be careful, when changing layers the table resets itself.
              </p>
            </div>
          </div>
        )}
        {showNumberOfLayers && (
          <div className={styles.firstOption}>
            <p className={styles.optionTitle}>Number of layers:</p>
            <NumericStepper
              initialValue={numberOfLayers}
              minValue={1}
              maxValue={10}
              onValueChanged={(newValue: number) => {
                setNumberOfLayers(newValue);
                onNumberOfLayersChangedAsync(newValue);
              }}
            />
          </div>
        )}
        <OverviewTableOption
          optionTitle="Numbered list:"
          isOptionOn={isNumbered}
          setIsOptionOn={setIsNumbered}
        />
        {isRequirementsTable && (
          <OverviewTableOption
            optionTitle="Rating enabled:"
            isOptionOn={isRatingEnabled}
            setIsOptionOn={setIsRatingEnabled}
          />
        )}
        <div className={styles.buttonsContainer}>
          {isEditing ? (
            <>
              <FindestButton
                title={updateButtonTitle}
                isDisabled={isInsertOrUpdateTableButtonDisabled}
                leftIconName={faSave}
                onClick={onUpdateTable}
              />
              <FindestButton
                title="Delete"
                isDisabled={isDeleteButtonDisabled}
                leftIconName={faTrash}
                buttonType="secondary"
                onClick={onDeleteScoutingServiceItemClickAsyncHandler}
              />
            </>
          ) : (
            <FindestButton
              title={insertButtonTitle}
              leftIconName={faPlus}
              isDisabled={isInsertOrUpdateTableButtonDisabled}
              onClick={onInsertTable}
            />
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contentHeader}>
          {modalTitle !== undefined && (
            <MainTitle
              title={modalTitle}
              onUpdateTitle={changeModalTitle}
              shouldEditableInputAutoGrow
              isEditable
              extraClassName={styles.modalTitle}
            />
          )}
          {isRequirementsTable && (
            <div className={styles.headerButtons}>
              {!showNumberOfLayers && (
                <FindestButton
                  isDisabled={isAddColumnButtonDisabled}
                  leftIconName={faPlus}
                  title="Add Column"
                  onClick={onAddColumn}
                />
              )}
              {isRefreshButtonShown && (
                <FindestButton
                  isDisabled={isRefreshButtonDisabled}
                  buttonType="secondary"
                  leftIconName={faRotateRight}
                  title="Refresh"
                  onClick={onRefreshClick}
                />
              )}
              {isCancelButtonShown && (
                <FindestButton
                  buttonType="secondary"
                  leftIconName={faCancel}
                  title="Cancel"
                  onClick={cancelAllRequirementSummaryRequests}
                />
              )}
            </div>
          )}
          {isMaturityRadar && (
            <div className={styles.headerRightContent}>
              <MaturityLevelScale
                min={0}
                max={0}
                showStepsTexts
                extraClassNames={{
                  container: styles.maturityScaleContainer,
                  stepsTextsContainer:
                    styles.maturityLevelScaleStepsTextsContainer,
                  actualStep: styles.maturityScaleActualStep,
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.modalBody}>
          <ScoutingServiceOptionTable
            type={type}
            newColumnHeaderPlaceholder={newColumnHeaderPlaceholder}
            headerTitles={shownHeaderTitles}
            changeHeaderTitle={changeHeaderTitle}
            isRatingEnabled={isRatingEnabled}
            isRatingEnabledPerLayerIndex={isRatingEnabledPerLayerIndex}
            updateIsRatingEnabledPerLayerIndex={
              updateIsRatingEnabledPerLayerIndex
            }
            getRatingStarRating={getRatingStarRating}
            tableObjectData={shownTableObjectData}
            setTableObjectData={setShownTableObjectData}
            isNumbered={isNumbered}
            recursivelyGetAllBottomChildrenCount={
              recursivelyGetAllBottomChildrenCount
            }
            refreshColumnHandler={
              isRequirementsTable ? onRefreshColumnClick : undefined
            }
            refreshCellHandler={
              isRequirementsTable ? onRefreshCellClickAsync : undefined
            }
            refreshRowHandler={
              isRequirementsTable ? onRefreshRowClick : undefined
            }
            deleteColumnHandler={
              isRequirementsTable ? onDeleteColumnClick : undefined
            }
            requirementSummariesRequested={requirementSummariesRequested}
            objectIdEdited={objectEdited?.id}
            objectTypeEdited={objectEdited?.objectType}
            extraClassNames={{
              container: styles.scoutingServiceOptionTableContainer,
              cellActionsContainer: extraClassNames?.cellActionsContainer,
            }}
            maturityLevelPerEntityId={maturityLevelPerEntityId}
            setMaturityLevelPerEntityId={setMaturityLevelPerEntityId}
          />
          {isMaturityRadar && (
            <TextArea
              extraClassName={styles.descriptionTextArea}
              value={description}
              onChange={(changeEvent: ChangeEvent<HTMLTextAreaElement>) => {
                setDescription(changeEvent.target.value);
              }}
              placeholder="Add a description that explains the context of the radar"
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
