import axios, { CanceledError } from "axios";
import { ObjectTypeEnum } from "Enums";
import { AxiosHelperSingleton } from "Helpers";
import { TSavedFileDTO } from "Types/DTOs/Files";

export class SavedFileController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/savedfile`;

  public async createSavedFileUsingForm(
    file: File,
    filename: string,
    linkToId: string,
    linkToType: ObjectTypeEnum,
    setProgress: (progress?: number) => void,
    abortSignal: AbortSignal
  ): Promise<TSavedFileDTO | null | undefined> {
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("filename", filename);
    formData.append("linkToId", linkToId);
    formData.append("linkToType", linkToType.toString());

    try {
      setProgress(0);
      const response = await axios.post<TSavedFileDTO>(
        `${this._resourcePath}/uploadformfile`,
        formData,
        {
          onUploadProgress(progressEvent: ProgressEvent) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (progress > 50) {
              setProgress(progress - 1);
            } else {
              setProgress(progress);
            }
          },
          signal: abortSignal,
        }
      );

      setProgress(undefined);
      if (response) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (error) {
      if (error instanceof CanceledError) {
        setProgress(undefined);
        return null;
      }
      setProgress(undefined);
      return undefined;
    }
  }

  public async deleteAsync(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${this._resourcePath}/${id}`);

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async getLinkedToObject(
    objectId: string
  ): Promise<TSavedFileDTO[] | undefined> {
    try {
      const response = await axios.get<TSavedFileDTO[]>(
        `${this._resourcePath}/linkedto/${objectId}`
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async getByIdsAsync(ids: string[]): Promise<TSavedFileDTO[]> {
    try {
      const response = await axios.get<TSavedFileDTO[]>(
        `${this._resourcePath}`,
        {
          params: {
            ids,
          },
        }
      );

      if (response && response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }
}

export const SavedFileControllerSingleton = new SavedFileController();
