// Node Modules
import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useResizeObserver from "@react-hook/resize-observer";
import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// Helpers
import {
  INSERT_RESULTS_OVERVIEW_TABLE_COMMAND,
  LinkGraphHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Hooks
import { useClickOutsideRef, useOverviewTable, useSelectionNode } from "Hooks";
// Components
import {
  Checkbox,
  EditableInput,
  FindestButton,
  Modal,
  NumericStepper,
  OverviewTableOption,
  RatingStar,
} from "Components";
// Providers
import { EditorContext } from "Providers";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Types
import {
  TGetAllRatingsOfObjectDTO,
  TLinkGraphDTO,
  TLinkGraphNodeDTO,
  TOverviewTableObject,
} from "Types";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Constants
import { LinkingConstants } from "Constants";
// Styles
import styles from "./overviewTableModal.module.scss";

export const OverviewTableModal: FC = () => {
  // Contexts
  const {
    objectEdited,
    isOverviewTableModalOpen,
    setIsOverviewTableModalOpen,
    editor,
  } = useContext(EditorContext);

  // States
  const [numberOfLayers, setNumberOfLayers] = useState<number>(
    LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT
  );
  const [isNumbered, setIsNumbered] = useState<boolean>(true);
  const [isRatingEnabled, setIsRatingEnabled] = useState<boolean>(true);
  const [headerTitles, setHeaderTitles] = useState<string[]>([]);
  const [tableObjectData, setTableObjectData] = useState<
    (TOverviewTableObject | null)[][]
  >([]);
  const [selectedRowOrCell, setSelectedRowOrCell] = useState<string>("");
  const [isRatingEnabledPerLayerIndex, setIsRatingEnabledPerLayerIndex] =
    useState<Map<number, boolean>>(new Map<number, boolean>());

  // Ref
  const tableElementRef = useRef<HTMLTableElement>(null);

  const {
    initRatingsFromOverviewTableDataAsync,
    getOverviewTableContent,
    recursivelyGetAllBottomChildrenCount,
  } = useOverviewTable();
  const selectionNodeData = useSelectionNode({ editor });

  // Logic
  useEffect(() => {
    // when isRatingEnabled and numberOfLayers change, set the isRatingEnabledPerLayerIndex
    const newIsRatingEnabledPerLayerIndex = new Map<number, boolean>();

    // if isRatingEnabled is true
    if (isRatingEnabled) {
      // for each layer
      for (let i = 0; i < numberOfLayers; i++) {
        // if layer is the first layer
        if (i === 0) {
          // set isRatingEnabledPerLayerIndex to false
          newIsRatingEnabledPerLayerIndex.set(i, false);
        } else {
          // otherwise set isRatingEnabledPerLayerIndex to true
          newIsRatingEnabledPerLayerIndex.set(i, true);
        }
      }

      // set the isRatingEnabledPerLayerIndex
      setIsRatingEnabledPerLayerIndex(newIsRatingEnabledPerLayerIndex);
    } else {
      // otherwise if isRatingEnabled is false
      // reset the isRatingEnabledPerLayerIndex
      setIsRatingEnabledPerLayerIndex(newIsRatingEnabledPerLayerIndex);
    }
  }, [isRatingEnabled, numberOfLayers]);

  // update is rating enabled per layer index
  const updateIsRatingEnabledPerLayerIndex = useCallback(
    (layerIndex: number, newIsRatingEnabledForLayerIndex: boolean) => {
      // set the isRatingEnabledPerLayerIndex
      setIsRatingEnabledPerLayerIndex((prevIsRatingEnabledPerLayerIndex) => {
        // create new map from prevIsRatingEnabledPerLayerIndex
        const newIsRatingEnabledPerLayerIndex = new Map<number, boolean>(
          prevIsRatingEnabledPerLayerIndex
        );

        // safety check
        if (!newIsRatingEnabledPerLayerIndex.has(layerIndex)) {
          // stop here, return
          return newIsRatingEnabledPerLayerIndex;
        }

        // set the isRatingEnabledPerLayerIndex
        newIsRatingEnabledPerLayerIndex.set(
          layerIndex,
          newIsRatingEnabledForLayerIndex
        );

        // return the newIsRatingEnabledPerLayerIndex
        return newIsRatingEnabledPerLayerIndex;
      });
    },
    []
  );

  const handleTableResize = useCallback(() => {
    // get current visible selection box div
    const selectionBox = document.querySelector(
      "div[id^='selectionBox_'][data-current-selection-box='1']"
    ) as HTMLElement;
    if (selectionBox) {
      const currentTdElement = selectionBox.closest("td");
      const tableElement = currentTdElement?.closest("table");
      const tableElementRect = tableElement?.getBoundingClientRect();
      // resize selection box
      if (currentTdElement && tableElementRect) {
        const currentRect = currentTdElement.getBoundingClientRect();
        selectionBox.style.height = `${Math.round(currentRect.height)}px`;
        const selectionBoxWidth = Math.round(
          tableElementRect.width - (currentRect.left - tableElementRect.left)
        );
        selectionBox.style.width = `${selectionBoxWidth}px`;
      }
    }
  }, []);

  // Hooks
  useClickOutsideRef(tableElementRef, () => {
    setSelectedRowOrCell("");
    resetTableCellSelection();
  });
  useResizeObserver(tableElementRef.current, handleTableResize);

  // Logic
  const getTableObjects = useCallback(
    (
      parentObject: TOverviewTableObject,
      currentData: TOverviewTableObject[][],
      lowerLevelNode: TLinkGraphNodeDTO,
      deeperCount: number
    ) => {
      // If the deeperCount is 0, then the function should not go deeper and return
      if (deeperCount === 0) return currentData;

      // Create a new array to store the results
      let results: TOverviewTableObject[][] = [];

      lowerLevelNode.lowerLevelNodes.forEach(
        (innerLowerLevelNode: TLinkGraphNodeDTO, index: number) => {
          // Create overview table objects for all the current level nodes
          const currentObject: TOverviewTableObject = {
            objectId: innerLowerLevelNode.id,
            objectType: innerLowerLevelNode.objectType,
            name: innerLowerLevelNode.name,
            numbering: `${parentObject.numbering}${(index + 1).toString()}.`,
            isChecked: true,
            parent: parentObject,
            children: [],
          };

          // Add the currentObject to the children of the parentObject
          parentObject.children.push(currentObject);

          // Create the rows for the table for the current top node, by recursively handling
          // all the children
          const newData = currentData.map(
            (tableRow: TOverviewTableObject[]) => {
              return [...tableRow, currentObject];
            }
          );

          // Add the results of the currentObject to the results array
          results = results.concat(
            getTableObjects(
              currentObject,
              newData,
              innerLowerLevelNode,
              deeperCount - 1
            )
          );
        }
      );

      // If the results array is empty, return the currentData passed by the parent
      if (results.length === 0) results = currentData;

      // Return the results
      return results;
    },
    []
  );

  const resetTableObjectData = useCallback(
    (newLinkGraphForFocusedNode: TLinkGraphDTO, newNumberOfLayers: number) => {
      let tableResultsObjectData: (TOverviewTableObject | null)[][] = [];

      // Get total amount of cells
      newLinkGraphForFocusedNode.lowerLevelNodes.forEach(
        (lowerLevelNode: TLinkGraphNodeDTO, index: number) => {
          // Create overview table objects for all the top level nodes
          const currentObject: TOverviewTableObject = {
            objectId: lowerLevelNode.id,
            objectType: lowerLevelNode.objectType,
            name: lowerLevelNode.name,
            numbering: `${(index + 1).toString()}.`,
            isChecked: true,
            children: [],
          };

          // Create the rows for the table for the current top node, by recursively handling
          // all the children
          const tableObjectDataRows = getTableObjects(
            currentObject,
            [[currentObject]],
            lowerLevelNode,
            newNumberOfLayers - 1
          );

          // Add the rows to the tableResultsObjectData
          tableResultsObjectData =
            tableResultsObjectData.concat(tableObjectDataRows);
        }
      );

      // If one of the table rows does not have enough cells, add empty cells
      for (const tableObjectDataRow of tableResultsObjectData) {
        if (tableObjectDataRow.length < newNumberOfLayers) {
          for (let i = tableObjectDataRow.length; i < newNumberOfLayers; i++) {
            tableObjectDataRow.push(null);
          }
        }
      }

      setTableObjectData(tableResultsObjectData);
    },
    [getTableObjects]
  );

  // handle number of layers changed
  const onNumberOfLayersChangedAsync = useCallback(
    async (newNumberOfLayers: number): Promise<void> => {
      // if number of layers is greater than header titles length or number of layers is lower than header titles length
      if (
        (newNumberOfLayers > headerTitles.length ||
          newNumberOfLayers < headerTitles.length) &&
        objectEdited
      ) {
        // get link graph async for the focused node
        const linkGraphForFocusedNode: TLinkGraphDTO | undefined =
          await LinkingControllerSingleton.getLinkGraphAsync(
            objectEdited.id,
            objectEdited.objectType,
            newNumberOfLayers
          );

        // safety-checks
        if (!linkGraphForFocusedNode) {
          // show error message
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not get overview table."
          );
          // stop here, return
          return;
        }

        if (newNumberOfLayers > headerTitles.length) {
          // add new header titles
          const newHeaderTitles = [...headerTitles];
          for (let i = headerTitles.length; i < newNumberOfLayers; i++) {
            newHeaderTitles.push(`Layer ${i + 1}`);
          }
          // set header titles
          setHeaderTitles(newHeaderTitles);

          // reset table object data
          resetTableObjectData(linkGraphForFocusedNode, newNumberOfLayers);
        } else if (newNumberOfLayers < headerTitles.length) {
          // remove header titles
          const newHeaderTitles = [...headerTitles];
          for (let i = headerTitles.length; i > newNumberOfLayers; i--) {
            newHeaderTitles.pop();
          }
          // set header titles
          setHeaderTitles(newHeaderTitles);

          // reset table object data
          resetTableObjectData(linkGraphForFocusedNode, newNumberOfLayers);
        }
      }
    },
    [headerTitles, objectEdited, resetTableObjectData]
  );

  const retrieveOverviewTableDataAsync = useCallback(
    async (objectId: string, objectType: ObjectTypeEnum) => {
      // get link graph async for the focused node
      const linkGraphForFocusedNode: TLinkGraphDTO | undefined =
        await LinkingControllerSingleton.getLinkGraphAsync(
          objectId,
          objectType,
          LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT
        );

      // safety-checks
      if (!linkGraphForFocusedNode) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not get overview table."
        );
        // stop here, return
        return;
      }

      // init number of columns
      let numberOfColumns = 1;

      // for each lower level node
      linkGraphForFocusedNode.lowerLevelNodes.forEach(
        (lowerLevelNode: TLinkGraphNodeDTO) => {
          // get deepest level number from lower level node
          const deepestLevelNumber =
            LinkGraphHelperSingleton.getDeepestLevelNumberFromLinkGraphNode(
              lowerLevelNode,
              1
            );

          // if deepest level number is greater than number of columns
          if (deepestLevelNumber > numberOfColumns) {
            // set number of columns to deepest level number
            numberOfColumns = deepestLevelNumber;
          }
        }
      );

      // set number of layers
      setNumberOfLayers(numberOfColumns);

      // set header t
      const newHeaderTitles = [];
      for (let i = 0; i < numberOfColumns; i++) {
        newHeaderTitles.push(`Layer ${i + 1}`);
      }
      setHeaderTitles(newHeaderTitles);

      // reset table object data
      resetTableObjectData(linkGraphForFocusedNode, numberOfColumns);
    },
    [resetTableObjectData]
  );

  useEffect(() => {
    // safety-checks
    if (!isOverviewTableModalOpen || !objectEdited) {
      // do nothing, return
      return;
    }

    // retrieve the overview table
    retrieveOverviewTableDataAsync(objectEdited.id, objectEdited.objectType);
  }, [isOverviewTableModalOpen, objectEdited, retrieveOverviewTableDataAsync]);

  const resetModalStateAndCloseAsync = useCallback(async () => {
    // call parent callbacks
    setIsOverviewTableModalOpen(false);
    setIsNumbered(true);
    setIsRatingEnabled(true);
    setNumberOfLayers(
      LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT
    );
    setHeaderTitles([]);
    setTableObjectData([]);

    // log
    //LogHelperSingleton.log("StopSharing");
  }, [setIsOverviewTableModalOpen]);

  const renumberTable = useCallback(
    (currentTableObjectData: (TOverviewTableObject | null)[][]) => {
      // Get all table unique objects without a parent
      const tableObjectsWithoutParent: TOverviewTableObject[] = [];
      const foundObjectWithParentIds: Set<string> = new Set<string>();

      let currentOrder = 1;
      for (const tableRow of currentTableObjectData) {
        for (const tableCell of tableRow) {
          // If the tableCell is null, continue
          if (!tableCell) continue;
          if (!tableCell.parent) {
            // Check if the tableCell is already in the tableObjectsWithoutParent array
            if (foundObjectWithParentIds.has(tableCell.objectId)) continue;
            // If this is not the case, add the tableCell to the tableObjectsWithoutParent array
            // and set the numbering
            tableObjectsWithoutParent.push(tableCell);

            // if table cell is not checked, set numbering to empty string
            if (!tableCell.isChecked) {
              tableCell.numbering = "";
            } else {
              // otherwise, set numbering to current order
              tableCell.numbering = `${currentOrder}.`;
              currentOrder++;
            }

            // add the objectId to the foundObjectWithParentIds set
            foundObjectWithParentIds.add(tableCell.objectId);
          }
        }
      }

      // Change the numbering of the children of the tableObjectsWithoutParent
      const changeNumberingOfChildren = (tableObject: TOverviewTableObject) => {
        let currentChildOrder = 1;
        for (const childObject of tableObject.children) {
          // Check if the childObject is checked and if not, set the numbering to empty string
          if (childObject.isChecked === false) {
            childObject.numbering = "";
          } else {
            // Set the numbering of the childObject
            childObject.numbering = `${tableObject.numbering}${currentChildOrder}.`;
            currentChildOrder++;
          }
          changeNumberingOfChildren(childObject);
        }
      };

      // Change the numbering of the children of the tableObjectsWithoutParent
      for (const tableObject of tableObjectsWithoutParent) {
        changeNumberingOfChildren(tableObject);
      }

      return currentTableObjectData;
    },
    []
  );

  const onTableCheckboxChange = useCallback(
    (rowIndex: number, columnIndex: number) => {
      const currentObject = tableObjectData[rowIndex][columnIndex];
      if (!currentObject) return;

      // Set isChecked value of current object and all its children
      const setCheckedValueOfObjectAndChildren = (
        innerCurrentObject: TOverviewTableObject,
        isChecked: boolean
      ) => {
        innerCurrentObject.isChecked = isChecked;

        for (const childObject of innerCurrentObject.children) {
          setCheckedValueOfObjectAndChildren(childObject, isChecked);
        }
      };

      // Set the isChecked value of the currentObject and all its children
      setCheckedValueOfObjectAndChildren(
        currentObject,
        !currentObject.isChecked
      );

      // Check if the currentObject has a parent
      if (currentObject.parent) {
        // Check if any children of the parent are checked
        const isAnyChildChecked = currentObject.parent.children.some(
          (childObject) => childObject.isChecked === true
        );

        // If any of the children are checked, check the parent
        if (isAnyChildChecked) {
          currentObject.parent.isChecked = true;
        }
      }

      let newTableObjectData = [...tableObjectData];
      // Renumber the table using the new order and ignoring unchecked objects
      newTableObjectData = renumberTable(newTableObjectData);
      // Update the tableObjectData state
      setTableObjectData(newTableObjectData);
    },
    [renumberTable, tableObjectData]
  );

  const onInsertTable = useCallback(async () => {
    if (!objectEdited || !editor) return;

    const allRatingsOfObject: TGetAllRatingsOfObjectDTO | undefined =
      await initRatingsFromOverviewTableDataAsync(
        tableObjectData,
        objectEdited,
        isRatingEnabledPerLayerIndex
      );

    if (!allRatingsOfObject) return;

    INSERT_RESULTS_OVERVIEW_TABLE_COMMAND.action(editor, {
      nodePos: selectionNodeData?.fromNodePos,
      content: getOverviewTableContent(
        allRatingsOfObject,
        tableObjectData,
        headerTitles,
        isRatingEnabledPerLayerIndex,
        isNumbered,
        objectEdited
      ),
    });

    LogHelperSingleton.log(
      `${LogFeatureNameEnum.Reporting}-InsertOverviewTable`
    );

    resetModalStateAndCloseAsync();
  }, [
    editor,
    getOverviewTableContent,
    headerTitles,
    initRatingsFromOverviewTableDataAsync,
    isNumbered,
    isRatingEnabledPerLayerIndex,
    objectEdited,
    resetModalStateAndCloseAsync,
    selectionNodeData?.fromNodePos,
    tableObjectData,
  ]);

  const moveRowsAboveRow = useCallback(
    (
      currentTableData: (TOverviewTableObject | null)[][],
      rowIndexToMoveAbove: number,
      rowIndicesToMove: number[]
    ) => {
      for (let i = 0; i < rowIndicesToMove.length; i++) {
        // Get the row to move
        const rowToMove = currentTableData[rowIndicesToMove[i]];
        if (!rowToMove) continue;

        // Remove the row from the tableObjectData
        currentTableData.splice(rowIndicesToMove[i], 1);

        // Add the row to the tableObjectData on right location
        currentTableData.splice(rowIndexToMoveAbove + i, 0, rowToMove);
      }

      return currentTableData;
    },
    []
  );

  const getLastRowIndexOfObject = useCallback(
    (
      tableObject: TOverviewTableObject,
      startRowIndex: number,
      columnIndex: number
    ) => {
      let lastRowIndex = 0;
      // Loop through all the rows below the startRowIndex
      for (let i = startRowIndex; i < tableObjectData.length; i++) {
        const tableObjectInRow = tableObjectData[i][columnIndex];
        // Check if the id of the tableObjectInRow is the same as the id of the current tableObject
        if (
          tableObjectInRow &&
          tableObjectInRow.objectId === tableObject.objectId
        ) {
          lastRowIndex = i;
        } else {
          // If this is not the case it means that this is a different object and the loop can be broken
          break;
        }
      }
      return lastRowIndex;
    },
    [tableObjectData]
  );

  const getFirstRowIndexOfObjectAboveObject = useCallback(
    (
      tableObject: TOverviewTableObject,
      firstDifferentObjectIndex: number,
      columnIndex: number
    ) => {
      let firstRowIndexOfObject = 0;

      // Loop through all the rows above the firstDifferentObjectIndex
      for (let i = firstDifferentObjectIndex; i >= 0; i--) {
        const tableObjectAbove = tableObjectData[i][columnIndex];
        // Check if the id of the tableObjectAbove is the same as the id of the current tableObject
        if (
          tableObjectAbove &&
          tableObjectAbove.objectId === tableObject.objectId
        ) {
          firstRowIndexOfObject = i;
        } else {
          // If this is not the case it means that this is a different object and the loop can be broken
          break;
        }
      }
      return firstRowIndexOfObject;
    },
    [tableObjectData]
  );

  const getFirstObjectWithDifferentIdAboveObject = useCallback(
    (
      tableObject: TOverviewTableObject,
      startRowIndex: number,
      columnIndex: number
    ) => {
      // Declare variables to fill in the loop
      let firstObjectWithDifferentIdAboveCurrentObject: TOverviewTableObject | null =
        null;
      let firstDifferentObjectLastRowIndex = 0;

      // Loop through all the rows above the startRowIndex
      for (let i = startRowIndex - 1; i >= 0; i--) {
        const tableObjectAbove = tableObjectData[i][columnIndex];
        // Check if the id of the tableObjectAbove is the same as the id of the current tableObject
        if (
          tableObjectAbove &&
          tableObjectAbove.objectId !== tableObject.objectId
        ) {
          // If this is not the case it means that this is the first object with a different
          // id above the current object, set the variables and break the loop
          firstObjectWithDifferentIdAboveCurrentObject = tableObjectAbove;
          firstDifferentObjectLastRowIndex = i;
          break;
        }
      }

      return {
        firstObjectWithDifferentIdAboveCurrentObject,
        firstDifferentObjectLastRowIndex,
      };
    },
    [tableObjectData]
  );

  const moveUp = useCallback(
    (rowIndex: number, columnIndex: number) => {
      // Get the currentObject
      const currentObject = tableObjectData[rowIndex][columnIndex];
      if (!currentObject) return;

      let newTableObjectData: (TOverviewTableObject | null)[][] = [
        ...tableObjectData,
      ];

      // Check if the currentObject has a parent
      if (!currentObject.parent) {
        // Check if the currentobject is the first object in the table
        const firstTableObject = tableObjectData[0][0];
        // If the currentObject is the first object in the table, do noting
        if (!firstTableObject || currentObject === firstTableObject) return;
      } else {
        // If the current object has a parent then change the order of the children of the parent

        // Get the position of the current object in it's parent's children array
        const currentObjectIndexInParentChildrenArray =
          currentObject.parent.children.indexOf(currentObject);

        // Check if the currentObject is the first child of the parent
        if (currentObjectIndexInParentChildrenArray === 0) return;

        // Get the object above the currentObject
        const objectAboveCurrentObject =
          currentObject.parent.children[
            currentObjectIndexInParentChildrenArray - 1
          ];

        // Swap the currentObject with the object above the currentObject
        currentObject.parent.children[currentObjectIndexInParentChildrenArray] =
          objectAboveCurrentObject;
        currentObject.parent.children[
          currentObjectIndexInParentChildrenArray - 1
        ] = currentObject;
      }

      // Find the last row index of the currentObject
      const lastRowIndexCurrentObject = getLastRowIndexOfObject(
        currentObject,
        rowIndex,
        columnIndex
      );

      // Find the first object with a different id above the currentObject
      const {
        firstObjectWithDifferentIdAboveCurrentObject,
        firstDifferentObjectLastRowIndex,
      } = getFirstObjectWithDifferentIdAboveObject(
        currentObject,
        rowIndex,
        columnIndex
      );

      // If there is no object with a different id above the currentObject, return
      if (!firstObjectWithDifferentIdAboveCurrentObject) return;

      // Find the first row index of the object above the currentObject
      const firstRowIndexOfObjectAbove = getFirstRowIndexOfObjectAboveObject(
        firstObjectWithDifferentIdAboveCurrentObject,
        firstDifferentObjectLastRowIndex,
        columnIndex
      );

      // Determine the first row index of the current object
      const firstCurrentObjectIndex = firstDifferentObjectLastRowIndex + 1;

      // Create all indices in between and including firstCurrentObjectIndex and firstDifferentObjectLastRowIndex
      const indicesToMoveUp: number[] = [];
      for (
        let i = firstCurrentObjectIndex;
        i <= lastRowIndexCurrentObject;
        i++
      ) {
        indicesToMoveUp.push(i);
      }

      // Move the rows up
      moveRowsAboveRow(
        newTableObjectData,
        firstRowIndexOfObjectAbove,
        indicesToMoveUp
      );
      // Renumber the table using the new order
      newTableObjectData = renumberTable(newTableObjectData);
      // Set the new tableObjectData state
      setTableObjectData(newTableObjectData);
    },
    [
      getFirstObjectWithDifferentIdAboveObject,
      getFirstRowIndexOfObjectAboveObject,
      getLastRowIndexOfObject,
      moveRowsAboveRow,
      renumberTable,
      tableObjectData,
    ]
  );

  // Moving down is the same as moving the object below the current object up
  const moveDown = useCallback(
    (rowIndex: number, columnIndex: number) => {
      // If the rowindex is the last row, return
      if (rowIndex === tableObjectData.length - 1) return;

      // Get the currentObject
      const currentObject = tableObjectData[rowIndex][columnIndex];

      // If currentObject is null, return
      if (!currentObject) return;

      // Get the last index of the currentObject
      const lastCurrentObjectIndex = getLastRowIndexOfObject(
        currentObject,
        rowIndex,
        columnIndex
      );

      // Check if an index below the index of the current index exsists
      if (lastCurrentObjectIndex === tableObjectData.length - 1) return;

      // Move the object below the current object up
      moveUp(lastCurrentObjectIndex + 1, columnIndex);
    },
    [getLastRowIndexOfObject, moveUp, tableObjectData]
  );

  const changeHeaderTitle = useCallback((newValue: string, index: number) => {
    setHeaderTitles((prevHeaderTitles) => {
      prevHeaderTitles[index] = newValue;
      return prevHeaderTitles;
    });
  }, []);

  const resetTableCellSelection = useCallback(() => {
    const visibleSelectionBox = document.querySelector(
      "div[id^='selectionBox_'][data-current-selection-box='1']"
    ) as HTMLElement;
    if (visibleSelectionBox) {
      visibleSelectionBox.dataset.currentSelectionBox = "0";
      visibleSelectionBox.style.display = "none";
    }
  }, []);

  const selectCurrentRowOrCell = useCallback(
    (cellId: string, e: MouseEvent, hasOnlyOneAction: boolean | undefined) => {
      const target = e.target as HTMLElement;
      const currentTdElement =
        target.tagName === "td" ? target : target.closest("td");
      const tableElement = currentTdElement?.closest("table");
      const tableElementRect = tableElement?.getBoundingClientRect();
      const lastChild = currentTdElement?.lastChild as HTMLElement;

      const actionsBar = currentTdElement?.querySelector(
        "div[id^='actionsBar_']"
      ) as HTMLElement;
      if (currentTdElement && tableElementRect && lastChild) {
        const currentRect = currentTdElement.getBoundingClientRect();

        resetTableCellSelection();
        lastChild.dataset.currentSelectionBox = "1";
        lastChild.style.display = "block";
        lastChild.style.height = `${Math.round(currentRect.height)}px`;
        const selectionBoxWidth = Math.round(
          tableElementRect.width - (currentRect.left - tableElementRect.left)
        );
        lastChild.style.width = `${selectionBoxWidth}px`;
        if (actionsBar) {
          const actionsBarWidth = hasOnlyOneAction ? 116 : 238;
          actionsBar.style.left = `${
            selectionBoxWidth / 2 - actionsBarWidth / 2
          }px`;
        }
      }
      setSelectedRowOrCell(cellId);
    },
    [resetTableCellSelection]
  );

  const onCellClick = useCallback(
    (
      e: MouseEvent,
      rowIndex: number,
      columnIndex: number,
      isFirstCellOfParent: boolean | undefined,
      isLastCellOfParent: boolean | undefined
    ) => {
      if (!(isFirstCellOfParent && isLastCellOfParent)) {
        selectCurrentRowOrCell(
          `table-cell-${rowIndex}-${columnIndex}`,
          e,
          isFirstCellOfParent || isLastCellOfParent
        );
      }
    },
    [selectCurrentRowOrCell]
  );

  const getRatingStarRating = useCallback(
    (layerIndex: number) => {
      // get is rating enabled for layer index
      const isRatingEnabledForLayerIndex =
        isRatingEnabledPerLayerIndex.get(layerIndex);

      // if is rating enabled for layer index, return 1
      if (isRatingEnabledForLayerIndex) {
        return 1;
      } else {
        // otherwise, return 0
        return 0;
      }
    },
    [isRatingEnabledPerLayerIndex]
  );

  return (
    <Modal
      isOpen={isOverviewTableModalOpen}
      onClose={resetModalStateAndCloseAsync}
      isFullscreen={true}
      extraClassNames={{
        container: styles.overviewTableModal,
        header: styles.header,
      }}
    >
      <div className={styles.tableOptionsContainer}>
        <h3>Table options</h3>
        <div className={styles.numberOfLayersContainer}>
          <p className={styles.optionTitle}>Number of layers:</p>
          <NumericStepper
            initialValue={numberOfLayers}
            minValue={1}
            maxValue={10}
            onValueChanged={(newValue: number) => {
              setNumberOfLayers(newValue);
              onNumberOfLayersChangedAsync(newValue);
            }}
          />
        </div>
        <OverviewTableOption
          optionTitle="Numbered list:"
          isOptionOn={isNumbered}
          setIsOptionOn={setIsNumbered}
        />
        <OverviewTableOption
          optionTitle="Rating enabled:"
          isOptionOn={isRatingEnabled}
          setIsOptionOn={setIsRatingEnabled}
        />
        <div className={styles.insertButtonContainer}>
          <FindestButton title="Insert Table" onClick={onInsertTable} />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table ref={tableElementRef}>
          <thead>
            <tr>
              {headerTitles.map((headerTitle, index) => {
                return (
                  <th className={styles.tableHeaderCell} key={`${index}`}>
                    <div className={styles.headerContent}>
                      <EditableInput
                        className={styles.columnTitle}
                        value={headerTitle}
                        setValue={(newValue: string) => {
                          changeHeaderTitle(newValue, index);
                        }}
                      />
                      {isRatingEnabled && (
                        <div className={styles.tableHeaderCellRatingContainer}>
                          <Checkbox
                            theme="black"
                            isChecked={
                              isRatingEnabledPerLayerIndex.get(index)
                                ? true
                                : false
                            }
                            onCheckboxChange={(isChecked: boolean) => {
                              updateIsRatingEnabledPerLayerIndex(
                                index,
                                isChecked
                              );
                            }}
                          />
                          <RatingStar rating={getRatingStarRating(index)} />
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableObjectData.map((tableRow, rowIndex) => {
              return (
                <tr key={`table-row-${rowIndex}`}>
                  {tableRow.map((tableCell, columnIndex) => {
                    if (!tableCell) {
                      return (
                        <td key={`table-cell-${rowIndex}-${columnIndex}`}></td>
                      );
                    }

                    // If the id of the current tableCell is the same as the id
                    // of the tableCell above, return null
                    if (rowIndex > 0) {
                      const tableCellAbove =
                        tableObjectData[rowIndex - 1][columnIndex];
                      if (
                        tableCellAbove &&
                        tableCell &&
                        ((tableCellAbove.numbering !== "" &&
                          tableCell.numbering !== "" &&
                          tableCellAbove.numbering === tableCell.numbering) ||
                          (tableCellAbove.numbering === "" &&
                            tableCell.numbering === "" &&
                            tableCellAbove.name === tableCell.name))
                      ) {
                        if (
                          tableCellAbove?.parent?.objectId ===
                          tableCell?.parent?.objectId
                        ) {
                          return null;
                        }
                      }
                    }

                    // Calculate the size of the row span
                    const rowSpan =
                      recursivelyGetAllBottomChildrenCount(tableCell);

                    const tableCellSiblings = tableCell.parent?.children;
                    // Check if the is the first cell of the parent
                    const isFirstCellOfParent =
                      tableCell.numbering === "1." ||
                      (tableCellSiblings &&
                        tableCell.objectId === tableCellSiblings[0].objectId);
                    // Check if the is the last cell of the parent
                    const isLastCellOfParent =
                      rowIndex === tableObjectData.length - 1 ||
                      (tableCellSiblings &&
                        tableCell.objectId ===
                          tableCellSiblings[tableCellSiblings.length - 1]
                            .objectId);
                    return (
                      <td
                        onClick={(e) => {
                          onCellClick(
                            e,
                            rowIndex,
                            columnIndex,
                            isFirstCellOfParent,
                            isLastCellOfParent
                          );
                        }}
                        className={tableCell.isChecked ? "" : styles.disabled}
                        rowSpan={rowSpan === 0 ? 1 : rowSpan}
                        key={`table-cell-${rowIndex}-${columnIndex}`}
                      >
                        <div className={styles.cellContent}>
                          {
                            <Checkbox
                              isChecked={tableCell.isChecked}
                              onCheckboxChange={() => {
                                onTableCheckboxChange(rowIndex, columnIndex);
                              }}
                            />
                          }
                          {isNumbered ? `${tableCell.numbering} ` : ""}
                          <div
                            className={
                              tableCell.objectType === ObjectTypeEnum.Entity
                                ? "entity-reference"
                                : "study-reference"
                            }
                          >
                            {tableCell.name}
                          </div>
                          {isRatingEnabled &&
                            tableCell.objectType === ObjectTypeEnum.Entity && (
                              <RatingStar
                                extraClassNames={{
                                  container: styles.ratingStarContainer,
                                }}
                                rating={getRatingStarRating(columnIndex)}
                              />
                            )}
                        </div>
                        <div
                          id={`actionsBar_${rowIndex}_${columnIndex}`}
                          className={`${styles.actionsBar} ${
                            !(isFirstCellOfParent && isLastCellOfParent) &&
                            selectedRowOrCell ===
                              `table-cell-${rowIndex}-${columnIndex}`
                              ? ""
                              : styles.hidden
                          }`}
                        >
                          {isFirstCellOfParent ? null : (
                            <div
                              className={styles.action}
                              onClick={(e) => {
                                e.stopPropagation();
                                resetTableCellSelection();
                                setSelectedRowOrCell("");
                                moveUp(rowIndex, columnIndex);
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowUp} />
                              <span>Move up</span>
                            </div>
                          )}
                          {isLastCellOfParent ? null : (
                            <div
                              className={styles.action}
                              onClick={(e) => {
                                e.stopPropagation();
                                resetTableCellSelection();
                                setSelectedRowOrCell("");
                                moveDown(rowIndex, columnIndex);
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowDown} />
                              <span>Move down</span>
                            </div>
                          )}
                        </div>
                        <div
                          id={`selectionBox_${rowIndex}_${columnIndex}`}
                          className={styles.selectionBox}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
