// node_modules
import {
  faBookOpenReader,
  faChevronDown,
  faChevronUp,
  faDiceD6,
  faEye,
  faFile,
  faFilter,
  faHighlighter,
  faInbox,
  faLink,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, Editor, JSONContent } from "@tiptap/react";
// Controllers
import {
  EntityControllerSingleton,
  ReferenceControllerSingleton,
  SearchControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
// Enums
import {
  LogFeatureNameEnum,
  ObjectTypeEnum,
  OrderByEnum,
  ReferenceSidebarFilterTypeEnum,
  ReferenceSidebarTabTitleEnum,
  SearchThroughEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import {
  EditorHelperSingleton,
  EntityTypeHelperSingleton,
  INSERT_BLOCKQUOTE_COMMAND,
  INSERT_CONTENT_COMMAND,
  INSERT_IMAGE_COMMAND,
  INSERT_LINK_COMMAND,
  INSERT_OBJECT_REFERENCE_LINK_COMMAND,
  LogHelperSingleton,
  SavedFiltersHelperSingleton,
  StudyTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Contexts
import { AuthContext, EditorContext } from "Providers";
import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Types
import {
  TCommentDTO,
  TDocumentReferencesDTO,
  TIdNameTypeObjectType,
  TOption,
  TOptions,
  TReferenceDTO,
  TTabIcon,
} from "Types";
// Components
import {
  Filters,
  FindestButton,
  FindestTextBox,
  IconnedTabs,
} from "Components";
import { ReferenceSidebarDocumentsTab } from "./ReferenceSidebarDocumentsTab";
import { ReferenceSidebarObjectsTab } from "./ReferenceSidebarObjectsTab";
// Constants
import { EditorConstants, ReferenceConstants } from "Constants";
// Custom hooks
import { useObjectNameChangeListener, useSelectionNode } from "Hooks";
// Styles
import rightSidebarStyles from "../rightSidebar.module.scss";
import styles from "./referenceSidebar.module.scss";

type TReferenceSidebarProps = {
  isInReferenceModal?: boolean;
  onCollapseButtonClick?: () => void;
};

export const ReferenceSidebar: FC<TReferenceSidebarProps> = ({
  isInReferenceModal,
  onCollapseButtonClick,
}: TReferenceSidebarProps) => {
  // Contexts
  const { isEditOn, objectEdited, editor } = useContext(EditorContext);
  const { auth } = useContext(AuthContext);

  // State
  const [isScrollPositionTop, setIsScrollPositionTop] = useState<boolean>(true);
  const [usedReferenceIds, setUsedReferenceIds] = useState<Set<string>>(
    new Set()
  );

  // Ref
  const lastSearchQueryRef = useRef<string | undefined>(undefined);
  const isSearchRunningRef = useRef<boolean>(false);
  const currentSearchQueryTextValueRef = useRef<string>("");

  const selectionNode = useSelectionNode({ editor });

  // Logic
  const onTransactionHandler = useCallback(
    async ({ editor: updatedEditor }: { editor: Editor }) => {
      setUsedReferenceIds(
        EditorHelperSingleton.getReferenceIds(
          updatedEditor.getJSON(),
          new Set()
        )
      );
    },
    []
  );

  useEffect(() => {
    if (!editor) return;

    editor.on("transaction", onTransactionHandler);

    return () => {
      editor.off("transaction", onTransactionHandler);
    };
  }, [editor, onTransactionHandler]);

  const insertDocumentAsReference = useCallback(
    (documentUrl: string, documentId: string, documentType: ObjectTypeEnum) => {
      if (!editor) return;

      INSERT_LINK_COMMAND.action(editor, {
        url: documentUrl,
        objectId: documentId,
        objectType: documentType,
        linkText: "[Ref]",
      });

      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertLink`);
    },
    [editor]
  );

  const insertImageAsReference = useCallback(
    (
      reference: TReferenceDTO,
      documentId: string,
      documentType: ObjectTypeEnum
    ) => {
      if (!editor) return;

      INSERT_IMAGE_COMMAND.action(editor, {
        imageId: reference.id,
        imageUrl: reference.url,
        caption: reference.text,
        documentUrl: reference.referenceUrl ?? "",
        objectId: documentId,
        objectType: documentType,
      });

      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertImage`);
    },
    [editor]
  );

  const insertHighlightAsReference = useCallback(
    (
      reference: TReferenceDTO,
      documentId: string,
      documentType: ObjectTypeEnum
    ) => {
      if (!editor) return;

      let parsedContent: JSONContent =
        EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
      try {
        parsedContent = reference.text
          ? JSON.parse(reference.text)
          : EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
      } catch (error) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not get reference text."
        );

        if (reference.text) {
          parsedContent = EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
          parsedContent.content = [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: reference.text,
                },
              ],
            },
          ];
        } else {
          parsedContent = EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
        }
      }

      INSERT_BLOCKQUOTE_COMMAND.action(editor, {
        highlightId: reference.id,
        highlightText: parsedContent,
        documentUrl: reference.url,
        nodePos: selectionNode?.fromNodePos,
        objectId: documentId,
        objectType: documentType,
      });

      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertHighlight`);
    },
    [editor, selectionNode?.fromNodePos]
  );

  const insertHighlightAsText = useCallback(
    (text: string) => {
      if (!editor) return;

      let parsedContent: Content = "";
      try {
        parsedContent = text ? JSON.parse(text) : "";
      } catch (error) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not get highlight text."
        );
        parsedContent = text ?? "";
      }

      INSERT_CONTENT_COMMAND.action(editor, {
        content: parsedContent,
      });

      INSERT_CONTENT_COMMAND.action(editor, {
        content: " ",
      });

      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertHighlight`);
    },
    [editor]
  );

  const updateCommentsPerReferenceId = useCallback(
    (newDocumentReferences: TDocumentReferencesDTO[]) => {
      // init comments per reference id
      const commentsPerReferenceId = new Map<string, TCommentDTO[]>();

      // for each document references
      newDocumentReferences.forEach((documentReference) => {
        // for each reference
        documentReference.references.forEach((reference) => {
          // init comments for reference
          commentsPerReferenceId.set(reference.id, reference.comments);
        });
      });
    },
    []
  );

  const insertObjectAsReference = useCallback(
    (object: TIdNameTypeObjectType) => {
      if (!editor) return;

      INSERT_OBJECT_REFERENCE_LINK_COMMAND.action(editor, {
        objectId: object.id,
        objectType: object.objectType,
        url: `/library/${
          object.objectType === ObjectTypeEnum.Study ? "studies" : "entities"
        }/${object.id}`,
        objectName: object.name,
      });

      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-InsertObjectReference`
      );
    },
    [editor]
  );

  // Tab/Search/filter/results state and logic
  const referenceSidebarTabs: TTabIcon[] = [
    { title: ReferenceSidebarTabTitleEnum.Inbox, icon: faInbox },
    {
      title: ReferenceSidebarTabTitleEnum.CurrentObjectHighlights,
      icon: faHighlighter,
    },
    { title: ReferenceSidebarTabTitleEnum.Documents, icon: faFile },
    { title: ReferenceSidebarTabTitleEnum.Entities, icon: faDiceD6 },
    { title: ReferenceSidebarTabTitleEnum.Studies, icon: faBookOpenReader },
  ];

  const [selectedTabTitle, setSelectedTabTitle] = useState<string>(
    ReferenceSidebarTabTitleEnum.Inbox
  );
  const [selectedReferencesFilterOptions, setSelectedReferencesFilterOptions] =
    useState<TOption<string>[]>([]);
  const [documentReferences, setDocumentReferences] = useState<
    TDocumentReferencesDTO[]
  >([]);
  const [objectList, setObjectList] = useState<TIdNameTypeObjectType[]>([]);
  // Pub sub hooks
  useObjectNameChangeListener(undefined, setObjectList);

  const getSelectedFilterOptions = ({
    searchThrough,
    referencesFilterOptions,
  }: {
    searchThrough: SearchThroughEnum;
    referencesFilterOptions: TOption<string>[];
  }) => {
    // init filters
    let doOnlyGetConnectedToObjectId: boolean | undefined = undefined;
    let isCreatedByMe: true | undefined = undefined;
    for (const referencesFilterOption of referencesFilterOptions) {
      if (
        referencesFilterOption.title ===
          ReferenceSidebarFilterTypeEnum.OnlyShowConnected &&
        referencesFilterOption.value ===
          ReferenceSidebarFilterTypeEnum.OnlyShowConnected &&
        searchThrough !== SearchThroughEnum.Inbox
      ) {
        doOnlyGetConnectedToObjectId = true;
      }
      if (
        referencesFilterOption.title ===
          ReferenceSidebarFilterTypeEnum.OnlyShowConnectedInbox &&
        referencesFilterOption.value ===
          ReferenceSidebarFilterTypeEnum.OnlyShowConnectedInbox &&
        searchThrough === SearchThroughEnum.Inbox
      ) {
        doOnlyGetConnectedToObjectId = true;
      }
      if (
        referencesFilterOption.title ===
          ReferenceSidebarFilterTypeEnum.AddedByMe &&
        referencesFilterOption.value ===
          ReferenceSidebarFilterTypeEnum.AddedByMe
      ) {
        isCreatedByMe = true;
      }
    }
    return { doOnlyGetConnectedToObjectId, isCreatedByMe };
  };

  const refreshDocuments = useCallback(async () => {
    const searchTextValue = currentSearchQueryTextValueRef.current;
    // get search through enum value
    const searchThrough: SearchThroughEnum =
      fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
        selectedTabTitle
      );

    // get the selected filter options
    const { doOnlyGetConnectedToObjectId, isCreatedByMe } =
      getSelectedFilterOptions({
        searchThrough,
        referencesFilterOptions: selectedReferencesFilterOptions,
      });

    // init new document references
    let newDocumentReferences: TDocumentReferencesDTO[] = [];

    // depending on search text value length, run different search
    if (searchTextValue.length === 0) {
      // get all document references
      newDocumentReferences =
        await ReferenceControllerSingleton.getDocumentReferencesAsync(
          searchThrough === SearchThroughEnum.Inbox ? true : false,
          objectEdited?.id,
          isCreatedByMe ? true : undefined,
          doOnlyGetConnectedToObjectId
        );
    } else {
      // search for document references
      newDocumentReferences =
        await SearchControllerSingleton.searchDocumentReferencesAsync(
          searchTextValue,
          searchThrough,
          objectEdited?.id,
          isCreatedByMe ? true : undefined,
          doOnlyGetConnectedToObjectId
        );
    }

    // update comments per reference id
    updateCommentsPerReferenceId(newDocumentReferences);

    // set the new document references
    setDocumentReferences(newDocumentReferences);
  }, [
    objectEdited,
    selectedReferencesFilterOptions,
    selectedTabTitle,
    updateCommentsPerReferenceId,
  ]);

  const onSelectedTabChange = (
    tabTitle: string,
    currentSelectedReferencesFilterOptions: TOption<string>[],
    currentObjectIdEdited: string
  ) => {
    setSelectedTabTitle(tabTitle);

    // get search through enum value
    const searchThrough: SearchThroughEnum =
      fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(tabTitle);

    // run search when changing tab with current search query text value
    onSearchTextValueChangeAsync(
      currentSearchQueryTextValueRef.current,
      searchThrough,
      currentSelectedReferencesFilterOptions,
      currentObjectIdEdited
    );
  };

  const onReferenceCommentsUpdated = useCallback(
    (
      currentDocumentReferences: TDocumentReferencesDTO[],
      referenceId: string,
      comments: TCommentDTO[]
    ) => {
      // go through each document reference
      for (const documentReference of currentDocumentReferences) {
        // go through each reference
        for (const reference of documentReference.references) {
          // if reference id matches
          if (reference.id === referenceId) {
            // update comments
            reference.comments = [...comments];
          }
        }
      }

      // update both object and document references
      setDocumentReferences([...currentDocumentReferences]);

      // update comments per reference id
      updateCommentsPerReferenceId(currentDocumentReferences);
    },
    [updateCommentsPerReferenceId]
  );

  // Render correct component based on selectedTab
  const renderedTabContent = useMemo(() => {
    // safety-checks
    if (!objectEdited) {
      return null;
    }

    switch (selectedTabTitle) {
      case ReferenceSidebarTabTitleEnum.Documents:
        return (
          <ReferenceSidebarDocumentsTab
            objectIdEdited={objectEdited.id}
            objectTypeEdited={objectEdited.objectType}
            searchTextValue={currentSearchQueryTextValueRef.current}
            searchThrough={SearchThroughEnum.Documents}
            documentReferences={documentReferences}
            usedReferenceIds={usedReferenceIds}
            insertDocumentAsReference={insertDocumentAsReference}
            insertHighlightAsReference={insertHighlightAsReference}
            insertHighlightAsText={insertHighlightAsText}
            insertImageAsReference={insertImageAsReference}
            isSearchRunning={isSearchRunningRef.current}
            refreshDocuments={refreshDocuments}
            onReferenceCommentsUpdated={(
              referenceId: string,
              comments: TCommentDTO[]
            ) =>
              onReferenceCommentsUpdated(
                documentReferences,
                referenceId,
                comments
              )
            }
          />
        );
      case ReferenceSidebarTabTitleEnum.Entities:
        return (
          <ReferenceSidebarObjectsTab
            objectType={ObjectTypeEnum.Entity}
            searchTextValue={currentSearchQueryTextValueRef.current}
            objectIdEdited={objectEdited.id}
            objectTypeEdited={objectEdited.objectType}
            usedReferenceIds={usedReferenceIds}
            objectList={objectList}
            applyInsertObjectReference={insertObjectAsReference}
            isSearchRunning={isSearchRunningRef.current}
          />
        );
      case ReferenceSidebarTabTitleEnum.Studies:
        return (
          <ReferenceSidebarObjectsTab
            objectType={ObjectTypeEnum.Study}
            searchTextValue={currentSearchQueryTextValueRef.current}
            objectIdEdited={objectEdited.id}
            objectTypeEdited={objectEdited.objectType}
            usedReferenceIds={usedReferenceIds}
            objectList={objectList}
            applyInsertObjectReference={insertObjectAsReference}
            isSearchRunning={isSearchRunningRef.current}
          />
        );
      case ReferenceSidebarTabTitleEnum.CurrentObjectHighlights:
        return (
          <ReferenceSidebarDocumentsTab
            objectIdEdited={objectEdited.id}
            objectTypeEdited={objectEdited.objectType}
            refreshDocuments={refreshDocuments}
            searchTextValue={currentSearchQueryTextValueRef.current}
            searchThrough={SearchThroughEnum.Highlights}
            documentReferences={documentReferences}
            usedReferenceIds={usedReferenceIds}
            insertDocumentAsReference={insertDocumentAsReference}
            insertHighlightAsReference={insertHighlightAsReference}
            insertHighlightAsText={insertHighlightAsText}
            insertImageAsReference={insertImageAsReference}
            isSearchRunning={isSearchRunningRef.current}
            onReferenceCommentsUpdated={(
              referenceId: string,
              comments: TCommentDTO[]
            ) =>
              onReferenceCommentsUpdated(
                documentReferences,
                referenceId,
                comments
              )
            }
          />
        );
      case ReferenceSidebarTabTitleEnum.Inbox:
      default:
        return (
          <ReferenceSidebarDocumentsTab
            objectIdEdited={objectEdited.id}
            objectTypeEdited={objectEdited.objectType}
            refreshDocuments={refreshDocuments}
            searchTextValue={currentSearchQueryTextValueRef.current}
            searchThrough={SearchThroughEnum.Inbox}
            documentReferences={documentReferences}
            usedReferenceIds={usedReferenceIds}
            insertDocumentAsReference={insertDocumentAsReference}
            insertHighlightAsReference={insertHighlightAsReference}
            insertHighlightAsText={insertHighlightAsText}
            insertImageAsReference={insertImageAsReference}
            isSearchRunning={isSearchRunningRef.current}
            onReferenceCommentsUpdated={(
              referenceId: string,
              comments: TCommentDTO[]
            ) =>
              onReferenceCommentsUpdated(
                documentReferences,
                referenceId,
                comments
              )
            }
          />
        );
    }
  }, [
    objectEdited,
    selectedTabTitle,
    documentReferences,
    usedReferenceIds,
    insertDocumentAsReference,
    insertHighlightAsReference,
    insertHighlightAsText,
    insertImageAsReference,
    refreshDocuments,
    objectList,
    insertObjectAsReference,
    onReferenceCommentsUpdated,
  ]);

  useEffect(() => {
    // on first render, get document references (empty query and for the inbox)
    (async () => {
      if (!auth.isRequestingAuthInformation && objectEdited) {
        // get saved filters from local storage
        const savedFilters: TOption<ReferenceSidebarFilterTypeEnum>[] =
          SavedFiltersHelperSingleton.getReferenceSidebarFilters();
        // current selected references filter options
        let selectedFilters = [];
        // if there are saved filters, set them as selected
        if (savedFilters.length > 0) {
          // set local filters to the corresponding selectedReferencesFilterOptions
          setSelectedReferencesFilterOptions(savedFilters);
          selectedFilters = [...savedFilters];
        } else {
          // if no saved filters in local storage set default filters in local storage
          SavedFiltersHelperSingleton.saveReferenceSidebarFilters([
            {
              value: ReferenceSidebarFilterTypeEnum.OnlyShowConnected,
              title: ReferenceSidebarFilterTypeEnum.OnlyShowConnected,
            },
            {
              value: ReferenceSidebarFilterTypeEnum.ShowWholeUniverseInbox,
              title: ReferenceSidebarFilterTypeEnum.ShowWholeUniverseInbox,
            },
          ]);
          const defaultFilters = [
            {
              value: ReferenceSidebarFilterTypeEnum.OnlyShowConnected,
              title: ReferenceSidebarFilterTypeEnum.OnlyShowConnected,
            },
          ];
          setSelectedReferencesFilterOptions(defaultFilters);
          selectedFilters = [...defaultFilters];
        }

        // Reset reference sidebar state
        currentSearchQueryTextValueRef.current = "";
        setObjectList([]);
        setSelectedTabTitle(ReferenceSidebarTabTitleEnum.Inbox);

        // get do only get connected to object id
        const { doOnlyGetConnectedToObjectId } = getSelectedFilterOptions({
          searchThrough:
            fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
              ReferenceSidebarTabTitleEnum.Inbox
            ),
          referencesFilterOptions: selectedFilters,
        });
        // init new document references
        const newDocumentReferences: TDocumentReferencesDTO[] =
          await ReferenceControllerSingleton.getDocumentReferencesAsync(
            true,
            objectEdited.id,
            true,
            doOnlyGetConnectedToObjectId
          );

        // update comments per reference id
        updateCommentsPerReferenceId(newDocumentReferences);

        // set document references
        setDocumentReferences(newDocumentReferences);
      }
    })();
  }, [
    auth.isRequestingAuthInformation,
    updateCommentsPerReferenceId,
    objectEdited,
  ]);

  const scrollEvent = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop > 0) {
      setIsScrollPositionTop(false);
    } else {
      setIsScrollPositionTop(true);
    }
  };

  // handle search text value changed
  const onSearchTextValueChangeAsync = async (
    searchTextValue: string,
    searchThroughEnum: SearchThroughEnum,
    referencesFilterOptions: TOption<string>[],
    currentObjectIdEdited: string
  ): Promise<void> => {
    // set the current search query text value
    currentSearchQueryTextValueRef.current = searchTextValue;

    // if a search is already running, do not run another one
    // and keep the last search query
    if (isSearchRunningRef.current) {
      lastSearchQueryRef.current = searchTextValue;
      return;
    }

    // set is search running to true
    isSearchRunningRef.current = true;
    // set the last search query ref to undefined
    lastSearchQueryRef.current = undefined;

    // run the search
    await runSearchAsync(
      searchTextValue,
      searchThroughEnum,
      referencesFilterOptions,
      currentObjectIdEdited
    );
  };

  const runSearchAsync = async (
    searchTextValue: string,
    searchThroughEnum: SearchThroughEnum,
    referencesFilterOptions: TOption<string>[],
    currentObjectIdEdited: string
  ): Promise<void> => {
    // get the selected filter options
    const { doOnlyGetConnectedToObjectId, isCreatedByMe } =
      getSelectedFilterOptions({
        searchThrough: searchThroughEnum,
        referencesFilterOptions,
      });

    if (
      searchThroughEnum === SearchThroughEnum.Inbox ||
      searchThroughEnum === SearchThroughEnum.Documents
    ) {
      // init new document references
      let newDocumentReferences: TDocumentReferencesDTO[] = [];

      // depending on search text value length, run different search
      if (searchTextValue.length === 0) {
        // get all document references
        newDocumentReferences =
          await ReferenceControllerSingleton.getDocumentReferencesAsync(
            searchThroughEnum === SearchThroughEnum.Inbox ? true : false,
            currentObjectIdEdited,
            isCreatedByMe ? true : undefined,
            doOnlyGetConnectedToObjectId
          );
      } else {
        // search for document references
        newDocumentReferences =
          await SearchControllerSingleton.searchDocumentReferencesAsync(
            searchTextValue,
            searchThroughEnum,
            currentObjectIdEdited,
            isCreatedByMe ? true : undefined,
            doOnlyGetConnectedToObjectId
          );
      }

      // update comments per reference id
      updateCommentsPerReferenceId(newDocumentReferences);

      // set the new document references
      setDocumentReferences(newDocumentReferences);
    } else if (
      searchThroughEnum === SearchThroughEnum.Entities ||
      searchThroughEnum === SearchThroughEnum.Studies
    ) {
      // init new object list
      let newObjectList: TIdNameTypeObjectType[] = [];

      // if searchThroughEnum is entities
      if (searchThroughEnum === SearchThroughEnum.Entities) {
        if (searchTextValue.length === 0) {
          // get all entities
          const entitiesDto = await EntityControllerSingleton.getAsync(
            OrderByEnum.Descending,
            undefined,
            [],
            isCreatedByMe,
            undefined,
            currentObjectIdEdited,
            doOnlyGetConnectedToObjectId
          );

          newObjectList = entitiesDto.entities.map((entity) => {
            return {
              id: entity.id,
              name: entity.title,
              type: EntityTypeHelperSingleton.getEntityTypeDisplayName(
                entity.type,
                entity.customTypeName
              ),
              objectType: ObjectTypeEnum.Entity,
              isConnected: entity.isConnected,
            };
          });
        } else {
          // get entities with search value
          const entitiesAsObjects =
            await SearchControllerSingleton.searchMultipleObjectsAsync(
              searchTextValue,
              [ObjectTypeEnum.Entity],
              currentObjectIdEdited,
              isCreatedByMe ? true : undefined,
              doOnlyGetConnectedToObjectId
            );
          newObjectList = entitiesAsObjects.map((entity) => {
            return {
              ...entity,
              type: EntityTypeHelperSingleton.getEntityTypeDisplayName(
                EntityTypeHelperSingleton.entityTypeStringToEnum(entity.type),
                entity.customTypeName
              ),
            };
          });
        }
      } else if (searchThroughEnum === SearchThroughEnum.Studies) {
        // otherwise, if searchThroughEnum is studies
        if (searchTextValue.length === 0) {
          // get all studies
          const studiesDto = await StudyControllerSingleton.getAsync(
            OrderByEnum.Descending,
            undefined,
            [],
            [],
            isCreatedByMe,
            undefined,
            currentObjectIdEdited,
            doOnlyGetConnectedToObjectId
          );

          newObjectList = studiesDto.studies.map((study) => {
            return {
              id: study.id,
              name: study.title,
              type: StudyTypeHelperSingleton.getStudyTypeDisplayName(
                study.type,
                study.customTypeName
              ),
              objectType: ObjectTypeEnum.Study,
              isConnected: study.isConnected,
            };
          });
        } else {
          // get studies with search value
          const studiesAsObjects =
            await SearchControllerSingleton.searchMultipleObjectsAsync(
              searchTextValue,
              [ObjectTypeEnum.Study],
              currentObjectIdEdited,
              isCreatedByMe ? true : undefined,
              doOnlyGetConnectedToObjectId
            );
          newObjectList = studiesAsObjects.map((study) => {
            return {
              ...study,
              type: study.customTypeName ? study.customTypeName : study.type,
            };
          });
        }
      }

      // set the new object list
      setObjectList(newObjectList);
    } else if (searchThroughEnum === SearchThroughEnum.Highlights) {
      // init new document references
      let newDocumentReferences: TDocumentReferencesDTO[] = [];

      if (searchTextValue.length === 0) {
        // get all document references
        newDocumentReferences =
          await ReferenceControllerSingleton.getHighlightDocumentReferencesAsync(
            currentObjectIdEdited,
            isCreatedByMe ? true : undefined,
            doOnlyGetConnectedToObjectId
          );
      } else {
        newDocumentReferences =
          await SearchControllerSingleton.searchHighlightDocumentReferencesAsync(
            searchTextValue,
            currentObjectIdEdited,
            isCreatedByMe ? true : undefined,
            doOnlyGetConnectedToObjectId
          );
      }

      // update comments per reference id
      updateCommentsPerReferenceId(newDocumentReferences);

      // set the new document references
      setDocumentReferences(newDocumentReferences);
    }

    // set is search running to false
    isSearchRunningRef.current = false;

    // if a last search query is set and different from the last one executed
    if (
      lastSearchQueryRef.current !== undefined &&
      lastSearchQueryRef.current !== searchTextValue
    ) {
      // then run the search again with the last search query
      await onSearchTextValueChangeAsync(
        lastSearchQueryRef.current,
        searchThroughEnum,
        referencesFilterOptions,
        currentObjectIdEdited
      );
    }
  };

  const getReferencesFilterOptions = (
    searchThroughEnum: SearchThroughEnum
  ): TOptions<string>[] => {
    // init references type options
    const referencesTypeOptions: TOptions<string>[] = [];

    // if search through is not inbox
    if (searchThroughEnum !== SearchThroughEnum.Inbox) {
      // init show added by options
      const showAddedByOptionsGroup: TOptions<string> = {
        group: "Show added by",
        options: [
          {
            value: ReferenceSidebarFilterTypeEnum.AddedByMe,
            title: ReferenceConstants.ADDED_BY_ME_FILTER_OPTION,
          },
        ],
      };
      // add show added by options to references type options
      referencesTypeOptions.push(showAddedByOptionsGroup);

      // init connected to this page options
      const connectedToThisPageOptionsGroup: TOptions<string> = {
        group: "Connected to this page",
        options: [
          {
            value: ReferenceSidebarFilterTypeEnum.OnlyShowConnected,
            title: ReferenceConstants.ONLY_SHOW_CONNECTED_FILTER_OPTION,
          },
        ],
      };
      // add connected to this page options to references type options
      referencesTypeOptions.push(connectedToThisPageOptionsGroup);
    } else {
      // init connected to this page options
      const connectedInboxToThisPageOptionsGroup: TOptions<string> = {
        group: "Connected to this page",
        options: [
          {
            value: ReferenceSidebarFilterTypeEnum.OnlyShowConnectedInbox,
            title: ReferenceConstants.ONLY_SHOW_CONNECTED_INBOX_FILTER_OPTION,
          },
        ],
      };
      // add connected to this page options to references type options
      referencesTypeOptions.push(connectedInboxToThisPageOptionsGroup);
    }

    // return references type options
    return referencesTypeOptions;
  };

  const updateReferencesFilterOptions = (
    action: "add" | "remove",
    option: TOption<string>,
    currentSelectedReferencesFilterOptions: TOption<string>[],
    currentSearchQueryTextValue: string,
    currentSearchThroughEnum: SearchThroughEnum,
    currentObjectIdEdited: string
  ): void => {
    // init new selected references filter options
    const newSelectedReferencesFilterOptions: TOption<string>[] = [
      ...currentSelectedReferencesFilterOptions,
    ];

    // Match option with ReferenceSidebarFilterTypeEnum
    let oppositeOption: TOption<string> = { value: "", title: "" };
    if (
      (option.value as ReferenceSidebarFilterTypeEnum) ===
      ReferenceSidebarFilterTypeEnum.AddedByMe.toString()
    ) {
      option.title = ReferenceSidebarFilterTypeEnum.AddedByMe;
    } else if (
      (option.value as ReferenceSidebarFilterTypeEnum) ===
      ReferenceSidebarFilterTypeEnum.OnlyShowConnected.toString()
    ) {
      option.title = ReferenceSidebarFilterTypeEnum.OnlyShowConnected;
      oppositeOption = {
        value: ReferenceSidebarFilterTypeEnum.ShowWholeUniverse,
        title: ReferenceSidebarFilterTypeEnum.ShowWholeUniverse,
      };
    } else if (
      (option.value as ReferenceSidebarFilterTypeEnum) ===
      ReferenceSidebarFilterTypeEnum.OnlyShowConnectedInbox.toString()
    ) {
      option.title = ReferenceSidebarFilterTypeEnum.OnlyShowConnectedInbox;
      oppositeOption = {
        value: ReferenceSidebarFilterTypeEnum.ShowWholeUniverseInbox,
        title: ReferenceSidebarFilterTypeEnum.ShowWholeUniverseInbox,
      };
    }

    // add to newSelectedReferencesFilterOptions
    if (action === "add") {
      newSelectedReferencesFilterOptions.push(option);
      // remove the opposite from option from the newSelectedReferencesFilterOptions
      if (oppositeOption.value !== "") {
        // remove from newSelectedReferencesFilterOptions using for loop
        for (let i = 0; i < newSelectedReferencesFilterOptions.length; i++) {
          if (
            newSelectedReferencesFilterOptions[i].value === oppositeOption.value
          ) {
            newSelectedReferencesFilterOptions.splice(i, 1);
            break;
          }
        }
      }
    } else if (action === "remove") {
      // remove from newSelectedReferencesFilterOptions using for loop
      for (let i = 0; i < newSelectedReferencesFilterOptions.length; i++) {
        if (newSelectedReferencesFilterOptions[i].value === option.value) {
          newSelectedReferencesFilterOptions.splice(i, 1);
          break;
        }
      }
      // Add the opposite from option from the newSelectedReferencesFilterOptions
      if (oppositeOption.value !== "") {
        newSelectedReferencesFilterOptions.push(oppositeOption);
      }
    }

    // update selectedReferencesFilterOptions
    setSelectedReferencesFilterOptions([...newSelectedReferencesFilterOptions]);

    // update local storage with new selected reference filter options
    const newSelectedReferencesFilterOptionsEnum =
      newSelectedReferencesFilterOptions as TOption<ReferenceSidebarFilterTypeEnum>[];
    SavedFiltersHelperSingleton.saveReferenceSidebarFilters([
      ...newSelectedReferencesFilterOptionsEnum,
    ]);

    // run search async
    onSearchTextValueChangeAsync(
      currentSearchQueryTextValue,
      currentSearchThroughEnum,
      newSelectedReferencesFilterOptions,
      currentObjectIdEdited
    );
  };

  const fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum = (
    referenceSidebarTabTitleEnumString: string
  ): SearchThroughEnum => {
    switch (referenceSidebarTabTitleEnumString) {
      case ReferenceSidebarTabTitleEnum.Inbox:
        return SearchThroughEnum.Inbox;
      case ReferenceSidebarTabTitleEnum.Documents:
        return SearchThroughEnum.Documents;
      case ReferenceSidebarTabTitleEnum.Entities:
        return SearchThroughEnum.Entities;
      case ReferenceSidebarTabTitleEnum.Studies:
        return SearchThroughEnum.Studies;
      case ReferenceSidebarTabTitleEnum.CurrentObjectHighlights:
        return SearchThroughEnum.Highlights;
      default:
        return SearchThroughEnum.Inbox;
    }
  };

  const resetSearchBar = async (
    currentSearchThrough: SearchThroughEnum,
    currentSelectedReferencesFilterOptions: TOption<string>[],
    currentObjectIdEdited: string
  ) => {
    // set values to default ones
    isSearchRunningRef.current = false;
    lastSearchQueryRef.current = undefined;
    currentSearchQueryTextValueRef.current = "";

    // run search async
    onSearchTextValueChangeAsync(
      "",
      currentSearchThrough,
      currentSelectedReferencesFilterOptions,
      currentObjectIdEdited
    );
  };

  const getSelectedReferencesFilterOptionsConstants = (
    currentSelectedReferencesFilterOptions: TOption<string>[],
    searchThroughEnum: SearchThroughEnum
  ) => {
    // Create an empty list of referenceFilterOptions
    const selectedReferencesFilterOptionsConstants: TOption<string>[] = [];
    // Loop through the currentSelectedReferenceFilterOptions,transform the needed ones into Constants and add to the options list
    for (const currentSelectedReferencesFilterOption of currentSelectedReferencesFilterOptions) {
      if (
        currentSelectedReferencesFilterOption.value ===
          ReferenceSidebarFilterTypeEnum.AddedByMe &&
        searchThroughEnum !== SearchThroughEnum.Inbox
      ) {
        selectedReferencesFilterOptionsConstants.push({
          value: currentSelectedReferencesFilterOption.value,
          title: ReferenceConstants.ADDED_BY_ME_FILTER_OPTION,
        });
      } else if (
        currentSelectedReferencesFilterOption.value ===
          ReferenceSidebarFilterTypeEnum.OnlyShowConnected &&
        searchThroughEnum !== SearchThroughEnum.Inbox
      ) {
        selectedReferencesFilterOptionsConstants.push({
          value: currentSelectedReferencesFilterOption.value,
          title: ReferenceConstants.ONLY_SHOW_CONNECTED_FILTER_OPTION,
        });
      } else if (
        currentSelectedReferencesFilterOption.value ===
          ReferenceSidebarFilterTypeEnum.OnlyShowConnectedInbox &&
        searchThroughEnum === SearchThroughEnum.Inbox
      ) {
        selectedReferencesFilterOptionsConstants.push({
          value: currentSelectedReferencesFilterOption.value,
          title: ReferenceConstants.ONLY_SHOW_CONNECTED_INBOX_FILTER_OPTION,
        });
      }
    }

    // return the selectedReferencesFilterOptionsConstants list
    return selectedReferencesFilterOptionsConstants;
  };

  // If the user is not in edit mode, do not render the reference sidebar
  if (!isEditOn || !objectEdited) return null;

  return (
    <div
      className={`${styles.referenceSidebarContainer} ${
        isInReferenceModal ? styles.isInReferenceModal : ""
      }`}
    >
      <div>
        <div className={styles.titleContainer}>
          <h3>References</h3>
          <FindestButton
            leftIconName={faXmark}
            onClick={onCollapseButtonClick}
            titleAttribute="Close reference bar"
            extraClassName={rightSidebarStyles.collapseButton}
            buttonType="tertiary"
          />
        </div>
        <div className={styles.searchBar}>
          <FindestTextBox
            placeholder="Filter on references..."
            leftIcon={faFilter}
            value={currentSearchQueryTextValueRef.current}
            showEmptyInputCrossIcon={
              currentSearchQueryTextValueRef.current?.length > 0
            }
            onEmptyInputButtonClickHandler={() =>
              resetSearchBar(
                fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
                  selectedTabTitle
                ),
                selectedReferencesFilterOptions,
                objectEdited.id
              )
            }
            onChange={(text: string) =>
              onSearchTextValueChangeAsync(
                text,
                fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
                  selectedTabTitle
                ),
                selectedReferencesFilterOptions,
                objectEdited.id
              )
            }
          />
        </div>
        <div className={styles.filters}>
          <Filters
            selectedTabTitle={selectedTabTitle}
            inReferenceSidebar
            leftIcon={faEye}
            rightIconOnOpen={faChevronUp}
            rightIconOnClose={faChevronDown}
            extraClassNames={{
              filtersContainer: styles.filtersContainer,
              filteredItem: styles.filteredItem,
              addFilterButtonContainer: styles.addFilterButtonContainer,
              addFilterButton: styles.addFilterButton,
              groupedListOption: styles.groupedListOption,
              groupedList: styles.groupedList,
              groupTitle: styles.groupTitle,
              selected: styles.selected,
            }}
            filterOptions={getReferencesFilterOptions(
              fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
                selectedTabTitle
              )
            )}
            selectedFilterOptions={getSelectedReferencesFilterOptionsConstants(
              selectedReferencesFilterOptions,
              fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
                selectedTabTitle
              )
            )}
            handleOptionSelect={(option) => {
              updateReferencesFilterOptions(
                "add",
                option,
                selectedReferencesFilterOptions,
                currentSearchQueryTextValueRef.current,
                fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
                  selectedTabTitle
                ),
                objectEdited.id
              );
            }}
            handleOptionUnselect={(option) => {
              updateReferencesFilterOptions(
                "remove",
                option,
                selectedReferencesFilterOptions,
                currentSearchQueryTextValueRef.current,
                fromReferenceSidebarTabTitleEnumStringToSearchThroughEnum(
                  selectedTabTitle
                ),
                objectEdited.id
              );
            }}
          />
        </div>
        <div className={styles.tabsContainer}>
          <FontAwesomeIcon className={styles.linkIcon} icon={faLink} />
          <IconnedTabs
            tabIcons={referenceSidebarTabs}
            onSelectedTabChange={(newSelectedTabTitle: string) =>
              onSelectedTabChange(
                newSelectedTabTitle,
                selectedReferencesFilterOptions,
                objectEdited.id
              )
            }
          />
        </div>
      </div>
      <div className={styles.referencesContainer} onScroll={scrollEvent}>
        <div
          className={`${styles.isScrollingElement} ${
            isScrollPositionTop ? "" : styles.isScrolling
          }`}
        ></div>
        {renderedTabContent}
      </div>
    </div>
  );
};
