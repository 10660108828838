// node_modules
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DragHandle from "@tiptap-pro/extension-drag-handle-react";
import { Editor } from "@tiptap/core";
import { FC, useEffect, useRef, useState } from "react";
// Custom hooks
import { useClickOutsideRef, useTooltipDelay, useStoreNode } from "Hooks";
// Components
import { Popover, Tooltip } from "Components";
import { EditorMenuCommand } from "./EditorMenuItems";
// Helpers
import {
  CLEAR_NODE_FORMATTING_COMMAND,
  COPY_NODE_COMMAND,
  CUT_NODE_COMMAND,
  DELETE_NODE_COMMAND,
  DUPLICATE_NODE_COMMAND,
  INSERT_AFTER_NODE_COMMAND,
  LogHelperSingleton,
  SET_META_COMMAND,
} from "Helpers";
// Enums
import { LogFeatureNameEnum } from "Enums";
// Styles
import styles from "./editorLeftFloatingMenu.module.scss";

interface IEditorLeftFloatingMenuProps {
  editor: Editor;
  isEditOn?: boolean;
}

export const EditorLeftFloatingMenu: FC<IEditorLeftFloatingMenuProps> = ({
  editor,
  isEditOn,
}: IEditorLeftFloatingMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { node, nodePos, handleNodeChange } = useStoreNode();
  const { isTooltipShown, handleMouseEnter, handleMouseLeave } = useTooltipDelay(1000);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useClickOutsideRef(
    containerRef,
    () => {
      setIsOpen(false);
    },
    [buttonRef]
  );

  useEffect(() => {
    if (isOpen) {
      SET_META_COMMAND.action(editor, {
        metaKey: "lockDragHandle",
        metaValue: true,
      });
    } else {
      SET_META_COMMAND.action(editor, {
        metaKey: "lockDragHandle",
        metaValue: false,
      });
    }
  }, [editor, isOpen]);

  useEffect(() => {
    if (!editor) return;

    const onDragStart = () => {
      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-StartDraggingNode`
      );
    };

    const onDragEnd = () => {
      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-StopDraggingNode`
      );
    };

    document.addEventListener("dragstart", onDragStart);
    document.addEventListener("dragend", onDragEnd);

    return () => {
      document.removeEventListener("dragstart", onDragStart);
      document.removeEventListener("dragend", onDragEnd);
    };
  }, [editor]);

  return (
    <div>
      <DragHandle
        pluginKey="leftFloatingMenu"
        editor={editor}
        onNodeChange={handleNodeChange}
        tippyOptions={{
          offset: [-3, 4],
          zIndex: 1,
        }}
      >
        {isEditOn ? (
          <div className={styles.container} ref={containerRef}>
            <EditorMenuCommand
              editor={editor}
              actionOptions={{ nodePos, node }}
              command={INSERT_AFTER_NODE_COMMAND}
              doShowTooltip
              doNotShowLabel
              extraClassNames={{ editorMenuCommand: styles.insertAfterButton }}
            />
            <button
              ref={buttonRef}
              type="button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={styles.gripButton}
            >
              <FontAwesomeIcon icon={faGripVertical} />
              <Tooltip referenceEl={buttonRef.current} isOpen={isTooltipShown}>
                <div className={styles.tooltip}>
                  <div className={styles.line}>
                    <span className={styles.action}>Drag</span>to move
                  </div>
                  <div className={styles.line}>
                    <span className={styles.action}>Click</span>to open menu
                  </div>
                </div>
              </Tooltip>
            </button>
            <Popover
              referenceEl={containerRef.current}
              placement="bottom-start"
              isOpen={isOpen}
              extraClassName={styles.leftFloatingMenuOptionPopover}
            >
              <EditorMenuCommand
                editor={editor}
                actionOptions={{ nodePos, node }}
                command={CLEAR_NODE_FORMATTING_COMMAND}
                onClickCallback={() => {
                  setIsOpen(false);
                }}
                doShowTooltip={false}
                extraClassNames={{ editorMenuCommand: styles.popoverButton }}
              />
              <EditorMenuCommand
                editor={editor}
                actionOptions={{ nodePos, node }}
                command={DUPLICATE_NODE_COMMAND}
                onClickCallback={() => {
                  setIsOpen(false);
                }}
                doShowTooltip={false}
                extraClassNames={{ editorMenuCommand: styles.popoverButton }}
              />
              <EditorMenuCommand
                editor={editor}
                actionOptions={{ nodePos, node }}
                command={COPY_NODE_COMMAND}
                onClickCallback={() => {
                  setIsOpen(false);
                }}
                doShowTooltip={false}
                extraClassNames={{ editorMenuCommand: styles.popoverButton }}
              />
              <EditorMenuCommand
                editor={editor}
                actionOptions={{ nodePos, node }}
                command={CUT_NODE_COMMAND}
                onClickCallback={() => {
                  setIsOpen(false);
                }}
                doShowTooltip={false}
                extraClassNames={{ editorMenuCommand: styles.popoverButton }}
              />
              <div className={styles.spacer} />
              <EditorMenuCommand
                editor={editor}
                actionOptions={{ nodePos, node }}
                command={DELETE_NODE_COMMAND}
                onClickCallback={() => {
                  setIsOpen(false);
                }}
                doShowTooltip={false}
                extraClassNames={{
                  editorMenuCommand: `${styles.popoverButton} ${styles.deleteButton}`,
                }}
              />
            </Popover>
          </div>
        ) : null}
      </DragHandle>
    </div>
  );
};
