// node_modules
import {Editor} from "@tiptap/core";
import {Dispatch, FC, SetStateAction} from "react";
// Components
import {
  EditorMenuAIToolsCommand,
  EditorMenuCommand,
  EditorMenuCommands,
  EditorMenuFormatCommand,
  EditorMenuLinkCommand,
  EditorMenuTableCommand,
  EditorMenuAdvancedCommand,
} from "./EditorMenuItems";
// Interfaces
import {ICommand} from "Interfaces";
// Helpers
import {
  ADVANCED_GROUP,
  BOLD_COMMAND,
  DO_COMMANDS,
  HIGHLIGHT_COMMAND,
  INSERT_FILE_COMMAND,
  INSERT_IMAGE_COMMAND,
  ITALIC_COMMAND,
  SCRIPTS_COMMANDS,
} from "Helpers";
// Custom hooks
import {useSelectionNode} from "Hooks";
// Styles
import styles from "./editorMenu.module.scss";

interface IEditorMenuProps {
  editor: Editor | null;
  hasAdvanced: boolean;
  setIsAddImageModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsOverviewTableModalOpen: Dispatch<SetStateAction<boolean>>;
  hiddenCommands?: ICommand[];
  openFileUploadModal: () => void;
}

export const EditorMenu: FC<IEditorMenuProps> = ({
  editor,
  hasAdvanced,
  setIsAddImageModalOpen,
  setIsOverviewTableModalOpen,
  hiddenCommands = [],
  openFileUploadModal,
}: IEditorMenuProps) => {
  const selectionNodeData = useSelectionNode({ editor });

    if (!editor) {
        return null;
    }

  const onClickCallback = (command: ICommand) => {
    switch (command.name) {
      case INSERT_IMAGE_COMMAND.name:
        setIsAddImageModalOpen((prev) => !prev);
        break;
      case INSERT_FILE_COMMAND.name:
        openFileUploadModal();
        break;
      default:
        break;
    }
  };

  const areCommandsVisible = (commands: ICommand[]) => {
    return commands.some(
      (command) => hiddenCommands.indexOf(command) === -1
    );
  };

  return (
    <div className={styles.editorMenu}>
      <EditorMenuAIToolsCommand editor={editor} />
      <div className={styles.editorSpacer} />
      <EditorMenuFormatCommand editor={editor} />
      <EditorMenuCommands
        commands={[
          BOLD_COMMAND,
          ITALIC_COMMAND,
          ...SCRIPTS_COMMANDS,
          HIGHLIGHT_COMMAND,
        ]}
        editor={editor}
        doShowTooltip={true}
        doNotShowCommandLabels
      />
      <div className={styles.editorSpacer} />
      <div className={styles.editorMenuCommands}>
        <EditorMenuLinkCommand editor={editor} />
        {[INSERT_IMAGE_COMMAND, INSERT_FILE_COMMAND].map(
          (command: ICommand) => {
            return (
              <EditorMenuCommand
                key={command.name}
                command={command}
                onClickCallback={onClickCallback}
                actionOptions={{
                  node: selectionNodeData?.fromNode,
                  nodePos: selectionNodeData?.fromNodePos,
                }}
                editor={editor}
                doShowTooltip
                doNotShowLabel
              />
            );
          }
        )}
        <EditorMenuTableCommand editor={editor} />
        {hasAdvanced && areCommandsVisible(ADVANCED_GROUP.commands) && (
          <EditorMenuAdvancedCommand
            editor={editor}
            setIsOverviewTableModalOpen={setIsOverviewTableModalOpen}
          />
        )}
      </div>
      {areCommandsVisible(DO_COMMANDS) && (
        <>
          <div className={styles.editorSpacer} />
          <EditorMenuCommands
            commands={DO_COMMANDS}
            editor={editor}
            doNotShowCommandLabels
            doShowTooltip={true}
          />
        </>
      )}
    </div>
  );
};
