// node_modules
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FC, FocusEvent, useContext, useMemo, useRef, useState } from "react";
// Components
import { FindestButton, FindestTextBox, ListObjectItem } from "Components";
// Controllers
import { SearchControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Contexts
import { AuthContext, RecentActivityContext } from "Providers";
// Types
import {
  TIdNameTypeObjectType,
  TIdNameTypeObjectTypeCreatedBy,
  TUseDragAndDrop,
  fromTRecentSidebarActivityItemDTO,
} from "Types";
// Hooks
import { useClickOutsideRef } from "Hooks";
// Styles
import styles from "./objectSearchPopupContent.module.scss";
// Constants
import { GeneralConstants } from "Constants";

export type TObjectSearchPopupContentProps = {
  currentObjectId?: string;
  onElementClick?: (result: TIdNameTypeObjectType) => void;
  initialLinkedObjects?: TIdNameTypeObjectType[];
  initialLinkedObjectsTitle?: string;
  doShowRecentActivity?: boolean;
  doShowCreateButton?: boolean;
  onCreateClick?: (text: string) => void;
  searchInputPlaceholder?: string;
  extraClassNames?: {
    objectSearchMainElement?: string;
    objectSearchInputElement?: string;
    searchPopupContentResults?: string;
  };
  dontInputAutoFocus?: boolean;
  useDragAndDropProps?: TUseDragAndDrop;
  doShowResultsContainerOnBlur?: boolean;
  isObjectSearchResultsElementActive?: boolean;
  setIsObjectResultsElementActive?: (
    isObjectSearchResultsElementActive: boolean
  ) => void;
  isLinksWindowSearchBarResultItem?: boolean;
  minimizeAllWindows?: (exceptId?: string) => void;
  moreActionsDropdownPopoverDataIdentifier?: string;
  openReferenceModal?: (objectId: string, objectType: ObjectTypeEnum) => void;
};

export const ObjectSearchPopupContent: FC<TObjectSearchPopupContentProps> = ({
  currentObjectId,
  initialLinkedObjects,
  initialLinkedObjectsTitle,
  doShowRecentActivity,
  doShowCreateButton,
  doShowResultsContainerOnBlur,
  onElementClick,
  onCreateClick,
  searchInputPlaceholder,
  extraClassNames = {},
  dontInputAutoFocus,
  useDragAndDropProps,
  isObjectSearchResultsElementActive,
  setIsObjectResultsElementActive,
  isLinksWindowSearchBarResultItem,
  minimizeAllWindows,
  moreActionsDropdownPopoverDataIdentifier,
  openReferenceModal,
}) => {
  // Constants
  moreActionsDropdownPopoverDataIdentifier ??=
    GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER;

  // Context
  const { mySimpleRecentActivity } = useContext(RecentActivityContext);
  const { auth } = useContext(AuthContext);

  // Ref
  const lastSearchQueryRef = useRef<string | undefined>(undefined);
  const currentSearchQueryTextValue = useRef<string>("");
  const isSearchRunningRef = useRef<boolean>(false);
  const objectSearchPopupContentResultsContainer = useRef<HTMLDivElement>(null);

  // State
  const [searchResults, setSearchResults] =
    useState<TIdNameTypeObjectTypeCreatedBy[] | undefined>(undefined);
  const [isInputElementOnFocus, setIsInputElementOnFocus] =
    useState<boolean>(false);

  // Custom hooks
  useClickOutsideRef(
    objectSearchPopupContentResultsContainer,
    () => {
      setIsObjectResultsElementActive
        ? setIsObjectResultsElementActive(false)
        : null;
    },
    [],
    moreActionsDropdownPopoverDataIdentifier
  );

  // Memo
  // get search input placeholder
  const searchTextBoxPlaceholder = useMemo((): string => {
    // if a search input placeholder is given
    if (searchInputPlaceholder) {
      // return it
      return searchInputPlaceholder;
    }

    // otherwise, return default search input placeholder
    return "Search for entity or study";
  }, [searchInputPlaceholder]);

  // Logic
  const runSearchAsync = async (
    searchQueryValue: string,
    objectId?: string
  ): Promise<void> => {
    // safety-checks
    if (!searchQueryValue) {
      // clear the search results
      setSearchResults(undefined);
      // stop execution
      return;
    }

    // run search
    const results: TIdNameTypeObjectTypeCreatedBy[] =
      await SearchControllerSingleton.searchMultipleObjectsAsync(
        searchQueryValue,
        [ObjectTypeEnum.Study, ObjectTypeEnum.Entity],
        objectId,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      );

    // set the search results
    setSearchResults(results && results.length > 0 ? results : []);

    // set is search running to false
    isSearchRunningRef.current = false;

    // if a last search query is set and different from the last one executed
    if (
      lastSearchQueryRef.current !== undefined &&
      lastSearchQueryRef.current !== searchQueryValue
    ) {
      // then run the search again with the last search query
      await onTextTypedAsync(lastSearchQueryRef.current, objectId);
    }
  };

  const onTextTypedAsync = async (
    text: string,
    objectId?: string
  ): Promise<void> => {
    // safety-checks
    if (!text) {
      // clear the search results
      setSearchResults(undefined);
      // stop execution
      return;
    }

    // set the current search query text value
    currentSearchQueryTextValue.current = text;

    // if a search is already running, do not run another one
    // and keep the last search query
    if (isSearchRunningRef.current) {
      lastSearchQueryRef.current = text;
      return;
    }

    // set is search running to true
    isSearchRunningRef.current = true;
    // set the last search query ref to undefined
    lastSearchQueryRef.current = undefined;

    // run search
    await runSearchAsync(text, objectId);
  };

  const moreActionsDropdownOnCloseCallback = (): void => {
    // if set is object results element active is set
    if (setIsObjectResultsElementActive) {
      // set it to false
      setIsObjectResultsElementActive(false);
    }
  };

  return (
    <div className={styles.objectSearchPopupContentContainer}>
      <FindestTextBox
        onChange={(text: string) => {
          onTextTypedAsync(text, currentObjectId);
        }}
        value={currentSearchQueryTextValue.current}
        leftIcon={faSearch}
        placeholder={searchTextBoxPlaceholder}
        showEmptyInputCrossIcon={true}
        doAutoFocus={dontInputAutoFocus ? false : true}
        extraClassName={
          extraClassNames.objectSearchMainElement &&
          (isInputElementOnFocus || isObjectSearchResultsElementActive)
            ? extraClassNames.objectSearchMainElement
            : ""
        }
        extraClassNameInputElement={
          extraClassNames.objectSearchInputElement
            ? extraClassNames.objectSearchInputElement
            : styles.objectSearchPopupContentSearchbar
        }
        onInputFocus={(event: FocusEvent) => {
          event.preventDefault();
          event.stopPropagation();
          setIsInputElementOnFocus(true);
        }}
        onBlur={() => {
          setIsInputElementOnFocus(false);
        }}
      />
      <div
        className={
          extraClassNames.searchPopupContentResults
            ? extraClassNames.searchPopupContentResults
            : styles.objectSearchPopupContentResults
        }
        onMouseDown={
          doShowResultsContainerOnBlur
            ? () => {
                setIsObjectResultsElementActive
                  ? setIsObjectResultsElementActive(true)
                  : null;
              }
            : undefined
        }
        ref={objectSearchPopupContentResultsContainer}
      >
        {searchResults && searchResults.length > 0 ? (
          <>
            <h6>Search results</h6>
            {searchResults.map((searchResult) => (
              <ListObjectItem
                key={searchResult.id}
                onClick={
                  onElementClick
                    ? () => {
                        onElementClick(searchResult);
                      }
                    : undefined
                }
                iconHasColor={true}
                object={searchResult}
                subItemType={
                  searchResult.customTypeName
                    ? searchResult.customTypeName
                    : searchResult.type
                }
                useDragAndDropProps={useDragAndDropProps}
                isCreatedByCurrentUser={
                  searchResult.createdBy === auth.userEmail
                }
                isLinksWindowSearchBarResultItem={
                  isLinksWindowSearchBarResultItem
                }
                setIsObjectResultsElementActive={
                  setIsObjectResultsElementActive
                }
                minimizeAllWindows={minimizeAllWindows}
                moreActionsDropdownOnCloseCallback={
                  moreActionsDropdownOnCloseCallback
                }
                moreActionsDropdownPopoverDataIdentifier={
                  moreActionsDropdownPopoverDataIdentifier
                }
                openReferenceModal={openReferenceModal}
              />
            ))}
          </>
        ) : (
          <div>
            {searchResults !== undefined ? (
              <p className={styles.objectSearchPopupContentNoResults}>
                No results found
              </p>
            ) : (
              <>
                {initialLinkedObjectsTitle &&
                  initialLinkedObjects &&
                  initialLinkedObjects.length > 0 && (
                    <>
                      <h6>{initialLinkedObjectsTitle}</h6>
                      {initialLinkedObjects?.map((linkedObject) => (
                        <ListObjectItem
                          key={linkedObject.id}
                          onClick={
                            onElementClick
                              ? () => {
                                  onElementClick(linkedObject);
                                }
                              : undefined
                          }
                          iconHasColor={true}
                          object={linkedObject}
                          subItemType={
                            linkedObject.customTypeName
                              ? linkedObject.customTypeName
                              : linkedObject.type
                          }
                          useDragAndDropProps={useDragAndDropProps}
                          isLinksWindowSearchBarResultItem={
                            isLinksWindowSearchBarResultItem
                          }
                          setIsObjectResultsElementActive={
                            setIsObjectResultsElementActive
                          }
                          minimizeAllWindows={minimizeAllWindows}
                          moreActionsDropdownOnCloseCallback={
                            moreActionsDropdownOnCloseCallback
                          }
                          moreActionsDropdownPopoverDataIdentifier={
                            moreActionsDropdownPopoverDataIdentifier
                          }
                          openReferenceModal={openReferenceModal}
                        />
                      ))}
                    </>
                  )}
                {doShowRecentActivity && mySimpleRecentActivity.length > 0 && (
                  <>
                    <h6>Recent activity</h6>
                    {mySimpleRecentActivity.map((recentActivity) => (
                      <div key={recentActivity.id}>
                        <ListObjectItem
                          onClick={
                            onElementClick
                              ? () => {
                                  onElementClick(
                                    fromTRecentSidebarActivityItemDTO(
                                      recentActivity
                                    )
                                  );
                                }
                              : undefined
                          }
                          iconHasColor={true}
                          object={fromTRecentSidebarActivityItemDTO(
                            recentActivity
                          )}
                          subItemType={
                            recentActivity.customType
                              ? recentActivity.customType
                              : recentActivity.fullType
                          }
                          useDragAndDropProps={useDragAndDropProps}
                          isCreatedByCurrentUser={
                            recentActivity.objectCreatedBy === auth.userEmail
                          }
                          isLinksWindowSearchBarResultItem={
                            isLinksWindowSearchBarResultItem
                          }
                          setIsObjectResultsElementActive={
                            setIsObjectResultsElementActive
                          }
                          minimizeAllWindows={minimizeAllWindows}
                          moreActionsDropdownOnCloseCallback={
                            moreActionsDropdownOnCloseCallback
                          }
                          moreActionsDropdownPopoverDataIdentifier={
                            moreActionsDropdownPopoverDataIdentifier
                          }
                          openReferenceModal={openReferenceModal}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {doShowCreateButton && (
        <FindestButton
          title="Create new entity"
          onClick={
            onCreateClick
              ? () => {
                  onCreateClick(currentSearchQueryTextValue.current);
                }
              : undefined
          }
          extraClassName={styles.createNewButton}
          buttonType={"secondary"}
        />
      )}
    </div>
  );
};
