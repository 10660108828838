// node_modules
import { BubbleMenu } from "@tiptap/react";
import { FC, useContext } from "react";
// Providers
import { AuthContext, EditorContext } from "Providers";
// Components
import { EditorMenu } from "./EditorMenu";
// Helpers
import { ADVANCED_GROUP, DO_COMMANDS } from "Helpers";
// Styles
import editorMenuStyles from "./editorMenu.module.scss";

export const EditorFloatingSelectMenu: FC = () => {
  const {
    editor,
    isEditOn,
    setIsAddImageModalOpen,
    openFileUploadModal,
    setIsOverviewTableModalOpen,
  } = useContext(EditorContext);
  const { hasAdvanced } = useContext(AuthContext);

  return (
    <BubbleMenu
      className={editorMenuStyles.bubbleMenuContainer}
      pluginKey="floatingSelectMenu"
      editor={editor}
    >
      {isEditOn ? (
        <EditorMenu
          editor={editor}
          hasAdvanced={hasAdvanced}
          hiddenCommands={[...DO_COMMANDS, ...ADVANCED_GROUP.commands]}
          setIsAddImageModalOpen={setIsAddImageModalOpen}
          openFileUploadModal={openFileUploadModal}
          setIsOverviewTableModalOpen={setIsOverviewTableModalOpen}
        />
      ) : (
        <div></div>
      )}
    </BubbleMenu>
  );
};
