// Types
import { TExplorerObjectItem, TLinkGraphNodeDTO } from "Types";

export class LinkGraphHelper {
  // recursive function to get children ids in link graph nodes
  public getChildrenIds(
    linkGraphNodes: TLinkGraphNodeDTO[],
    doOnlyFirstLevel = false
  ): Set<string> {
    // go through each link graph node
    return linkGraphNodes.reduce(
      (objectIds: Set<string>, linkGraphNode: TLinkGraphNodeDTO) => {
        // add the current link graph node id to the object ids
        objectIds.add(linkGraphNode.id);

        // if we only want the first level object ids, return the object ids
        if (doOnlyFirstLevel) return objectIds;

        // get object ids in explorer for the lower level nodes of the current link graph node
        this.getChildrenIds(linkGraphNode.lowerLevelNodes).forEach((id) =>
          objectIds.add(id)
        );

        // return the object ids
        return objectIds;
      },
      new Set<string>()
    );
  }

  public getDeepestLevelNumberFromLinkGraphNode = (
    linkGraphNode: TLinkGraphNodeDTO,
    currentDepth: number
  ): number => {
    // safety-checks
    if (!linkGraphNode.lowerLevelNodes) {
      // return 0
      return currentDepth;
    }

    // init deepest level number
    let maxDeepestLevelNumber = currentDepth;

    // for each lower level node
    linkGraphNode.lowerLevelNodes.forEach(
      (lowerLevelNode: TLinkGraphNodeDTO) => {
        // get deepest level number from lower level node
        const deepestLevelNumber = this.getDeepestLevelNumberFromLinkGraphNode(
          lowerLevelNode,
          currentDepth + 1
        );

        // if deepest level number is greater than max deepest level number
        if (deepestLevelNumber > maxDeepestLevelNumber) {
          // set max deepest level number to deepest level number
          maxDeepestLevelNumber = deepestLevelNumber;
        }
      }
    );

    // return max deepest level number
    return maxDeepestLevelNumber;
  };

  public setLowerLevelNodesCountPerNodeId = (
    linkGraphNode: TLinkGraphNodeDTO,
    lowerLevelNodesCountPerNodeId: Map<string, number>
  ): void => {
    // safety-checks
    if (!linkGraphNode.lowerLevelNodes) {
      // do nothing, return
      return;
    }

    // set lower level nodes count per node id for link graph node
    lowerLevelNodesCountPerNodeId.set(
      linkGraphNode.id,
      linkGraphNode.lowerLevelNodes.length
    );

    // for each lower level node
    linkGraphNode.lowerLevelNodes.forEach(
      (lowerLevelNode: TLinkGraphNodeDTO) => {
        // set lower level nodes count per node id for lower level node
        lowerLevelNodesCountPerNodeId.set(
          lowerLevelNode.id,
          lowerLevelNode.lowerLevelNodes
            ? lowerLevelNode.lowerLevelNodes.length
            : 0
        );

        // set lower level nodes count per node id for lower level node
        this.setLowerLevelNodesCountPerNodeId(
          lowerLevelNode,
          lowerLevelNodesCountPerNodeId
        );
      }
    );
  };

  /** Add child to parent */
  public addChildToParent(
    currentItems: TExplorerObjectItem[],
    parentId: string,
    child: TExplorerObjectItem
  ) {
    // go through each current item
    return currentItems.map((currentItem: TExplorerObjectItem) => {
      // if the current item is the one we are looking for
      if (
        currentItem.id === parentId &&
        !currentItem.lowerLevelNodes.find(
          (lln: TExplorerObjectItem) => lln.id === child.id
        )
      ) {
        // add the child to the current item's lower level nodes
        currentItem.lowerLevelNodes = [...currentItem.lowerLevelNodes, child];
        // set the current item as expanded since it has at least one child now
        currentItem.isCollapsed = false;
      } else {
        // add child to the current item's children
        currentItem.lowerLevelNodes = this.addChildToParent(
          currentItem.lowerLevelNodes,
          parentId,
          child
        );
      }

      // return the current item
      return currentItem;
    });
  }

  public removeChildFromParent(
    currentItems: TLinkGraphNodeDTO[],
    parentId: string,
    childId: string
  ) {
    // go through each current item
    return currentItems.map((currentItem: TLinkGraphNodeDTO) => {
      // if the current item is the one we are looking for
      if (currentItem.id === parentId) {
        // remove the child from the current item's lower level nodes
        currentItem.lowerLevelNodes = [
          ...currentItem.lowerLevelNodes.filter(
            (lln: TLinkGraphNodeDTO) => lln.id !== childId
          ),
        ];
      } else {
        // remove child from the current item's children
        currentItem.lowerLevelNodes = this.removeChildFromParent(
          currentItem.lowerLevelNodes,
          parentId,
          childId
        );
      }

      // return the current item
      return currentItem;
    });
  }

  public removeItem(currentItems: TLinkGraphNodeDTO[], itemId: string) {
    // go through each current item
    return currentItems.filter((currentItem: TLinkGraphNodeDTO) => {
      // if the current item is the one we are looking for
      if (currentItem.id === itemId) {
        // remove the current item
        return false;
      } else {
        // remove item from the current item's children
        currentItem.lowerLevelNodes = this.removeItem(
          currentItem.lowerLevelNodes,
          itemId
        );
      }

      // return the current item
      return true;
    });
  }

  /** function to get explorer object item by id */
  public getExplorerObjectItemById(
    id: string | undefined,
    explorerObjectItems: TExplorerObjectItem[] | undefined
  ): TExplorerObjectItem | undefined {
    // try to find explorer object item by id on the current level
    const explorerObjectItemToFind: TExplorerObjectItem | undefined =
      explorerObjectItems?.find((eoi: TExplorerObjectItem) => eoi.id === id);

    // if explorer object item is not found
    if (!explorerObjectItemToFind) {
      // go through each explorer object item
      for (const explorerObjectItem of explorerObjectItems ?? []) {
        // recursively search in lower level nodes
        const explorerObjectItemFound = this.getExplorerObjectItemById(
          id,
          explorerObjectItem.lowerLevelNodes
        );

        // if explorer object item is found, return it
        if (explorerObjectItemFound) return explorerObjectItemFound;
      }
    }

    // return explorer object item
    return explorerObjectItemToFind;
  }

  // helper function to map isCollapsed property recursively
  public mapIsCollapsed = (
    currentListItems: TExplorerObjectItem[],
    isCollapsedPerListItemId: Map<string, boolean | undefined>
  ): void => {
    for (const currentListItem of currentListItems) {
      isCollapsedPerListItemId.set(
        currentListItem.id,
        currentListItem.isCollapsed
      );
      if (currentListItem.lowerLevelNodes) {
        this.mapIsCollapsed(
          currentListItem.lowerLevelNodes,
          isCollapsedPerListItemId
        );
      }
    }
  };

  // helper function to set isCollapsed recursively
  public setIsCollapsed = (
    currentListItems: TExplorerObjectItem[],
    isCollapsedPerListItemId: Map<string, boolean | undefined>
  ): void => {
    for (const currentListItem of currentListItems) {
      if (isCollapsedPerListItemId.has(currentListItem.id)) {
        currentListItem.isCollapsed = isCollapsedPerListItemId.get(
          currentListItem.id
        );
      }
      if (currentListItem.lowerLevelNodes) {
        this.setIsCollapsed(
          currentListItem.lowerLevelNodes,
          isCollapsedPerListItemId
        );
      }
    }
  };

  // helper function to extract unique children parent ids
  public extractUniqueChildrenParentIds = (
    newParents: TExplorerObjectItem[]
  ): Set<string> => {
    const uniqueChildrenParentIds = new Set<string>();
    if (newParents.length > 1) {
      for (const newParent of newParents) {
        let isUnique = true;
        for (const otherNewParent of newParents) {
          if (newParent.id === otherNewParent.id) continue;
          for (const child of newParent.lowerLevelNodes) {
            if (
              LinkGraphHelperSingleton.getChildrenIds(
                otherNewParent.lowerLevelNodes,
                true
              ).has(child.id)
            ) {
              isUnique = false;
              break;
            }
          }
          if (!isUnique) break;
        }
        if (isUnique) uniqueChildrenParentIds.add(newParent.id);
      }
    }
    return uniqueChildrenParentIds;
  };

  // helper function to get new parents common children
  public getNewParentsCommonChildren = (
    newParents: TExplorerObjectItem[]
  ): TExplorerObjectItem[] => {
    const newParentsCommonChildren: TExplorerObjectItem[] = [];
    for (const parent of newParents) {
      for (const child of parent.lowerLevelNodes) {
        if (
          newParents.every((newParent) =>
            LinkGraphHelperSingleton.getChildrenIds(
              newParent.lowerLevelNodes,
              true
            ).has(child.id)
          ) &&
          !newParentsCommonChildren.some((c) => c.id === child.id)
        ) {
          newParentsCommonChildren.push(child);
        }
      }
    }
    return newParentsCommonChildren;
  };
}

export const LinkGraphHelperSingleton = new LinkGraphHelper();
