// node_modules
import { Editor } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import { NodePos } from "@tiptap/react";
import { Mark, Node } from "@tiptap/pm/model";
import { useCallback } from "react";
// Controllers
import {
  ImageControllerSingleton,
  RatingControllerSingleton,
  SavedFileControllerSingleton,
} from "Controllers";
// Types
import {
  TEntityDetailDTO,
  TGetAllRatingsOfObjectDTO,
  TImageDTO,
  TSavedFileDTO,
} from "Types";
// Helpers
import {
  RatingMarkExtension,
  SET_FILE_AT_NODE_POS_COMMAND,
  SET_IMAGE_AT_NODE_POS_COMMAND,
  SET_RATING_AT_NODE_POS_COMMAND,
} from "Helpers";
// Providers
import { CustomImage } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";

export const useLoadURL = () => {
  const loadImagesAsync = useCallback(async (editor: Editor): Promise<void> => {
    const imageNodePos: NodePos[] | null = editor.$nodes(CustomImage.name);
    if (!imageNodePos) return;

    const imageIds: string[] = imageNodePos
      .filter((nodePos: NodePos) => {
        if (!nodePos.node.attrs.id) return false;
        return true;
      })
      .map((nodePos: NodePos) => nodePos.node.attrs.id);

    if (!imageIds || imageIds.length <= 0) return;

    const images: TImageDTO[] = await ImageControllerSingleton.getByIdsAsync(
      imageIds
    );

    imageNodePos.forEach((nodePos: NodePos) => {
      const image: TImageDTO | undefined = images.find(
        (img: TImageDTO) => img.id === nodePos.node.attrs.id
      );

      if (!image) return;

      SET_IMAGE_AT_NODE_POS_COMMAND.action(editor, {
        nodePos: nodePos.pos - 1, // - 1 cause of the known issue https://github.com/ueberdosis/tiptap/issues/5314
        imageUrl: image.path,
        imageId: image.id,
        content: nodePos.node.content.toJSON(),
      });
    });
  }, []);

  const loadFilesAsync = useCallback(async (editor: Editor): Promise<void> => {
    const fileLinkData: { mark: Mark; node: Node; nodePos: number }[] = [];

    editor.state.doc.descendants((node: Node, pos: number) => {
      const fileLinkMark: Mark | undefined = node.marks.find(
        (mark) =>
          mark.type.name === Link.name &&
          mark.attrs.type === ObjectTypeEnum.File &&
          mark.attrs.id
      );

      if (fileLinkMark) {
        fileLinkData.push({ mark: fileLinkMark, node, nodePos: pos });
      }
    });

    if (!fileLinkData || fileLinkData.length <= 0) return;

    const files: TSavedFileDTO[] =
      await SavedFileControllerSingleton.getByIdsAsync(
        fileLinkData.map(({ mark }: { mark: Mark }) => mark.attrs.id)
      );

    fileLinkData.forEach(
      ({
        mark,
        node,
        nodePos,
      }: {
        mark: Mark;
        node: Node;
        nodePos: number;
      }) => {
        const savedFile: TSavedFileDTO | undefined = files.find(
          (file: TSavedFileDTO) => file.id === mark.attrs.id
        );
        if (!savedFile) return;

        SET_FILE_AT_NODE_POS_COMMAND.action(editor, {
          nodePos: nodePos,
          toNodePos: nodePos + node.nodeSize,
          url: savedFile.url,
          objectId: savedFile.id,
          objectType: ObjectTypeEnum.File,
        });
      }
    );
  }, []);

  const loadRatingsAsync = useCallback(
    async (
      editor: Editor,
      sourceId: string,
      sourceType: ObjectTypeEnum
    ): Promise<void> => {
      const ratingData: { mark: Mark; nodePos: number }[] = [];

      editor.state.doc.descendants((node: Node, pos: number) => {
        const ratingMark: Mark | undefined = node.marks.find(
          (mark) =>
            mark.type.name === RatingMarkExtension.name &&
            mark.attrs.sourceType === sourceType &&
            mark.attrs.sourceId === sourceId
        );

        if (ratingMark) {
          ratingData.push({ mark: ratingMark, nodePos: pos });
        }
      });

      if (!ratingData || ratingData.length <= 0) return;

      const ratingOverview: TGetAllRatingsOfObjectDTO | undefined =
        await RatingControllerSingleton.getOverviewAsync(
          `${sourceType}`,
          sourceId
        );

      if (
        !ratingOverview ||
        !ratingOverview.overview ||
        !ratingOverview.overview.entities ||
        ratingOverview.overview.entities.length <= 0
      ) {
        return;
      }

      ratingData.forEach(
        ({ mark, nodePos }: { mark: Mark; nodePos: number }) => {
          const rating: TEntityDetailDTO | undefined =
            ratingOverview?.overview.entities.find(
              (entity: TEntityDetailDTO) =>
                entity.objectId === mark.attrs.targetId
            );
          if (!rating) return;

          SET_RATING_AT_NODE_POS_COMMAND.action(editor, {
            nodePos: nodePos,
            rating: rating.averageRating.score,
            ratersCount: rating.averageRating.count,
            isRatingNeeded: !rating.isRatedByCurrentUser,
            objectId: sourceId,
            objectType: sourceType,
            targetId: rating.objectId,
            metaKey: "doNotTriggerUpdate",
            metaValue: true,
          });
        }
      );
    },
    []
  );

  return {
    loadImagesAsync,
    loadFilesAsync,
    loadRatingsAsync,
  };
};
