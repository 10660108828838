// node_modules
import { faCircle, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { TextSelection } from "@tiptap/pm/state";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Heading } from "@tiptap/extension-heading";
// Components
import { CollapsibleList, FindestButton } from "Components";
import { TableOfContentsItem } from "./TableOfContentsItem";
// Contexts
import { AuthContext, EditorContext, LinksContext } from "Providers";
// Interfaces
import { ICollapsibleListItem } from "Interfaces";
import { IntakeSheetNodeExtension } from "Helpers";
// Styles
import rightSidebarStyles from "../rightSidebar.module.scss";
import styles from "./tableOfContents.module.scss";
// Custom hooks
import { useRating } from "Hooks";
// Constants
import { EditorConstants, FeatureToggleConstants, LinkingConstants } from "Constants";

type TTableOfContentsProps = {
  onCollapseButtonClick: () => void;
};

export const TableOfContents: FC<TTableOfContentsProps> = ({
  onCollapseButtonClick,
}: TTableOfContentsProps) => {
  const [collapsedItems, setCollapsedItems] = useState<Set<string>>(
    new Set<string>()
  );

  const { getRatingState } = useRating({});

  const {
    tableOfContents,
    editor,
    staticTOCItems,
    objectEdited,
    scrollingParentElement,
    savedDocumentsCount,
    setStaticTOCItems,
    updateTOCItems,
    setTableOfContents,
    scrollToItem
  } = useContext(EditorContext);
  const { requirementsTable, maturityRadar } = useContext(LinksContext);
  const { hasAdvanced } = useContext(AuthContext);

  const objectId = objectEdited?.id ?? "";
  const connectedQueriesHeaderElementId = `${LinkingConstants.CONNECTED_QUERIES_HEADER_ID}_${objectId}`;
  const linkedDocumentsHeaderElementId = `${LinkingConstants.LINKED_DOCUMENTS_HEADER_ID}_${objectId}`;
  const pageCommentsHeaderElementId = `${LinkingConstants.PAGE_COMMENTS_HEADER_ID}_${objectId}`;
  const maturityRadarHeaderElementId = `${LinkingConstants.MATURITY_RADAR_HEADER_ID}_${objectId}`;
  const requirementsTableHeaderElementId = `${LinkingConstants.REQUIREMENTS_TABLE_HEADER_ID}_${objectId}`;

  const didTOCRender = useRef(false);

  useEffect(() => {
    setStaticTOCItems((prevStaticHeaders) => {
      // Create a map of existing headers by ID for quick lookup
      const existingHeaderMap = new Map(prevStaticHeaders.map(header => [header.id, header]));
  
      // Helper function to merge header properties if it already exists
      const getOrCreateHeader = (id: string, level: number, title: string): ICollapsibleListItem => {
        if (existingHeaderMap.has(id)) {
          // Merge and return existing header
          return { ...existingHeaderMap.get(id), level, title, id };
        }
        // Create new header if it doesn't exist
        return { id, level, title };
      };
  
      // Collect new static headers
      const newStaticHeaders: ICollapsibleListItem[] = [];
  
      if (FeatureToggleConstants.RequirementsTable && requirementsTable?.tableRows && requirementsTable.tableRows.length > 0) {
        newStaticHeaders.push(getOrCreateHeader(requirementsTableHeaderElementId, 1, requirementsTable.title ?? "Requirements table"));
      }
  
      if (FeatureToggleConstants.MaturityRadar && maturityRadar?.assessments && maturityRadar.assessments.length > 0) {
        newStaticHeaders.push(getOrCreateHeader(maturityRadarHeaderElementId, 1, maturityRadar.title ?? "Maturity radar"));
      }
  
      if (savedDocumentsCount > 0) {
        newStaticHeaders.push(getOrCreateHeader(linkedDocumentsHeaderElementId, 1, "Linked documents"));
      }
  
      if (hasAdvanced) {
        newStaticHeaders.push(getOrCreateHeader(connectedQueriesHeaderElementId, 1, "Connected queries"));
      }
  
      newStaticHeaders.push(getOrCreateHeader(pageCommentsHeaderElementId, 1, "Page comments"));
  
      return newStaticHeaders;
    });
  }, [
    connectedQueriesHeaderElementId,
    hasAdvanced,
    linkedDocumentsHeaderElementId,
    maturityRadar?.assessments,
    maturityRadar?.title,
    maturityRadarHeaderElementId,
    pageCommentsHeaderElementId,
    requirementsTable?.tableRows,
    requirementsTable?.title,
    requirementsTableHeaderElementId,
    savedDocumentsCount,
    setStaticTOCItems
  ]);

  useEffect(() => {
    // set first item active by default
    if (tableOfContents?.content && tableOfContents?.content?.length > 0 && !didTOCRender.current) {
      setTableOfContents({
        ...tableOfContents,
        content: tableOfContents.content.map((header, index) => {
          if (index === 0) {
            return {
              ...header,
              isActive: true,
            };
          }
          return header;
        })
      });
      didTOCRender.current = true;
    }
  }, [updateTOCItems, tableOfContents, setTableOfContents]);

  const shouldShowToCExplanation =
    !tableOfContents ||
    tableOfContents.content?.filter((header) => !header.id.startsWith("js-"))
      .length === 0;

  const collapsibleListItems = useMemo((): ICollapsibleListItem[] => {
    if (tableOfContents?.content.length === 0 || !editor || !objectEdited) {
      return [];
    }
    const ratingState: {
      ratingsDoneCount: number;
      ratingsToDoCount: number;
    } = getRatingState(editor, objectEdited.id, objectEdited.objectType);
    const root: ICollapsibleListItem[] = [];
    let lastLevel1Item: ICollapsibleListItem | null = null;
    let lastLevel2Item: ICollapsibleListItem | null = null;

    let lastHeader = "";
    let intakeSheetFound = false;
    let intakeSheetConfirmStatus = false;
    editor?.state.doc.descendants((node) => {
      if (
        node.textContent &&
        node.type.name === Heading.name &&
        !intakeSheetFound
      ) {
        lastHeader = node.textContent;
      }
      if (node.type.name === IntakeSheetNodeExtension.name) {
        intakeSheetFound = true;
        intakeSheetConfirmStatus = node.attrs.confirmed;
        return false;
      }
    });

    tableOfContents?.content.forEach((item) => {
      const newItem: ICollapsibleListItem = {
        id: item.id,
        title: item.textContent,
        children: [],
        level: item.originalLevel,
        isActive: item.isActive,
        ...(item.textContent
          .toLowerCase()
          .includes(
            EditorConstants.OVERVIEW_TABLE_HEADING_TEXT.toLowerCase()
          ) && ratingState.ratingsToDoCount > 0
          ? {
              rightIcon: {
                icon: faCircle,
                className: styles.ratingProgressIcon,
              },
            }
          : {}),
      };

      switch (item.originalLevel) {
        case 1:
          root.push(newItem);
          lastLevel1Item = newItem;
          lastLevel2Item = null;
          break;
        case 2:
          if (lastLevel1Item) {
            lastLevel1Item.children?.push(newItem);
          } else {
            root.push(newItem);
          }
          lastLevel2Item = newItem;
          break;
        case 3:
          if (lastLevel2Item) {
            lastLevel2Item.children?.push(newItem);
          } else if (lastLevel1Item) {
            lastLevel1Item.children?.push(newItem);
          } else {
            root.push(newItem);
          }
          break;
        default:
          root.push(newItem);
          break;
      }
      if (lastHeader === item.textContent && intakeSheetFound) {
        const intakeSheetItem: ICollapsibleListItem = {
          id: "intake-sheet-confirmation",
          title: "Confirmation",
          children: [],
          level: item.originalLevel,
          rightIcon: {
            icon: faCircle,
            className: intakeSheetConfirmStatus
              ? styles.confirmedIcon
              : styles.unConfirmedIcon,
          },
        };
        root.push(intakeSheetItem);
      }
    });

    return root;
  }, [editor, getRatingState, objectEdited, tableOfContents?.content]);

  /** Toggles the clicked table of content item */
  const handleToggle = (id: string) => {
    setCollapsedItems((prev) => {
      const newState = new Set(prev);

      if (newState.has(id)) {
        newState.delete(id);
      } else {
        newState.add(id);
      }

      return newState;
    });
  };

  /** Scrolls to the selected header */
  const handleClick = (id: string) => {
    if (editor) {
      const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);
      if (!element || !scrollingParentElement) {
        return;
      }
      const pos = editor.view.posAtDOM(element, 0);

      // set focus
      const tr = editor.view.state.tr;

      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

      editor.view.dispatch(tr);

      editor.view.focus();

      scrollToItem(element, id);
    }
  };

  const handleClickToStaticItem = (id: string) => {
    if (!scrollingParentElement) return;
    const element = scrollingParentElement.querySelector(`#${id}`);
    if (!element) return;
    scrollToItem(element, id);
  };

  return (
    <div className={styles.tableOfContents}>
      <div className={styles.tableOfContentsHeader}>
        <h3>On this page</h3>
        <FindestButton
          leftIconName={faXmark}
          onClick={onCollapseButtonClick}
          titleAttribute="Close sidebar"
          extraClassName={rightSidebarStyles.collapseButton}
          buttonType="tertiary"
        />
      </div>
      <div className={styles.contentContainer}>
        {shouldShowToCExplanation && (
          <h4>
            Headers you add to this page will appear here as table of content.
          </h4>
        )}
        <div className={styles.collapsibleListContainer}>
          <CollapsibleList
            items={collapsibleListItems}
            collapsedItems={collapsedItems}
            onToggle={handleToggle}
            onClick={handleClick}
            ItemRenderer={TableOfContentsItem}
          />
        </div>
        <div className={styles.staticTOCItems}>
          <CollapsibleList
            items={staticTOCItems}
            onClick={handleClickToStaticItem}
            ItemRenderer={TableOfContentsItem}
          />
        </div>
      </div>
    </div>
  );
};
