// node_modules
import axios from "axios";
// Enums
import { EntityTypeEnum, OrderByEnum } from "Enums";
// Helpers
import {
  AxiosHelperSingleton,
  EditorHelperSingleton,
  LogHelperSingleton,
} from "Helpers";
// Types
import { TAxiosParams, TEntitiesDTO, TIdNameType } from "Types";
// Interfaces
import { IEntityDTO, IUndeleteObjectsDTO } from "Interfaces";

export class EntityController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/entity`;

  public async createAsync(
    entityDTO: IEntityDTO,
    logProperties = {}
  ): Promise<IEntityDTO | undefined> {
    try {
      entityDTO.contentAsPlainText =
        EditorHelperSingleton.getContentAsPlainText(entityDTO.description);

      const response = await axios.post<IEntityDTO>(
        `${this._resourcePath}`,
        entityDTO
      );

      if (response) {
        LogHelperSingleton.logWithProperties("CreateEntity", {
          ...logProperties,
        });
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async getAsync(
    orderBy: OrderByEnum,
    fromDate: Date | undefined,
    entityTypes: EntityTypeEnum[],
    isCreatedByMe = false,
    customTypeNames: string[] | undefined = undefined,
    connectedToObjectId?: string,
    doOnlyGetConnectedToObjectId?: boolean
  ): Promise<TEntitiesDTO> {
    try {
      // init url
      const url = `${this._resourcePath}`;
      const axiosParams: TAxiosParams = {
        orderBy: orderBy,
      };

      // put createdByMe in url if it is defined
      if (isCreatedByMe !== undefined) {
        axiosParams.createdByMe = isCreatedByMe;
      }

      // put fromDate in url if it is defined
      if (fromDate) {
        axiosParams.fromDate = fromDate;
      }

      // put connectedToObjectId in url if it is defined
      if (connectedToObjectId) {
        axiosParams.connectedToObjectId = connectedToObjectId;
      }

      // if doOnlyGetConnectedToObjectId is set
      if (doOnlyGetConnectedToObjectId !== undefined) {
        // append the query string parameter
        axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
      }

      // put entityTypes in url if it is defined
      if (entityTypes.length > 0) {
        axiosParams.types = entityTypes;
      }

      // put custom type names in url if it is defined
      if (customTypeNames && customTypeNames.length > 0) {
        axiosParams.customTypeNames = customTypeNames;
      }

      // get entities
      const response = await axios.get<TEntitiesDTO>(url, {
        params: axiosParams,
      });

      if (response && response.data) {
        return response.data;
      } else {
        return {
          entities: [],
          totalEntitiesCount: 0,
        };
      }
    } catch {
      return {
        entities: [],
        totalEntitiesCount: 0,
      };
    }
  }

  public async getByIdAsync(
    id: string,
    doIgnoreIsDeleted?: boolean
  ): Promise<IEntityDTO | undefined> {
    try {
      // init url
      const url = `${this._resourcePath}/${id}`;
      const axiosParams: TAxiosParams = {};

      // if doIgnoreDeleted is set
      if (doIgnoreIsDeleted !== undefined) {
        // append the query string parameter
        axiosParams.doIgnoreIsDeleted = doIgnoreIsDeleted;
      }

      const response = await axios.get<IEntityDTO | undefined>(url, {
        params: axiosParams,
      });

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async updateTitleAsync(
    entityId: string,
    title: string
  ): Promise<IEntityDTO | undefined> {
    try {
      const formData = new FormData();
      formData.append("title", title);

      const response = await axios.put<IEntityDTO | undefined>(
        `${this._resourcePath}/${entityId}/title`,
        formData
      );
      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async getCustomTypeNamesAsync(): Promise<string[] | undefined> {
    try {
      const response = await axios.get<string[] | undefined>(
        `${this._resourcePath}/type/custom`
      );
      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async updateDescriptionAsync(
    entityId: string,
    description: string
  ): Promise<IEntityDTO | undefined> {
    try {
      const formData = new FormData();
      formData.append("description", description);
      formData.append(
        "contentAsPlainText",
        EditorHelperSingleton.getContentAsPlainText(description)
      );

      const response = await axios.put<IEntityDTO | undefined>(
        `${this._resourcePath}/${entityId}/description`,
        formData
      );
      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async updateTypeAsync(
    entityId: string,
    type: EntityTypeEnum,
    customTypeName?: string
  ): Promise<IEntityDTO | undefined> {
    try {
      const formData = new FormData();
      formData.append("type", `${type}`);
      if (customTypeName) {
        formData.append("customTypeName", customTypeName);
      }

      const response = await axios.put<IEntityDTO | undefined>(
        `${this._resourcePath}/${entityId}/type`,
        formData
      );
      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async deleteAsync(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${this._resourcePath}/${id}`);

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async bulkDeleteAsync(ids: string[]): Promise<boolean> {
    try {
      // add ids to url
      const response = await axios.delete(`${this._resourcePath}`, {
        params: {
          ids: ids,
        },
      });

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async convertToStudyAsync(entity: IEntityDTO): Promise<boolean> {
    try {
      const response = await axios.post(
        `${this._resourcePath}/${entity.id}/convertToStudy`,
        {
          contentAsPlainText: EditorHelperSingleton.getContentAsPlainText(
            entity.description
          ),
        }
      );

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async getAllCompactAsync(): Promise<TIdNameType[]> {
    try {
      // get studies
      const response = await axios.get<TIdNameType[]>(
        `${this._resourcePath}/all/compact`
      );

      if (response && response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async getDeletedAsync(
    orderBy: OrderByEnum,
    fromDate: Date | undefined
  ): Promise<TEntitiesDTO> {
    try {
      // init url
      const url = `${this._resourcePath}/deleted`;
      const axiosParams: TAxiosParams = {
        orderBy: orderBy,
      };

      // put fromDate in url if it is defined
      if (fromDate) {
        axiosParams.fromDate = fromDate;
      }

      // get studies
      const response = await axios.get<TEntitiesDTO>(url, {
        params: axiosParams,
      });

      if (response && response.data) {
        return response.data;
      } else {
        return {
          entities: [],
          totalEntitiesCount: 0,
        };
      }
    } catch {
      return {
        entities: [],
        totalEntitiesCount: 0,
      };
    }
  }

  public async getLockedAsync(
    orderBy: OrderByEnum,
    fromDate: Date | undefined
  ): Promise<TEntitiesDTO> {
    try {
      // init url
      const url = `${this._resourcePath}/locked`;
      const axiosParams: TAxiosParams = {
        orderBy: orderBy,
      };

      // put fromDate in url if it is defined
      if (fromDate) {
        axiosParams.fromDate = fromDate;
      }

      // get studies
      const response = await axios.get<TEntitiesDTO>(url, {
        params: axiosParams,
      });

      if (response && response.data) {
        return response.data;
      } else {
        return {
          entities: [],
          totalEntitiesCount: 0,
        };
      }
    } catch {
      return {
        entities: [],
        totalEntitiesCount: 0,
      };
    }
  }

  public async undeleteAsync(
    undeleteObjects: IUndeleteObjectsDTO
  ): Promise<boolean> {
    try {
      // init url
      const url = `${this._resourcePath}`;

      const response = await axios.post(`${url}/undelete`, undeleteObjects);

      if (response) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}

export const EntityControllerSingleton = new EntityController();
