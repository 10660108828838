// node_modules
import { Editor } from "@tiptap/core";
import { BubbleMenu as BaseBubbleMenu } from "@tiptap/react";
import { FC, useCallback, useState } from "react";
// Components
import { EditorLinkPanel } from "./EditorLinkPanel";
import { EditorLinkPreviewPanel } from "./EditorLinkPreviewPanel";
// Helpers
import { LogHelperSingleton, SET_LINK_COMMAND } from "Helpers";
// Styles
import editorMenuStyles from "../editorMenu.module.scss";
import styles from "./editorFloatingLinkMenu.module.scss";
// Enums
import { LogFeatureNameEnum } from "Enums";

interface IEditorFloatingLinkMenuProps {
  editor: Editor;
  appendTo: HTMLElement;
}

export const EditorFloatingLinkMenu: FC<IEditorFloatingLinkMenuProps> = ({
  editor,
  appendTo,
}: IEditorFloatingLinkMenuProps) => {
  const [isEditorLinkPanelShown, setIsEditorLinkPanelShown] = useState(false);

  const { href, id, type } = editor.getAttributes("link");

  const setLink = useCallback(
    (url: string) => {
      SET_LINK_COMMAND.action(editor, { url });

      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-SetLink`);

      setIsEditorLinkPanelShown(false);
    },
    [editor]
  );

  const unsetLink = useCallback(() => {
    SET_LINK_COMMAND.undoaction(editor);
    setIsEditorLinkPanelShown(false);
  }, [editor]);

  return (
    <div>
      <BaseBubbleMenu
        editor={editor}
        pluginKey="linkFloatingMenu"
        shouldShow={() => {
          return editor.isActive("link");
        }}
        updateDelay={0}
        tippyOptions={{
          popperOptions: {
            modifiers: [{ name: "flip", enabled: false }],
          },
          appendTo: () => {
            return appendTo;
          },
          onHidden: () => {
            setIsEditorLinkPanelShown(false);
          },
        }}
        className={editorMenuStyles.bubbleMenuContainer}
      >
        <div className={styles.editorLinkFloatingMenuContainer}>
          {isEditorLinkPanelShown ? (
            <EditorLinkPanel
              initialUrl={href}
              onSave={setLink}
              onCancel={() => {
                setIsEditorLinkPanelShown(false);
              }}
            />
          ) : (
            <>
              {id && type ? null : (
                <EditorLinkPreviewPanel
                  url={href}
                  onRemove={unsetLink}
                  onEdit={() => {
                    setIsEditorLinkPanelShown(true);
                  }}
                />
              )}
            </>
          )}
        </div>
      </BaseBubbleMenu>
    </div>
  );
};
