// node_modules
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
// Components
import { FindestButton, IsFindestAdministrator } from "Components";
// Controllers
import { CachingControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";

export const CachingPage: FC = () => {
  // Custom hooks
  const navigate = useNavigate();

  const handleClearUserTenantCache = async () => {
    const isSuccess = await CachingControllerSingleton.clearUserTenantCache();

    if (isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Success,
        "Cleared user tenant cache."
      );
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to clear user tenant cache."
      );
    }
  };

  return (
    <IsFindestAdministrator>
      <div>
        <div
          onClick={() => {
            navigate("/admin");
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div>
          <FindestButton
            title="Clear user tenant cache"
            onClick={handleClearUserTenantCache}
          />
        </div>
      </div>
    </IsFindestAdministrator>
  );
};
