// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// Font awesome
import {
  faCheck,
  faPlus,
  faThumbTack,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Types"
import { TIdNameTypeObjectType } from "Types";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Controllers
import { FindestButton, Tooltip } from "Components";
import { PinControllerSingleton } from "Controllers";
import { EditorContext, PinnedContext } from "Providers";
// Styles
import styles from "./objectPinElement.module.scss";

type TTopbarPin = {
  id?: string;
  pinType?: string;
  onEditPinList?: () => void;
};
export const ObjectPinElement: FC<TTopbarPin> = ({
  id,
  pinType,
  onEditPinList,
}) => {
  // Contexts
  const { objectEdited } = useContext(EditorContext);
  const { pinnedItems, refreshPins } = useContext(PinnedContext);

  // State
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [isPinnedTooltipOpen, setIsPinnedTooltipOpen] =
    useState<boolean>(false);

  const [isPinned, setIsPinned] = useState(false);
  const [justPinned, setJustPinned] = useState(false);

  // Refs
  const previousObjectIdRef = useRef<string | undefined>(undefined);
  const pinnedItemsRef = useRef<TIdNameTypeObjectType[] | undefined>(undefined);

  const onPinClick = async () => {
    if (!objectEdited) return;

    let isSuccess = false;
    // Add or remove pin based on current state
    if (isPinned) {
      // log
      LogHelperSingleton.log("RemovePinFromObject");
      isSuccess = await PinControllerSingleton.removeAsync(objectEdited.id);
    } else {
      // log
      LogHelperSingleton.log("AddPinToObject");
      isSuccess = await PinControllerSingleton.addAsync(
        objectEdited.id,
        objectEdited.objectType
      );
    }

    // On success toggle the pinned state
    if (isSuccess) {
      setIsPinned(!isPinned);
      setJustPinned(!justPinned);
    }

    // Refresh the pins in the pinned provider
    await refreshPins();
  };

  const onUndoPinClick = () => {
    onPinClick();
    onEditPinList?.();
  };

  const onPinKeyDown = (e: React.KeyboardEvent) => {
    e.key === "Enter" && onPinClick();
  };

  const synchronizePinnedState = useCallback(async () => {
    if (!objectEdited) return;
    setIsPinned(await PinControllerSingleton.isPinnedAsync(objectEdited.id));
  }, [objectEdited]);

  // useEffect
  useEffect(() => {
    if (
      objectEdited &&
      (pinnedItemsRef.current !== pinnedItems ||
        previousObjectIdRef.current !== objectEdited.id)
    ) {
      id &&
        id === objectEdited.id &&
        pinType === "objectBar" &&
        synchronizePinnedState();
      pinnedItemsRef.current = pinnedItems;
      previousObjectIdRef.current = objectEdited.id;
    }
  }, [pinnedItems, objectEdited, id, pinType, synchronizePinnedState]);

  return (
    <div className={styles.objectPinElement}>
      {pinType === "topBar" && objectEdited && (
        <>
          {justPinned && (
            <div className={styles.pinnedPage}>
              <FontAwesomeIcon icon={faCheck} />
              <p>Page is pinned</p>
              <FindestButton
                title="Undo"
                buttonType="secondary"
                onClick={onUndoPinClick}
              />
            </div>
          )}
          {!justPinned && !isPinned && (
            <div className={`${styles.pinnedPage} ${styles.notPinned}`}>
              <FontAwesomeIcon icon={faPlus} onClick={onPinClick} />
              <p
                onClick={onPinClick}
                role="button"
                tabIndex={0}
                onKeyDown={onPinKeyDown}
              >
                Pin current page
              </p>
            </div>
          )}
        </>
      )}
      {pinType === "unpinned" && onEditPinList && (
        <div className={`${styles.pinnedPage} ${styles.unpinnedPage}`}>
          <FontAwesomeIcon icon={faCheck} />
          <p>Page is unpinned</p>
          <FindestButton
            title="Undo"
            buttonType="secondary"
            onClick={onEditPinList}
          />
        </div>
      )}
      {pinType === "pinList" && onEditPinList && (
        <div
          className={styles.listElement}
          ref={setReferenceElement}
          onClick={onEditPinList}
          role="button"
          tabIndex={0}
          onKeyDown={onPinKeyDown}
          onMouseEnter={() => setIsPinnedTooltipOpen(true)}
          onMouseLeave={() => setIsPinnedTooltipOpen(false)}
        >
          <FontAwesomeIcon icon={faThumbTack} />
          <FontAwesomeIcon icon={faXmark} />
          <Tooltip
            referenceEl={referenceElement}
            isOpen={isPinnedTooltipOpen}
            placement="right"
            tooltipText="Unpin"
          />
        </div>
      )}
      {pinType === "objectBar" && (
        <FindestButton
          extraClassName={`${styles.pinButton} ${
            isPinned ? styles.pinned : ""
          }`}
          title="Pin"
          leftIconName={faThumbTack}
          onClick={onPinClick}
        />
      )}
    </div>
  );
};
