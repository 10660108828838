// node_modules
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useResizeObserver from "@react-hook/resize-observer";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor } from "@tiptap/react";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Types
import { THighlightDTO, TOption } from "Types";
// Styles
import styles from "./highlight.module.scss";
// Components
import {
  AnnotationActions,
  CompactEditorMenu,
  Dropdown,
  FindestButton,
  Reference,
  EditorContent,
} from "Components";
// Controllers
import { HighlightControllerSingleton } from "Controllers";
// Enums
import { HighlightTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  CompactEditorExtensionsKit,
  FOCUS_COMMAND,
  GetCustomLink,
  HighlightTypeHelperSingleton,
  LogHelperSingleton,
  SET_CONTENT_COMMAND,
  ToastHelperSingleton,
} from "Helpers";

type THighlightProps = {
  highlight: THighlightDTO;
  extraClassNames?: { highlightContainer?: string };
  doShowType: boolean;
  onDeleteHighlight?: (highlightId: string) => void;
  onEditHighlight?: (editedHighlight: THighlightDTO) => void;
};

export const Highlight: FC<THighlightProps> = ({
  highlight,
  extraClassNames = {},
  doShowType,
  onDeleteHighlight,
  onEditHighlight,
}: THighlightProps) => {
  // Ref
  const divContainerRef = useRef<HTMLDivElement>(null);
  const highlightContainerRef = useRef<HTMLDivElement>(null);

  // State
  const [doesNeedReadMore, setDoesNeedReadMore] = useState<boolean>(false);
  const [isReadMoreExpanded, setIsReadMoreExpanded] = useState<boolean>(false);
  const [editedHighlight, setEditedHighlight] = useState<THighlightDTO>({
    ...highlight,
  });

  const navigate = useNavigate();

  // Parse the JSON description from the database and provide it to TipTap
  const parsedContent = useMemo(() => {
    try {
      return highlight.description ? JSON.parse(highlight.description) : "";
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not display highlight description."
      );
      return highlight.description ?? "";
    }
  }, [highlight.description]);

  // Initialize the TipTap editor
  const highlightEditor = useEditor({
    extensions: [
      ...CompactEditorExtensionsKit,
      Placeholder.configure({
        placeholder: "Highlight description...",
        showOnlyWhenEditable: true,
      }),
      GetCustomLink(navigate),
    ],
    content: parsedContent,
  });

  // Check if we need to force edit mode based on the highlight's properties
  const forceEditMode = useMemo(() => {
    return editedHighlight.doAutoTurnEditModeOn ? true : false;
  }, [editedHighlight]);

  useEffect(() => {
    if (!highlightEditor || !highlightContainerRef.current) return;

    if (forceEditMode) {
      highlightContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      highlightEditor.setEditable(true);

      FOCUS_COMMAND.action(highlightEditor, {
        focusPosition: "start",
        focusOptions: { scrollIntoView: false },
      });
    } else {
      highlightEditor.setEditable(false);
    }
  }, [forceEditMode, highlightEditor]);

  // Logic
  const determineIfReadMoreIsNeeded = useCallback(() => {
    // safety-checks
    if (!divContainerRef.current) {
      return;
    }

    const needsReadMore =
      divContainerRef.current.clientHeight !==
      divContainerRef.current.scrollHeight;
    setDoesNeedReadMore(needsReadMore);
  }, [divContainerRef]);

  useResizeObserver(divContainerRef, determineIfReadMoreIsNeeded);

  useEffect(() => {
    if (!highlightEditor?.isEditable) {
      setIsReadMoreExpanded(false);
    }
    determineIfReadMoreIsNeeded();
  }, [determineIfReadMoreIsNeeded, highlightEditor?.isEditable]);

  // Logic
  const onHighlightTypeChangeHandlerAsync = async (
    newEditedHighlight: THighlightDTO,
    option: TOption<HighlightTypeEnum>
  ) => {
    // If highlight is not editable then do nothing
    if (!onEditHighlight) return;

    newEditedHighlight = { ...newEditedHighlight, type: option.value };

    await HighlightControllerSingleton.updateHighlight(
      newEditedHighlight.id,
      newEditedHighlight
    );

    setEditedHighlight({
      ...newEditedHighlight,
    });

    // call onEditHighlight parent prop callback
    onEditHighlight(newEditedHighlight);

    LogHelperSingleton.log("UpdateHighlightDescription");
  };

  const onDeleteHandler = async () => {
    if (!onDeleteHighlight) return;

    // Confirm with the user that they want to delete the current highlight
    if (!confirm("Are you sure you want to delete this highlight?")) return;

    const isDeleted = await HighlightControllerSingleton.deleteAsync(
      editedHighlight.id
    );

    if (isDeleted) {
      onDeleteHighlight(editedHighlight.id);
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not delete highlight."
      );
    }
  };

  const onCancelAsync = useCallback(() => {
    if (!highlightEditor) return;

    highlightEditor.setEditable(false);
    SET_CONTENT_COMMAND.action(highlightEditor, { content: parsedContent });
  }, [highlightEditor, parsedContent]);

  const onSaveAsync = useCallback(async () => {
    if (!onEditHighlight || !highlightEditor) return;

    const newEditedHighlight: THighlightDTO = {
      ...editedHighlight,
      description:
        JSON.stringify(highlightEditor.getJSON()) ||
        editedHighlight.description,
    };

    const isUpdated = await HighlightControllerSingleton.updateHighlight(
      newEditedHighlight.id,
      newEditedHighlight
    );

    setEditedHighlight({ ...newEditedHighlight });

    if (isUpdated) {
      onEditHighlight(newEditedHighlight);
      highlightEditor.setEditable(false);
    }
  }, [highlightEditor, editedHighlight, onEditHighlight]);

  // safety-checks
  if (!editedHighlight || editedHighlight.description === undefined) {
    return null;
  }

  const toggleReadMore = (): void => {
    setIsReadMoreExpanded(!isReadMoreExpanded);

    LogHelperSingleton.log("ReadMoreOnHighlight");
  };

  const onEditModeChangeHandler = (isInEditMode: boolean): void => {
    if (!onEditHighlight || !highlightEditor) return;

    highlightEditor.setEditable(isInEditMode);

    if (isInEditMode && highlightEditor) {
      SET_CONTENT_COMMAND.action(highlightEditor, { content: parsedContent });

      FOCUS_COMMAND.action(highlightEditor, {
        focusPosition: "end",
        focusOptions: { scrollIntoView: false },
      });

      setIsReadMoreExpanded(true);
    }
  };

  // Render
  return (
    <div
      ref={highlightContainerRef}
      className={[
        styles.highlightContainer,
        extraClassNames.highlightContainer || "",
      ].join(" ")}
    >
      <div className={styles.highlight}>
        {highlightEditor?.isEditable ? undefined : (
          <AnnotationActions
            extraClassNames={{
              annotationActions: styles.highlightAnnotationActions,
            }}
            onDeleteClick={onDeleteHighlight ? onDeleteHandler : undefined}
            onEditClick={
              onEditHighlight ? () => onEditModeChangeHandler(true) : undefined
            }
          />
        )}
        {doShowType && (
          <div className={styles.highlightTypeSelectionDropdown}>
            <Dropdown
              selectedOption={{
                value: editedHighlight.type,
                title:
                  HighlightTypeHelperSingleton.getHighlightTypeDisplayName(
                    editedHighlight
                  ),
              }}
              handleOptionSelect={(option: TOption<HighlightTypeEnum>) =>
                onHighlightTypeChangeHandlerAsync(editedHighlight, option)
              }
              options={
                HighlightTypeHelperSingleton.highlightTypesDropdownOptions
              }
              classNameSelect={styles.classNameSelect}
            />
          </div>
        )}
        <div className={styles.highlightTextContainer}>
          <div
            ref={divContainerRef}
            className={`${styles.highlightText} ${
              isReadMoreExpanded || highlightEditor?.isEditable
                ? styles.unclampText
                : ""
            }`}
          >
            <EditorContent
              editor={highlightEditor}
              className="compactEditorContent"
              content={editedHighlight.description}
            />
          </div>
          {(doesNeedReadMore || isReadMoreExpanded) &&
          !highlightEditor?.isEditable ? (
            <p onClick={toggleReadMore} className={styles.readMore}>
              {isReadMoreExpanded ? (
                <>
                  Collapse text
                  <FontAwesomeIcon icon={faChevronUp} />
                </>
              ) : (
                <>
                  Read more
                  <FontAwesomeIcon icon={faChevronDown} />
                </>
              )}
            </p>
          ) : null}
          {highlightEditor?.isEditable ? (
            <>
              <CompactEditorMenu editor={highlightEditor} />
              <div className={styles.buttonSection}>
                <FindestButton title={"Save"} onClick={onSaveAsync} />
                <FindestButton
                  title={"Cancel"}
                  buttonType="secondary"
                  onClick={onCancelAsync}
                />
              </div>
            </>
          ) : (
            <Reference referenceUrl={editedHighlight.reference} />
          )}
        </div>
      </div>
    </div>
  );
};
