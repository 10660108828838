// node_modules
import { FC, useContext } from "react";
// Providers
import { AuthContext, EditorContext } from "Providers";
// Components
import { EditorMenu } from "./EditorMenu";

export const EditorStickyMenu: FC = () => {
  const {
    editor,
    isEditOn,
    setIsAddImageModalOpen,
    openFileUploadModal,
    setIsOverviewTableModalOpen,
  } = useContext(EditorContext);
  const { hasAdvanced } = useContext(AuthContext);

  return isEditOn ? (
    <EditorMenu
      editor={editor}
      hasAdvanced={hasAdvanced}
      setIsAddImageModalOpen={setIsAddImageModalOpen}
      openFileUploadModal={openFileUploadModal}
      setIsOverviewTableModalOpen={setIsOverviewTableModalOpen}
    />
  ) : null;
};
