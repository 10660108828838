// node_modules
import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useContext, useEffect, useState } from "react";
// Components
import { FindestButton, LoadingStatusIndicator } from "Components";
import { ForceDirectedGraphView } from "./ForceDirectedGraphView";
import { OverviewDetails } from "./OverviewDetails/OverviewDetails";
import { PackGraphView } from "./PackGraphView";
import { RecentActivity } from "./RecentActivity";
import { RecentCompanyActivity } from "./RecentCompanyActivity";
// Contexts
import { AuthContext } from "Providers";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Types
import { TTypeGraphNodeDTO } from "Types";
// Styles
import styles from "./universeOverview.module.scss";
// Enums
import { GraphViewContainerTypeEnum } from "Enums";
// Custom hooks
import { useForceDirectedLinkGraph } from "Hooks";

export const UniverseOverview: FC = () => {
  // State
  const [overviewDetailsPageType, setOverviewDetailsPageType] =
    useState<"Link" | "Pack" | null>(null);
  const [packGraphData, setPackGraphData] =
    useState<TTypeGraphNodeDTO[] | undefined>(undefined);

  // Context
  const { auth } = useContext(AuthContext);

  // Custom hooks
  const forceDirectedLinkGraph = useForceDirectedLinkGraph();

  const loadTypeGraph = useCallback(async () => {
    const allData = await LinkingControllerSingleton.getGraphByTypesAsync();
    setPackGraphData(allData);
  }, []);

  // Effect
  useEffect(() => {
    loadTypeGraph();
  }, [loadTypeGraph]);

  const showDashboardScreen = () => {
    setOverviewDetailsPageType(null);
  };

  const showRelationsGraph = () => {
    setOverviewDetailsPageType("Link");
  };

  const showTypesBreakdownGraph = () => {
    setOverviewDetailsPageType("Pack");
  };

  if (overviewDetailsPageType && forceDirectedLinkGraph) {
    return (
      <OverviewDetails
        packData={packGraphData}
        data={forceDirectedLinkGraph}
        graphType={overviewDetailsPageType}
        goBackHandler={showDashboardScreen}
        setOverviewDetailsPageType={setOverviewDetailsPageType}
      />
    );
  }

  return (
    <div className={styles.universeOverview}>
      <div
        className={`${styles.box} ${styles.left} ${styles.myRecentActivity}`}
      >
        <div className={styles.title}>Pick up where you left off…</div>
        <RecentActivity />
      </div>
      <div className={styles.box}>
        <div className={styles.title}>Relations graph</div>
        <div className={styles.graphBoxContainer}>
          {forceDirectedLinkGraph ? (
            <>
              <ForceDirectedGraphView
                data={forceDirectedLinkGraph}
                searchKeyword=""
                selectedFilterOptions={[]}
                containerType={GraphViewContainerTypeEnum.UniverseOverview}
              />
              <div
                onClick={showRelationsGraph}
                className={styles.hoverContentContainer}
              >
                <FindestButton
                  leftIconName={faLongArrowAltRight}
                  extraClassName={styles.seeRelationsButton}
                  title="See relations graph"
                />
                <div
                  className={styles.hoverText}
                >{`Relations between entities and studies in ${auth.tenantName} Universe`}</div>
              </div>
            </>
          ) : (
            <LoadingStatusIndicator shouldCenter size={32} status={1} />
          )}
        </div>
      </div>
      <div
        className={`${styles.box} ${styles.left} ${styles.myRecentActivity}`}
      >
        <div
          className={styles.title}
        >{`What's happening at ${auth.tenantName}?`}</div>
        <RecentCompanyActivity />
      </div>
      <div className={styles.box}>
        <div className={styles.title}>Page type breakdown</div>
        <div className={styles.graphBoxContainer}>
          {packGraphData ? (
            <>
              <PackGraphView
                data={packGraphData}
                extraClassName={styles.fitToView}
              />
              <div
                onClick={showTypesBreakdownGraph}
                className={styles.hoverContentContainer}
              >
                <FindestButton
                  leftIconName={faLongArrowAltRight}
                  extraClassName={styles.seeItemTypesButton}
                  title="See Page type breakdown"
                />
                <div
                  className={styles.hoverText}
                >{`Presentation of Entities & Studies in ${auth.tenantName} Universe - based on their types`}</div>
              </div>
            </>
          ) : (
            <LoadingStatusIndicator shouldCenter size={32} status={1} />
          )}
        </div>
      </div>
    </div>
  );
};
