// node_modules
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
import { FC, useMemo, useRef, useState } from "react";
// Custom hooks
import { useClickOutsideRef, useEditorTable } from "Hooks";
// Components
import { Popover } from "Components";
import { EditorMenuGroups } from "../EditorMenuItems";
// Helpers
import { TABLE_COMMANDS, TABLE_MENU_GROUPS } from "Helpers";
// Styles
import styles from "./editorTableFloatingMenu.module.scss";
import { IGroup } from "Interfaces";

interface IEditorTableFloatingMenuProps {
  editor: Editor;
  appendTo: HTMLElement;
}

export const EditorFloatingTableMenu: FC<IEditorTableFloatingMenuProps> = ({
  editor,
  appendTo,
}: IEditorTableFloatingMenuProps) => {
  const { selectedCell, isSplitAllowed, isTableCellChevronVisible, setIsTableCellChevronVisible } = useEditorTable(editor);
  const [isTableCommandsDropdownVisible, setIsTableCommandsDropdownVisible] =
    useState<boolean>(false);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const editorMenuGroupsRef = useRef<HTMLDivElement | null>(null);

  const appendPopoverTo = useMemo(
    () => selectedCell?.element ?? appendTo,
    [selectedCell, appendTo]
  );

  useClickOutsideRef(buttonRef, () => {
    setIsTableCommandsDropdownVisible(false);
    setIsTableCellChevronVisible(false);
  }, [{ current: selectedCell?.element }, editorMenuGroupsRef]);

  const groups = useMemo(() => {
    return TABLE_MENU_GROUPS.reduce((acc: IGroup[], group) => {
      const commands = group.commands.filter((command) => {
        const isInsertTableCommand = command.name === TABLE_COMMANDS.INSERT_TABLE_COMMAND.name;
        const isMergeCellsCommand = command.name === TABLE_COMMANDS.MERGE_CELLS_COMMAND.name;
        const isSplitCellCommand = command.name === TABLE_COMMANDS.SPLIT_CELL_COMMAND.name;
  
        if (isInsertTableCommand || isMergeCellsCommand) return false;
        if (isSplitCellCommand && !isSplitAllowed) return false;
  
        return true;
      });
  
      if (commands.length > 0) {
        acc.push({ ...group, commands });
      }
  
      return acc;
    }, []);
  }, [isSplitAllowed]);

  if (!selectedCell?.element) return null;

  return (
    <Popover
      referenceEl={appendPopoverTo}
      isOpen={isTableCellChevronVisible}
      placement="right-start"
      showInPortal={true}
      extraClassName={styles.floatingTableMenu}
      popoverOffset={{ mainAxis: -20, crossAxis: 2 }}
    >
      <>
        <button
          type="button"
          ref={buttonRef}
          onClick={(event) => {
            event.stopPropagation();
            setIsTableCommandsDropdownVisible(!isTableCommandsDropdownVisible);
          }}
        >
          <FontAwesomeIcon
            icon={isTableCommandsDropdownVisible ? faChevronUp : faChevronDown}
          />
        </button>
        <Popover
          referenceEl={buttonRef.current}
          isOpen={isTableCommandsDropdownVisible}
          placement="bottom-end"
          showInPortal={true}
          extraClassName={styles.editorTableCellCommandsPopover}
        >
          <div ref={editorMenuGroupsRef}>
            <EditorMenuGroups
              groups={groups}
              editor={editor}
              commandOnClickCallback={() => {
                setIsTableCommandsDropdownVisible(false);
              }}
              doShowTooltip={false}
            />
          </div>
        </Popover>
      </>
    </Popover>
  );
};
