// React
import { FC, useCallback, useContext, useMemo, useRef } from "react";
// Tip Tap
import { NodeViewWrapper, NodeViewProps } from "@tiptap/react";
// Components
import { Action, Card } from "Components";
// Controllers
import { StudyControllerSingleton } from "Controllers";
// Providers
import { AuthContext, EditorContext } from "Providers";
// Helpers
import { DateHelperSingleton, ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";
// Style
import styles from "./intakeSheet.module.scss";

export const IntakeSheet: FC<NodeViewProps> = ({ node, updateAttributes }) => {
  const { auth } = useContext(AuthContext);
  const { objectEdited } = useContext(EditorContext);

  const componentRef = useRef<HTMLDivElement>(null);

  const handleUnConfirmIntakeSheet = useCallback(async () => {
    if (objectEdited?.id) {
      const result =
        await StudyControllerSingleton.updateStudyIntakeSheetConfirmationAsync(
          objectEdited.id,
          false
        );
      if (result) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Success,
          "Intake sheet unconfirmed"
        );
      } else {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Intake sheet unconfirmation failed"
        );
      }
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Error: Study not found"
      );
    }
    // Update the node attributes
    updateAttributes({
      confirmed: false,
      confirmedBy: "",
      date: "",
    });
  }, [objectEdited, updateAttributes]);

  const handleConfirmIntakeSheet = useCallback(async () => {
    if (objectEdited?.id) {
      const result =
        await StudyControllerSingleton.updateStudyIntakeSheetConfirmationAsync(
          objectEdited.id,
          true
        );
      if (result) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Success,
          "Intake sheet confirmed"
        );
      } else {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Intake sheet confirmation failed"
        );
      }
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Error: Study not found"
      );
    }
    // Update the node attributes
    updateAttributes({
      confirmed: true,
      confirmedBy: auth?.userEmail || "",
      date: DateHelperSingleton.getTodayFormatted(),
    });
  }, [auth, objectEdited, updateAttributes]);

  const unconfirmedCardAction: Action = useMemo(() => {
    return {
      label: "Accept",
      buttonType: "primary",
      onClick: handleConfirmIntakeSheet,
    };
  }, [handleConfirmIntakeSheet]);

  const confirmedCardAction: Action = useMemo(() => {
    return {
      label: "Remove confirmation",
      buttonType: "secondary",
      onClick: handleUnConfirmIntakeSheet,
    };
  }, [handleUnConfirmIntakeSheet]);

  return (
    <NodeViewWrapper
      ref={componentRef}
      contentEditable={false}
      className={styles.card}
      data-toc-id="intake-sheet-confirmation"
    >
      {!node.attrs.confirmed && (
        <Card actions={[unconfirmedCardAction]}>
          <Card.Content>
            <p>
              Please press the &quot;accept&quot; button if the intake sheet
              matches the scouting challenge we have discussed or place a
              message with your comment if this is not the case.
            </p>
          </Card.Content>
        </Card>
      )}
      {node.attrs.confirmed && (
        <Card actions={[confirmedCardAction]}>
          <Card.Content>
            <div className={styles.iconContainer}></div>
            <p>
              Intake sheet confirmed by {node.attrs.confirmedBy} on{" "}
              {node.attrs.date}.
            </p>
          </Card.Content>
        </Card>
      )}
    </NodeViewWrapper>
  );
};
