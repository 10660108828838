// node_modules
import { FC, useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { faChevronLeft, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
// Components
import { FindestTextBox, IsFindestAdministrator } from "Components";
import { TenantSwitcherUserRow } from "./TenantSwitcherUserRow";
// Controllers
import { UserControllerSingleton } from "Controllers";
// Types
import { TUserDTO } from "Types";
// Constants
import { GeneralConstants } from "Constants";

export const TenantSwitcher: FC = () => {
  // Custom hooks
  const navigate = useNavigate();

  // State
  const [searchEmailQuery, setSearchEmailQuery] = useState("");
  const [allUsers, setAllUsers] = useState<TUserDTO[]>([]);

  // refresh all users async
  const refreshAllUsersAsync = useCallback(async (query: string) => {
    // set search email query
    setSearchEmailQuery(query);

    // if query is empty
    if (!query) {
      // set all users
      setAllUsers([]);
      // stop execution, return
      return;
    }

    // retrieve all users
    const retrievedAllUsers: TUserDTO[] =
      await UserControllerSingleton.getAllUsersAsync(query);

    // set all users
    setAllUsers([...retrievedAllUsers]);
  }, []);

  // debounce the refreshAllUsersAsync function
  const debouncedRefreshAllUsersAsync = useMemo(
    () => debounce(refreshAllUsersAsync, GeneralConstants.DEFAULT_MS_DELAY),
    [refreshAllUsersAsync]
  );

  // on empty email input button click handler
  const onEmptyEmailInputButtonClickHandler = async () => {
    // set all users
    setAllUsers([]);

    // clear search email query
    setSearchEmailQuery("");
  };

  return (
    <IsFindestAdministrator>
      <div>
        <div
          onClick={() => {
            navigate("/admin");
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div>
          <h1>Users</h1>
          <FindestTextBox
            placeholder="Email"
            onChange={debouncedRefreshAllUsersAsync}
            showEmptyInputCrossIcon={
              searchEmailQuery && searchEmailQuery.length > 0 ? true : false
            }
            leftIcon={faSearch}
            doAutoFocus={true}
            onEmptyInputButtonClickHandler={onEmptyEmailInputButtonClickHandler}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Tenants</th>
              <th>Roles</th>
            </tr>
          </thead>
          <tbody>
            {allUsers.map((user) => (
              <TenantSwitcherUserRow
                key={user.id}
                user={user}
                refreshAllUsersAsync={async () => {
                  await refreshAllUsersAsync(searchEmailQuery);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
    </IsFindestAdministrator>
  );
};
