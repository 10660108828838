// node_modules
import { faEye, faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FC, useContext } from "react";
// Components
import { ToggleButton } from "Components";
// Contexts
import { EditorContext } from "Providers";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Styles
import styles from "./objectTopbar.module.scss";
// Enums
import { LogFeatureNameEnum } from "Enums";

export const ObjectModeToggle: FC = () => {
  // Contexts
  const { isEditOn, stopClaimingEditAsync, tryClaimingEditAsync } =
    useContext(EditorContext);

  const onClickToggleButton = (leftToggleActive: boolean) => {
    if (leftToggleActive && isEditOn) {
      stopClaimingEditAsync();
      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-TurnEditModeOff-Manually`
      );
    } else if (!leftToggleActive && !isEditOn) {
      tryClaimingEditAsync();
      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-TurnEditModeOn-Manually`
      );
    }
  };

  // Render
  return (
    <ToggleButton
      buttonWidth="164px"
      toggleTextLeft="View"
      toggleTextRight="Edit"
      leftToggleActive={!isEditOn}
      toggleIconLeft={faEye}
      toggleIconRight={faPenToSquare}
      extraClassName={styles.editViewToggleButton}
      onClickToggleButton={onClickToggleButton}
    />
  );
};
