// node_modules
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
// Enums
import { AskIgorMenuItemEnum } from "Enums/AskIgorMenuItemEnum";
// Components
import { AskIgorMenuItem } from "./AskIgorMenuItem/AskIgorMenuItem";
// Styles
import styles from "./askIgorMenu.module.scss";
// Constants
import { FeatureToggleConstants } from "Constants";

type TAskIgorMenuProps = {
  selectedItem: AskIgorMenuItemEnum | undefined;
  onSelectedItemUpdate: (newSelectedItem: AskIgorMenuItemEnum) => void;
};

export const AskIgorMenu: FC<TAskIgorMenuProps> = ({
  selectedItem,
  onSelectedItemUpdate,
}: TAskIgorMenuProps) => {
  // Logic
  const onAskIgorMenuItemClick = (item: AskIgorMenuItemEnum): void => {
    // set selected menu item
    onSelectedItemUpdate(item);
  };

  // Render
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FontAwesomeIcon icon={faMessageBot} />
        Ask IGOR
      </div>
      <div>
        <AskIgorMenuItem
          item={AskIgorMenuItemEnum.QuestionAndAnswer}
          isSelected={selectedItem === AskIgorMenuItemEnum.QuestionAndAnswer}
          onClick={onAskIgorMenuItemClick}
        />
        <div className={styles.generateContentTitle}>Generate</div>
        <AskIgorMenuItem
          item={AskIgorMenuItemEnum.GenerateReport}
          isSelected={selectedItem === AskIgorMenuItemEnum.GenerateReport}
          onClick={onAskIgorMenuItemClick}
        />
        {FeatureToggleConstants.TIAutomation && (
          <>
            <AskIgorMenuItem
              item={AskIgorMenuItemEnum.GeneralDescription}
              isSelected={
                selectedItem === AskIgorMenuItemEnum.GeneralDescription ||
                selectedItem ===
                  AskIgorMenuItemEnum.GeneralDescriptionUsingLinks ||
                selectedItem ===
                  AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
              }
              onClick={onAskIgorMenuItemClick}
            />
          </>
        )}
        <AskIgorMenuItem
          item={AskIgorMenuItemEnum.WriteSection}
          isSelected={selectedItem === AskIgorMenuItemEnum.WriteSection}
          onClick={onAskIgorMenuItemClick}
        />
        {FeatureToggleConstants.TIAutomation && (
          <>
            <AskIgorMenuItem
              item={AskIgorMenuItemEnum.Table}
              isSelected={selectedItem === AskIgorMenuItemEnum.Table}
              onClick={onAskIgorMenuItemClick}
            />
            <AskIgorMenuItem
              item={AskIgorMenuItemEnum.ExecutiveSummary}
              isSelected={selectedItem === AskIgorMenuItemEnum.ExecutiveSummary}
              onClick={onAskIgorMenuItemClick}
            />
          </>
        )}
      </div>
    </div>
  );
};
