// node_modules
import { FC, ReactNode } from "react";
import { Placement } from "@floating-ui/react-dom";
// Components
import { Popover } from "Components";
// Styles
import styles from "./editorPopover.module.scss";

interface IEditorPopoverProps {
  refElement?: HTMLElement;
  closePopover: () => void;
  isOpen: boolean;
  children: ReactNode;
  placement?: Placement;
  extraClassNames?: { popover?: string, innerContent?: string };
}

export const EditorPopover: FC<IEditorPopoverProps> = ({
  refElement,
  closePopover,
  isOpen,
  placement,
  children,
  extraClassNames
}: IEditorPopoverProps) => {
  if (!refElement || !isOpen) return null;

  return (
    <Popover
      popoverOffset={8}
      extraClassName={`${styles.popover} ${extraClassNames?.popover ?? ""} ${placement?.includes("left") || placement?.includes("right") ? styles.horizontal : ""}`}
      placement={placement ?? "bottom-end"}
      referenceEl={refElement}
      onMouseLeave={closePopover}
      onClickOutside={closePopover}
    >
      <div className={`${styles.innerContent} ${extraClassNames?.innerContent ?? ""}`}>
        {children}
      </div>
    </Popover>
  );
};
