// node_modules
import { Dispatch, FC, SetStateAction } from "react";
// Components
import { Popover } from "Components";
import { ReferenceCard } from "./ReferenceCard";
// Types
import { TReferenceModalProps } from "Types";
// Styles
import referenceModalStyles from "./referenceModal.module.scss";

type TReferencePopoverProps = TReferenceModalProps & {
  hideReferencePopover: () => void;
  setReferenceModalProps: Dispatch<SetStateAction<TReferenceModalProps>>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  popoverOffset?: number;
  showInPortal?: boolean;
  hideRefencePopoverOnOutsideClick?: boolean;
};

export const ReferencePopover: FC<TReferencePopoverProps> = ({
  isOpen,
  id,
  type,
  doIgnoreIsDeleted,
  hideReferencePopover,
  setReferenceModalProps,
  referenceElement,
  onMouseEnter,
  onMouseLeave,
  popoverOffset,
  showInPortal,
  hideRefencePopoverOnOutsideClick,
}: TReferencePopoverProps) => {
  if (!referenceElement || !isOpen) return null;

  return (
    <Popover
      popoverOffset={popoverOffset !== undefined ? popoverOffset : 8}
      extraClassName={`${referenceModalStyles.referencePopoverComponent}`}
      placement="bottom-end"
      referenceEl={referenceElement ?? null}
      onMouseEnter={onMouseEnter}
      onMouseLeave={
        hideRefencePopoverOnOutsideClick
          ? undefined
          : onMouseLeave ?? hideReferencePopover
      }
      showInPortal={showInPortal}
      onClickOutside={
        hideRefencePopoverOnOutsideClick ? hideReferencePopover : undefined
      }
    >
      <ReferenceCard
        id={id}
        type={type}
        setReferenceModalProps={setReferenceModalProps}
        onClickCallback={() => {
          hideReferencePopover();
        }}
        doIgnoreIsDeleted={doIgnoreIsDeleted}
      />
    </Popover>
  );
};
