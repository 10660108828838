// node_modules
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DragEvent,
  FC,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
// Types
import {
  TLinkGraphNodeDTO,
  TUseDragAndDrop,
  fromTLinkGraphNodeDTO,
} from "Types";
// Components
import { Checkbox, CollapsibleLinksList, ObjectItem } from "Components";
// Enums
import { ObjectTypeEnum } from "Enums";
// Styles
import styles from "./collapsibleLinksListItem.module.scss";

type TCollapsibleLinksListItemProps = {
  linkGraphNode: TLinkGraphNodeDTO;
  upperLevelNode: TLinkGraphNodeDTO;
  addToCheckedLinks: (from: TLinkGraphNodeDTO, to: TLinkGraphNodeDTO) => void;
  removeFromCheckedLinks: (
    from: TLinkGraphNodeDTO,
    to: TLinkGraphNodeDTO
  ) => void;
  useDragAndDropProps?: TUseDragAndDrop;
  isUserExternal: boolean;
  openItemInModal: (objectId: string, objectType: ObjectTypeEnum) => void;
};

export const CollapsibleLinksListItem: FC<TCollapsibleLinksListItemProps> = ({
  linkGraphNode,
  upperLevelNode,
  addToCheckedLinks,
  removeFromCheckedLinks,
  useDragAndDropProps,
  isUserExternal,
  openItemInModal,
}: TCollapsibleLinksListItemProps) => {
  // State
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  // Memo
  const doesLinkGraphNodeHaveLowerLevelNodes = useMemo((): boolean => {
    // check if link graph node has lower level nodes
    return (
      linkGraphNode.lowerLevelNodes && linkGraphNode.lowerLevelNodes.length > 0
    );
  }, [linkGraphNode.lowerLevelNodes]);

  // Logic
  // on collapse icon click handler
  const onCollapseIconClick = (mouseEvent: MouseEvent<Element>) => {
    // prevent default
    mouseEvent.preventDefault();

    // stop propagation
    mouseEvent.stopPropagation();

    // toggle is collapsed
    setIsCollapsed(!isCollapsed);
  };

  // on checkbox change handler
  const onCheckboxChange = useCallback(
    (newIsCheckedValue: boolean) => {
      // set is checked
      setIsChecked(newIsCheckedValue);

      // if is checked
      if (newIsCheckedValue) {
        // add to checked links
        addToCheckedLinks(upperLevelNode, linkGraphNode);
      } else {
        // otherwise, remove from checked links
        removeFromCheckedLinks(upperLevelNode, linkGraphNode);
      }
    },
    [addToCheckedLinks, removeFromCheckedLinks, linkGraphNode, upperLevelNode]
  );

  // Render
  return (
    <li
      onDragOver={
        useDragAndDropProps
          ? (dragEvent: DragEvent) => {
              useDragAndDropProps.onDragOver(dragEvent, linkGraphNode);
            }
          : undefined
      }
      onDrop={useDragAndDropProps ? useDragAndDropProps.onDrop : undefined}
      className={`${styles.linksListItemContainer} ${
        useDragAndDropProps?.isObjectDraggedOver(linkGraphNode.id)
          ? styles.isDraggedOver
          : undefined
      }`}
    >
      <div className={styles.linksListItemActionsContainer}>
        {doesLinkGraphNodeHaveLowerLevelNodes && (
          <div
            className={styles.actionContainer}
            title={isCollapsed ? "Uncollapse" : "Collapse"}
            onClick={onCollapseIconClick}
          >
            <FontAwesomeIcon
              icon={isCollapsed ? faChevronRight : faChevronDown}
            />
          </div>
        )}
        {!isUserExternal && (
          <Checkbox isChecked={isChecked} onCheckboxChange={onCheckboxChange} />
        )}
      </div>
      <div className={styles.linkListItemContentContainer}>
        <div
          className={styles.draggableObjectItem}
          draggable={!isUserExternal}
          onDragStart={
            isUserExternal || !useDragAndDropProps
              ? undefined
              : () => {
                  useDragAndDropProps.onDragStart(
                    linkGraphNode,
                    upperLevelNode
                  );
                }
          }
        >
          <ObjectItem
            objectItem={fromTLinkGraphNodeDTO(linkGraphNode)}
            onDoubleClickEditable={() => {
              openItemInModal(linkGraphNode.id, linkGraphNode.objectType);
            }}
            onDoubleClick={() => {
              openItemInModal(linkGraphNode.id, linkGraphNode.objectType);
            }}
            isNavigateToObjectItemDisabled={true}
            isEditable={!isUserExternal}
            isUserExternal={isUserExternal}
          />
        </div>
        {!isCollapsed && doesLinkGraphNodeHaveLowerLevelNodes && (
          <CollapsibleLinksList
            upperLevelNode={linkGraphNode}
            linkGraphNodes={linkGraphNode.lowerLevelNodes}
            addToCheckedLinks={addToCheckedLinks}
            removeFromCheckedLinks={removeFromCheckedLinks}
            useDragAndDropProps={useDragAndDropProps}
            isUserExternal={isUserExternal}
            openItemInModal={openItemInModal}
          />
        )}
      </div>
    </li>
  );
};
