import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// React
import { FC, useContext, useEffect, useMemo, useState } from "react";
// Controllers
import { UserControllerSingleton } from "Controllers";
// Types
import {
  TUserClaimDTO,
  TUserDTO,
  TUserDetailsUser,
  fromTUserDetailsUserToTUserDTO,
} from "Types";
// Components
import {
  Checkbox,
  CreateUserModal,
  FindestButton,
  UserDetailsTable,
} from "Components/Shared";
// Helpers
import {
  RolesEnumHelperSingleton,
  ToastHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Enums
import {
  ClaimTypeEnum,
  PermissionsEnum,
  RolesEnum,
  ToastTypeEnum,
  UniverseRolesEnum,
} from "Enums";
// Styles
import styles from "./users.module.scss";
// Contexts
import { AuthContext } from "Providers";

export const UsersSettings: FC = () => {
  // State
  const [users, setUsers] = useState<TUserDTO[]>([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState<Set<string>>(
    new Set<string>()
  );
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] =
    useState<boolean>(false);

  // Contexts
  const { auth } = useContext(AuthContext);

  // Memo
  // Check if any users are selected
  const isAnyUserSelected = useMemo(() => {
    return selectedUserEmails.size > 0;
  }, [selectedUserEmails]);
  // Check if all users are selected
  const areAllUsersSelected = useMemo(() => {
    return selectedUserEmails.size === users.length;
  }, [selectedUserEmails, users]);

  // Filter out external users
  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const userUniverseRole =
        RolesEnumHelperSingleton.getUniverseRoleFromRoles(user.roles);
      // If user has the external role then don't show them
      if (userUniverseRole === UniverseRolesEnum.External) return false;

      return true;
    });
  }, [users]);

  const onAllCheckboxChange = (currentUserEmail: string) => {
    // If all or any users are selected, deselect all users
    if (areAllUsersSelected || isAnyUserSelected) {
      setSelectedUserEmails(new Set<string>());
    } else {
      // Otherwise, select all users (except the current user)
      const allUserEmails = users
        .filter((user) => user.email !== currentUserEmail)
        .map((user) => user.email);
      setSelectedUserEmails(new Set<string>(allUserEmails));
    }
  };

  const onUserCheckboxChange = (userEmail: string) => {
    // If the user is already selected, remove them from the selected users
    if (selectedUserEmails.has(userEmail)) {
      selectedUserEmails.delete(userEmail);
    } else {
      // Otherwise, add them to the selected users
      selectedUserEmails.add(userEmail);
    }

    // Update the selected users
    setSelectedUserEmails(new Set<string>(selectedUserEmails));
  };

  const onDeleteUsersClick = async () => {
    // Confirm with the user that they want to delete the users
    if (!confirm("Are you sure you want to delete the selected users?")) return;

    // Loop over the users and delete the selected ones
    for (const user of users) {
      if (!selectedUserEmails.has(user.email)) continue;

      // Delete the user
      const isUserDeletedSuccess =
        await UserControllerSingleton.deleteUserAsync(user.id);
      if (!isUserDeletedSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          `Failed to delete user with email: ${user.email}.`
        );
        return;
      }
    }

    // Refresh the users
    await refreshUsers();
    setSelectedUserEmails(new Set<string>());
  };

  const refreshUsers = async () => {
    const retrievedUsers = await UserControllerSingleton.getUsersAsync();
    setUsers(retrievedUsers);
  };

  const onRoleChange = async (user: TUserDTO, newRole: UniverseRolesEnum) => {
    // Add the new role to the user
    const isRoleAddedSuccess = await UserControllerSingleton.addRoleAsync(
      user.id,
      newRole
    );
    // Notify the user if the new role addition failed
    if (!isRoleAddedSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to change role of user."
      );
      return;
    }

    // Remove the old role from the user
    const oldRole = RolesEnumHelperSingleton.getUniverseRoleFromRoles(
      user.roles
    );
    if (oldRole) {
      const isRoleRemovedSuccess =
        await UserControllerSingleton.removeRoleAsync(user.id, oldRole);

      // Notify the user if the old role removal failed
      if (!isRoleRemovedSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to remove old role of user."
        );
        return;
      }
    }

    // Refresh the users
    await refreshUsers();
  };

  useEffect(() => {
    refreshUsers();
  }, []);

  const isAdvancedCheckboxDisabled = (
    user: TUserDTO,
    currentUserEmail: string
  ): boolean => {
    // if user email is the same as the current user email
    if (user.email === currentUserEmail) {
      // then the advanced checkbox should be disabled
      return true;
    }

    // go through user roles
    for (const role of user.roles) {
      // if the user has any findest roles
      if (UserHelperSingleton.getFindestRoles().includes(role as RolesEnum)) {
        // then the advanced checkbox should be disabled
        return true;
      }
    }

    // otherwise, return false
    return false;
  };

  const onAdvancedCheckboxChangeAsync = async (
    isChecked: boolean,
    user: TUserDTO
  ): Promise<void> => {
    // depending on isChecked
    if (isChecked) {
      // call api to add advanced permission to user
      const userClaim: TUserClaimDTO | undefined =
        await UserControllerSingleton.addClaimAsync(
          user.id,
          { type: ClaimTypeEnum.Permission },
          { value: PermissionsEnum.Advanced }
        );

      // safety-checks
      if (!userClaim) {
        // show error toast
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to add advanced permission to user."
        );
        // stop execution, return
        return;
      }
    } else {
      // otherwise, call api to remove advanced permission from user
      const result: boolean = await UserControllerSingleton.deleteClaimAsync(
        user.id,
        { type: ClaimTypeEnum.Permission },
        { value: PermissionsEnum.Advanced }
      );

      // safety-checks
      if (!result) {
        // show error toast
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to remove advanced permission from user."
        );
        // stop execution, return
        return;
      }
    }

    // set users
    setUsers((prevUsers: TUserDTO[]) => {
      // init new users
      const newUsers: TUserDTO[] = [...prevUsers];

      // go through new users
      for (const newUser of newUsers) {
        // if new user id is not the same as user id
        if (newUser.id !== user.id) {
          // then continue
          continue;
        }

        // if isChecked is true
        if (isChecked) {
          // add advanced permission to user permissions
          newUser.permissions.push(PermissionsEnum.Advanced);
        } else {
          // otherwise, remove advanced permission from user permissions
          const advancedPermissionIndex: number = newUser.permissions.indexOf(
            PermissionsEnum.Advanced
          );
          newUser.permissions.splice(advancedPermissionIndex, 1);
        }

        // set new user permissions
        newUser.permissions = [...newUser.permissions];
      }

      // return new users
      return [...newUsers];
    });
  };

  const isSelectCheckboxDisabled = (email: string): boolean => {
    // if user email is the same as the current user email
    if (email === auth.userEmail) {
      // then the select checkbox should be disabled
      return true;
    }

    // otherwise, return false
    return false;
  };

  return (
    <div className={styles.users}>
      <div className={styles.usersHead}>
        <Checkbox
          isChecked={isAnyUserSelected}
          onCheckboxChange={() => {
            onAllCheckboxChange(auth.userEmail);
          }}
        />
        <FontAwesomeIcon
          className={`${styles.trashIcon} ${
            isAnyUserSelected ? styles.isVisible : ""
          }`}
          icon={faTrashCan}
          onClick={onDeleteUsersClick}
        />
        <FindestButton
          isDisabled={isAnyUserSelected}
          title="Add User"
          onClick={() => {
            setIsCreateUserModalOpen(true);
          }}
        />
      </div>
      <UserDetailsTable
        isExistingUsers
        users={filteredUsers}
        selectedUserEmails={selectedUserEmails}
        isSelectCheckboxDisabled={isSelectCheckboxDisabled}
        isAdvancedCheckboxDisabled={(user: TUserDetailsUser) =>
          isAdvancedCheckboxDisabled(
            fromTUserDetailsUserToTUserDTO(user),
            auth.userEmail
          )
        }
        onUserCheckboxChange={onUserCheckboxChange}
        onRoleChange={(user, role) =>
          onRoleChange(fromTUserDetailsUserToTUserDTO(user), role)
        }
        onAdvancedCheckboxChange={(isChecked, user) => {
          onAdvancedCheckboxChangeAsync(
            isChecked,
            fromTUserDetailsUserToTUserDTO(user)
          );
        }}
      />
      <CreateUserModal
        isOpen={isCreateUserModalOpen}
        setIsOpen={setIsCreateUserModalOpen}
        onCreateCallback={() => {
          refreshUsers();
        }}
      />
    </div>
  );
};
