import {
  BaseType,
  HierarchyCircularNode,
  Selection,
  Transition,
  ZoomView,
  hsl as d3Hsl,
  pack as d3Pack,
  hierarchy,
  interpolateZoom,
  scaleOrdinal,
  select,
} from "d3";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { IPackGraphNode, TTypeGraphNodeDTO } from "Types";

export class PackGraphHelper {
  width: number;
  height: number;
  multicolor: boolean;
  bold: boolean;
  hexcolor: string;
  svg:
    | Selection<
        SVGSVGElement,
        HierarchyCircularNode<IPackGraphNode>,
        HTMLElement,
        undefined
      >
    | undefined;
  node:
    | Selection<
        SVGCircleElement,
        HierarchyCircularNode<IPackGraphNode>,
        SVGGElement,
        HierarchyCircularNode<IPackGraphNode>
      >
    | undefined;
  tooltip:
    | Selection<HTMLDivElement, unknown, HTMLElement, undefined>
    | undefined;
  label:
    | Selection<
        SVGTextElement | BaseType,
        HierarchyCircularNode<IPackGraphNode>,
        SVGGElement,
        HierarchyCircularNode<IPackGraphNode>
      >
    | undefined;
  view: ZoomView;
  focus: HierarchyCircularNode<IPackGraphNode> | undefined;
  root!: HierarchyCircularNode<IPackGraphNode>;

  constructor() {
    this.width = document.getElementById("packGraph")?.clientWidth ?? 1000;
    this.height = this.width;
    this.bold = true;
    this.multicolor = true;
    this.hexcolor = "#0099cc";
    this.view = [0, 0, 0];
  }

  public createSVG(
    data: TTypeGraphNodeDTO[],
    extraClassNames: { tooltipClassName: string },
    navigateToObject: (object: {
      objectType: ObjectTypeEnum;
      id: string;
    }) => void
  ): void {
    select("#packGraph > svg").remove();

    this.root = d3Pack().size([this.width, this.height]).padding(16)(
      hierarchy(this.getModifiedDataForTypeGraph(data) as unknown)
        .sum((d) => (d as { size: number }).size)
        .sort((a, b) => (b?.value || 0) - (a?.value || 0))
    ) as HierarchyCircularNode<IPackGraphNode>;

    this.focus = this.root;

    this.svg = select("#packGraph")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .data(this.root.descendants())
      .attr(
        "viewBox",
        `-${this.width / 2} -${this.height / 2} ${this.width} ${this.height}`
      )
      .style("display", "block")
      .style("width", "100%")
      .style("height", "auto")
      .style("background", "white")
      .style("cursor", "pointer")
      .on("click", (event) => this.zoom(event, this.root, navigateToObject)); // to zoom out

    this.node = this.svg
      .append("g")
      .selectAll("circle")
      .data(this.root.descendants())
      .enter()
      .append("circle")
      .attr("fill", this.setCircleColor)
      .attr("stroke", "white")
      .attr("stroke-width", ".6");

    this.handleNodeEvent(1, navigateToObject);

    this.tooltip = select("#packGraph")
      .append("div")
      .attr("class", extraClassNames.tooltipClassName ?? "")
      .style("position", "absolute")
      .style("background-color", "white")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("simple");

    this.label = this.svg
      .append("g")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(this.root.descendants())
      .join("text")
      .attr("pointer-events", (d: HierarchyCircularNode<IPackGraphNode>) => {
        return !d?.children ? "unset" : "none";
      })
      .style("display", (d: HierarchyCircularNode<IPackGraphNode>) =>
        d.parent === this.root ? "inline" : "none"
      )
      .style(
        "font",
        (d: HierarchyCircularNode<IPackGraphNode>) =>
          `${this.fontsize(
            (d.parent === this.root ? "22" : "16").toString()
          )}px IBM Plex Sans, sans-serif`
      )
      .on(
        "click",
        (_event: MouseEvent, d: HierarchyCircularNode<IPackGraphNode>) => {
          if (d.parent === this.focus) {
            navigateToObject({ objectType: d.data.objectType, id: d.data.id });
          }
        }
      );

    this.label
      ?.append("tspan")
      .each(
        (
          d: HierarchyCircularNode<IPackGraphNode>,
          index: number,
          b: SVGTSpanElement[] | ArrayLike<SVGTSpanElement>
        ) => {
          const title =
            d.r < 30 ? d.data.name.substring(0, 1).concat(".") : d.data.name;

          // split name by space
          const n = title?.split(" ");

          if (!n) return;
          const text = (
            select(b[index]) as Selection<
              SVGTSpanElement,
              HierarchyCircularNode<IPackGraphNode>,
              null,
              undefined
            >
          )
            .attr("dy", `${n.length / 3 - (n.length - 1) * 0.9}em`)
            .style("font", (f: HierarchyCircularNode<IPackGraphNode>) => {
              return `${f.r < 30 ? 14 : 16}px IBM Plex Sans, sans-serif`;
            })
            .style("fill", "#252525")
            .style("font-weight", (d: HierarchyCircularNode<IPackGraphNode>):
              | "bold"
              | "normal" => {
              return d.children && d.children.length > 0 ? "bold" : "normal";
            })
            .on(
              "mouseover",
              (event: MouseEvent, d: HierarchyCircularNode<IPackGraphNode>) => {
                text.style("fill", (d: HierarchyCircularNode<IPackGraphNode>) =>
                  !d?.children ? "#007AFF" : ""
                );

                if (d.r < 30 && this.focus === d.parent) {
                  this.tooltip?.text(d.data.name);
                  this.tooltip?.style("visibility", "visible");
                }
              }
            )
            .on("mousemove", (event: MouseEvent) => {
              this.tooltip
                ?.style("top", `${event.pageY - 10}px`)
                .style("left", `${event.pageX + 20}px`);
            })
            .on("mouseout", () => {
              text.style("fill", "#252525");
              this.tooltip?.style("visibility", "hidden");
            });

          const splittedText = this.splitTextToLines(title, n);

          splittedText.forEach((line) => {
            text
              .append("tspan")
              .attr("x", 0)
              .attr("dy", splittedText.length > 1 ? "1em" : 0)
              .attr("width", line.width)
              .text(line.text);
          });
        }
      );

    this.zoomTo([this.root.x, this.root.y, this.root.r * 2], navigateToObject);
  }

  private handleNodeEvent(
    k: number,
    navigateToObject: (object: {
      objectType: ObjectTypeEnum;
      id: string;
    }) => void
  ) {
    this.node
      ?.on(
        "mouseover",
        (event: MouseEvent, data: HierarchyCircularNode<IPackGraphNode>) => {
          const currentTarget = event.currentTarget as SVGCircleElement;
          if (data.children) {
            select(currentTarget).attr("stroke", "#252525");
          }
          if (PackGraphHelperSingleton.focus === data.parent) {
            select(currentTarget).attr("fill-opacity", ".8");
          }

          if (
            data.r * k < 30 &&
            PackGraphHelperSingleton.focus === data.parent
          ) {
            PackGraphHelperSingleton.tooltip?.text(data.data.name);
            PackGraphHelperSingleton.tooltip?.style("visibility", "visible");
          }
        }
      )
      .on("mousemove", (event: MouseEvent) => {
        this.tooltip
          ?.style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 20}px`);
      })
      .on("mouseout", (event: MouseEvent) => {
        const currentTarget = event.currentTarget as SVGCircleElement;
        select(currentTarget).attr("fill-opacity", "1");
        select(currentTarget).attr("stroke", "white");
        this.tooltip?.style("visibility", "hidden");
      })
      .on(
        "click",
        (event: MouseEvent, d: HierarchyCircularNode<IPackGraphNode>) => {
          if (!d.children) {
            if (d.parent === this.focus) {
              navigateToObject({
                objectType: d.data.objectType,
                id: d.data.id,
              });
            }
            return;
          }
          return (
            this.focus !== d &&
            (this.zoom(event, d, navigateToObject), event.stopPropagation())
          );
        }
      );
  }

  private color = this.setColorScheme(true);

  private zoom(
    event: MouseEvent,
    d: HierarchyCircularNode<IPackGraphNode>,
    navigateToObject: (object: {
      objectType: ObjectTypeEnum;
      id: string;
    }) => void
  ) {
    this.focus = d;
    if (this.svg) {
      const transition: Transition<
        SVGSVGElement,
        HierarchyCircularNode<IPackGraphNode>,
        HTMLDivElement,
        undefined
      > = this.svg
        .transition()
        .duration(event.altKey ? 7500 : 750)
        .tween("zoom", () => {
          const i = interpolateZoom(this.view, [
            this.focus?.x ?? 0,
            this.focus?.y ?? 0,
            (this.focus?.r ?? 0) * 2,
          ]);
          return (t: number) => this.zoomTo(i(t), navigateToObject);
        });

      if (this.label) {
        this.label
          .filter(
            (
              d: HierarchyCircularNode<IPackGraphNode>,
              index: number,
              elements:
                | (SVGTextElement | BaseType)[]
                | ArrayLike<SVGTextElement | BaseType>
            ) => {
              return (
                d.parent === PackGraphHelperSingleton.focus ||
                (elements[index] as SVGTextElement)?.style.display === "inline"
              );
            }
          )
          .style("fill-opacity", (d: HierarchyCircularNode<IPackGraphNode>) =>
            d.parent === PackGraphHelperSingleton.focus ? 1 : 0
          )
          .transition(transition as any)
          .on(
            "start",
            (
              d: HierarchyCircularNode<IPackGraphNode>,
              index: number,
              elements:
                | (SVGTextElement | BaseType)[]
                | ArrayLike<SVGTextElement | BaseType>
            ) => {
              if (d.parent === PackGraphHelperSingleton.focus)
                (elements[index] as SVGTextElement).style.display = "inline";
            }
          )
          .on(
            "end",
            (
              d: HierarchyCircularNode<IPackGraphNode>,
              index: number,
              elements:
                | (SVGTextElement | BaseType)[]
                | ArrayLike<SVGTextElement | BaseType>
            ) => {
              if (d.parent !== PackGraphHelperSingleton.focus)
                (elements[index] as SVGTextElement).style.display = "none";
            }
          );
      }
    }
  }

  private fontsize = scaleOrdinal().domain(["16", "24"]).range([16, 24]);

  private zoomTo(
    v: ZoomView,
    navigateToObject: (object: {
      objectType: ObjectTypeEnum;
      id: string;
    }) => void
  ) {
    const k = this.width / v[2];

    this.view = v;
    this.label?.attr(
      "transform",
      (d: HierarchyCircularNode<IPackGraphNode>) =>
        `translate(${(d.x - v[0]) * k},${
          (d.y - v[1]) * k +
          (this.fontsize(
            (d.parent === this.root ? this.root.r : d.r).toString()
          ) as number) /
            4
        })`
    );
    this.node?.attr(
      "transform",
      (d: HierarchyCircularNode<IPackGraphNode>) =>
        `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
    );
    this.node?.attr("r", (d: HierarchyCircularNode<IPackGraphNode>) => d.r * k);

    this.handleNodeEvent(k, navigateToObject);

    // change text in circles based on the circle size
    this.label
      ?.select("tspan")
      .each(
        (
          d: HierarchyCircularNode<IPackGraphNode>,
          index: number,
          b: BaseType[] | ArrayLike<BaseType>
        ) => {
          if (d.r > 30) return;
          const title =
            d.r * k < 30
              ? d.data.name.substring(0, 1).concat(".")
              : d.data.name;
          // split name by space
          const n = title?.split(" ");

          if (!n) return;

          const text = select(b[index]).on("mouseover", () => {
            text.style("fill", (d: any) => (!d?.children ? "#007AFF" : ""));
            if (d.r * k < 30 && this.focus === d.parent) {
              this.tooltip?.text(d.data.name);
              this.tooltip?.style("visibility", "visible");
            } else {
              this.tooltip?.style("visibility", "hidden");
            }
          });
          const splittedText = this.splitTextToLines(title, n);
          text.selectAll("tspan").remove();

          splittedText.forEach((line) => {
            text
              .append("tspan")
              .attr("x", 0)
              .attr("dy", splittedText.length > 1 ? "1em" : 0)
              .attr("width", line.width)
              .text(line.text);
          });
        }
      );
  }

  private splitTextToLines(word: string, words: string[]) {
    const lineHeight = 12;
    const targetWidth = Math.sqrt(this.measureWidth(word.trim()) * lineHeight);

    let line: undefined | { width: number; text: string } = undefined;
    let lineWidth0 = Infinity;
    const lines = [];
    for (let i = 0, n = words.length; i < n; ++i) {
      const lineText1 = (line ? `${line.text} ` : "") + words[i];
      const lineWidth1 = this.measureWidth(lineText1);
      if (line && (lineWidth0 + lineWidth1) / 2 < targetWidth) {
        line.width = lineWidth0 = lineWidth1;
        line.text = lineText1;
      } else {
        lineWidth0 = this.measureWidth(words[i]);
        line = { width: lineWidth0, text: words[i] };
        lines.push(line);
      }
    }
    return lines;
  }

  private measureWidth(txt: string) {
    const context = document.createElement("canvas").getContext("2d");
    return context?.measureText(txt).width ?? 0;
  }

  private setCircleColor(obj: HierarchyCircularNode<IPackGraphNode>) {
    const depth = obj.depth;
    while (obj.depth > 1 && obj.parent) {
      obj = obj.parent;
    }
    const firstColor = PackGraphHelperSingleton.color
      ? (PackGraphHelperSingleton.color(obj.data.name) as string)
      : this.hexcolor;
    const newcolor = PackGraphHelperSingleton.multicolor
      ? d3Hsl(firstColor)
      : d3Hsl(this.hexcolor);
    newcolor.l += depth == 1 ? 0 : depth * 0.1;
    return newcolor.toString();
  }

  private setColorScheme(multi: boolean) {
    if (multi) {
      const colors = ["#F2F4F8", "#00ADEF", "#5856D6"];
      const color = scaleOrdinal().range(colors);
      return color;
    }
  }

  private getModifiedDataForTypeGraph(typeData: TTypeGraphNodeDTO[]) {
    const test = typeData.map((a) => {
      return {
        ...a,
        name: a.type,
        children: a.children.map((c) => ({
          ...c,
          objectType: a.objectType,
          size: a.children.length,
        })),
      };
    });

    const entitygroups = test
      .filter((d) => d.objectType === 1 && d.name !== "Entity")
      .map((a) => {
        return {
          ...a,
          children: a.children.map((c) => ({ ...c, size: a.children.length })),
        };
      });

    const singleEntities = test
      .find((d) => d.name === "Entity")
      ?.children?.map((a) => {
        return {
          ...a,
          size: 1,
        };
      });

    const entityGroup = {
      name: "ENTITY",
      children: [...entitygroups, ...(singleEntities ?? [])],
    };

    const studyGroups = test
      .filter((d) => d.objectType === 4 && d.name !== "Study")
      .map((a) => {
        return {
          ...a,
          children: a.children.map((c) => ({ ...c, size: a.children.length })),
        };
      });

    const singleStudies = test
      .find((d) => d.name === "Study")
      ?.children?.map((a) => {
        return {
          ...a,
          size: 1,
        };
      });
    const studyGroup = {
      name: "STUDY",
      children: [...studyGroups, ...(singleStudies ?? [])],
    };

    return {
      name: "PackData",
      size: 1,
      children: [
        entityGroup.children.length > 0 ? entityGroup : null,
        studyGroup.children.length > 0 ? studyGroup : null,
      ].filter((a) => a),
    };
  }
}

export const PackGraphHelperSingleton = new PackGraphHelper();
