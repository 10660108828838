// node_modules
import { Editor } from "@tiptap/core";
import { FC } from "react";
// Components
import { EditorMenuCommand } from "./EditorMenuCommand";
// Interfaces
import { IActionOptions, ICommand } from "Interfaces";
// Styles
import styles from "../editorMenu.module.scss";

interface IEditorMenuCommandsProps {
  commands: ICommand[];
  editor: Editor;
  doNotShowCommandLabels?: boolean;
  doShowTooltip: boolean;
  actionOptions?: IActionOptions;
  onClickCallback?: (command: ICommand) => void;
  extraClassNames?: { container?: string; command?: string };
  onClickHandler?: (command: ICommand) => void;
}

export const EditorMenuCommands: FC<IEditorMenuCommandsProps> = ({
  commands,
  editor,
  doNotShowCommandLabels,
  doShowTooltip,
  actionOptions,
  onClickCallback,
  extraClassNames = {},
  onClickHandler,
}: IEditorMenuCommandsProps) => {
  return (
    <div
      className={`${styles.editorMenuCommands} ${
        extraClassNames?.container ?? ""
      }`}
    >
      {commands.map((command: ICommand, index: number) => {
        const isNextCommandActive = commands[index + 1]?.isActive(editor);
        const isPreviousCommandActive = commands[index - 1]?.isActive(editor);
        const isCommandBetweenActiveCommands =
          isNextCommandActive && isPreviousCommandActive;
        let commandClassName = "";
        if (isCommandBetweenActiveCommands) {
          commandClassName = styles.isCommandBetweenActiveCommands;
        } else if (isNextCommandActive) {
          commandClassName = styles.isFirstCommand;
        } else if (isPreviousCommandActive) {
          commandClassName = styles.isLastCommand;
        }
        return (
          <EditorMenuCommand
            extraClassNames={{
              editorMenuCommand: `${
                extraClassNames?.command ?? ""
              } ${commandClassName}`,
            }}
            key={command.name}
            editor={editor}
            command={command}
            doNotShowLabel={doNotShowCommandLabels}
            actionOptions={actionOptions}
            doShowTooltip={doShowTooltip}
            onClickHandler={onClickHandler}
            onClickCallback={onClickCallback}
          />
        );
      })}
    </div>
  );
};
