// node_modules
import { Content, Editor, NodePos } from "@tiptap/react";
import Link from "@tiptap/extension-link";
// Helpers
import { ExtensionsKit, GetCustomLink, ToastHelperSingleton } from "Helpers";
// Enums
import { DocumentObjectTypeEnums, ObjectTypeEnum, ToastTypeEnum } from "Enums";

class EditorHelper {
  public getContentAsPlainText(content?: string): string {
    let newContent: Content = "";
    try {
      newContent = content ? JSON.parse(content) : "";
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not get content as plain text."
      );
      newContent = content ?? "";
    }

    const editor = new Editor({
      extensions: [...ExtensionsKit, GetCustomLink()],
      content: newContent,
    });

    return editor.getText();
  }

  public getHeadingText(editor: Editor, nodePos: NodePos | null): string {
    if (!editor) return "";
    try {
      if (!nodePos) return "";

      if (nodePos.node.type.name === editor.schema.nodes.heading.name) {
        return nodePos.node.textContent;
      }

      const closestHeading = nodePos.closest(editor.schema.nodes.heading.name);
      if (closestHeading) {
        return closestHeading.node.textContent;
      }

      let nodePosBefore = nodePos.before;
      while (
        nodePosBefore &&
        nodePosBefore.node.type.name !== editor.schema.nodes.heading.name
      ) {
        nodePosBefore = nodePosBefore.before;
      }
      if (nodePosBefore) {
        return nodePosBefore.node.textContent;
      }

      return this.getHeadingText(editor, nodePos.parent);
    } catch (error) {
      if (error instanceof RangeError) {
        return "";
      }

      throw error;
    }
  }

  getReferenceIds(content: Content, idSet: Set<string>): Set<string> {
    if (!content) return idSet;

    if (typeof content === "string") {
      return idSet;
    }

    if (Array.isArray(content)) {
      content.forEach((currentContent) =>
        this.getReferenceIds(currentContent, idSet)
      );
      return idSet;
    }

    if (content.marks && content.marks.length > 0) {
      for (const mark of content.marks) {
        if (
          mark.type === Link.name &&
          mark.attrs &&
          mark.attrs.id &&
          mark.attrs.type &&
          [
            ObjectTypeEnum.Entity,
            ObjectTypeEnum.Study,
            ...DocumentObjectTypeEnums,
          ].includes(parseInt(mark.attrs.type, 10))
        ) {
          idSet.add(mark.attrs.id);
        }
      }
    }

    if (content.content) {
      content.content.forEach((childContent) =>
        this.getReferenceIds(childContent, idSet)
      );
    }

    return idSet;
  }
}

export const EditorHelperSingleton = new EditorHelper();
