// node_modules
import {
  faBookOpenReader,
  faDiceD6,
  faFile,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  AddDocument,
  CreateEntityModal,
  CreateQueryModal,
  CreateStudyModal,
  HasAdvanced,
  Modal,
} from "Components";
import { CreateNewModalCard } from "./CreateNewModalCard";
// Styles
import styles from "./createNewModal.module.scss";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";

type TCreateNewModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const CreateNewModal: FC<TCreateNewModalProps> = ({
  isOpen,
  closeModal,
}: TCreateNewModalProps) => {
  // Hooks
  const navigate = useNavigate();

  // Render
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      header="Create new"
      extraClassNames={styles}
    >
      <div className={styles.mainContainer}>
        <CreateNewModalCard
          title="Study"
          iconName={faBookOpenReader}
          extraClassName={styles.newStudyCardHeader}
          description="Create a workspace to gather and present acquired knowledge and insights from your technical or scientific research studies."
        >
          <CreateStudyModal onCreationDone={closeModal} />
        </CreateNewModalCard>
        <CreateNewModalCard
          title="Entity"
          iconName={faDiceD6}
          extraClassName={styles.newEntityCardHeader}
          description="Create an encyclopedia-style page within your Universe, focusing on a specific entity like a technology, material, or company."
        >
          <CreateEntityModal onCreationDone={closeModal} />
        </CreateNewModalCard>
        <CreateNewModalCard
          title="Document"
          iconName={faFile}
          extraClassName={styles.newDocCardHeader}
          description="Manually add a scientific article, patent or webpage directly into your inbox by providing the DOI, patent number or URL."
        >
          <AddDocument
            onCreationDone={(createdSavedDocument: ISavedDocumentDTO) => {
              closeModal();
              navigate(`/library/documents/${createdSavedDocument.id}`);
            }}
          />
        </CreateNewModalCard>
        <HasAdvanced>
          <CreateNewModalCard
            title="Query"
            iconName={faMagnifyingGlass}
            extraClassName={styles.newQueryCardHeader}
            description="Create a query in order to search through millions of documents."
          >
            <CreateQueryModal onCreationDone={closeModal} />
          </CreateNewModalCard>
        </HasAdvanced>
      </div>
    </Modal>
  );
};
