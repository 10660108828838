// node_modules
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, MouseEvent, useRef } from "react";
// Styles
import styles from "./textSelectionMenuPopupItem.module.scss";
// Custom hooks
import { useTextSelection } from "Hooks";
// Enums
import { TextSelectionMenuPopupItemEnum } from "Enums";

export type TTextSelectionMenuPopupItemProps = {
  title: TextSelectionMenuPopupItemEnum | string;
  icon: IconDefinition;
  isChildrenDisplayed: boolean;
  children?: JSX.Element[] | JSX.Element | null;
  onClickHandler: (mouseEvent: MouseEvent<HTMLDivElement>) => void;
};

export const TextSelectionMenuPopupItem: FC<TTextSelectionMenuPopupItemProps> =
  ({
    title,
    icon,
    isChildrenDisplayed,
    children,
    onClickHandler,
  }: TTextSelectionMenuPopupItemProps) => {
    // Ref
    const menuItemContainerRef = useRef<HTMLDivElement>(null);

    // Custom hooks
    const { preventUnselection } = useTextSelection();

    // Render
    return (
      <div>
        <div
          className={styles.textSelectionMenuPopupItemContainer}
          ref={menuItemContainerRef}
          onMouseDown={preventUnselection}
          onClick={onClickHandler}
        >
          <div className={styles.textSelectionMenuPopupItemIconContainer}>
            <FontAwesomeIcon icon={icon} />
          </div>
          {title.toString()}
        </div>
        {isChildrenDisplayed ? (
          <div className={styles.textSelectionMenuPopupItemSecondaryContainer}>
            {children}
          </div>
        ) : null}
      </div>
    );
  };
