// node_modules
import { useCallback, useEffect, useRef, useState } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
// Constants
import { GeneralConstants } from "Constants";
// Enums
import { ObjectTypeEnum } from "Enums";

interface IUseReferenceLinkProps {
  ref: HTMLDivElement | null;
}

export const useReference = ({ ref }: IUseReferenceLinkProps) => {
  const [referenceLink, setReferenceLink] =
    useState<TIdNameTypeObjectType | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | undefined>(undefined);

  const setReferenceLinkTimeout = useRef<NodeJS.Timeout | null>(null);

  const clearSetReferenceLinkTimeout = useCallback(() => {
    if (setReferenceLinkTimeout.current) {
      clearTimeout(setReferenceLinkTimeout.current);
      setReferenceLinkTimeout.current = null;
    }
  }, []);

  const resetReference = useCallback(() => {
    setReferenceElement(undefined);
    setReferenceLink(null);
    clearSetReferenceLinkTimeout();
  }, [clearSetReferenceLinkTimeout]);

  useEffect(() => {
    const refElement = ref;

    const mouseOverHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const referenceId = target.getAttribute("id");
      const referenceType = target.getAttribute("type");
      const url = target.getAttribute("href");

      if ((target.tagName !== "A" && target.tagName !== "SPAN") || !url) {
        resetReference();
        return;
      }

      setReferenceLinkTimeout.current = setTimeout(() => {
        setReferenceLink({
          id: referenceId ?? "",
          objectType: referenceType
            ? parseInt(referenceType, 10)
            : ObjectTypeEnum.Weblink,
          type: "",
          name: url,
        });
      }, GeneralConstants.DEFAULT_POPOVER_MS_DELAY);
      setReferenceElement(target);
    };

    if (refElement) {
      refElement.addEventListener("mouseover", mouseOverHandler);
    }

    return () => {
      if (refElement) {
        refElement.removeEventListener("mouseover", mouseOverHandler);
        clearSetReferenceLinkTimeout();
      }
    };
  }, [clearSetReferenceLinkTimeout, ref, resetReference]);

  return { referenceLink, referenceElement, resetReference };
};
