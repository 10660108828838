// node_modules
import { faPencilAlt, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Node } from "prosemirror-model";
import { FC, useContext, useEffect, useMemo, useState } from "react";
// Components
import {
  MarkdownEditor,
  FindestButton,
  MarkdownItComponent,
  findestMarkdownParser,
} from "Components";
// Helpers
import {
  ProseMirrorHelperSingleton,
  ProseMirrorPluginHelperSingleton,
} from "Helpers";
// Styles
import styles from "./saveableEditableMarkdown.module.scss";
// Contexts
import { AuthContext } from "Providers";

type TSaveableEditableMarkdownProps = {
  source: string;
  noSourcePlaceholder: string;
  isEditing: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  onSaveClick: (savedText: string) => void;
  onCancelClick: () => void;
  isHighlighted?: boolean;
  doHideMarkdown?: boolean;
  extraClassNames?: { editModeButton?: string };
  editModeButtonText?: string;
  saveButtonTitle?: string;
  hasContentBorder?: boolean;
};

export const SaveableEditableMarkdown: FC<TSaveableEditableMarkdownProps> = ({
  source,
  noSourcePlaceholder,
  isEditing,
  setIsEditing,
  onSaveClick,
  onCancelClick,
  isHighlighted,
  doHideMarkdown,
  extraClassNames = {},
  editModeButtonText = "Edit mode",
  saveButtonTitle,
  hasContentBorder,
}: TSaveableEditableMarkdownProps) => {
  // State
  const [editableMarkdown, setEditableMarkdown] = useState<string>(source);

  // Contexts
  const { auth } = useContext(AuthContext);

  // Logic
  useEffect(() => {
    setEditableMarkdown(source);
  }, [source]);

  // isSaveButtonDisabled
  const isSaveButtonDisabled = useMemo(() => {
    // init new is save button disabled
    let newIsSaveButtonDisabled = false;

    // if editable markdown is empty, disable save button
    newIsSaveButtonDisabled = editableMarkdown.length <= 0;

    // parse editable markdown
    const parsedEditableMarkdown: Node | null =
      findestMarkdownParser.parse(editableMarkdown);

    // if parsed editable markdown is defined
    if (parsedEditableMarkdown) {
      newIsSaveButtonDisabled =
        newIsSaveButtonDisabled ||
        ProseMirrorHelperSingleton.isNodeEmpty(parsedEditableMarkdown);
    }

    // return new is save button disabled
    return newIsSaveButtonDisabled;
  }, [editableMarkdown]);

  // Render
  return isEditing ? (
    <>
      <MarkdownEditor
        source={source}
        plugins={ProseMirrorPluginHelperSingleton.getDefault(
          auth,
          noSourcePlaceholder
        )}
        onSourceChange={(newSource: string) => setEditableMarkdown(newSource)}
        extraClassNames={
          isHighlighted
            ? {
                proseMirrorEditor: !hasContentBorder
                  ? styles.proseMirrorEditor
                  : "",
                wysiwygContent: hasContentBorder
                  ? styles.wysiwygContentBordered
                  : styles.wysiwygContent,
              }
            : {}
        }
      />
      <div className={styles.editingButtonContainer}>
        <FindestButton
          buttonType="primary"
          titleAttribute={saveButtonTitle ? saveButtonTitle : "Save"}
          title={saveButtonTitle ? saveButtonTitle : "Save"}
          isDisabled={isSaveButtonDisabled}
          onClick={() => onSaveClick(editableMarkdown)}
        />
        <FindestButton
          buttonType="cancel"
          titleAttribute="Cancel"
          title="Cancel"
          onClick={() => {
            setEditableMarkdown(source);
            onCancelClick();
          }}
        />
      </div>
    </>
  ) : (
    <div className={styles.isViewMode}>
      {(doHideMarkdown === undefined || doHideMarkdown === false) && (
        <MarkdownItComponent
          source={editableMarkdown}
          noSourcePlaceholder={noSourcePlaceholder}
          onDoubleClickHandler={
            setIsEditing ? () => setIsEditing(true) : undefined
          }
        />
      )}
      {setIsEditing ? (
        <button
          className={`${extraClassNames.editModeButton} ${
            !doHideMarkdown ? styles.editModeButton : ""
          }`}
          title={editModeButtonText}
          type="button"
          onClick={() => setIsEditing(true)}
        >
          {doHideMarkdown && editModeButtonText}
          <FontAwesomeIcon icon={doHideMarkdown ? faPlus : faPencilAlt} />
        </button>
      ) : null}
    </div>
  );
};
