// node_modules
import { ChangeEvent, FC, LegacyRef } from "react";

interface IFileInputProps {
  title?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  extraClassNames?: {
    input?: string;
  };
}

export const FileInput: FC<IFileInputProps> = ({
  title,
  inputRef,
  onChange,
  extraClassNames,
}: IFileInputProps) => {
  return (
    <input
      title={title ?? "File upload button"}
      ref={inputRef}
      type="file"
      onChange={onChange}
      className={extraClassNames?.input ?? ""}
    />
  );
};
