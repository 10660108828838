// node_modules
import { FC } from "react";
// Components
import { CollapsibleListItem } from "./CollapsibleListItem";
// Interfaces
import { ICollapsibleListProps } from "Interfaces";

export const CollapsibleList: FC<ICollapsibleListProps> = ({ items, collapsedItems, onToggle, onClick, ItemRenderer }: ICollapsibleListProps) => {
  return (
    <ul>
      {items.map((item) => (
        <CollapsibleListItem
          key={item.id}
          item={item}
          collapsedItems={collapsedItems}
          onToggle={onToggle}
          onClick={onClick}
          ItemRenderer={ItemRenderer}          
        />
      ))}
    </ul>
  );
};
