// nodes_modules
import { Editor } from "@tiptap/core";
import { Heading } from "@tiptap/extension-heading";
import { Link } from "@tiptap/extension-link";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Table } from "@tiptap/extension-table";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { Text } from "@tiptap/extension-text";
import { Content, JSONContent, NodePos } from "@tiptap/react";
// Constants
import { EditorConstants } from "Constants";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Controllers
import { RatingControllerSingleton } from "Controllers";
// Helpers
import {
  getRatingContent,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Types
import {
  TEntityDetailDTO,
  TGetAllRatingsOfObjectDTO,
  TOverviewTableObject,
} from "Types";
// Interfaces
import { IObject } from "Interfaces";

export const useOverviewTable = () => {
  const getIsOverviewTableAlreadyInDocument = (
    editor: Editor | null
  ): boolean => {
    if (!editor) return false;

    const $headings: NodePos[] | null = editor.$nodes("heading");

    if ($headings) {
      for (const $heading of $headings) {
        if (
          $heading.node.textContent
            .toLowerCase()
            .includes(EditorConstants.OVERVIEW_TABLE_HEADING_TEXT.toLowerCase())
        ) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "A results overview table is already in the document, please remove it first."
          );
          return true;
        }
      }
    }

    return false;
  };

  const recursivelyGetAllBottomChildrenCount = (
    tableObject: TOverviewTableObject,
    doSkipIsNotChecked = false
  ) => {
    // if do skip is not checked and table object is not checked, return 0
    if (doSkipIsNotChecked && !tableObject.isChecked) return 0;

    // if table object has no children, return 1
    if (tableObject.children.length === 0) return 1;

    // init recursive count
    let count = 0;
    // go over each child
    for (const child of tableObject.children) {
      // get recursively all bottom children count
      count += recursivelyGetAllBottomChildrenCount(child, doSkipIsNotChecked);
    }

    // if do skip is not checked and count is 0 and table object has children and is checked, set count to 1
    // because then the current table object is the only checked object in the tree at this point
    if (
      doSkipIsNotChecked &&
      count === 0 &&
      tableObject.children.length > 0 &&
      tableObject.isChecked
    ) {
      count = 1;
    }

    // return count
    return count;
  };

  const initRatingsFromOverviewTableDataAsync = async (
    overviewTableData: (TOverviewTableObject | null)[][],
    objectEdited: IObject,
    isRatingEnabledPerLayerIndex: Map<number, boolean>
  ): Promise<TGetAllRatingsOfObjectDTO | undefined> => {
    // init data to predefine rating
    const targetId: Set<string> = new Set<string>();
    // go through each row
    for (
      let tableRowIndex = 0;
      tableRowIndex < overviewTableData.length;
      tableRowIndex++
    ) {
      // get table row
      const tableRow = overviewTableData[tableRowIndex];
      // go through each cell
      for (
        let tableCellIndex = 0;
        tableCellIndex < tableRow.length;
        tableCellIndex++
      ) {
        // get table cell
        const tableCell = tableRow[tableCellIndex];

        // if is rating enabled for layer index
        if (
          (isRatingEnabledPerLayerIndex.get(tableCellIndex) ?? false) &&
          tableCell &&
          tableCell.objectType === ObjectTypeEnum.Entity
        ) {
          // add table cell object id to targetId if tableCell is defined
          if (tableCell) {
            targetId.add(tableCell.objectId);
          }
        }
      }
    }

    // targetId as array
    const targetIdArray = Array.from(targetId);

    // init all ratings of object edited
    let allRatingsOfObjectEdited: TGetAllRatingsOfObjectDTO = {
      overview: {
        id: "",
        name: "",
        type: "",
        entities: [],
      },
    };

    // if target id array is not empty
    if (targetIdArray.length > 0) {
      // predefine rating
      const isSuccess = await RatingControllerSingleton.predefineRatingAsync(
        objectEdited.id,
        objectEdited.objectType,
        targetIdArray
      );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not predefine rating."
        );
        // stop here, return
        return undefined;
      }

      // get all ratings for object
      const newAllRatingsOfObjectEdited: TGetAllRatingsOfObjectDTO | undefined =
        await RatingControllerSingleton.getOverviewAsync(
          ObjectTypeHelperSingleton.getObjectTypeDropdownButtonAction(
            objectEdited.objectType
          ),
          objectEdited.id
        );
      // safety-checks
      if (!newAllRatingsOfObjectEdited) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not get all ratings of object."
        );
        // stop here, return
        return undefined;
      }

      // assign newAllRatingsOfObjectEdited to allRatingsOfObjectEdited
      allRatingsOfObjectEdited = {
        ...newAllRatingsOfObjectEdited,
      };
    }

    return allRatingsOfObjectEdited;
  };

  // check if two overview table objects are the same
  const isSameOverviewTableObject = (
    objectA: TOverviewTableObject | null | undefined,
    objectB: TOverviewTableObject | null | undefined
  ) => {
    return (
      objectA &&
      objectB &&
      ((objectA.numbering !== "" &&
        objectB.numbering !== "" &&
        objectA.numbering === objectB.numbering) ||
        (objectA.numbering === "" &&
          objectB.numbering === "" &&
          objectA.name === objectB.name)) &&
      objectA?.parent?.objectId === objectB?.parent?.objectId
    );
  };

  const getOverviewTableContent = (
    allRatingsOfObjectEdited: TGetAllRatingsOfObjectDTO,
    overviewTableData: (TOverviewTableObject | null)[][],
    headerTitles: string[],
    isRatingEnabledPerLayerIndex: Map<number, boolean>,
    isNumbered: boolean,
    objectEdited: IObject
  ): Content => {
    const overviewTable: JSONContent[] = [
      {
        type: Heading.name,
        attrs: {
          level: 1,
        },
        content: [
          {
            type: Text.name,
            text: EditorConstants.OVERVIEW_TABLE_HEADING_TEXT,
          },
        ],
      },
      {
        type: Table.name,
        content: [],
      },
    ];

    // make a map of TEntityDetailDTO per object id using allRatingsOfObjectEdited.overview.entities array
    const entityDetailPerObjectId: Map<string, TEntityDetailDTO> = new Map<
      string,
      TEntityDetailDTO
    >();
    // go through each entity
    for (const entity of allRatingsOfObjectEdited.overview.entities) {
      // add entity to entityDetailPerObjectId
      entityDetailPerObjectId.set(entity.objectId, entity);
    }

    // Hold track of entities which are already shown
    const shownEntities: Set<string> = new Set<string>();

    // Filter unchecked objects and duplicates from tableObjectData
    let newTableObjectData: (TOverviewTableObject | null)[][] = [];
    for (const tableRow of overviewTableData) {
      // filter out tableCells which are null
      const filteredTableRow = tableRow.filter(
        (tableCell) => tableCell !== null
      );
      // Get the last tableCell of the filteredTableRow which is checked
      const lastCheckedTableCell = filteredTableRow
        .filter((tableCell) => tableCell?.isChecked)
        .pop();

      // Safety check
      if (!lastCheckedTableCell) continue;

      // If the lastCheckedTableCell is already shown, continue
      if (shownEntities.has(lastCheckedTableCell.objectId)) continue;

      // add lastCheckedTableCell to shownEntities
      shownEntities.add(lastCheckedTableCell.objectId);

      // Replace unchecked objects with null
      const newTableRow = tableRow.map((tableCell) => {
        if (!tableCell || tableCell.isChecked) return tableCell;
        return null;
      });
      newTableObjectData.push(newTableRow);
    }

    // Check if the last one in each row is not already used in the row after
    for (let i = 0; i < newTableObjectData.length - 1; i++) {
      const currentTableRow = newTableObjectData[i];
      const nextTableRow = newTableObjectData[i + 1];

      // Get the last checked tableCell of the currentTableRow
      const lastCheckedTableCell = currentTableRow
        .filter((tableCell) => tableCell?.isChecked)
        .pop();

      // check if you can find last checked table cell in next table row
      const isDuplicateWithNext = nextTableRow.some((tableCell) =>
        isSameOverviewTableObject(tableCell, lastCheckedTableCell)
      );
      // If this is a duplicate then set the current table row to nulls
      if (isDuplicateWithNext) {
        newTableObjectData[i] = currentTableRow.map(() => null);
      }

      // if i > 0
      if (i > 0) {
        // get previous table row
        const previousTableRow = newTableObjectData[i - 1];

        // check if you can find last checked table cell in previous table row
        const isDuplicateWithPrevious = previousTableRow.some((tableCell) =>
          isSameOverviewTableObject(tableCell, lastCheckedTableCell)
        );

        // if is duplicate with previous
        if (isDuplicateWithPrevious) {
          // set the current table row to nulls
          newTableObjectData[i] = currentTableRow.map(() => null);
        }
      }
    }

    // Remove rows which are only null from the newTableObjectData
    newTableObjectData = newTableObjectData.filter((tableRow) =>
      tableRow.some((tableCell) => tableCell !== null)
    );

    const tableHeaders: JSONContent[] = [];

    // prepare header cells
    for (let i = 0; i < headerTitles.length; i++) {
      let headerTitle = headerTitles[i].trim();
      if (!headerTitle) {
        headerTitle = " ";
      }

      tableHeaders.push({
        type: TableHeader.name,
        attrs: {
          colspan: 1,
          rowspan: 1,
          colwidth: null,
        },
        content: [
          {
            type: Paragraph.name,
            content: [
              {
                type: Text.name,
                text: headerTitle,
              },
            ],
          },
        ],
      });
    }

    const headersTableRow: JSONContent = {
      type: TableRow.name,
      content: tableHeaders,
    };

    overviewTable[1].content?.push(headersTableRow);

    // map to track rows span per cell
    const rowsSpanPerCell: Map<string, number> = new Map<string, number>();

    // go through each row
    for (
      let tableRowIndex = 0;
      tableRowIndex < newTableObjectData.length;
      tableRowIndex++
    ) {
      // get table row
      const tableRow = newTableObjectData[tableRowIndex];

      const cellsTableRow: JSONContent = {
        type: TableRow.name,
        content: [],
      };

      // go through each cell
      for (
        let tableCellIndex = 0;
        tableCellIndex < tableRow.length;
        tableCellIndex++
      ) {
        // get is rating enabled for layer index
        const isRatingEnabledForLayerIndex =
          isRatingEnabledPerLayerIndex.get(tableCellIndex) ?? false;

        // get table cell
        const tableCell = tableRow[tableCellIndex];
        // rows span per cell key
        const rowsSpanPerCellKey = tableCell
          ? tableCell.parent
            ? `${tableCell.numbering}_${tableCell.parent.numbering}`
            : `${tableCell.numbering}`
          : "";

        const paragraph: Content = {
          type: Paragraph.name,
          content: [],
        };

        // init cell rows span to 1
        let cellRowsSpan = 1;

        // if tableCell is not null and object id is not in rowsSpanPerCell
        if (tableCell && !rowsSpanPerCell.has(rowsSpanPerCellKey)) {
          // get cell rows span from table object
          cellRowsSpan = recursivelyGetAllBottomChildrenCount(tableCell, true);
          cellRowsSpan = cellRowsSpan === 0 ? 1 : cellRowsSpan;

          // add cell rows span to rowsSpanPerCell
          rowsSpanPerCell.set(rowsSpanPerCellKey, cellRowsSpan);

          // if is numbered
          if (isNumbered) {
            const text: Content = {
              type: Text.name,
              text: `${tableCell.numbering} `,
            };
            paragraph.content?.push(text);
          }

          // if tableCell is checked
          if (tableCell.isChecked) {
            let cellText = tableCell.name.trim();
            if (!cellText) {
              cellText = " ";
            }

            const text: Content = {
              type: Text.name,
              text: cellText,
              marks: [
                {
                  type: Link.name,
                  attrs: {
                    href: `${ObjectTypeHelperSingleton.getNavigateUrlBasedOnObjectType(
                      tableCell.objectType,
                      tableCell.objectId
                    )}`,
                    id: tableCell.objectId,
                    type: tableCell.objectType,
                  },
                },
              ],
            };

            paragraph.content?.push(text);
          }

          paragraph.content?.push({
            type: Text.name,
            text: " ",
          });

          // if is rating enabled for layer index
          if (
            isRatingEnabledForLayerIndex &&
            tableCell.objectType === ObjectTypeEnum.Entity
          ) {
            // get entity detail for tableCell object id
            const entityDetail: TEntityDetailDTO | undefined =
              entityDetailPerObjectId.get(tableCell.objectId);

            // safety-checks
            if (entityDetail) {
              paragraph.content?.push(
                getRatingContent(
                  entityDetail.averageRating.score,
                  objectEdited.id,
                  objectEdited.objectType,
                  tableCell.objectId,
                  entityDetail.averageRating.count,
                  !entityDetail.isRatedByCurrentUser
                )
              );
            }
          }

          cellsTableRow.content?.push({
            type: TableCell.name,
            attrs: {
              colspan: 1,
              rowspan: cellRowsSpan,
              colwidth: null,
            },
            content: [paragraph],
          });
        } else if (!tableCell) {
          cellsTableRow.content?.push({
            type: TableCell.name,
            attrs: {
              colspan: 1,
              rowspan: cellRowsSpan,
              colwidth: null,
            },
            content: [paragraph],
          });
        }
      }

      overviewTable[1].content?.push(cellsTableRow);
    }

    return overviewTable;
  };

  return {
    initRatingsFromOverviewTableDataAsync,
    getIsOverviewTableAlreadyInDocument,
    getOverviewTableContent,
    recursivelyGetAllBottomChildrenCount,
  };
};
