import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { EdgeProps, getBezierPath } from "reactflow";
// Styles
import styles from "./buttonEdge.module.scss";

const foreignObjectSize = 36;

type TButtonEdgeProps = EdgeProps & {
  onEdgeDelete: (edgeId: string) => void;
  isViewOnlyMode: boolean;
};

export const ButtonEdge: FC<TButtonEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  onEdgeDelete,
  isViewOnlyMode,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  return (
    <>
      <path
        id={`${id}_selector`}
        style={style}
        className={styles.reactFlowEdgePathSelector}
        d={edgePath}
        fillRule="evenodd"
      />
      <path
        id={id}
        style={style}
        className={styles.reactFlowEdgePath}
        d={edgePath}
        markerEnd={markerEnd}
        fillRule="evenodd"
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className={styles.edgeButtonForeignObject}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        {!isViewOnlyMode && (
          <div>
            <button
              title="Delete link"
              type="button"
              className={styles.edgeButton}
              onClick={() => {
                onEdgeDelete(id);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        )}
      </foreignObject>
    </>
  );
};
