// node_modules
import axios from "axios";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";

export class AxiosHelper {
    public getServerBaseURL(): string {
        axios.defaults.withCredentials = true;
        return process.env.REACT_APP_API_BASE_URL || `https://${window.location.host}/`;
    }

    public setCommonHeaderConfigDefaults(commonHeaderName: string, commonHeaderValue: string): void {
        axios.defaults.headers.common[commonHeaderName] = commonHeaderValue;
    }

    public getCommonHeader(commonHeaderName: string): string | number | boolean | undefined {
        return axios.defaults.headers.common[commonHeaderName];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayAxiosError(error: any, defaultErrorMessage = "Something went wrong."): void {
        let errorMessage = defaultErrorMessage;
        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        } else if (error.response && error.response.message) {
            errorMessage = error.response.message;
        } else if (error.message) {
            errorMessage = error.message;
        }

        // show error message
        ToastHelperSingleton
            .showToast(ToastTypeEnum.Error, errorMessage);
    }
}

export const AxiosHelperSingleton = new AxiosHelper();