import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { QRCodeSVG } from "qrcode.react";
import { FC, useEffect, useState } from "react";
// Components
import { FindestButton, FindestTextBox } from "Components";
// Controllers
import { AuthControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Styles
import styles from "./configureAuthenticatorApp.module.scss";

type ConfigureAuthenticatorAppProps = {
  onBack: () => void;
  onVerify: (recoveryCodes: string[]) => void;
};

export const ConfigureAuthenticatorApp: FC<ConfigureAuthenticatorAppProps> = ({
  onBack,
  onVerify,
}) => {
  const [otpAuthUrl, setOtpAuthUrl] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [sharedKey, setSharedKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchTwoFactorAuthKey = async () => {
      try {
        const data =
          await AuthControllerSingleton.getTwoFactorAuthenticationKey();
        if (data) {
          setOtpAuthUrl(data.authenticatorUri);
          setSharedKey(data.sharedKey);
        }
      } catch (error) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "An error occurred while fetching the two factor authentication key."
        );
      }
    };

    fetchTwoFactorAuthKey(); // Trigger data fetching
  }, []);

  const handleVerify = async () => {
    if (!verificationCode) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Please enter a verification code."
      );
      return;
    }

    const result =
      await AuthControllerSingleton.verifyTwoFactorAuthenticationCode(
        verificationCode
      );

    if (result && result.recoveryCodes.length > 0) {
      onVerify(result.recoveryCodes);
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Verification code is invalid. Please try again."
      );
    }
  };

  return (
    <div className={styles.configureAuthenticator}>
      <FindestButton
        title="back"
        buttonType="secondary"
        leftIconName={faArrowLeft}
        onClick={onBack}
        extraClassName={styles.backButton}
      />
      <h2 className={styles.configureAuthenticatorAppHeader}>
        Configure Authenticator App
      </h2>
      <p>To use an authenticator app go through the following steps:</p>
      <ol>
        <li>
          <p>Download a two-factor authenticator app:</p>
        </li>
        <ul>
          <li>
            <p>
              <b>Microsoft Authenticator</b> for{" "}
              <a
                rel="noreferrer noopener"
                target="_blank"
                className={styles.link}
                href="https://go.microsoft.com/fwlink/?linkid=2273142"
              >
                Android
              </a>{" "}
              and{" "}
              <a
                rel="noreferrer noopener"
                target="_blank"
                className={styles.link}
                href="https://go.microsoft.com/fwlink/?linkid=2273143"
              >
                iOS
              </a>
            </p>
          </li>
          <li>
            <b>Google Authenticator</b> for{" "}
            <a
              rel="noreferrer noopener"
              target="_blank"
              className={styles.link}
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"
            >
              Android
            </a>{" "}
            and{" "}
            <a
              rel="noreferrer noopener"
              target="_blank"
              className={styles.link}
              href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
            >
              iOS
            </a>
            .
          </li>
        </ul>
        <li>
          <p>
            Scan <b>the QR Code or enter the key</b> into your two factor
            authenticator app. Spaces and casing do not matter.
          </p>
          <div className={styles.codeSection}>
            {otpAuthUrl && <QRCodeSVG value={otpAuthUrl} size={182} />}
            <div className={styles.formattedKeySection}>
              <p> {sharedKey} </p>
            </div>
          </div>
        </li>
        <li>
          <p>
            Once you have scanned the QR code or input the key above, your two
            factor authentication app will provide you with a{" "}
            <b>unique verification code.</b> Enter the code in the confirmation
            box below.
          </p>
          <div className={styles.verificationCodeSection}>
            <h3>Verification Code</h3>
            <FindestTextBox
              placeholder=""
              extraClassName={styles.verificationCodeInput}
              onChange={setVerificationCode}
            />
            <FindestButton
              title="Verify"
              buttonType="primary"
              onClick={handleVerify}
            />
          </div>
        </li>
      </ol>
    </div>
  );
};
