import {
  faArrowsAltH,
  faGreaterThan,
  faInfinity,
  faLessThan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DivDropdown } from "Components/Shared/DivDropdown";
import { MathematicalOperationEnum } from "Enums";
import { FC } from "react";

import styles from "./unitComparisonDropdown.module.scss";

export type TUnitComparisonDropdownProps = {
  // Should be temporary till all units are imported inlcuding patents
  mathematicalOperation: MathematicalOperationEnum;
  selectMathOperation: (operation: MathematicalOperationEnum) => void;
  overriddenStyles?: { [key: string]: string };
};

export const UnitComparisonDropdown: FC<TUnitComparisonDropdownProps> = ({
  mathematicalOperation,
  selectMathOperation,
  overriddenStyles,
}) => {
  const mathematicalOperationList = [
    MathematicalOperationEnum.GreaterThan,
    MathematicalOperationEnum.LessThan,
    MathematicalOperationEnum.Between,
    MathematicalOperationEnum.None,
  ];

  const onSelectOperation = (selected: any) => {
    const selectedOperation = selected as MathematicalOperationEnum;
    selectMathOperation(selectedOperation);
  };

  const getSelectedObject = () => {
    return mathematicalOperationList.indexOf(mathematicalOperation);
  };

  return (
    <DivDropdown
      overridenStyle={overriddenStyles ? overriddenStyles : styles}
      className={
        overriddenStyles
          ? overriddenStyles.unitComparisonEditDropdown
          : styles.unitComparisonDropdown
      }
      objectList={mathematicalOperationList}
      newObjectSelected={onSelectOperation}
      selectedObjectIndex={getSelectedObject()}
    >
      <div>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonIcon
              : styles.comparisonIcon
          }
          title="Greater than"
        >
          <FontAwesomeIcon icon={faGreaterThan} />
        </span>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonChoice
              : styles.comparisonChoice
          }
        >
          Greater than
        </span>
      </div>
      <div>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonIcon
              : styles.comparisonIcon
          }
          title="Less than"
        >
          <FontAwesomeIcon icon={faLessThan} />
        </span>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonChoice
              : styles.comparisonChoice
          }
        >
          Less than
        </span>
      </div>
      <div>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonIcon
              : styles.comparisonIcon
          }
          title="Between"
        >
          <FontAwesomeIcon icon={faArrowsAltH} />
        </span>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonChoice
              : styles.comparisonChoice
          }
        >
          Between
        </span>
      </div>
      <div>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonIcon
              : styles.comparisonIcon
          }
          title="All values"
        >
          <FontAwesomeIcon icon={faInfinity} />
        </span>
        <span
          className={
            overriddenStyles
              ? overriddenStyles.comparisonChoice
              : styles.comparisonChoice
          }
        >
          All values
        </span>
      </div>
    </DivDropdown>
  );
};
