// node_modules
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  ChangeEvent,
  FC,
  FormEvent,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import { AccountBox, MaintenanceBox } from "Components";
import { LoginImageSlider } from "./LoginImageSlider/LoginImageSlider";
import { LoginOptions } from "./LoginOptions/LoginOptions";
import { LoginWithEmail } from "./LoginWithEmail/LoginWithEmail";
import { LoginWithMagicLink } from "./LoginWithMagicLink/LoginWithMagicLink";
import { LoginWithPassword } from "./LoginWithPassword/LoginWithPassword";
import { MagicLinkMessage } from "./MagicLinkMessage/MagicLinkMessage";
// Controllers
import { AuthControllerSingleton } from "Controllers";
// Contexts
import { AuthContext } from "Providers";
// Types
import { TJsonWebTokenDTO, TLoginOptionsDTO, TLoginResponseDTO } from "Types";
// Images
import FindestUniverseLogo from "Assets/Images/universe_logo_color.png";
// Constants
import { ErrorConstants } from "Constants";
// Helpers
import {
  AuthenticationHelperSingleton,
  AxiosHelperSingleton,
  ExtensionCommunicationHelperSingleton,
} from "Helpers";
// Styles
import styles from "./loginPage.module.scss";

export const LoginPage: FC = () => {
  // Contexts
  const { auth, setAuth, setDefaultAuth } = useContext(AuthContext);

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Ref
  const formRef = useRef<HTMLFormElement>(null);

  // Const
  const magicTokenFromParams = params.get("token");
  const emailFromParams = params.get("email");
  const isRememberMeEnabledParams = params.get("isRememberMeEnabled");
  const sharedObjectURLFromParams = params.get("sharedObjectURL");

  // State
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [email, setEmail] = useState(emailFromParams ? emailFromParams : "");
  const [sharedObjectURL, setSharedObjectURL] = useState(
    sharedObjectURLFromParams ? sharedObjectURLFromParams.trim() : ""
  );
  const [password, setPassword] = useState("");
  const [loginOptions, setLoginOptions] =
    useState<TLoginOptionsDTO | undefined>(undefined);
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  // Logic
  useEffect(() => {
    // init auth updates
    const authUpdates: { isRememberMeEnabled: boolean; userEmail: string } = {
      isRememberMeEnabled: true,
      userEmail: "",
    };
    let doUpdateAuth = false;

    if (sharedObjectURLFromParams) {
      setSharedObjectURL(sharedObjectURLFromParams.trim());
    }

    if (
      isRememberMeEnabledParams !== null &&
      isRememberMeEnabledParams !== undefined
    ) {
      // set isRememberMeEnabled and userEmail from params
      authUpdates.isRememberMeEnabled =
        isRememberMeEnabledParams.toLocaleUpperCase() === "TRUE";
      doUpdateAuth = doUpdateAuth || true;
    }

    if (emailFromParams) {
      setEmail(emailFromParams);
      authUpdates.userEmail = emailFromParams;
      doUpdateAuth = doUpdateAuth || true;
    }

    // if doUpdateAuth is true
    if (doUpdateAuth) {
      // update auth context
      setAuth((prevAuth) => ({
        ...prevAuth,
        ...authUpdates,
      }));
    }
  }, [
    emailFromParams,
    isRememberMeEnabledParams,
    sharedObjectURLFromParams,
    setAuth,
  ]);

  // validate the form to check if the email address is valid
  const validateForm = (): boolean => {
    // safety-checks
    if (formRef.current) {
      // check if the form is valid
      return formRef.current.checkValidity();
    }

    // return false
    return false;
  };

  // prevent the real submission of the form
  const onFormSubmit = (submitEvent: FormEvent<HTMLFormElement>): boolean => {
    // if login options are not set
    if (!loginOptions) {
      // then go to the next step
      onNextButtonClickAsync();
    } else if (password.trim().length > 0) {
      // otherwise, if the password is set
      // then try to log in with the password
      onLoginButtonClickAsync();
    }

    // prevent the real submission of the form
    submitEvent.preventDefault();
    submitEvent.stopPropagation();

    // return false
    return false;
  };

  // check if the email is valid and receive the login options
  const onNextButtonClickAsync = async (): Promise<void> => {
    // check if the provided email is correct
    if (!validateForm() || email.trim().length <= 0) {
      // stop execution, return
      return;
    }

    // if there is an error message
    if (errorMessage.length > 0) {
      // then clear it
      setErrorMessage("");
    }

    // retrieve the login options from the server and set the state
    const receivedLoginOptions =
      await AuthControllerSingleton.getLoginOptionsAsync(email);

    // safety-checks
    if (!receivedLoginOptions) {
      // show an error message
      setErrorMessage("Could not login with the given email address.");
      return;
    }

    // if the user does not have access to the universe
    if (!receivedLoginOptions.hasAccessToUniverse) {
      // show an error message
      setErrorMessage(ErrorConstants.NO_ACCESS_TO_UNIVERSE);
      // stop execution, return
      return;
    }

    // if authentication type is OKTA
    if (receivedLoginOptions.authenticationType === "OKTA") {
      // redirect to the authorize url
      window.location.href = receivedLoginOptions.authModel.authorizeUrl;
      // stop execution, return
      return;
    }

    setAuth((prevAuth) => {
      return {
        ...prevAuth,
        userEmail: email,
        hasPassword: receivedLoginOptions.hasPassword,
      };
    });

    // if the user has no password and the authentication type is not OKTA
    if (
      !receivedLoginOptions.hasPassword &&
      receivedLoginOptions.authenticationType !== "OKTA"
    ) {
      // then send magic link automatically
      await requestMagicLinkAsync();
    }

    // set login options state
    setLoginOptions(receivedLoginOptions);
  };

  const requestMagicLinkAsync = async (
    isRequestingNewMagicLink?: boolean
  ): Promise<void> => {
    // changing is magic link sent state
    setIsMagicLinkSent(true);

    // send the magic link to the user
    const isSuccess = await AuthControllerSingleton.requestMagicLinkAsync(
      email,
      auth.isRememberMeEnabled,
      sharedObjectURL
    );

    // safety-checks
    if (!isSuccess) {
      // set default auth state in context
      setDefaultAuth();
      // show an error message
      setErrorMessage("Error while requesting magic link. Please contact us.");
    }

    // if it is requesting new magic link
    if (isRequestingNewMagicLink) {
      // set error message to empty
      setErrorMessage("");
      // set login options to undefined
      setLoginOptions(undefined);
      // remove params from location
      navigate(location.pathname);
    }
  };

  // try to log the user in with a password
  const onLoginButtonClickAsync = async (): Promise<void> => {
    // safety-checks
    if (password.trim().length <= 0) {
      // show an error message
      setErrorMessage("Please enter a password.");
      return;
    }

    // try to log the user in with the provided password
    const loginResponse: TLoginResponseDTO | undefined =
      await AuthControllerSingleton.loginAsync(
        email,
        password,
        auth.isRememberMeEnabled
      );

    // safety-checks
    if (!loginResponse) {
      // set default auth state in context
      setDefaultAuth();
      // show an error message
      setErrorMessage(
        "Could not login with the given credentials. Please contact us."
      );
      return;
    }

    // decode access token
    const jwt: TJsonWebTokenDTO | undefined =
      AuthenticationHelperSingleton.decodeAccessToken(
        loginResponse.accessToken
      );

    // init isSuccess to true
    let isSuccess = true;

    // safety-checks
    if (!jwt || !AuthenticationHelperSingleton.isJWTValid(jwt)) {
      // set isSuccess to false
      isSuccess = false;
    }

    // if isSuccess true and jwt is set
    if (isSuccess && jwt) {
      // set Authorization header
      AxiosHelperSingleton.setCommonHeaderConfigDefaults(
        "Authorization",
        `Bearer ${loginResponse.accessToken}`
      );

      // reset the authentication with the plugin
      await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

      // update the auth context
      setAuth({
        ...auth,
        isTwoFactorRequired: false,
        userId:
          jwt[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ],
        userEmail:
          jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        roles:
          jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
        permissions: jwt.permissions,
        tenantId: jwt.tenantId,
        tenantName: jwt.tenantName,
      });

      if (sharedObjectURL) {
        navigate(sharedObjectURL);
      } else {
        navigate("/");
      }
    } else {
      // set the authentication state according to the login result
      setAuth({
        ...auth,
        isTwoFactorRequired: loginResponse.isTwoFactorRequired,
      });

      // safety-checks
      if (!loginResponse.isTwoFactorRequired) {
        setAuth({
          ...auth,
          isTwoFactorRequired: false,
        });

        // show an error message
        setErrorMessage(
          "Could not login with the given credentials. Please contact us."
        );
      } else {
        // reset the authentication with the plugin
        await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

        if (sharedObjectURL) {
          navigate(sharedObjectURL);
        } else {
          navigate("/");
        }
      }
    }
  };

  const processMagicLinkAsync = async (): Promise<void> => {
    // check if the magic link tokens are set
    if (!magicTokenFromParams || !emailFromParams) {
      // show an error message
      setErrorMessage(
        "Invalid magic link, the clicked magic link could be expired."
      );
      // stop execution, return
      return;
    }

    // get isRememberMeEnabled from params
    const isRememberMeEnabled =
      isRememberMeEnabledParams?.toLocaleUpperCase() === "TRUE";

    // try to log in using the magic link
    const loginResponse: TLoginResponseDTO | undefined =
      await AuthControllerSingleton.loginWithMagicLink(
        magicTokenFromParams,
        emailFromParams,
        isRememberMeEnabled
      );

    // safety-checks
    if (!loginResponse) {
      // show an error message
      setErrorMessage(
        "Invalid magic link, the clicked magic link could be expired."
      );
      // stop execution, return
      return;
    }

    // decode access token
    const jwt: TJsonWebTokenDTO | undefined =
      AuthenticationHelperSingleton.decodeAccessToken(
        loginResponse.accessToken
      );

    // init isSuccess to true
    let isSuccess = true;

    // safety-checks
    if (!jwt || !AuthenticationHelperSingleton.isJWTValid(jwt)) {
      // set isSuccess to false
      isSuccess = false;
    }

    // if isSuccess true and jwt is set
    if (isSuccess && jwt) {
      // set Authorization header
      AxiosHelperSingleton.setCommonHeaderConfigDefaults(
        "Authorization",
        `Bearer ${loginResponse.accessToken}`
      );

      // reset the authentication with the plugin
      await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

      // update the auth context
      setAuth({
        ...auth,
        isTwoFactorRequired: false,
        userId:
          jwt[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ],
        userEmail:
          jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        roles:
          jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
        permissions: jwt.permissions,
        tenantId: jwt.tenantId,
        tenantName: jwt.tenantName,
      });

      if (sharedObjectURL) {
        navigate(sharedObjectURL);
      } else {
        navigate("/");
      }
    } else {
      // set the authentication state according to the login result
      setAuth({
        ...auth,
        isTwoFactorRequired: loginResponse.isTwoFactorRequired,
      });

      // safety-checks
      if (!loginResponse.isTwoFactorRequired) {
        // show an error message
        setErrorMessage(
          "Invalid magic link, the clicked magic link could be expired."
        );

        setAuth({
          ...auth,
          isTwoFactorRequired: false,
        });
      } else {
        // reset the authentication with the plugin
        await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

        if (sharedObjectURL) {
          navigate(sharedObjectURL);
        } else {
          navigate("/");
        }
      }
    }
  };

  const getLoginRightSideContent = (): ReactNode => {
    // if login options are not set and magic link tokens are set
    if (!loginOptions && magicTokenFromParams && emailFromParams) {
      // return the magic link process
      return (
        <LoginWithMagicLink
          errorMessage={errorMessage}
          onRequestNewMagicLinkClickAsync={async () => {
            await requestMagicLinkAsync(true);
          }}
          onLoginClickAsync={processMagicLinkAsync}
        />
      );
    }

    // if magic link has been sent
    if (isMagicLinkSent) {
      // return the magic link message
      return <MagicLinkMessage email={email} />;
    }

    // otherwise, return the login options or the normal login process
    return (
      <form ref={formRef} onSubmit={onFormSubmit}>
        {!loginOptions ? (
          <LoginOptions
            email={email}
            onEmailInputChange={(
              changeEvent: ChangeEvent<HTMLInputElement>
            ) => {
              setEmail(changeEvent.target.value);
            }}
            isRememberMeChecked={auth.isRememberMeEnabled}
            onIsRememberMeCheckboxChange={(isChecked: boolean) => {
              setAuth((prevAuth) => {
                return { ...prevAuth, isRememberMeEnabled: isChecked };
              });
            }}
            onNextButtonClickAsync={onNextButtonClickAsync}
          />
        ) : (
          <div className={styles.loginWithEmailorPasswordContainer}>
            <AccountBox
              email={email}
              text={email}
              icon={faArrowLeft}
              extraClassname={styles.goBackButton}
              onIconClick={() => {
                setLoginOptions(undefined);
              }}
            />
            <LoginWithEmail requestMagicLinkAsync={requestMagicLinkAsync} />
            <LoginWithPassword
              password={password}
              onPasswordInputChange={(
                changeEvent: ChangeEvent<HTMLInputElement>
              ) => {
                setPassword(changeEvent.target.value);
              }}
              onForgotYourPasswordButtonClickAsync={async () => {
                await requestMagicLinkAsync();
              }}
              onLoginButtonClickAsync={onLoginButtonClickAsync}
            />
          </div>
        )}
      </form>
    );
  };

  // Render
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginLeftSide}>
        <LoginImageSlider />
      </div>
      <div className={styles.loginRightSide}>
        <div className={styles.findestUniverseLogoContainer}>
          <img
            className={styles.findestUniverseLogo}
            src={FindestUniverseLogo}
            alt="Findest Universe Logo"
          />
        </div>
        <div className={styles.loginRightSideContent}>
          {!isUnderMaintenance && getLoginRightSideContent()}
          <div className={styles.loginErrorMessage}>{errorMessage}</div>

          <div className={styles.maintenanceContainer}>
            <MaintenanceBox
              isUnderMaintenance={isUnderMaintenance}
              setIsUnderMaintenance={setIsUnderMaintenance}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
