// node_modules
import axios, { AxiosRequestConfig } from "axios";
// Enums
import { ObjectTypeEnum, SearchThroughEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";
// Types
import {
  TAxiosParams,
  TDocumentReferencesDTO,
  TIdNameTypeObjectType,
  TSearchbarResultDTO,
} from "Types";
// Constants
import { ApiVersionConstants } from "Constants";
// Interfaces
import { IEntityDTO, IStudyDTO } from "Interfaces";

export class SearchController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/search`;

  public async searchBarAsync(
    query: string,
    doSearchThroughInbox: boolean,
    doSearchThroughDocuments: boolean,
    doSearchThroughEntities: boolean,
    doSearchThroughStudies: boolean,
    doSearchThroughQueries: boolean,
    isFromGoogle: boolean,
    abortController?: AbortController
  ): Promise<TSearchbarResultDTO | null> {
    const searchTypeFilters: SearchThroughEnum[] = [];

    if (doSearchThroughInbox) searchTypeFilters.push(SearchThroughEnum.Inbox);
    if (doSearchThroughDocuments)
      searchTypeFilters.push(SearchThroughEnum.Documents);
    if (doSearchThroughEntities)
      searchTypeFilters.push(SearchThroughEnum.Entities);
    if (doSearchThroughStudies)
      searchTypeFilters.push(SearchThroughEnum.Studies);
    if (doSearchThroughQueries)
      searchTypeFilters.push(SearchThroughEnum.Queries);

    const url = isFromGoogle
      ? `${this._resourcePath}/aftersearchengine`
      : `${this._resourcePath}/searchbar`;
    const axiosParams: TAxiosParams = {
      query: query,
      "api-version": ApiVersionConstants.DefaultVersion,
    };

    // Build url by adding id array query param
    if (searchTypeFilters.length > 0) {
      axiosParams.searchTypeFilters = searchTypeFilters;
    }

    try {
      // init axios request config
      const axiosRequestConfig: AxiosRequestConfig = {
        params: axiosParams,
      };
      // if abortController is set
      if (abortController) {
        // append the signal to the axios request config
        axiosRequestConfig.signal = abortController.signal;
      }

      LogHelperSingleton.log("PerformUniverseSearch");

      const response = await axios.get<TSearchbarResultDTO>(
        url,
        axiosRequestConfig
      );

      if (response) {
        return response.data;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }

  public async searchEntitiesAsync(query: string): Promise<IEntityDTO[]> {
    try {
      const url = `${this._resourcePath}/entities`;
      const axiosParams: TAxiosParams = {
        query: query,
        "api-version": ApiVersionConstants.DefaultVersion,
      };

      LogHelperSingleton.log("PerformUniverseSearch");

      const response = await axios.get<IEntityDTO[]>(url, {
        params: axiosParams,
      });

      if (response) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async searchStudiesAsync(query: string): Promise<IStudyDTO[]> {
    try {
      const url = `${this._resourcePath}/studies`;
      const axiosParams: TAxiosParams = {
        query: query,
        "api-version": ApiVersionConstants.DefaultVersion,
      };

      LogHelperSingleton.log("PerformUniverseSearch");

      const response = await axios.get<IStudyDTO[]>(url, {
        params: axiosParams,
      });

      if (response) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async searchMultipleObjectsAsync(
    query: string,
    objectTypes: ObjectTypeEnum[],
    connectedToObjectId?: string,
    isCreatedByMe?: true,
    doOnlyGetConnectedToObjectId?: boolean,
    amountOfResults?: number,
    abortController?: AbortController,
    doIncludeCreatedBy?: boolean
  ): Promise<TIdNameTypeObjectType[]> {
    const url = `${this._resourcePath}/multiple`;
    const axiosParams: TAxiosParams = {
      query: query,
      "api-version": ApiVersionConstants.DefaultVersion,
    };

    // Build url by adding id array query param
    if (objectTypes.length > 0) {
      axiosParams.objectTypeFilters = objectTypes;
    }

    // if connectedToObjectId is set
    if (connectedToObjectId) {
      // append the query string parameter
      axiosParams.connectedToObjectId = connectedToObjectId;
    }
    // put doOnlyGetConnectedToObjectId in url if it is defined
    if (doOnlyGetConnectedToObjectId !== undefined) {
      axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
    }

    // Put doIncludeCreatedBy in url if it is defined
    if (doIncludeCreatedBy !== undefined) {
      axiosParams.doIncludeCreatedBy = doIncludeCreatedBy;
    }

    // if isCreatedByMe is true
    if (isCreatedByMe) {
      // append the query string parameter
      axiosParams.isCreatedByMe = isCreatedByMe;
    }

    // If amountOfResults is set
    if (amountOfResults) {
      // append the query string parameter
      axiosParams.amountOfResults = amountOfResults;
    }

    // init axios request config
    const axiosRequestConfig: AxiosRequestConfig = {
      params: axiosParams,
    };
    // if abortController is set
    if (abortController) {
      // append the signal to the axios request config
      axiosRequestConfig.signal = abortController.signal;
    }

    try {
      LogHelperSingleton.log("PerformUniverseSearch");

      const response = await axios.get<TIdNameTypeObjectType[]>(
        url,
        axiosRequestConfig
      );

      if (response) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async searchDocumentReferencesAsync(
    query: string,
    searchThroughEnum: SearchThroughEnum,
    connectedToObjectId?: string,
    isCreatedByMe?: true,
    doOnlyGetConnectedToObjectId?: boolean
  ): Promise<TDocumentReferencesDTO[]> {
    try {
      // init url
      const url = `${this._resourcePath}/references`;

      const axiosParams: TAxiosParams = {
        query: query,
        searchTypeFilters: [searchThroughEnum],
        "api-version": ApiVersionConstants.DefaultVersion,
      };

      // if connectedToObjectId is set
      if (connectedToObjectId) {
        // append the query string parameter
        axiosParams.connectedToObjectId = connectedToObjectId;
      }
      // if doOnlyGetConnectedToObjectId is set
      if (doOnlyGetConnectedToObjectId !== undefined) {
        // append the query string parameter
        axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
      }
      // if isCreatedByMe is true
      if (isCreatedByMe) {
        // append the query string parameter
        axiosParams.isCreatedByMe = isCreatedByMe;
      }

      LogHelperSingleton.log("PerformUniverseSearch");

      const response = await axios.get<TDocumentReferencesDTO[]>(url, {
        params: axiosParams,
      });

      if (response) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async searchHighlightDocumentReferencesAsync(
    query: string,
    connectedToObjectId?: string,
    isCreatedByMe?: true,
    doOnlyGetConnectedToObjectId?: boolean
  ): Promise<TDocumentReferencesDTO[]> {
    try {
      // init url
      const url = `${this._resourcePath}/references`;
      const axiosParams: TAxiosParams = {
        query: query,
        searchTypeFilters: [SearchThroughEnum.Highlights],
        "api-version": ApiVersionConstants.DefaultVersion,
      };

      // if connectedToObjectId is set
      if (connectedToObjectId) {
        // append the query string parameter
        axiosParams.connectedToObjectId = connectedToObjectId;
      }
      // if doOnlyGetConnectedToObjectId is set
      if (doOnlyGetConnectedToObjectId !== undefined) {
        // append the query string parameter
        axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
      }
      // if isCreatedByMe is true
      if (isCreatedByMe) {
        axiosParams.isCreatedByMe = isCreatedByMe;
      }

      LogHelperSingleton.log("PerformUniverseSearch");

      const response = await axios.get<TDocumentReferencesDTO[]>(url, {
        params: axiosParams,
      });

      if (response) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }
}

export const SearchControllerSingleton = new SearchController();
