/* eslint-disable react/no-array-index-key */
// node_modules
import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
// Enums
import { SearchQueryTypeEnum, ToastTypeEnum } from "Enums";
// Components
import { DeletableTextbox, FindestButton, FindestTextBox } from "Components";
// Styles
import styles from "./queryFilters.module.scss";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Controllers
import { QueryControllerSingleton } from "Controllers";
// Types
import { TQueryFiltersDTO } from "Types";

type TAffiliationsProps = {
  queryId: string;
  queryFilters: TQueryFiltersDTO;
  setQueryFilters: Dispatch<SetStateAction<TQueryFiltersDTO>>;
  searchQueryType: SearchQueryTypeEnum;
};

export const Affiliations: FC<TAffiliationsProps> = ({
  queryId,
  queryFilters,
  setQueryFilters,
  searchQueryType,
}: TAffiliationsProps) => {
  // State
  const [affiliationInputValue, setAffiliationInputValue] =
    useState<string>("");
  const [affiliations, setAffiliations] = useState<string[]>(
    queryFilters.scienceFilters?.affiliations ?? []
  );
  const [assignees, setAssignees] = useState<string[]>(
    queryFilters.patentFilters?.assignees ?? []
  );

  // Helper
  const mapArrayToArrayWithId = (array: string[]) =>
    array.map((value, index) => ({ id: index, value }));

  // Logic
  const onAddAffiliation = useCallback(async () => {
    // safety-checks
    if (!affiliationInputValue) {
      // do nothing
      return;
    }

    // prepare copy of new affiliations (or assignees)
    const newAffiliations: string[] = [];

    // if search query type is universe science articles
    if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
      // copy affiliations and add new affiliation
      newAffiliations.push(...affiliations, affiliationInputValue);

      // set query filters with new affiliations
      queryFilters.scienceFilters.affiliations = [...newAffiliations];

      // set affiliations state with new affiliations
      setAffiliations(newAffiliations);
    } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
      // otherwise if search query type is patents
      // copy assignees and add new assignee
      newAffiliations.push(...assignees, affiliationInputValue);

      // set query filters with new assignees
      queryFilters.patentFilters.assignees = [...newAffiliations];

      // set assignees state with new assignees
      setAssignees(newAffiliations);
    }

    // call back-end to update filters
    const isSuccess: boolean =
      await QueryControllerSingleton.updateFiltersAsync(queryId, queryFilters);

    // safety-checks
    if (!isSuccess) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not update filters."
      );
      // stop execution
      return;
    }

    // empty new affiliation input
    setAffiliationInputValue("");

    // set query filters state with new query filters
    setQueryFilters(queryFilters);
  }, [
    affiliationInputValue,
    searchQueryType,
    queryId,
    queryFilters,
    setQueryFilters,
    affiliations,
    assignees,
  ]);

  const onUpdateAffiliation = useCallback(
    async (indexOfAffiliationUpdated: number, newValue: string) => {
      // safety-checks
      if (!newValue) {
        // do nothing
        return;
      }

      // prepare copy of new affiliations (or assignees)
      let newAffiliations: string[] = [];

      // if search query type is universe science articles
      if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
        // copy affiliations
        newAffiliations = [...affiliations];

        // update affiliation
        newAffiliations[indexOfAffiliationUpdated] = newValue;

        // set query filters with new affiliations
        queryFilters.scienceFilters.affiliations = [...newAffiliations];

        // set affiliations state with new affiliations
        setAffiliations(newAffiliations);
      } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
        // otherwise if search query type is patents
        // copy assignees
        newAffiliations = [...assignees];

        // update assignee
        newAffiliations[indexOfAffiliationUpdated] = newValue;

        // set query filters with new assignees
        queryFilters.patentFilters.assignees = [...newAffiliations];

        // set assignees state with new assignees
        setAssignees(newAffiliations);
      }

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          queryId,
          queryFilters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // set query filters state with new query filters
      setQueryFilters(queryFilters);
    },
    [
      searchQueryType,
      queryId,
      queryFilters,
      setQueryFilters,
      affiliations,
      assignees,
    ]
  );

  const onDeleteAffiliation = useCallback(
    async (indexOfAffiliationDeleted: number) => {
      // prepare copy of new affiliations (or assignees)
      let newAffiliations: string[] = [];

      // if search query type is universe science articles
      if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
        // delete affiliation
        affiliations.splice(indexOfAffiliationDeleted, 1);
        newAffiliations = [...affiliations];

        // set query filters with new affiliations
        queryFilters.scienceFilters.affiliations = [...newAffiliations];

        // set affiliations state with new affiliations
        setAffiliations(newAffiliations);
      } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
        // otherwise if search query type is patents
        // delete assignee
        assignees.splice(indexOfAffiliationDeleted, 1);
        newAffiliations = [...assignees];

        // set query filters with new assignees
        queryFilters.patentFilters.assignees = [...newAffiliations];

        // set assignees state with new assignees
        setAssignees(newAffiliations);
      }

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          queryId,
          queryFilters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // set query filters state with new query filters
      setQueryFilters(queryFilters);
    },
    [
      searchQueryType,
      queryId,
      queryFilters,
      setQueryFilters,
      affiliations,
      assignees,
    ]
  );

  return (
    <div className={styles.filterListItem}>
      <div className={styles.filterListItemTitle}>
        <FontAwesomeIcon icon={faBuilding} />
        <h6>
          {searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles
            ? "Affiliations"
            : "Assignees"}
        </h6>
      </div>
      <div className={[styles.dataListItem, styles.addDataListItem].join(" ")}>
        <FindestTextBox
          placeholder={
            searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles
              ? "+ Affiliation"
              : "+ Assignee"
          }
          onChange={(text: string) => {
            setAffiliationInputValue(text);
          }}
          onEnter={onAddAffiliation}
          value={affiliationInputValue}
        />
        <FindestButton
          buttonType="secondary"
          title="Add"
          onClick={onAddAffiliation}
        />
      </div>
      <div className={styles.addedDataListItemContainer}>
        {searchQueryType === SearchQueryTypeEnum.UniversePatents ? (
          <div>
            {mapArrayToArrayWithId(assignees).map(
              ({ id, value }: { id: number; value: string }, index: number) => {
                return (
                  <DeletableTextbox
                    key={id}
                    text={value}
                    index={index}
                    onChange={onUpdateAffiliation}
                    onDelete={onDeleteAffiliation}
                  />
                );
              }
            )}
          </div>
        ) : null}
        {searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles ? (
          <div>
            {mapArrayToArrayWithId(affiliations).map(
              ({ id, value }: { id: number; value: string }, index: number) => {
                return (
                  <DeletableTextbox
                    key={id}
                    text={value}
                    index={index}
                    onChange={onUpdateAffiliation}
                    onDelete={onDeleteAffiliation}
                  />
                );
              }
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
