// React
import { FC, useMemo, useState } from "react";
// Components
import { PasswordSettings } from "./PasswordSettings/PasswordSettings";
import { SelectableTextList } from "Components/Shared";
// Styles
import styles from "../tabWithSubTabs.module.scss";
import { TwoFactorAuthenticationSettings } from "./TwoFactorAuthenticationSettings/TwoFactorAuthenticationSettings";

enum PersonalInformationTabsEnum {
  Password = "Password",
  ExtraSecurity = "Extra Security (2FA)",
}

export const PersonalInformation: FC = () => {
  // Memo needed for the tabs
  const possiblePersonalInformationTabs = useMemo(() => {
    return [
      PersonalInformationTabsEnum.Password,
      PersonalInformationTabsEnum.ExtraSecurity,
    ];
  }, []);

  const [activeTab, setActiveTab] = useState<PersonalInformationTabsEnum>(
    possiblePersonalInformationTabs[0]
  );

  const onTabChange = (tab: PersonalInformationTabsEnum) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.tabSettings}>
      <div className={styles.selectableTextListContainer}>
        <SelectableTextList<PersonalInformationTabsEnum>
          items={possiblePersonalInformationTabs.map((tab) => ({
            key: tab,
            keyAsString: tab,
            name: tab,
          }))}
          onItemSelect={(key) => {
            onTabChange(key);
          }}
          selectedItemKey={activeTab}
        />
      </div>
      <div className={styles.tabContent}>
        {activeTab === PersonalInformationTabsEnum.Password && (
          <PasswordSettings />
        )}
        {activeTab === PersonalInformationTabsEnum.ExtraSecurity && (
          <TwoFactorAuthenticationSettings />
        )}
      </div>
    </div>
  );
};
