// node_modules
import { Node } from "@tiptap/pm/model";
import { useCallback, useState } from "react";

export const useStoreNode = () => {
  const [node, setNode] = useState<Node | undefined>(undefined);
  const [nodePos, setNodePos] = useState<number | undefined>(undefined);

  const handleNodeChange = useCallback(
    (data: { node: Node | null; pos: number }) => {
      if (data.node) {
        setNode(data.node);
        setNodePos(data.pos);
      } else {
        setNode(undefined);
        setNodePos(undefined);
      }
    },
    [setNodePos, setNode]
  );

  return {
    node,
    nodePos,
    handleNodeChange,
  };
};
