// node_modules
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
import { useEditor } from "@tiptap/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  EditorContent,
  FindestButton,
  LoadingStatusIndicator,
  Modal,
} from "Components";
import { AskIgorLineChats } from "./AskIgorLineChats/AskIgorLineChats";
import { AskIgorMenu } from "./AskIgorMenu/AskIgorMenu";
// Styles
import styles from "./askIgorModal.module.scss";
// Enums
import {
  AskIgorMenuItemEnum,
  DocumentObjectTypeEnums,
  ObjectTypeEnum,
} from "Enums";
// Hooks
import { useAskIgor, useObserveScrolling } from "Hooks";
// Types
import {
  TAskIgorRequirement,
  TIdNameTypeObjectType,
  TLogEventName,
} from "Types";
// Constants
import { AiConstants } from "Constants";
// Providers
import {
  AskIgorMenuItemHelperSingleton,
  ExtensionsKit,
  GetCustomLink,
  INSERT_CONTENT_COMMAND,
  LogHelperSingleton,
  SET_CONTENT_COMMAND,
} from "Helpers";
// Interfaces
import { IAskIgorModalOptions } from "Interfaces";

interface IAskIgorModalProps {
  editor: Editor;
  object: TIdNameTypeObjectType;
  options: IAskIgorModalOptions;
  setOptions: Dispatch<SetStateAction<IAskIgorModalOptions>>;
}

export const AskIgorModal: FC<IAskIgorModalProps> = ({
  editor,
  object,
  options,
  setOptions,
}: IAskIgorModalProps) => {
  const [shouldAddBoxShadowToFooter, setShouldAddBoxShadowToFooter] =
    useState<boolean>(false);

  const bodyRef = useRef<HTMLDivElement>(null);
  const didTriggerWriteSection = useRef<boolean>(false);

  const navigate = useNavigate();
  const askIgorModalEditor = useEditor({
    extensions: [...ExtensionsKit, GetCustomLink(navigate)],
    editable: false,
  });
  const {
    isGeneratingText,
    aiGeneratedText,
    onSubmitHandlerAsync: askIgorOnSubmitHandlerAsync,
    onCancelCompletion,
    setAIGeneratedText,
    setIsGeneratingText,
  } = useAskIgor({
    askIgorMenuItem: options.selectedMenuItem,
    documentsSelected: options.documentsSelected,
  });
  const observeScrolling = useObserveScrolling({
    ref: bodyRef,
    doStartObserving: isGeneratingText,
  });

  const isAIGeneratedTextEmpty = useMemo(
    () => !aiGeneratedText,
    [aiGeneratedText]
  );
  const textToShowWhileGenerating = useMemo(
    () =>
      "I am generating the answer, it could take few minutes, please wait...",
    []
  );
  const doShowLoadingIndicator = useMemo(
    () => isGeneratingText && aiGeneratedText.length === 0,
    [isGeneratingText, aiGeneratedText]
  );
  const doShowText = useMemo(
    () => aiGeneratedText.length > 0,
    [aiGeneratedText.length]
  );
  const didStreamErrorHappen = useMemo(
    () => aiGeneratedText.includes(AiConstants.ERROR_START_MESSAGE),
    [aiGeneratedText]
  );
  const aiGeneratedTextWithoutError = useMemo(
    () => aiGeneratedText.replace(AiConstants.ERROR_START_MESSAGE, ""),
    [aiGeneratedText]
  );

  useEffect(() => {
    if (!askIgorModalEditor) return;

    if (doShowText) {
      if (didStreamErrorHappen) {
        SET_CONTENT_COMMAND.action(askIgorModalEditor, {
          content: aiGeneratedTextWithoutError,
        });
      } else {
        SET_CONTENT_COMMAND.action(askIgorModalEditor, {
          content: aiGeneratedText,
        });
      }
    }
  }, [
    aiGeneratedTextWithoutError,
    aiGeneratedText,
    askIgorModalEditor,
    didStreamErrorHappen,
    doShowText,
  ]);

  useEffect(() => {
    if (
      observeScrolling.distanceFromBottom <= 1 &&
      shouldAddBoxShadowToFooter
    ) {
      setShouldAddBoxShadowToFooter(false);
    } else if (
      observeScrolling.distanceFromBottom > 1 &&
      shouldAddBoxShadowToFooter === false
    ) {
      setShouldAddBoxShadowToFooter(true);
    }
  }, [observeScrolling.distanceFromBottom, shouldAddBoxShadowToFooter]);

  const onClose = (): void => {
    didTriggerWriteSection.current = false;

    resetAskIgor();

    setOptions({
      defaultInput: undefined,
      selectedMenuItem: undefined,
      isOpen: false,
      documentsSelected: options.documentsSelected,
    });

    setShouldAddBoxShadowToFooter(false);

    observeScrolling.disconnectObserver();
  };

  const resetAskIgor = useCallback(() => {
    onCancelCompletion();

    setAIGeneratedText("");

    setIsGeneratingText(false);
  }, [onCancelCompletion, setAIGeneratedText, setIsGeneratingText]);

  const onSelectedItemUpdateHandler = useCallback(
    (newSelectedItem: AskIgorMenuItemEnum): void => {
      didTriggerWriteSection.current = true;

      resetAskIgor();

      setShouldAddBoxShadowToFooter(false);

      setOptions({
        defaultInput: !options.selectedMenuItem ? options.defaultInput : "",
        selectedMenuItem: newSelectedItem,
        isOpen: true,
        documentsSelected: options.documentsSelected,
      });
    },
    [
      options.defaultInput,
      options.documentsSelected,
      options.selectedMenuItem,
      resetAskIgor,
      setOptions,
    ]
  );

  const updateSelectedMenuItem = useCallback(
    (newSelectedMenuItem: AskIgorMenuItemEnum) => {
      didTriggerWriteSection.current = true;

      setOptions({
        defaultInput: !options.selectedMenuItem ? options.defaultInput : "",
        selectedMenuItem: newSelectedMenuItem,
        isOpen: true,
        documentsSelected: options.documentsSelected,
      });
    },
    [
      options.defaultInput,
      options.documentsSelected,
      options.selectedMenuItem,
      setOptions,
    ]
  );

  const onSubmitHandlerAsync = async (
    text: string,
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number,
    requirements?: TAskIgorRequirement[]
  ): Promise<void> => {
    if (!object) return;

    setShouldAddBoxShadowToFooter(false);

    await askIgorOnSubmitHandlerAsync(
      object,
      text,
      documentTypes,
      dataPointsAmount,
      undefined,
      requirements
    );
  };

  const onAcceptClickHandler = (currentAIGeneratedText: string): void => {
    if (!currentAIGeneratedText || !options.selectedMenuItem) return;

    const insertResultLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedInsertResultLogEventName(
        options.selectedMenuItem
      );

    LogHelperSingleton.log(insertResultLogEventName);

    INSERT_CONTENT_COMMAND.action(editor, {
      content: askIgorModalEditor?.getJSON(),
    });

    onClose();
  };

  const onClearHandler = (): void => {
    setAIGeneratedText("");

    setShouldAddBoxShadowToFooter(false);
  };

  // TODO: find a better way to only trigger this once without using the hacky didTriggerWriteSection ref
  useEffect(() => {
    if (
      options.isOpen &&
      options.defaultInput &&
      options.selectedMenuItem === AskIgorMenuItemEnum.WriteSection &&
      !didTriggerWriteSection.current
    ) {
      didTriggerWriteSection.current = true;

      askIgorOnSubmitHandlerAsync(
        object,
        options.defaultInput,
        DocumentObjectTypeEnums,
        10,
        options.selectedMenuItem
      );
    }
  }, [
    askIgorOnSubmitHandlerAsync,
    isGeneratingText,
    object,
    options,
    options.defaultInput,
    options.isOpen,
    options.selectedMenuItem,
    setOptions,
  ]);

  return (
    <Modal
      isOpen={options.isOpen}
      extraClassNames={{
        container: styles.container,
        header: styles.header,
      }}
      onClose={onClose}
    >
      <div className={styles.leftSection}>
        <AskIgorMenu
          selectedItem={options.selectedMenuItem}
          onSelectedItemUpdate={onSelectedItemUpdateHandler}
        />
      </div>
      <div className={styles.rightSection}>
        <div
          className={styles.body}
          ref={bodyRef}
          onScroll={observeScrolling.onScroll}
        >
          <AskIgorLineChats
            object={object}
            selectedMenuItem={options.selectedMenuItem}
            isGeneratingText={isGeneratingText}
            defaultInput={options.defaultInput ?? ""}
            onSubmit={onSubmitHandlerAsync}
            updateSelectedMenuItem={updateSelectedMenuItem}
            documentsSelected={options.documentsSelected}
          />
          {doShowLoadingIndicator && (
            <div className={styles.loadingIndicatorContainer}>
              <LoadingStatusIndicator size={40} status={1} />
              {textToShowWhileGenerating &&
                textToShowWhileGenerating.trim().length > 0 && (
                  <p>{textToShowWhileGenerating}</p>
                )}
            </div>
          )}
          {doShowText && (
            <div className={styles.generatedContentContainer}>
              <div className={styles.generatedContentOwner}>
                <FontAwesomeIcon icon={faMessageBot} />
                <span>IGOR</span>
              </div>
              {didStreamErrorHappen ? (
                <div
                  className={`${styles.errorMessage} ${styles.generatedContent}`}
                >
                  <h3>Error</h3>
                  <EditorContent
                    className="compactEditorContent"
                    editor={askIgorModalEditor}
                  />
                </div>
              ) : (
                <EditorContent
                  editor={askIgorModalEditor}
                  className={styles.generatedContent}
                />
              )}
            </div>
          )}
        </div>
        {!didStreamErrorHappen &&
        ((!isGeneratingText && !isAIGeneratedTextEmpty) || isGeneratingText) ? (
          <div
            className={`${styles.footer} ${
              shouldAddBoxShadowToFooter ? styles.addBoxShadow : ""
            }`}
          >
            {!isGeneratingText && !isAIGeneratedTextEmpty && (
              <>
                <FindestButton
                  title="Insert"
                  onClick={() => {
                    onAcceptClickHandler(aiGeneratedText);
                  }}
                  extraClassName={styles.acceptButton}
                />
                <FindestButton
                  title="Clear"
                  buttonType="secondary"
                  onClick={onClearHandler}
                />
              </>
            )}
            {isGeneratingText && (
              <FindestButton
                buttonType="secondary"
                title="Cancel"
                onClick={() => {
                  onCancelCompletion();
                  setIsGeneratingText(false);
                }}
                extraClassName={styles.cancelButton}
              />
            )}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
