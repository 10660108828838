// node_modules
import { FC } from "react";
// Components
import { FindestButton, InformationBox } from "Components";
// Styles
import styles from "./recoveryCodesView.module.scss";
import { ExtraSecurityViewTypeEnum } from "Enums";

interface RecoveryCodesInformationBoxProps {
  recoveryCodes: string[];
  onAcknowledged(): void;
  extraSecurityViewType: ExtraSecurityViewTypeEnum;
}

// Create the functional component using TypeScript FC
export const RecoveryCodesView: FC<RecoveryCodesInformationBoxProps> = ({
  recoveryCodes,
  onAcknowledged,
  extraSecurityViewType,
}) => {
  const isResetView =
    extraSecurityViewType === ExtraSecurityViewTypeEnum.ResetRecoveryCodes;

  return (
    <div className={styles.recoveryCodesView}>
      <InformationBox type="success">
        <div className={styles.informationContent}>
          <h3>
            {isResetView
              ? "Your recovery codes have been reset"
              : "Your two-factor authentication enabled"}
          </h3>
          <p className={styles.informationDesc}>
            {isResetView
              ? "Remember to store your new recovery codes in a secure place. You can use these codes to access your account if you lose access to your two-factor authentication app."
              : "Remember to store your recovery codes in a secure place. You can use these codes to access your account if you lose access to your two-factor authentication app."}
          </p>
        </div>
        {recoveryCodes && recoveryCodes.length > 0 && (
          <div className={styles.recoveryCodesSection}>
            <h3 className={styles.recoveryCodesHeader}>Recovery Codes</h3>
            <ul>
              {recoveryCodes.map((code, index) => (
                <li key={index}>{code}</li>
              ))}
            </ul>
          </div>
        )}
      </InformationBox>
      <div className={styles.acknowledgedSection}>
        <FindestButton onClick={onAcknowledged} title="Acknowledge" />
      </div>
    </div>
  );
};
