// node_modules
import { FC, ReactNode } from "react";
// Styles
import styles from "./headings.module.scss";

type THeading4Props = {
  children: ReactNode,
}

export const Heading4: FC<THeading4Props> = ({ children }: THeading4Props) => {
  return (
    <h4 className={styles.heading4}>{children}</h4>
  );
};
