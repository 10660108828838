/* eslint-disable react/no-danger */
// node_modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Editor } from "@tiptap/core";
import { FC, useState } from "react";
// Interfaces
import { IActionOptions, ICommand } from "Interfaces";
// Styles
import styles from "../editorMenu.module.scss";
// Components
import { Tooltip } from "Components";
// Custom hooks
import { useTooltipDelay } from "Hooks";

interface IEditorMenuCommandProps {
  editor: Editor;
  command: ICommand;
  onClickHandler?: (command: ICommand) => void;
  onClickCallback?: (command: ICommand) => void;
  doNotShowLabel?: boolean;
  doShowTooltip: boolean;
  actionOptions?: IActionOptions;
  extraClassNames?: {
    editorMenuCommand?: string;
  };
  selectedCommand?: ICommand;
}

export const EditorMenuCommand: FC<IEditorMenuCommandProps> = ({
  editor,
  command,
  onClickHandler,
  onClickCallback,
  doNotShowLabel,
  doShowTooltip,
  actionOptions,
  extraClassNames = {},
  selectedCommand,
}: IEditorMenuCommandProps) => {
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const { isTooltipShown, handleMouseEnter, handleMouseLeave } = useTooltipDelay(1000);

  const getCommandClassName = () => {
    const className = [];
    className.push(styles.editorMenuCommand);
    className.push(styles[command.name]);
    if (extraClassNames.editorMenuCommand) {
      className.push(extraClassNames.editorMenuCommand);
    }
    if (command.isActive(editor)) className.push(styles.isActive);

    if (command.styleOptions) {
      const styleOptions: { [key: string]: string } = command.styleOptions;
      Object.keys(styleOptions).forEach((key) => {
        className.push(styles[styleOptions[key]]);
      });
    }

    if (command.name === selectedCommand?.name) {
      className.push(styles.selected);
    }

    return className.join(" ");
  };

  return (
    <button
      ref={setButtonRef}
      type="button"
      onMouseEnter={doShowTooltip ? handleMouseEnter : undefined}
      onMouseLeave={doShowTooltip ? handleMouseLeave : undefined}
      onClick={() => {
        if (onClickHandler) {
          onClickHandler(command);
        } else {
          command.action(editor, actionOptions);
          if (onClickCallback) onClickCallback(command);
        }
      }}
      className={getCommandClassName()}
    >
      {command.customIconAsHtml && (
        <div
          className={styles.customIconAsHtmlDiv}
          dangerouslySetInnerHTML={{ __html: command.customIconAsHtml }}
        />
      )}
      {command.icon && (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={command.icon} />
        </div>
      )}
      {!doNotShowLabel && (
        <>
          {command.label}
          {((!command.isActive(editor) && command.shortcut) ||
            command.isActive(editor)) && (
            <div className={styles.rightSide}>
              {!command.isActive(editor) && command.shortcut && (
                <span className={styles.shortcut}>{command.shortcut}</span>
              )}
              {command.isActive(editor) && (
                <FontAwesomeIcon className={styles.checkmark} icon={faCheck} />
              )}
            </div>
          )}
        </>
      )}

      {doShowTooltip && (
        <Tooltip
          referenceEl={buttonRef}
          isOpen={isTooltipShown}
          tooltipText={command.description}
        />
      )}
    </button>
  );
};
