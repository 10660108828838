// node_modules
import { Placement } from "@floating-ui/react-dom";
import { FC } from "react";
// Components
import { Popover, Ratings } from "Components";
// Types
import { TRatingsPopoverProps } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";
// Styles
import ratingPopoverStyles from "./ratingsPopover.module.scss";

// Props type
type TRatingsPopoverExtraProps = TRatingsPopoverProps & {
  forSourceId?: string;
  forSourceType?: ObjectTypeEnum;
  hideRatingsPopover: () => void;
  onNewAverageRating: (
    forSourceId: string,
    forTargetId: string,
    newCount: number,
    newScore: number,
    newIsRatingNeeded: boolean
  ) => void;
  firstPopoverRef?: HTMLDivElement | null;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  popoverPlacement?: Placement;
  popoverOffset?: number;
};

// Component
export const RatingsPopover: FC<TRatingsPopoverExtraProps> = ({
  isOpen,
  forSourceId,
  forSourceType,
  forTargetId,
  styles,
  currentUserEmail,
  hideRatingsPopover,
  onNewAverageRating,
  firstPopoverRef,
  onMouseEnter,
  onMouseLeave,
  usePopoverComponent = true,
  popoverPlacement = "left-start",
  popoverOffset = 0,
}: TRatingsPopoverExtraProps) => {
  // Render
  return usePopoverComponent ? (
    <Popover
      extraClassName={ratingPopoverStyles.ratingsPopoverContainer}
      placement={popoverPlacement}
      popoverOffset={popoverOffset}
      showInPortal
      referenceEl={firstPopoverRef ?? null}
      isOpen={isOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Ratings
        isOpen={isOpen}
        hideRatingsPopover={hideRatingsPopover}
        forSourceId={forSourceId}
        forSourceType={forSourceType}
        isInPopover
        onNewAverageRating={onNewAverageRating}
        currentUserEmail={currentUserEmail}
        forTargetId={forTargetId}
        styles={styles}
      />
    </Popover>
  ) : (
    <div>
      <Ratings
        isOpen={isOpen}
        hideRatingsPopover={hideRatingsPopover}
        forSourceId={forSourceId}
        forSourceType={forSourceType}
        onNewAverageRating={onNewAverageRating}
        currentUserEmail={currentUserEmail}
        forTargetId={forTargetId}
        styles={styles}
      />
    </div>
  );
};
