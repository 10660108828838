// node_modules
import { FC, useEffect, useRef, useState } from "react";
// Types
import { TAskIgorRequirement, TIdNameTypeObjectType } from "Types";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Components
import { Checkbox, FindestTextBox, SuggestingTextbox } from "Components";
// Helpers
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Controllers
import {
  ActivityControllerSingleton,
  RequirementsTableControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Styles
import styles from "./askIgorRequirementsLineChat.module.scss";

// Component props type
type AskIgorRequirementsLineChatProps = {
  object: TIdNameTypeObjectType;
  onRequirementsChange: (requirements: TAskIgorRequirement[]) => void;
};

// Component
export const AskIgorRequirementsLineChat: FC<AskIgorRequirementsLineChatProps> =
  ({ object, onRequirementsChange }: AskIgorRequirementsLineChatProps) => {
    // State
    const [requirements, setRequirements] = useState<TAskIgorRequirement[]>([]);
    const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([
      object,
    ]);
    const [selectedLinkToObject, setSelectedLinkToObject] =
      useState<TIdNameTypeObjectType | undefined>(object);
    const [isRecentActivityVisible, setIsRecentActivityVisible] =
      useState<boolean>(false);

    // Ref
    const currentObjectNameRef = useRef<string>("");

    // Logic
    // when requirements or onRequirementsChange change
    useEffect(() => {
      // call on requirements change
      onRequirementsChange(requirements);
    }, [onRequirementsChange, requirements]);

    // when selectedLinkToObject or onRequirementsChange change
    useEffect(() => {
      (async () => {
        // safety-checks
        if (!selectedLinkToObject) {
          // stop execution, return
          return;
        }

        // extract requirements from object description
        const extractedRequirements: string[] =
          await RequirementsTableControllerSingleton.extractAsync(
            selectedLinkToObject.id,
            selectedLinkToObject.objectType
          );

        // build new requirements
        const newRequirements = extractedRequirements.map(
          (requirement: string) => {
            // create new requirement
            const newRequirement: TAskIgorRequirement = {
              id: window.crypto.randomUUID(),
              text: requirement,
              isChecked: true,
            };

            // return new requirements
            return newRequirement;
          }
        );

        // set new requirements
        setRequirements([...newRequirements]);
      })();
    }, [selectedLinkToObject, onRequirementsChange]);

    const retrieveRecentActivityAsync = async () => {
      // retrieve recent activity
      const recentActivity: TIdNameTypeObjectType[] =
        await ActivityControllerSingleton.getMySimpleActivityAsync();

      // safety-checks
      if (!recentActivity) {
        // set suggestions to empty array
        setSuggestions([]);
        // stop execution, return
        return;
      }

      // set suggestions to recent activity
      setSuggestions(recentActivity);
    };

    const runSuggestionsSearchAsync = async (
      suggestionValue: string,
      currentSearchTypes: ObjectTypeEnum[]
    ): Promise<void> => {
      // set is recent activity visible to false
      setIsRecentActivityVisible(false);

      // set current object name ref to suggestion value
      currentObjectNameRef.current = suggestionValue;

      // safety-checks
      if (!suggestionValue) {
        // show recent activity results when suggestionValue is empty
        retrieveRecentActivityAsync();
        setIsRecentActivityVisible(true);
        // stop execution, return
        return;
      }

      // search for suggestions
      const foundSuggestions: TIdNameTypeObjectType[] =
        await SearchControllerSingleton.searchMultipleObjectsAsync(
          suggestionValue,
          currentSearchTypes
        );

      // safety-checks
      if (!foundSuggestions) {
        // reset suggestions state variable
        setSuggestions([]);
        // stop execution, return
        return;
      }

      // set suggestions to found suggestions
      setSuggestions(foundSuggestions);
    };

    const onEnter = (text: string, inputRef: HTMLInputElement) => {
      // trim text
      text = text.trim();

      // check if text already in requirements
      const requirement: TAskIgorRequirement | undefined = requirements.find(
        (r) => r.text === text
      );
      if (requirement) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Detail is already in the list."
        );
        // stop execution, return
        return;
      }

      // set requirements
      setRequirements((prevRequirements) => {
        // create new requirement
        const newRequirement: TAskIgorRequirement = {
          id: window.crypto.randomUUID(),
          text: text,
          isChecked: true,
        };

        // return new requirements
        return [...prevRequirements, newRequirement];
      });

      // clear input
      inputRef.value = "";
    };

    const onChange = (requirementId: string, text: string) => {
      // trim text
      text = text.trim();

      // check if text already in requirements
      const existingRequirement: TAskIgorRequirement | undefined =
        requirements.find((r) => r.text === text && r.id !== requirementId);
      if (existingRequirement) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Detail is already in the list."
        );
      }

      // set requirements
      setRequirements((prevRequirements) => {
        // find requirement
        const requirement = prevRequirements.find(
          (currenRequirement: TAskIgorRequirement) =>
            currenRequirement.id === requirementId
        );

        // check if requirement exists
        if (requirement) {
          // set requirement text
          requirement.text = text;
        }

        // return new requirements
        return [...prevRequirements];
      });
    };

    const onCheckboxChange = (requirementId: string, isChecked: boolean) => {
      // set requirements
      setRequirements((prevRequirements) => {
        // find requirement
        const requirement = prevRequirements.find(
          (currenRequirement: TAskIgorRequirement) =>
            currenRequirement.id === requirementId
        );

        // check if requirement exists
        if (requirement) {
          // set requirement text
          requirement.isChecked = isChecked;
        }

        // return new requirements
        return [...prevRequirements];
      });
    };

    const onDelete = (requirementId: string) => {
      // set requirements
      setRequirements((prevRequirements) => {
        // find requirement
        const requirementIndex = prevRequirements.findIndex(
          (currenRequirement: TAskIgorRequirement) =>
            currenRequirement.id === requirementId
        );

        // check if requirement exists
        if (requirementIndex >= 0) {
          // remove requirement
          prevRequirements.splice(requirementIndex, 1);
        }

        // return new requirements
        return [...prevRequirements];
      });
    };

    // Render
    return (
      <div className={styles.askIgorRequirementsLineChat}>
        <div className={styles.existingObjectSuggestions}>
          <h6>Object</h6>
          <SuggestingTextbox
            forcedSuggestionValue={object.name}
            placeholder="Search for entity or study"
            title={
              isRecentActivityVisible
                ? "Recently updated objects"
                : "Universe suggestions"
            }
            onValueChangeHandler={(value) =>
              (currentObjectNameRef.current = value)
            }
            refreshSuggestionsAsync={async (newValue: string) => {
              runSuggestionsSearchAsync(newValue, [
                ObjectTypeEnum.Entity,
                ObjectTypeEnum.Study,
              ]);
            }}
            suggestions={suggestions}
            handleSuggestionClick={(suggestion: TIdNameTypeObjectType) => {
              setSelectedLinkToObject(suggestion);
            }}
            selectedOption={selectedLinkToObject}
            setSelectedOption={setSelectedLinkToObject}
          />
        </div>
        {requirements.map((requirement: TAskIgorRequirement) => {
          return (
            <div className={styles.requirementLine} key={requirement.id}>
              <Checkbox
                isChecked={requirement.isChecked}
                onCheckboxChange={(isChecked: boolean) => {
                  onCheckboxChange(requirement.id, isChecked);
                }}
              />
              <FindestTextBox
                isReadonly={!requirement.isChecked}
                extraClassName={styles.existingDetailInput}
                value={requirement.text}
                onChange={(text: string) => {
                  onChange(requirement.id, text);
                }}
              />
              <div
                className={styles.deleteContainer}
                onClick={() => onDelete(requirement.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>
          );
        })}
        <FindestTextBox
          extraClassName={styles.addDetailInput}
          placeholder="Add detail here"
          onEnter={onEnter}
        />
      </div>
    );
  };
