// node_modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useContext, useRef, useState } from "react";
// Components
import { RatingProgressPopover } from "Components";
// Providers
import { EditorContext } from "Providers";
// Constants
import { EditorConstants } from "Constants";
// Interfaces
import { ICollapsibleListItem } from "Interfaces";
// Styles
import styles from "./tableOfContentsItem.module.scss";

type TTableOfContentsItemProps = {
  item: ICollapsibleListItem;
  children?: ReactNode;
};

export const TableOfContentsItem = ({
  item,
  children,
}: TTableOfContentsItemProps) => {
  const { editor } = useContext(EditorContext);

  const [isRatingsPopoverOpen, setIsRatingsPopoverOpen] = useState(false);

  const isResultsOverviewTableHeader: boolean = item.title
    .toLowerCase()
    .includes(EditorConstants.OVERVIEW_TABLE_HEADING_TEXT.toLowerCase());

  const hasChildren = item.children && item.children.length > 0;

  const ratingProgressPopoverContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`${styles.tableOfContentsItem} ${
        !hasChildren ? styles.hasNoChildren : ""
      }`}
      ref={ratingProgressPopoverContainerRef}
      onMouseEnter={
        isResultsOverviewTableHeader
          ? () => setIsRatingsPopoverOpen(true)
          : undefined
      }
      onMouseLeave={
        isResultsOverviewTableHeader
          ? () => setIsRatingsPopoverOpen(false)
          : undefined
      }
    >
      <div className={styles.leftContent}>
        {children}
        {item.title}
      </div>
      {item.rightIcon && (
        <FontAwesomeIcon
          className={`${styles.rightIcon} ${item.rightIcon.className ?? ""}`}
          icon={item.rightIcon.icon}
        />
      )}
      {editor && (
        <RatingProgressPopover
          editor={editor}
          isOpen={isRatingsPopoverOpen && isResultsOverviewTableHeader}
          referenceElement={ratingProgressPopoverContainerRef.current}
        />
      )}
    </div>
  );
};
