// Types
import { TImageDTO } from "Types";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Controllers
import { ImageControllerSingleton } from "Controllers";
// Helpers
import {
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";

export class ImageHelper {
  public async addImageToObjectAsync(
    image: File | Blob,
    objectId: string,
    objectType: ObjectTypeEnum,
    caption?: string
  ): Promise<TImageDTO | undefined> {
    LogHelperSingleton.log("AddImage");

    const newImage: TImageDTO | undefined =
      await ImageControllerSingleton.addImageToObjectAsync(
        image,
        objectId,
        objectType,
        caption
      );

    if (!newImage) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Could not add image to ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          objectType
        ).toLowerCase()}.`
      );
    }

    return newImage;
  }
}

export const ImageHelperSingleton = new ImageHelper();
