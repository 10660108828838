// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { ObjectTypeEnum, PubSubEventTypeEnum } from "Enums";

export const useObjectDeletedListener = (onObjectDeleted: (id: string, type: ObjectTypeEnum) => void) => {
    const { pubSubHandler } = useContext(PubSubContext);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.ObjectDeleted, onObjectDeleted);

        return () => {
            pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.ObjectDeleted, onObjectDeleted);
        };
    }, [onObjectDeleted, pubSubHandler]);
};