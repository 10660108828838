// React
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { FC, useEffect, useMemo, useRef, useState } from "react";
// Components
import {
  FileInputButton,
  FindestButton,
  FindestTextBox,
  LoadingStatusIndicator,
} from "Components";
// Constants
import { FeatureToggleConstants } from "Constants";
// Controllers
import { DocumentControllerSingleton } from "Controllers";
// Enums
import { LoadingStatusEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  DocumentTypeHelperSingleton,
  FileHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Types
import { TDocumentDTO } from "Types";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";
// Styles
import styles from "./addDocument.module.scss";

type TAddDocumentProps = {
  onCreationDone: (addedSavedDocument: ISavedDocumentDTO) => void;
  onCancel?: () => void;
};

export const AddDocument: FC<TAddDocumentProps> = ({
  onCreationDone,
  onCancel,
}: TAddDocumentProps) => {
  // State
  const [textualInputValidationStatus, setTextualInputValidationStatus] =
    useState<LoadingStatusEnum>(LoadingStatusEnum.NotStarted);
  const [foundDocument, setFoundDocument] =
    useState<TDocumentDTO | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const fileUrlRef = useRef<string>("");

  // Memos
  const isAddButtonEnabled = useMemo(() => {
    return (
      !!selectedFile || textualInputValidationStatus === LoadingStatusEnum.Done
    );
  }, [selectedFile, textualInputValidationStatus]);

  const selectedFileExtension = useMemo(() => {
    if (!selectedFile) return "";
    const splitted = selectedFile.name.split(".");
    return splitted[splitted.length - 1];
  }, [selectedFile]);

  // at component mount
  useEffect(() => {
    // log
    LogHelperSingleton.log("StartAddingDocument");
  }, []);

  // Handle the change of the file input
  const onFileInputChangedAsync = async (file: File): Promise<void> => {
    setSelectedFile(file);
    fileUrlRef.current = URL.createObjectURL(file);
  };

  // Remove the currently selected file
  const removeSelectedFile = () => {
    setSelectedFile(undefined);
    fileUrlRef.current = "";
  };

  // Handle the change of the textbox input
  const onTextualInputChanged = debounce(async (value: string) => {
    setFoundDocument(undefined);

    if (!value || value.trim().length === 0) {
      setTextualInputValidationStatus(LoadingStatusEnum.NotStarted);
      return;
    }

    setTextualInputValidationStatus(LoadingStatusEnum.Loading);
    const foundDocument =
      await DocumentControllerSingleton.validateAddDocumentAsync(value);
    if (!foundDocument) {
      setTextualInputValidationStatus(LoadingStatusEnum.Failed);
      return;
    }

    setTextualInputValidationStatus(LoadingStatusEnum.Done);
    setFoundDocument(foundDocument);
  }, 100);

  // Handle the click on the add document button
  const onAddDocumentClick = async () => {
    if (!isAddButtonEnabled) return;
    if (!foundDocument && !selectedFile) return;

    if (foundDocument) {
      const savedDocument = await DocumentControllerSingleton.createAsync(
        foundDocument
      );

      if (!savedDocument) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not save the document."
        );
        return;
      }

      onCreationDone(savedDocument);
    }
  };

  const customRightIcon = () => (
    <div className={styles.customInputRightIcon}>
      <LoadingStatusIndicator size={20} status={textualInputValidationStatus} />
    </div>
  );

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.title}>DOI, patent number or URL</div>
        <div>
          <FindestTextBox
            placeholder="DOI, patent number, URL"
            onChange={onTextualInputChanged}
            rightIconProps={{
              customIcon: customRightIcon(),
            }}
          />
        </div>

        {foundDocument ? (
          <div className={styles.foundDocument}>
            <div>
              <span>
                Found a{" "}
                {DocumentTypeHelperSingleton.getSavedDocumentTypeDisplayName(
                  foundDocument.type
                )}{" "}
                document with the following title:
              </span>
            </div>
            <div className={styles.webpageTitle}>
              <span>&ldquo;{foundDocument.webpageTitle}&rdquo;</span>
            </div>
          </div>
        ) : null}
      </div>
      {FeatureToggleConstants.CreateDocumentFromFile && (
        <div className={styles.orContainer}>
          <span>OR</span>
        </div>
      )}
      {FeatureToggleConstants.CreateDocumentFromFile && (
        <div className={styles.section}>
          <div className={styles.title}>Upload file</div>
          <FileInputButton
            fileSizeLimit={25_000_000}
            onFileSelectedAsync={onFileInputChangedAsync}
          />

          {selectedFile ? (
            <div className={styles.selectedFileContainer}>
              <FontAwesomeIcon
                className={styles.attachFileIcon}
                icon={FileHelperSingleton.getIconFromExtension(
                  selectedFileExtension
                )}
              />
              <span>{selectedFile.name}</span>
              <FontAwesomeIcon
                className={styles.trash}
                icon={faTrash}
                onClick={removeSelectedFile}
              />
            </div>
          ) : null}
        </div>
      )}
      <div className={styles.footer}>
        <FindestButton
          title="Add document"
          onClick={onAddDocumentClick}
          isDisabled={!isAddButtonEnabled}
          extraClassName={styles.addButton}
        />
        {onCancel && (
          <FindestButton
            title="Cancel"
            onClick={onCancel}
            extraClassName={styles.cancelButton}
            buttonType="cancel"
          />
        )}
      </div>
    </div>
  );
};
