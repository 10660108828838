// React
import { LogHelperSingleton } from "Helpers";
import { useMemo, useState } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useCheckboxedList = <T extends { id: string }>(
  items: any,
  singleLogName: string,
  pluralLogName: string,
  mapFunction: (item: T) => TIdNameTypeObjectType
) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const onSelectAllItems = (isChecked: boolean, items: T[]): void => {
    let newSelectedItems = [...selectedItems];

    if (isChecked) {
      newSelectedItems = items.filter(
        (document) =>
          !selectedItems.some(
            (savedDocument) => savedDocument.id === document.id
          )
      );
      setSelectedItems([...selectedItems, ...newSelectedItems]);

      // log
      LogHelperSingleton.log(`SelectAll${pluralLogName}`);
    } else {
      newSelectedItems = newSelectedItems.filter(
        (savedDocument) =>
          !items.some((selected) => selected.id === savedDocument.id)
      );

      setSelectedItems(newSelectedItems);

      // log
      LogHelperSingleton.log(`UnselectAll${pluralLogName}`);
    }
  };

  const onSelectItem = (
    isChecked: boolean,
    currentItem: T,
    id: string
  ): void => {
    let newSelectedItems = [...selectedItems];

    if (isChecked) {
      newSelectedItems.push(mapFunction(currentItem));
      // log
      LogHelperSingleton.log(`Select${singleLogName}`);
    } else {
      newSelectedItems = newSelectedItems.filter(
        (selectedItem) => selectedItem.id !== id
      );
      // log
      LogHelperSingleton.log(`Unselect${singleLogName}`);
    }
    setSelectedItems(newSelectedItems);
  };

  // Const
  const areAllItemsSelected = useMemo(() => {
    return (
      items.length &&
      items.every((element: { id: string }) =>
        selectedItems.some((selected) => selected.id === element.id)
      )
    );
  }, [selectedItems, items]);

  const isAnyItemSelected = useMemo(() => {
    const someMatch = items.some((element: { id: string }) =>
      selectedItems.find((selected) => selected.id === element.id)
    );
    return someMatch && !areAllItemsSelected;
  }, [items, areAllItemsSelected, selectedItems]);

  return {
    selectedItems,
    setSelectedItems,
    areAllItemsSelected,
    isAnyItemSelected,
    onSelectAllItems,
    onSelectItem,
  };
};
